/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { colors } from "../../../constants/colors";
import {
  AddressesIcon,
  ArrowDown,
  ArrowUp,
  CitiesIcon,
  MLSIcon,
  PostalIcon,
} from "../../../constants/icons";
import { toObjFilterConverter } from "../../../constants/methods";
import { getAllHouseCardsAction } from "../../../store/actions/getAllHouseCards";
import ClickOutside from "../../clickOutside/clickoutSideComponent";
import Spiner from "../../spiner/spiner";
import "./baseSearchInputList.css";

const BaseSearchInputList = ({
  name,
  className,
  onClick,
  list,
  placeholder,
  colorEndIcon = colors.mainBg,
  height,
  listTop = "54px",
  padding,
  setItem,
  item,
  setFound,
  found,
  parrentValue,
  parrentName,
  field = null,
  callback,
  isLoading = false,
  tempOrder = null,
  filters,
  searchApplyHandler,
  autocomplete,
  ...props
}) => {
  useEffect(() => {
    setFound(list);
  }, [list]);

  const [value, setValue] = useState("");

  const filter = (e) => {
    const keyword = e.target.value;
    setValue(e.target.value);
    if (keyword !== "") {
      setItem(e.target.value);
      callback(e.target.value);
    } else setFound([]);
    setItem(keyword);
  };

  const [isVisible, setIsVisible] = useState(false);

  const inputHandler = () => {
    setIsVisible(true);
  };

  const clickOutsideHandler = () => {
    setIsVisible(false);
  };

  const onIconItemMLS = (item) => {
    setItem(item);
    setIsVisible(false);
    searchApplyHandler(item, "mls");
  };

  const onIconItemPostal = (item) => {
    setItem(item);
    setIsVisible(false);
    searchApplyHandler(item, "postal");
  };

  const onIconItemCity = (item) => {
    setItem(item);
    setIsVisible(false);
    searchApplyHandler(item, "cities");
  };

  const onIconItemAddress = (item) => {
    setItem(item);
    setIsVisible(false);
    searchApplyHandler(item, "address");
  };

  return (
    <div
      className="filters-page-search-active-input-list-wrapper"
      onClick={() => {
        if (onClick) onClick();
        inputHandler();
      }}
    >
      <div className="filters-page-search-active-input-list-end-icon-wrapper">
        <ArrowUp
          color={colorEndIcon}
          style={{ display: !isVisible ? "none" : "block" }}
        />
      </div>
      <ClickOutside onClick={clickOutsideHandler} style={{ height: height }}>
        <div className="filters-page-search-active-input-list-end-icon-wrapper">
          <ArrowDown
            color={colorEndIcon}
            style={{ display: isVisible ? "none" : "block" }}
          />
        </div>
        <input
          readOnly={false}
          name={name}
          type="text"
          placeholder={placeholder}
          className={className}
          style={{
            width: "100%",
            height: { height },
            borderBottomRightRadius: isVisible ? 0 : 10,
            borderBottomLeftRadius: isVisible ? 0 : 10,
            padding: padding ? padding : "19px 24px",
          }}
          value={item === null ? "" : item[field] ? item[field] : item}
          onChange={filter}
          autoComplete={autocomplete ? autocomplete : null}
        />
      </ClickOutside>
      <ul
        style={{
          display:
            isVisible && (value !== "" || !found.length) ? "block" : "none",
          top: listTop,
          // border: found.length ? "1px solid #908F8F" : "none",
          border:
            isLoading === true
              ? "1px solid #908F8F"
              : found.length
              ? "1px solid #908F8F"
              : "none",
        }}
      >
        {isLoading === false && (value !== "" || !found.length) && (
          <li className="title">
            <MLSIcon />
            <p>MLS #</p>
          </li>
        )}
        {isLoading === false &&
          value !== "" &&
          found?.mlsnum?.map((item, i) => {
            if (field) {
              return (
                <li
                  className="content"
                  key={i}
                  onClick={() => onIconItemMLS(item)}
                >
                  {item[field]}
                </li>
              );
            } else {
              return (
                <li
                  className="content"
                  key={i}
                  onClick={() => onIconItemMLS(item)}
                >
                  {item}
                </li>
              );
            }
          })}

        {isLoading === false &&
          ((value !== "" && found?.mlsnum?.length === 0) ||
            found?.length === 0) && <li className="noResult">No Results</li>}
        {isLoading === true && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
            }}
          >
            <Spiner />
          </div>
        )}
        {/* {isLoading === false && (value !== "" || !found.length) && (
          <li className="title">
            <PostalIcon />
            <p>Postal</p>
          </li>
        )}
        {isLoading === false &&
          value !== "" &&
          found?.postal?.map((item, i) => {
            if (field) {
              return (
                <li
                  className="content"
                  key={i}
                  onClick={() => onIconItemPostal(item)}
                >
                  {item[field]}
                </li>
              );
            } else {
              return (
                <li
                  className="content"
                  key={i}
                  onClick={() => onIconItemPostal(item)}
                >
                  {item}
                </li>
              );
            }
          })}

        {isLoading === false &&
          ((value !== "" && found?.postal?.length === 0) ||
            found?.length === 0) && <li className="noResult">No Results</li>} */}

        {/* {isLoading === false && (value !== "" || !found.length) && (
          <li className="title">
            <CitiesIcon />
            <p>Cities</p>
          </li>
        )} */}
        {/* {isLoading === false &&
          value !== "" &&
          found?.city?.map((item, i) => {
            if (field) {
              return (
                <li
                  className="content"
                  key={i}
                  onClick={() => onIconItemCity(item)}
                >
                  {item[field]}
                </li>
              );
            } else {
              return (
                <li
                  className="content"
                  key={i}
                  onClick={() => onIconItemCity(item)}
                >
                  {item}
                </li>
              );
            }
          })}
        {isLoading === false &&
          ((value !== "" && found?.city?.length === 0) ||
            found?.length === 0) && <li className="noResult">No Results</li>} */}
        {/* found
            .filter((v) => v !== item) */}
        {isLoading === false && (value !== "" || !found.length) && (
          <li className="title">
            <AddressesIcon />
            <p>Address</p>
          </li>
        )}
        {isLoading === false &&
          value !== "" &&
          found?.address?.map((item, i) => {
            if (field) {
              return (
                <li
                  className="content"
                  key={i}
                  // onClick={() => onIconItemAddress(item)}
                >
                  <a href={`${item?.subdomain}${item?.url}`}>{item[field]}</a>
                </li>
              );
            } else {
              return (
                <li
                  className="content"
                  key={i}
                  onClick={() => onIconItemAddress(item)}
                >
                  {item}
                </li>
              );
            }
          })}

        {isLoading === false &&
          ((value !== "" && found?.address?.length === 0) ||
            found?.length === 0) && <li className="noResult">No Results</li>}
      </ul>
    </div>
  );
};

export default BaseSearchInputList;
