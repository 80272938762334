/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  ArrowDown,
  ArrowUp,
  BackIcon,
  CloseIcon,
  PlusIcon,
} from "../../constants/icons";
import { ClickOutside, Spiner } from "../../ui";
import { colors } from "../../constants/colors";
import "./mapOverlaysBtn.css";
import { useSelector } from "react-redux";
import axios from "axios";

const mokedList = [
  { label: "Remove all", value: "remove" },
  { label: "Regions", value: "region" },
  { label: "Metro", value: "metro" },
  { label: "County", value: "county" },
  { label: "City", value: "city" },
  { label: "Postal Code", value: "zip" },
  { label: "School District", value: "schoolDistrict" },
];

const MapOverlaysBtn = ({
  title,
  list = mokedList,
  isOpened,
  setIsOpened,
  colorEndIcon = colors.white,
  value,
  setValue,
  subValue,
  setSubValue,
  removeAllPolygons,
  allChecked,
  setAllChecked,
  setToRemovePolygon,
  currentLayersPolygons,
  setIsOpenAlarmToRemoveAllRegions,
  isSubOpenList,
  setIsSubOpenList,
  isSubListData,
  setIsSubListData,
  twCities,
  twPostals,
  isDraw,
}) => {
  // const [isSubOpenList, setIsSubOpenList] = useState(false);
  // const [isSubListData, setIsSubListData] = useState([]);

  const isMap = useSelector((state) => state.isMap);

  const [isSubListDataCounter, setIsSubListDataCounter] = useState(0);

  const [liHandlerCounter, setLiHandlerCounter] = useState(false);
  const [isSubCounter, setIsSubCounter] = useState(1);
  const [clickCounter, setClickCounter] = useState(0);

  const locationStateData = useSelector((state) => state.filterLocationLists);
  const [isLoadingSubListData, setIsLoadingSubListData] = useState(false);
  const [isSubDataEmpty, setIsSubDataEmpty] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const [schoolListData, setSchoolListData] = useState([]);

  // const [twCities, setTwCities] = useState([]);
  // const [twCitiesPage, setTwCitiesPage] = useState(1);

  // const [twPostals, setTwPostals] = useState([]);
  // const [twPostalsPage, setTwPostalsPage] = useState(1);

  // useEffect(() => {
  //   if (
  //     value?.value === "city" &&
  //     inputValue === "" &&
  //     (isSubListData.length > 0 || allChecked.length > 0)
  //   ) {
  //     const lastItem = document.querySelector(".map-overlays-btn-list-li-city");
  //     if (lastItem) observerList("city", setTwCitiesPage);
  //   }
  // }, [
  //   twCities.length,
  //   liHandlerCounter,
  //   inputValue,
  //   isSubListData,
  //   allChecked,
  //   isSubListDataCounter,
  // ]);

  // useEffect(() => {
  //   if (
  //     value?.value === "zip" &&
  //     inputValue === "" &&
  //     (isSubListData.length > 0 || allChecked.length > 0)
  //   ) {
  //     const lastItem = document.querySelector(".map-overlays-btn-list-li-zip");
  //     if (lastItem) observerList("zip", setTwPostalsPage);
  //   }
  // }, [
  //   twPostals.length,
  //   liHandlerCounter,
  //   inputValue,
  //   isSubListData,
  //   allChecked,
  //   isSubListDataCounter,
  // ]);

  const listHandler = () => {
    setIsOpened(true);
  };

  const handleClickOutside = () => {
    setIsOpened(false);
    // setInputValue("");
  };

  const [firstClick, setFirstClick] = useState(true);

  useEffect(() => {
    if (
      firstClick &&
      (value?.value === "city" || value?.value === "zip") &&
      isSubListData.length === 0
    ) {
      setFirstClick(false);
      setIsSubCounter(1);
    } else {
      if (isSubCounter === 2) {
        setIsSubOpenList(false);
        setIsSubCounter(0);
      }
    }
  }, [isSubCounter]);

  const liSubHandler = (item) => {
    // setInputValue("");
    setClickCounter((c) => c + 1);
    if (
      allChecked?.filter((checked) => checked.value === item.value)?.length ===
      0
    ) {
      setSubValue({
        ...item,
        type: value.value,
      });
      if (allChecked?.length > 0) {
        setAllChecked([...allChecked, { ...item, checked: value.value }]);
      } else {
        setAllChecked([{ ...item, checked: value.value }]);
      }
      // setIsSubOpenList(false);
      setIsSubCounter(1);
      setToRemovePolygon({ ...item, clicked: clickCounter });
    } else {
      setToRemovePolygon({ ...item, clicked: clickCounter });
      setAllChecked([
        ...allChecked.filter((checked) => {
          if (checked.value !== item.value)
            return { ...checked, checked: value.value };
        }),
      ]);
    }
  };

  const liHandler = (item) => {
    setInputValue("");
    setLiHandlerCounter(!liHandlerCounter);
    if (item?.value !== "remove") {
      setValue(item);
      setIsOpened(false);
      setIsSubOpenList(true);
    } else {
      setValue(item);
      setIsOpened(false);
    }
  };

  const inputHandler = (e) => {
    setInputValue(e.target.value);
    setIsSubDataEmpty(false);
  };

  useEffect(() => {
    if (value?.value === "remove" && allChecked?.length > 0) {
      setIsSubOpenList(false);
      setIsOpened(false);
      // removeAllPolygons();
      // setSubValue([]);
      // setIsSubListData([]);
      setIsOpenAlarmToRemoveAllRegions(true);
    }
    if (
      value?.value === "region" &&
      locationStateData?.data?.regions?.length > 0
    ) {
      setIsSubListData([...locationStateData.data.regions]);
    }
    if (
      value?.value === "metro" &&
      locationStateData?.data?.metros?.length > 0
    ) {
      setIsSubListData(
        [...locationStateData.data.metros].map((item) => {
          return { ...item, value: item.subdomain };
        })
      );
    }
    if (
      value?.value === "county" &&
      locationStateData?.data?.countries?.length > 0
    ) {
      setIsSubListData([...locationStateData.data.countries]);
    }

    if (value?.value === "city") {
      // setIsSubOpenList(false);
      // setIsOpened(false);
      setIsSubListData([...twCities]);
    }

    if (value?.value === "zip") {
      // setIsSubOpenList(false);
      // setIsOpened(false);
      // setSubValue([]);
      setIsSubListData([...twPostals]);
    }

    if (value?.value === "schoolDistrict") {
      // setSubValue([]);
      // setIsSubListData([]);
      // getSchoolDistrictObj();
      setIsSubListData(schoolListData);
      // setIsSubOpenList(false);
      // setIsOpened(false);
    }
  }, [locationStateData.data, liHandlerCounter]);

  // useEffect(() => {
  //   setInputValue("");
  // }, [value]);

  // useEffect(() => {
  //   setIsSubListData([...twCities]);
  // }, [twCities]);

  // useEffect(() => {
  //   setIsSubListData([...twPostals]);
  // }, [twPostals]);

  useEffect(() => {
    if (value?.value === "city") {
      if (inputValue !== "") {
        // getCities(inputValue);
        let temp = twCities.filter((k) => {
          return k?.City.toString()
            .toLowerCase()
            ?.includes(inputValue.toLowerCase());
        });
        setIsSubListData(temp);
        setIsLoadingSubListData(false);
        if (temp.length === 0) setIsSubDataEmpty(true);
      }
    }
    if (value?.value === "zip") {
      if (inputValue !== "") {
        // getPostalCodes(inputValue);
        let temp = twPostals.filter((k) => {
          return k?.ZipCode.toString()
            .toLowerCase()
            ?.includes(inputValue.toLowerCase());
        });
        setIsSubListData(temp);
        setIsLoadingSubListData(false);
        if (temp.length === 0) setIsSubDataEmpty(true);
      }
    }
  }, [inputValue]);

  const getCities = (data) => {
    setIsLoadingSubListData(true);
    axios
      .get(`https://api.realtytexas.net/v1/cognitive-searches/address/${data}`)
      .then(function (response) {
        const temp = [...response?.data?.city];
        const res = temp.map((c) => {
          c.label = c["City"];
          c.value = c["City"];
          return c;
        });
        setIsSubListData(res);
        setIsLoadingSubListData(false);
        if (res.length === 0) setIsSubDataEmpty(true);
      })
      .catch(function (error) {
        console.log(error, "ERROR");
        setIsLoadingSubListData(false);
      });
  };

  const getPostalCodes = (data) => {
    setIsLoadingSubListData(true);
    axios
      .post(
        `https://api.realtytexas.net/v1/zip-codes/cognitive-search/${data}`,
        {
          top: 3000,
          count: "true",
          maxPrice: 50000000,
          minPrice: 0,
          filters: [],
          select: null,
          page: 1,
          orderby: "ZipCode asc",
          ["elastic-search"]: true,
          ["elastic-search-fields"]: "ZipCode",
        }
      )
      .then(function (response) {
        const temp = [...response?.data?.data?.zipcodes];
        const res = temp.map((c) => {
          c.label = c["ZipCode"];
          c.value = c["ZipCode"];
          return c;
        });
        setIsSubListData(res);
        setIsLoadingSubListData(false);
        if (res.length === 0) setIsSubDataEmpty(true);
      })
      .catch(function (error) {
        console.log(error, "ERROR");
        setIsLoadingSubListData(false);
      });
  };

  const observerList = (name, callback) => {
    const lastItem = document.querySelector(
      `.map-overlays-btn-list-li-${name}:last-child`
    );

    if (lastItem) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // setTwCitiesPage((twCitiesPage) => twCitiesPage + 1);
            callback((c) => c + 1);
            observer.disconnect();
          }
        },
        { threshold: 0 }
      );
      observer.observe(lastItem);
    }
  };

  // const setCitiesOrPostalsData = (page, name, top = 1000) => {
  //   let data = JSON.stringify({
  //     top: top,
  //     filters: [],
  //     ["elastic-search"]: true,
  //     page: page,
  //     orderby: name === "cities" ? "City" : "ZipCode",
  //   });
  //   let url = `https://api.realtytexas.net/v1/${name}/cognitive-search`;
  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: url,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };
  //   axios
  //     .request(config)
  //     .then(function (res) {
  //       let arr;
  //       if (name === "cities") {
  //         arr = [...res.data.data.cities];
  //       } else arr = [...res.data.data.zipcodes];
  //       const temp = arr.map((c) => {
  //         if (name === "cities") {
  //           c.label = c["City"];
  //           c.value = c["City"];
  //         } else {
  //           c.label = c["ZipCode"];
  //           c.value = c["ZipCode"];
  //         }
  //         return c;
  //       });
  //       if (name === "cities") {
  //         setTwCities([...twCities, ...temp]);
  //       } else {
  //         setTwPostals([...twPostals, ...temp]);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error, "POST ERROR");
  //     });
  // };

  // useEffect(() => {
  //   if (value?.value === "city") setCitiesOrPostalsData(twCitiesPage, "cities");
  // }, [twCitiesPage, value]);

  // useEffect(() => {
  //   if (value?.value === "zip")
  //     setCitiesOrPostalsData(twPostalsPage, "zip-codes");
  // }, [twPostalsPage, value]);

  const getSchoolDistrictObj = () => {
    setIsLoadingSubListData(true);
    axios
      // .get(`https://${window.location.host}/api/v1/map/school-district)`
      .get(`https://realtytexas.co/api/v1/map/school-district`)
      .then(function (response) {
        // console.log(response, "response");
        const temp = [...response?.data?.data];
        const res = temp.map((c) => {
          c.label = c["isd"];
          c.value = c["DistrictID"];
          return c;
        });
        // setIsSubListData(res);
        setSchoolListData(res);
        setIsLoadingSubListData(false);
        if (res.length === 0) setIsSubDataEmpty(true);
      })
      .catch(function (error) {
        console.log(error, "ERROR");
        setIsLoadingSubListData(false);
      });
  };

  useEffect(() => {
    getSchoolDistrictObj();
  }, []);

  return (
    <div
      id="map-overlays-btn-form"
      className={
        isMap.data === null
          ? "map-overlays-btn-form-both"
          : "map-overlays-btn-form-map"
      }
      onClick={listHandler}
    >
      {!isOpened && !isSubOpenList && (
        <div className="map-overlays-btn-form-list-end-icon">
          <ArrowDown
            ArrowUp
            width={10}
            height={10}
            color={colorEndIcon}
            // style={{ display: !isOpened ? "none" : "block" }}
          />
        </div>
      )}
      {isOpened && !isSubOpenList && (
        <div className="map-overlays-btn-form-list-end-icon">
          <ArrowUp width={10} height={10} color={colorEndIcon} />
        </div>
      )}
      <ClickOutside
        onClick={handleClickOutside}
        style={{
          width: !isSubOpenList ? "58px" : "90px",
          marginLeft: !isSubOpenList ? "0px" : "10px",
        }}
      >
        <div className="map-overlays-btn-title">
          {!isSubOpenList ? "Overlays" : "Back"}
          {isSubOpenList && (
            <div className="map-overlays-btn-form-list-end-icon map-overlays-btn-form-list-end-icon-back">
              <BackIcon color={colorEndIcon} />
            </div>
          )}
        </div>
      </ClickOutside>
      {isOpened && list?.length > 0 && (
        <ul className="map-overlays-btn-list">
          {list?.map((item, i) => (
            <li
              onClick={() => liHandler(item)}
              key={`map-overlays-btn-list-li-${i}`}
              className={i === 0 ? "map-overlays-btn-list-li-0" : ""}
            >
              <p>{item.label}</p>
            </li>
          ))}
        </ul>
      )}
      {((isSubOpenList && isSubListData?.length > 0) ||
        (value?.value === "city" && isSubOpenList) ||
        (value?.value === "zip" && isSubOpenList)) && (
        <ClickOutside
          onClick={() => {
            // if (
            //   firstClick &&
            //   !(
            //     (value?.value === "city" && isSubOpenList) ||
            //     (value?.value === "zip" && isSubOpenList)
            //   )
            // ) {
            //   setIsSubCounter(2);
            //   setFirstClick(false);
            // } else {
            if (value?.value === "city" && isSubListData.length === 0) {
              // setIsSubCounter(0);
              setIsSubCounter((c) => c + 1);
            } else {
              setIsSubCounter((c) => c + 1);
            }
            // }

            if (
              (value?.value === "city" || value?.value === "zip") &&
              isSubListData.length > 0
            )
              // setIsSubListData([]);
              // setIsSubListDataCounter(isSubListDataCounter + 1);
              setIsSubDataEmpty(false);
          }}
        >
          <ul className="map-overlays-btn-list">
            {value?.value !== "region" && value?.value !== "metro" && (
              <div className="map-overlays-btn-list-input-wrapper">
                <input
                  placeholder={`Type ${value.label}`}
                  onChange={inputHandler}
                  value={inputValue}
                />
              </div>
            )}
            {[
              ...allChecked.filter(
                (item) => item?.checked === value.value
                /* &&
                  value.value !== "region" &&
                  value.value !== "metro" &&
                  value.value !== "county" */
              ),
              /* ...[...isSubListData, ...twCities].filter((obj1) => { */
              ...[...isSubListData]
                .filter((obj1) => {
                  return !allChecked.some((obj2) => {
                    return obj1.value === obj2.value;
                  });
                })
                .filter((str, i) => {
                  // if (value.value === "schoolDistrict" && i === 0) {
                  //   console.log(allChecked, "inputValue");
                  // }
                  if (inputValue !== "") {
                    if (
                      str.label.toLowerCase().includes(inputValue.toLowerCase())
                    ) {
                      return str;
                    }
                  } else {
                    return str;
                  }
                }),
            ]

              /* .sort((a, b) => {
                const nameA = a.value.toLowerCase();
                const nameB = b.value.toLowerCase();

                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              }) */
              .map((item, i) => (
                <li
                  onClick={() => liSubHandler(item)}
                  key={`map-overlays-btn-list-li-${i}`}
                  className={
                    allChecked?.filter(
                      (checked) => checked.value === item.value
                    )?.length > 0
                      ? `map-overlays-btn-list-li map-overlays-btn-list-li-checked map-overlays-btn-list-li-${value?.value}`
                      : `map-overlays-btn-list-li map-overlays-btn-list-li-${value?.value}`
                  }
                >
                  <p>{item.label}</p>
                  {/* {allChecked?.filter((checked) => checked.value === item.value)
                    ?.length > 0 && ( */}
                  <div className="map-overlays-btn-list-li-close-icon">
                    <PlusIcon height={12} width={12} />
                  </div>
                  {/* )} */}
                </li>
              ))}
            {isLoadingSubListData && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px 0",
                }}
              >
                <Spiner />
              </div>
            )}
            {isSubDataEmpty && (
              <div className="map-overlays-btn-list-no-resault">
                No Results...
              </div>
            )}
          </ul>
        </ClickOutside>
      )}
    </div>
  );
};

export default MapOverlaysBtn;
