import React from "react";

const LinkWrapper = ({ link, target = "_blank", children }) => {
  return (
    <a href={link} target={target}>
      {children}
    </a>
  );
};

export default LinkWrapper;
