import React, { useEffect, useState } from "react";
import {
  FavoriteIcon,
  HeardIcon,
  LeftArrowIcon,
  NoteIcon,
  PossibilityIcon,
  RightArrowIcon,
  SavedIcon,
} from "../../constants/icons.jsx";
import "./houseCardNarrow.css";
import "./responsive.css";
import Slider from "react-slick";
import axios from "axios";
import Spiner from "../../ui/spiner/spiner.jsx";
import HouseCardHeardList from "../HouseCardHeardList/HouseCardHeardList.jsx";
import { useDispatch, useSelector } from "react-redux";
import { updateHouseCardsHeardData } from "../../store/actions/updateHouseCardsHeard.js";
import ShareIconList from "../ShareIconList/ShareIconList.jsx";
import NotesBox from "../NotesBox/NotesBox";
import AlertNote from "../../ui/alertNote/AlertNote";
import ClickForPremiumAccess from "../ClickForPremiumAccess/ClickForPremiumAccess";
import { ClickOutside, LinkWrapper } from "../../ui";
import BAWithTooltip from "../BAWithTooltip/BAWithTooltip.jsx";

const HouseCardNarrow = ({
  item,
  isActive,
  currentPage,
  container,
  isUserLogin,
  picturesFeature,
  rootRef,
  inView,
  isMap,
}) => {
  let k = <HeardIcon />;
  const { data } = useSelector((state) => state.houseCardsHeard);

  const [isHeardActive, setIsHeardActive] = useState(false);
  const [images, setImages] = useState(null);
  const [imagesError, setImagesError] = useState(null);
  const [noteBox, setNoteBox] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [note, setNote] = useState(null);
  const [alertBox, setAlertBox] = useState(false);
  const [alertNoUserBox, setAlertNoUserBox] = useState(false);
  const [alertNoUserMessageBox, setAlertNoUserMessageBox] = useState(false);
  const [isNoteContentBox, setIsNoteContentBox] = useState(false);
  const [isNoteIconShow, setIsNoteIconShow] = useState(false);
  const [isDataHeart, setIsDataHeart] = useState(false);

  const dataIsShareIcon = container.getAttribute("data-share-icon");

  const handleMapMarker = () => {
    const el = document.getElementById(
      `rounded-marker-${item?.ListingKeyNumeric}`
    );
    const priceEl = document.getElementById(
      `rounded-marker-price-${item?.ListingKeyNumeric}`
    );
    if (el) {
      if (priceEl) {
        priceEl.classList.add("filters-page-map-rounded-marker-price-hovered");
      }
      el.classList.add("filters-page-map-rounded-marker-wrapper-hovered");
      el.style.height = "18px";
      el.style.width = "18px";
      el.style.zIndex = 1;
    }
  };

  const handleResetMapMarker = () => {
    const el = document.getElementById(
      `rounded-marker-${item?.ListingKeyNumeric}`
    );
    if (el) {
      const priceEl = document.getElementById(
        `rounded-marker-price-${item?.ListingKeyNumeric}`
      );
      if (priceEl) {
        priceEl.classList.remove(
          "filters-page-map-rounded-marker-price-hovered"
        );
      }
      el.classList.remove("filters-page-map-rounded-marker-wrapper-hovered");
      el.style.height = "12px";
      el.style.width = "12px";
      el.style.zIndex = 0;
    }
  };

  useEffect(() => {
    const isData = container.getAttribute("data-heart-icon");
    setIsDataHeart(isData === "false" ? false : true);
  }, []);

  const onNoteContentIconHandler = () => {
    setNoteBox(true);
  };

  const onHeardIconHandler = () => {
    setIsHeardActive(true);
    setIsNoteContentBox(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentSlide(1);
    setIsNoteIconShow(false);
  }, [currentPage]);

  useEffect(() => {
    setCurrentSlide(1);
    setIsNoteIconShow(false);
  }, [data]);

  useEffect(() => {
    if (item.total_notes > 0) {
      setIsNoteIconShow(true);
    } else {
      setIsNoteIconShow(false);
    }
  }, [data]);

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => {
    setCurrentSlide(currentSlide + 1);
    return (
      <button
        {...props}
        className={
          isActive
            ? "slick-arrow filters-page-house-card-narrow-slider-left-arrow-active" +
              (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            : "slick-arrow filters-page-house-card-narrow-slider-left-arrow" +
              (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        // aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
        aria-label="Previous image button"
      >
        <LeftArrowIcon color="white" />
      </button>
    );
  };
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        isActive
          ? "slick-arrow filters-page-house-card-narrow-slider-right-arrow-active" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
          : "slick-arrow filters-page-house-card-narrow-slider-right-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      //   aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
      aria-label="Next image button"
    >
      <RightArrowIcon color="white" />
    </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: true,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  };

  const [currentItemLoaded, setCurrentItemLoaded] = useState(null);

  // useEffect(() => {
  //   if (
  //     inView &&
  //     item?.ListingKeyNumeric &&
  //     (!currentItemLoaded ||
  //       (currentItemLoaded &&
  //         currentItemLoaded.ListingKeyNumeric !== item.ListingKeyNumeric))
  //   ) {
  //     getImages(item.ListingKeyNumeric);
  //   }
  // }, [inView, item]);

  useEffect(() => {
    if (item?.GatewayDescID === 1) {
      k = <SavedIcon />;
    } else {
      if (item?.GatewayDescID === 2) {
        k = <FavoriteIcon />;
      } else {
        if (item?.GatewayDescID === 3) {
          k = <PossibilityIcon />;
        } else {
          if (item?.GatewayDescID === -1) {
            k = <NoteIcon />;
          }
        }
      }
    }
    if (item.ListingKeyNumeric && item.GatewayDescID !== null) {
      dispatch(
        updateHouseCardsHeardData({
          id: item.ListingKeyNumeric,
          value: item.GatewayDescID,
        })
      );
    }
  }, [item]);

  const defaultMediaUrl =
    "https://realtytexas.com/assets/images/placeholder-image.jpg";

  const defaultImgData = {
    MediaURL: defaultMediaUrl,
    img_order: "01",
    LongDescription: null,
  };

  useEffect(() => {
    if (
      item &&
      item?.Media?.length > 0 &&
      inView &&
      item?.ListingKeyNumeric &&
      (!currentItemLoaded ||
        (currentItemLoaded &&
          currentItemLoaded.ListingKeyNumeric !== item.ListingKeyNumeric))
    ) {
      const temp = item?.Media?.map((url) => {
        return {
          MediaURL: "https://propertyimages.realtytexas.com" + url?.MediaURL,
          order: url?.Order,
        };
      });
      setImages([...temp]);
    }
    if (item && (!item?.Media || item?.Media?.length === 0) && inView) {
      setImages([defaultImgData]);
    }
  }, [item, inView]);

  // const getImages = () => {
  //   setImages(null);
  //   axios
  //     .get(
  //       `https://d1c4wot07153w7.cloudfront.net/api/v1/listings/${item.ListingKeyNumeric}/images`
  //     )
  //     .then(function (response) {
  //       setImages([...response.data.data]);
  //       setCurrentItemLoaded(item);
  //     })
  //     .catch(function (error) {
  //       setImagesError(error.response?.status);
  //       setImages([defaultImgData]);
  //     });
  // };

  const getIcon = () => {
    if (data)
      data.map((ob, i, arr) => {
        if (ob?.id === item?.ListingKeyNumeric) {
          if (ob.value === 1) {
            k = <SavedIcon key={i} />;
          } else {
            if (ob.value === 2) {
              k = <FavoriteIcon key={i} />;
            } else {
              if (ob.value === 3) {
                k = <PossibilityIcon key={i} />;
              } else {
                if (ob.value === -1) {
                  k = <NoteIcon key={i} />;
                } else {
                  k = <HeardIcon key={i} />;
                }
              }
            }
          }
        }
        if (item.ListingKeyNumeric && item.GatewayDescID !== null) {
          dispatch(
            updateHouseCardsHeardData({
              id: item.ListingKeyNumeric,
              value: item.GatewayDescID,
            })
          );
        }
      });
    return k;
  };

  const logInLinkHandler = () => {
    if (document?.getElementById("root-react-login-form"))
      document
        .getElementById("root-react-login-form")
        .setAttribute("visible", "true");
    setAlertNoUserBox(false);
    setAlertNoUserMessageBox(false);
  };

  function initHeardHandler() {
    const myRTP = JSON?.parse(localStorage?.getItem("MyRTP"));
    if (isUserLogin || myRTP?.SubjectID) {
      onHeardIconHandler();
    } else {
      logInLinkHandler();
    }
  }

  let link = `https://realtytexas.com/${item.subdomain}${item.url}`;

  const [isRI, setIsRI] = useState(false);

  const clickRealtyIconHandler = () => {
    setIsRI(!isRI);
  };

  const clickOutsideHandler = () => {
    setIsRI(false);
  };

  return (
    <div
      className={
        isActive
          ? "filters-page-house-card-narrow-wrapper-active"
          : isMap.data === null
          ? "filters-page-house-card-narrow-wrapper-both"
          : "filters-page-house-card-narrow-wrapper"
      }
      ref={rootRef}
      onMouseEnter={handleMapMarker}
      onMouseLeave={handleResetMapMarker}
    >
      <div
        className={
          isActive
            ? "filters-page-house-card-narrow-img-active"
            : "filters-page-house-card-narrow-img"
        }
      >
        <AlertNote
          isVisible={alertBox}
          setIsVisible={setAlertBox}
          width="90%"
        />
        <AlertNote
          width="90%"
          isVisible={alertNoUserBox}
          setIsVisible={setAlertNoUserBox}
          text={
            <>
              To synchronize your listings actions across all devices, please{" "}
              <span
                className="filters-page-house-card-narrow-note-link"
                onClick={() => logInLinkHandler()}
              >
                create
              </span>{" "}
              an account or{" "}
              <span
                className="filters-page-house-card-narrow-note-link"
                onClick={() => logInLinkHandler()}
              >
                log in
              </span>
              .
            </>
          }
        />
        <AlertNote
          width="90%"
          isVisible={alertNoUserMessageBox}
          setIsVisible={setAlertNoUserMessageBox}
          text={
            <>
              To add a note to this listing, you must{" "}
              <span
                className="filters-page-house-card-narrow-note-link"
                onClick={() => logInLinkHandler()}
              >
                create
              </span>{" "}
              an account or{" "}
              <span
                className="filters-page-house-card-narrow-note-link"
                onClick={() => logInLinkHandler()}
              >
                log in
              </span>
              . We look forward to providing you with a personalized experience!
            </>
          }
        />

        <NotesBox
          currentPage={currentPage}
          isVisible={noteBox}
          setIsVisible={setNoteBox}
          setIsNoteIconShow={setIsNoteIconShow}
          item={item}
          narrowView={true}
          isNoteIconShow={isNoteIconShow}
        />
        {images?.length > 0 && (
          <Slider {...settings}>
            {images
              ?.sort((a, b) => parseFloat(+a.order) - parseFloat(+b.order))
              ?.map((img, i) => {
                if (picturesFeature !== "true") {
                  return (
                    <div
                      className={
                        picturesFeature !== "true"
                          ? "filters-page-house-card--blur-wrapper filters-page-house-card-narrow-img-container"
                          : "filters-page-house-card-narrow-img-container"
                      }
                      key={i}
                    >
                      <img
                        src={img?.MediaURL ? img?.MediaURL : defaultMediaUrl}
                        onError={(img) => (img.src = defaultMediaUrl)}
                      />
                      {picturesFeature !== "true" && <ClickForPremiumAccess />}
                    </div>
                  );
                } else
                  return (
                    <LinkWrapper link={link} key={i}>
                      <div
                        className={
                          picturesFeature !== "true"
                            ? "filters-page-house-card--blur-wrapper filters-page-house-card-narrow-img-container"
                            : "filters-page-house-card-narrow-img-container"
                        }
                      >
                        <img
                          src={img?.MediaURL ? img?.MediaURL : defaultMediaUrl}
                          alt={
                            item?.Address
                              ? `${item?.Address.trim()}, ${item?.City} ${
                                  item?.StateOrProvince
                                } ${item?.PostalCode}`
                              : ""
                          }
                          onError={(img) => (img.src = defaultMediaUrl)}
                        />
                        {picturesFeature !== "true" && (
                          <ClickForPremiumAccess />
                        )}
                      </div>
                    </LinkWrapper>
                  );
              })}
            {images?.length < 1 && (
              <a href={link} target="_blank">
                <div className="filters-page-house-card-narrow-img-container">
                  <img src={defaultMediaUrl} />
                </div>
              </a>
            )}
          </Slider>
        )}

        <div
          className={
            item?.OpenHouseDate !== undefined || item?.oh
              ? "filters-page-house-card-narrow-menu-open-house-icon"
              : "filters-page-house-card-narrow-menu-label-disabled-1"
          }
        >
          <img src="https://realtytexas.azureedge.net/resources/images/open-house-200.png" />
        </div>

        {images && (
          <a href={link} target="_blank">
            <div className="filters-page-house-card-narrow-img-slide-counter">{`${currentSlide}/${images.length}`}</div>
          </a>
        )}
        {!images && (
          <div className="filters-page-house-card-narrow-img-spiner">
            <Spiner />
          </div>
        )}
        <HouseCardHeardList
          isHeardActive={isHeardActive}
          setIsHeardActive={setIsHeardActive}
          index={item.ListingKeyNumeric}
          currentPage={currentPage}
          item={item}
          note={note}
          setNote={setNote}
          setAlertBox={setAlertBox}
          setAlertNoUserBox={setAlertNoUserBox}
          alertBox={alertBox}
          alertNoUserBox={alertNoUserBox}
          setNoteBox={setNoteBox}
          setAlertNoUserMessageBox={setAlertNoUserMessageBox}
          alertNoUserMessageBox={alertNoUserMessageBox}
        />
        {images && (
          <div className="filters-page-house-card-narrow-menu">
            <div className="filters-page-house-card-narrow-menu-labels">
              {item?.rtlist === true && (
                <ClickOutside onClick={clickOutsideHandler}>
                  <div
                    className="filters-page-house-card-menu-realtytexas-icon"
                    onClick={clickRealtyIconHandler}
                  >
                    {isRI && (
                      <>
                        <div className="house-card-realty-icon-tooltip">
                          <p>Listed by Realty Texas</p>
                        </div>
                        <div className="house-card-realty-icon-tooltip-triangle" />
                      </>
                    )}
                  </div>
                </ClickOutside>
              )}

              <BAWithTooltip item={item} />
              <div className="filters-page-house-card-narrow-menu-label-2">
                <p
                  style={{
                    backgroundColor: item?.color ? item?.color : "#2078BD",
                  }}
                >
                  {item?.MlsStatus === "Closed"
                    ? `${item?.MlsStatus} - ${item?.CloseDate}`
                    : item?.MlsStatus}
                </p>
              </div>
              <div
                className={
                  item?.DOM < 1
                    ? "filters-page-house-card-narrow-menu-label-1"
                    : "filters-page-house-card-narrow-menu-label-disabled-1"
                }
              >
                <p>Just Listed</p>
              </div>
            </div>
            <div
              className={
                isNoteIconShow
                  ? "filters-page-house-card-narrow-menu-labels-right-note"
                  : "filters-page-house-card-narrow-menu-labels-right"
              }
            >
              <div
                className={
                  isNoteIconShow
                    ? "filters-page-house-card-narrow-menu-note-content-icon"
                    : "filters-page-house-card-narrow-menu-note-content-icon-disabled"
                }
                onClick={onNoteContentIconHandler}
              >
                <NoteIcon className="filters-page-house-card-narrow-menu-note-content-icon-main" />
              </div>
              {isDataHeart && (
                <div
                  className="featured-listings-house-card-menu-heard-icon"
                  onClick={initHeardHandler}
                >
                  {getIcon()}
                </div>
              )}
              {dataIsShareIcon === "false" ? null : (
                <ShareIconList
                  data={item}
                  isHeardIconVisible={setIsHeardActive}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <a href={link} target="_blank">
        <div
          className={
            isActive
              ? "filters-page-house-card-narrow-content-active"
              : "filters-page-house-card-narrow-content"
          }
        >
          <div className="filters-page-house-card-narrow-content-price">
            <h4>
              {item.ListPrice ? "$" : ""}
              {item.ListPrice
                ? item.ListPrice.toLocaleString("en-US").replaceAll("$", "")
                : "No Price"}
            </h4>
          </div>
          <div className="filters-page-house-card-narrow-content-adress">
            <p>
              {item?.Address ? item?.Address.trim() : ""}, {item?.City}{" "}
              {item?.StateOrProvince} {item?.PostalCode}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default HouseCardNarrow;
