import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  note: {
    data: null,
  },
  allNotes: {
    data: null,
    isLoading: false,
    error: "",
  },
};

export const houseCardsHeardNotesSlice = createSlice({
  name: "houseCards",
  initialState,
  reducers: {
    houseCardsHeardNotesFetching(state) {
      state.allNotes.isLoading = true;
      state.allNotes.error = "";
    },
    setHouseCardsHeardNote(state, actions) {
      state.note.data = actions.payload;
    },
    houseCardsHeardNotesFetchingError(state, actions) {
      state.allNotes.isLoading = false;
      state.allNotes.error = actions.payload;
    },
  },
});

export default houseCardsHeardNotesSlice.reducer;
