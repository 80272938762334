import { filterLocationListsSlice } from "../reducers/filterLocationListsSlice";
import axios from "axios";

export const getFilterLocationMLS_addressAction =
  (data) => async (dispatch) => {
    dispatch(filterLocationListsSlice.actions.filterLocationFetching());
    axios
      .get(`https://api.realtytexas.net/v1/cognitive-searches/address/${data}`)
      .then(function (response) {
        if (response.data.address.length > 0) {
          response.data.address.map((c) => {
            c.value = c.Address;
            c.label = "Address";
          });
        }
        if (response.data.city.length > 0) {
          response.data.city.map((c) => {
            c.value = c.City;
            c.label = "City";
          });
        }
        if (response.data.mlsnum.length > 0) {
          response.data.mlsnum.map((c) => {
            c.value = c.MlsNum;
            c.label = "MlsNum";
          });
        }
        if (response.data.postal.length > 0) {
          response.data.postal.map((c) => {
            c.value = c["ZIP code"];
            c.label = "PostalCode";
          });
        }
        dispatch(
          filterLocationListsSlice.actions.getFilterLocationMLS_addressSuccess(
            response.data
          )
        );
      })
      .catch(function (error) {
        console.log(error, "response.data.data MLS ADDRESS ERROR");
        dispatch(
          filterLocationListsSlice.actions.filterLocationMLS_addressFetchingError(
            error
          )
        );
      });
  };
