/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import "./houseCardsBlock.css";
import "./responsive.css";
import { HouseCard, HouseCardNarrow } from "../../components/index.js";
import Pagination from "../../components/Pagination/ReactPagination.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getAllHouseCardsAction } from "../../store/actions/getAllHouseCards.js";
import { useScreenSize } from "../../hooks/index.js";
import { setUserHouseCardsHeard } from "../../store/actions/setUserHouseCardsHeard";
import { isUserLogin, toObjFilterConverter } from "../../constants/methods";
import { Spiner } from "../../ui";
import { InView } from "react-intersection-observer";
import { mokedMapViewPagesList } from "../../constants/viewMapPagesList";

const HouseCardsBlock = ({
  isActive,
  isActive3,
  filters,
  isApply,
  container,
  sortingValue,
  currentPage,
  setCurrentPage,
  isPicturesFeature,
  dataInRectPage,
  setDataInRectPage,
  isFirstLoadedMinMaxQPar,
  queryParamsObject,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}) => {
  const [heardData, setHeardData] = useState([{ id: null, value: 4 }]);
  const isMap = useSelector((state) => state.isMap);
  const { data, isLoading } = useSelector((state) => state.houseCards);
  const houseCardDataInRect = useSelector((state) => state.houseCardsMap);
  const dispatch = useDispatch();

  //CloseDate:"2008-06-06T00:00:00Z"

  // useEffect(() => {
  //   console.log({ dateTo: dateTo, dateFrom: dateFrom }, "dateToFrom22");
  //   if (dateFrom && dateTo) {
  //     // let a = new Date(dateTo); //
  //     // let currentDays = new Date(item.ListingContractDate);
  //     let currentDays = new Date("2024-04-05T00:00:00Z");
  //     let dateToDays = new Date(dateTo);
  //     let dateFromDays = new Date(dateFrom);
  //     // let dateFromDiff = Math.floor(
  //     //   (dateFromDays - currentDays) / (1000 * 60 * 60 * 24) + 1
  //     // );
  //     // let dateToDiff = Math.floor(
  //     //   (dateToDays - currentDays) / (1000 * 60 * 60 * 24) + 1
  //     // );
  //     let dateFromDiff = currentDays > dateFromDays;
  //     let dateToDiff = currentDays < dateToDays;
  //     console.log(dateFromDiff && dateToDiff, "<---from differense to--->");
  //   }
  // }, [dateTo, dateFrom]);

  const visibleHouseCards = useMemo(() => {
    return data?.listings;
    // .filter((item) => {
    //   if (dateFrom && dateTo) {
    //     // let a = new Date(dateTo); //
    //     // let currentDays = new Date(item.ListingContractDate);
    //     let currentDays = new Date(item?.CloseDate);
    //     let dateToDays = new Date(dateTo);
    //     let dateFromDays = new Date(dateFrom);
    //     let dateFromDiff = currentDays > dateFromDays;
    //     let dateToDiff = currentDays < dateToDays;
    //     if (dateFromDiff && dateToDiff) {
    //       return item;
    //     } else {
    //       return null;
    //     }
    //   } else return item;
    // });
  }, [data?.listings, isApply]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleBothViewPageClick = (event) => {
    const currentMapViewPage = mokedMapViewPagesList.filter(
      (item) => item.value === event.selected + 1
    )[0];
    setDataInRectPage(currentMapViewPage);
  };

  const { isOpenNarrowFilters } = useSelector((state) => state.isResetFilters);

  useEffect(() => {
    // if (isMap.data !== true)
    if (queryParamsObject?.ListPriceMin || queryParamsObject?.ListPriceMax) {
      if (isFirstLoadedMinMaxQPar) {
        const filtedrArr = toObjFilterConverter(filters);
        let tempOrder = null;
        if (
          sortingValue?.orderBySort === "desc" ||
          sortingValue?.orderBySort === "asc"
        ) {
          tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
        }
        dispatch(
          getAllHouseCardsAction(currentPage + 1, filtedrArr, tempOrder)
        );
      }
    } else if (isFirstLoadedMinMaxQPar) {
      const filtedrArr = toObjFilterConverter(filters);
      let tempOrder = null;
      if (
        sortingValue?.orderBySort === "desc" ||
        sortingValue?.orderBySort === "asc"
      ) {
        tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
      }
      dispatch(getAllHouseCardsAction(currentPage + 1, filtedrArr, tempOrder));
    }
  }, [currentPage, isFirstLoadedMinMaxQPar]);

  useEffect(() => {
    dispatch(setUserHouseCardsHeard(heardData));
  }, [heardData]);

  const screenSize = useScreenSize();

  const getUserHeardList = () => {
    const myRTP = JSON?.parse(localStorage?.getItem("MyRTP"));
    const listingInteractions = JSON?.parse(
      localStorage?.getItem("listingInteractions")
    );

    if (myRTP?.SubjectID) {
      if (listingInteractions?.listings) {
        setHeardData([
          ...listingInteractions?.listings.map((item) => {
            return { id: item.id, value: item.value };
          }),
        ]);
      } else {
        setHeardData([{ id: null, value: 4 }]);
      }
    }
  };

  useEffect(() => {
    const myRTP = JSON?.parse(localStorage?.getItem("MyRTP"));
    if (myRTP?.SubjectID) {
      getUserHeardList();
    }
  }, []);

  const handleRTLoginEvent = () => {
    getUserHeardList();
  };

  useEffect(() => {
    document.addEventListener("RTLoginEvent", handleRTLoginEvent);
    return () => {
      document.removeEventListener("RTLoginEvent", handleRTLoginEvent);
    };
  }, []);

  const isUserLogined = isUserLogin();

  let link = `https://${window.location?.hostname}/our-listings`;

  return (
    <>
      <div
        id="filters-page-house-cards-block-wrapper"
        className="filters-page-house-cards-block-wrapper"
      >
        <div id="filters-page-house-cards-block">
          {isMap.data !== null &&
            visibleHouseCards?.map((item, i) => {
              return isActive3 && screenSize[0] > 768 ? (
                <InView key={i}>
                  {({ inView, ref }) => (
                    <HouseCardNarrow
                      inView={inView}
                      rootRef={ref}
                      key={i}
                      item={item}
                      isActive={!isActive3}
                      currentPage={currentPage}
                      container={container}
                      isUserLogin={isUserLogined}
                      picturesFeature={isPicturesFeature}
                      isMap={isMap}
                      ind={i}
                    />
                  )}
                </InView>
              ) : (
                <InView key={i}>
                  {({ inView, ref }) => (
                    <HouseCard
                      inView={inView}
                      rootRef={ref}
                      key={i}
                      item={item}
                      isActive={isActive}
                      currentPage={currentPage}
                      container={container}
                      isUserLogin={isUserLogined}
                      picturesFeature={isPicturesFeature}
                      isMap={isMap}
                      ind={i}
                    />
                  )}
                </InView>
              );
            })}
          {isMap.data === null &&
            houseCardDataInRect?.isLoading === false &&
            houseCardDataInRect?.data?.listings?.map((item, i) => {
              return isActive3 && screenSize[0] > 768 ? (
                <InView key={i}>
                  {({ inView, ref }) => (
                    <HouseCardNarrow
                      inView={inView}
                      rootRef={ref}
                      key={i}
                      item={item}
                      isActive={!isActive3}
                      currentPage={currentPage}
                      container={container}
                      isUserLogin={isUserLogined}
                      picturesFeature={isPicturesFeature}
                      isMap={isMap}
                    />
                  )}
                </InView>
              ) : (
                <InView key={i}>
                  {({ inView, ref }) => (
                    <HouseCard
                      inView={inView}
                      rootRef={ref}
                      key={i}
                      item={item}
                      isActive={isActive}
                      currentPage={currentPage}
                      container={container}
                      isUserLogin={isUserLogined}
                      picturesFeature={isPicturesFeature}
                      isApply={isApply}
                      isMap={isMap}
                    />
                  )}
                </InView>
              );
            })}
        </div>

        {((isMap.data === null && houseCardDataInRect?.isLoading === true) ||
          (isMap.data === false && isLoading)) && (
          <div className="houseCardRectSpinerWrapper">
            <Spiner />
          </div>
        )}

        {isMap.data === null &&
          houseCardDataInRect?.isLoading === false &&
          houseCardDataInRect?.data?.total > 200 && (
            <Pagination
              currentPage={dataInRectPage?.value - 1}
              initialPage={dataInRectPage?.value - 1}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={Math.ceil(
                houseCardDataInRect?.data?.total /
                  houseCardDataInRect?.data?.perpage
              )}
              onPageChange={handleBothViewPageClick}
              maxPageCount={600}
            />
          )}

        {isMap.data !== null &&
        data !== null &&
        visibleHouseCards?.length !== 0 ? (
          <Pagination
            currentPage={currentPage}
            initialPage={0}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={Math.ceil(data.total / data.perpage)}
            onPageChange={handlePageClick}
            maxPageCount={3334}
          />
        ) : null}
        {isMap.data !== null &&
          data !== null &&
          visibleHouseCards?.length === 0 && <div>No Results</div>}

        {isMap.data === null &&
          houseCardDataInRect?.isLoading === false &&
          houseCardDataInRect?.data?.listings?.length === 0 && (
            <div className="No-Realty-Texas-Listings">
              <span>No Realty Texas Listings in Map Boundary. Click</span>{" "}
              <span>
                <a href={link} target="_blank">
                  here
                </a>
              </span>{" "}
              <span>
                to see a comprehensive list of all Reatly Texas listings.
              </span>
            </div>
          )}
      </div>
    </>
  );
};

export default HouseCardsBlock;
