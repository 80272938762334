/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { colors } from "../../../constants/colors";
import { ArrowDown, ArrowUp, CloseIcon } from "../../../constants/icons";
import ClickOutside from "../../clickOutside/clickoutSideComponent";
import Spiner from "../../spiner/spiner";
import "./baseActiveInputList.css";

const BaseActiveInputList = ({
  name,
  className,
  onClick,
  list,
  placeholder,
  colorEndIcon = colors.mainBg,
  height,
  listTop = "54px",
  padding,
  setItem,
  item,
  setFound,
  found,
  parrentValue = false,
  parrentName,
  field = null,
  callback,
  isLoading = false,
  qparent = false,
  qparentValue = false,
  autocomplete = null,
  ...props
}) => {
  const [firstLoaded, setFirstLoaded] = useState(0);

  useEffect(() => {
    if (parrentValue === null && firstLoaded > 0) {
      setItem(null);
      setFound([]);
    }
  }, [parrentValue]);

  useEffect(() => {
    if (parrentValue && firstLoaded > 0) {
      setItem(null);
      setFound([]);
    }
    setFirstLoaded((c) => c + 1);
  }, [parrentValue && parrentValue[parrentName]]);

  const [prevKeyword, setPrevKeyword] = useState(null);

  useEffect(() => {
    setFound(list);
  }, [list]);

  const filter = (e) => {
    const oldValue = prevKeyword;
    const keyword = e.target.value;
    setPrevKeyword(keyword);
    if (keyword !== "") {
      let results;
      if (!oldValue?.length || oldValue?.length < keyword?.length) {
        results = found.filter((k) => {
          if (field) {
            return k[field].toLowerCase().includes(keyword.toLowerCase());
          } else {
            return k.toLowerCase().includes(keyword.toLowerCase());
          }
        });
      } else {
        results = list.filter((k) => {
          if (field) {
            return k[field].toLowerCase().includes(keyword.toLowerCase());
          } else {
            return k.toLowerCase().includes(keyword.toLowerCase());
          }
        });
      }
      setFound(results);
      inputHandler();
    } else {
      setFound(list);
    }
    setItem(keyword);
  };

  const [isVisible, setIsVisible] = useState(false);

  const inputHandler = () => {
    setIsVisible(true);
  };

  const clickOutsideHandler = () => {
    setIsVisible(false);
  };

  const onIconItem = (item) => {
    setItem(item);
    setIsVisible(false);
  };

  const onClearIcon = () => {
    setItem(null);
    setPrevKeyword(null);
  };

  return (
    <div
      className="filters-page-base-active-input-list-wrapper filters-page-base-input"
      onClick={() => {
        if (onClick) onClick();
        inputHandler();
        setFound(list);
      }}
    >
      {((prevKeyword !== "" && prevKeyword !== null) ||
        (item !== null && item !== "")) && (
        <div
          className="filters-page-base-active-input-value-close-icon"
          onClick={onClearIcon}
        >
          <CloseIcon height={14} width={14} color={colors.mainBg} />
        </div>
      )}
      <div className="filters-page-base-active-input-list-end-icon-wrapper">
        <ArrowUp
          color={colorEndIcon}
          style={{ display: !isVisible ? "none" : "block" }}
        />
      </div>
      <ClickOutside onClick={clickOutsideHandler} style={{ height: height }}>
        <div
          className="filters-page-base-active-input-list-end-icon-wrapper"
          onClick={() => {
            if (callback && found && found.length === 0) {
              callback();
            }
          }}
        >
          <ArrowDown
            color={colorEndIcon}
            style={{ display: isVisible ? "none" : "block" }}
          />
        </div>
        <input
          readOnly={false}
          // name={name}
          type="text"
          placeholder={placeholder}
          className={className}
          style={{
            width: "100%",
            height: { height },
            // borderBottomRightRadius: isVisible && found.length ? 0 : 10,
            // borderBottomLeftRadius: isVisible && found.length ? 0 : 10,
            borderBottomRightRadius:
              isLoading === true || (isVisible && found.length) ? 0 : 10,
            borderBottomLeftRadius:
              isLoading === true || (isVisible && found.length) ? 0 : 10,
            padding: padding ? padding : "19px 24px",
          }}
          value={item === null ? "" : item[field] ? item[field] : item}
          onChange={filter}
          onClick={() => {
            if (callback && found.length === 0) {
              callback();
            }
          }}
          autoComplete={autocomplete ? autocomplete : null}
        />
      </ClickOutside>
      <ul
        style={{
          display: isVisible ? "block" : "none",
          top: listTop,
          // border: found.length ? "1px solid #908F8F" : "none",
          border:
            isLoading === true
              ? "1px solid #908F8F"
              : found.length
              ? "1px solid #908F8F"
              : "none",
        }}
      >
        {isLoading === true && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
            }}
          >
            <Spiner />
          </div>
        )}
        {isLoading === false &&
          found
            .filter((v) => v !== item)
            .map((item, i) => {
              if (field) {
                return (
                  <li key={i} onClick={() => onIconItem(item)}>
                    <p>{item[field]}</p>
                  </li>
                );
              } else {
                return (
                  <li key={i} onClick={() => onIconItem(item)}>
                    <p>{item}</p>
                  </li>
                );
              }
            })}
      </ul>
    </div>
  );
};

export default BaseActiveInputList;
