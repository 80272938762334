import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { setupStore } from "./store/store.js";
import App from "./App.js";
import { BrowserRouter } from "react-router-dom";

const store = setupStore();
const rootDiv = document?.getElementById("buy-page");
if (rootDiv) {
  const root = ReactDOM.createRoot(rootDiv);
  root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App container={rootDiv} />
        </BrowserRouter>
    </Provider>
  );
}
