/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BaseMultipleActiveInput } from "../../../ui/index.js";
import "./propertySubType.css";
import "./responsive.css";

const PropertySubType = ({
  residentialSubType,
  setResidentialSubType,
  foundResidentialSubType,
  setFoundResidentialSubType,
  allPropertyTypes,
  allSubPropertyTypes,
  isExpanded,
}) => {
  const [currentSubPropertyTypeList, setCurrentSubPropertyTypeList] = useState(
    []
  );

  useEffect(() => {
    let currentPropertyType = allPropertyTypes.filter((type) => type.show)[0];
    setCurrentSubPropertyTypeList(
      allSubPropertyTypes.filter(
        (subType) => subType.PropertyType === currentPropertyType.PropertyType
      )
    );
  }, [allPropertyTypes]);

  return (
    <div id="filters-block-filter-PropertySubType" className={
              isExpanded
                ? "filters-block-filter-PropertySubType-content-wrapper"
                : "filters-block-filter-PropertySubType-content-wrapper collapsed-PropertySubType-content"
            }>
      <div id="filters-block-filter-PropertySubType-title">
        <h4>Property Sub Types</h4>
      </div>
      <div id="filters-block-filter-PropertySubType-content">
        <BaseMultipleActiveInput
          name="propertySubType"
          list={currentSubPropertyTypeList}
          placeholder={
            currentSubPropertyTypeList?.length
              ? `Select ${currentSubPropertyTypeList[0].PropertyTypeDisplay} Sub Type`
              : "None"
          }
          listTop="48px"
          item={residentialSubType ? residentialSubType : []}
          setItem={setResidentialSubType}
          found={
            residentialSubType
              ? foundResidentialSubType.filter(
                  (obj1) => !residentialSubType.some((obj2) => obj1 === obj2)
                )
              : foundResidentialSubType
          }
          setFound={setFoundResidentialSubType}
          field="label"
          height={50}
          stringValue={true}
        />
      </div>
    </div>
  );
};

export default PropertySubType;
