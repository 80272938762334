/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { CollapseIcon, ExpandIcon } from "../../../constants/icons";
import {
  toObjFilterConverter,
  toObjMLSFilterConverter,
} from "../../../constants/methods";
import { setFilterLocationCItiesData } from "../../../store/actions/setFilterLocationCItiesData";
import { setFilterLocationZipsData } from "../../../store/actions/setFilterLocationZipsData";
import { getAllHouseCardsAction } from "../../../store/actions/getAllHouseCards";
import { getFilterLocationCities } from "../../../store/actions/getFilterLocationCities";
import { getFilterLocationCitiesSearch } from "../../../store/actions/getFilterLocationCitiesSearch";
import { getFilterLocationCountries } from "../../../store/actions/getFilterLocationCountries";
import { getFilterLocationMetrosAction } from "../../../store/actions/getFilterLocationMetros";
import { getFilterLocationMLS_addressAction } from "../../../store/actions/getFilterLocationMLS_address";
import { getFilterLocationPostal } from "../../../store/actions/getFilterLocationPostal";
import { getFilterLocationPostalSearch } from "../../../store/actions/getFilterLocationPostalSearch";
import { getFilterLocationRegionsAction } from "../../../store/actions/getFilterLocationRegions";
import { updateIsOpenNarrowFilters } from "../../../store/actions/updateIsOpenNarrowFilters";
import { filterLocationListsSlice } from "../../../store/reducers/filterLocationListsSlice";
import { BaseMultipleActiveInput, BaseSearchInputList } from "../../../ui";
import BaseActiveInputList from "../../../ui/inputs/BaseActiveInputList/BaseActiveInputList";
import BaseInput from "../../../ui/inputs/BaseInput/BaseInput";
import BaseMultipleActiveInput2v from "../../../ui/inputs/BaseMultipleActiveInput/BaseMultipleActiveInput2v";
import "./filterLocation.css";

const FilterLocation = ({
  filters,
  setFilters,
  sortingValue,
  currentPage,
  setCurrentPage,
  MLS_address,
  MLS_addressRes,
  setMLS_Address,
  setMLS_AddressRes,
  setFoundMLS_address,
  region,
  metro,
  country,
  city,
  postal,
  setRegion,
  setMetro,
  setCountry,
  setCity,
  setPostal,
  foundRegions,
  foundMetro,
  foundCountry,
  foundCity,
  foundPostal,
  setFoundRegions,
  setFoundMetro,
  setFoundCountry,
  setFoundCity,
  setFoundPostal,
  filterLocationRegions,
  filterLocationMetros,
  filterLocationCounties,
  twCities,
  setTwCities,
  twPostals,
  setTwPostals,
  twCitiesPage,
  setTwCitiesPage,
  twPostalsPage,
  setTwPostalsPage,
}) => {
  const { data, isLoading } = useSelector((state) => state.filterLocationLists);
  const filterLocationData = useSelector((state) => state.filterLocationData);
  const dispatch = useDispatch();

  const { isReset } = useSelector((state) => state.isResetFilters);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Convert all query parameters to an object
  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });

  useEffect(() => {
    if (filterLocationData?.cities?.length === 0) {
      axios
        .get(
          "https://rtcdn2.azureedge.net/public/assets/FilterLocation/filter_location_city_data.json"
        )
        .then((res) => {
          dispatch(
            setFilterLocationCItiesData(
              res.data.map((o) => {
                o.label = o.City;
                o.value = o.subdomain;
                return o;
              })
            )
          );
        });
    }

    if (filterLocationData?.zips?.length === 0) {
      axios
        .get(
          "https://rtcdn2.azureedge.net/public/assets/FilterLocation/filter_location_zip_data.json"
        )
        .then((res) => {
          dispatch(
            setFilterLocationZipsData(
              res.data.map((o) => {
                o.label = o["ZIP Code"];
                o.value = o["ZIP Code"];
                return o;
              })
            )
          );
        });
    }
  }, [filterLocationData]);

  //it will reset input values of quickSearch if filter location and vice versa !!!!!!!!!!!!!!!!!!

  useEffect(() => {
    if (MLS_addressRes && MLS_addressRes[0]) {
      setRegion("");
      setMetro("");
      setCountry([]);
      setCity([]);
      setPostal([]);
      setFoundRegions([]);
      setFoundMetro([]);
      setFoundCountry([]);
      setFoundCity([]);
      setFoundPostal([]);
    }
  }, [MLS_address]);

  useEffect(() => {
    if (
      (region && region !== "") ||
      (metro && metro !== "") ||
      (country && country[0]) ||
      (city && city[0]) ||
      (postal && postal[0])
    ) {
      setMLS_Address("");
      setMLS_AddressRes([]);
      setFoundMLS_address([]);
    }
  }, [region, metro, country, city, postal]);

  const filterLocationMLS_Address = (MLS_address) => {
    dispatch(getFilterLocationMLS_addressAction(MLS_address));
  };

  // const filterLocationRegions = () => {
  //   dispatch(getFilterLocationRegionsAction());
  // };

  // const filterLocationMetros = () => {
  //   let regionCodes = null;
  //   if (region?.region) {
  //     regionCodes = region?.region;
  //     dispatch(getFilterLocationMetrosAction(regionCodes));
  //   } else dispatch(getFilterLocationMetrosAction());
  // };

  // const filterLocationCounties = () => {
  //   let metroCodes = null;
  //   if (metro?.Metro) {
  //     metroCodes = metro?.subdomain;
  //     dispatch(getFilterLocationCountries(metroCodes));
  //   } else dispatch(getFilterLocationCountries());
  // };

  const filterLocationCities = (value) => {
    let countyCodes;
    if (country[0] && country[0] !== "") {
      countyCodes = country.map((c) => +c.CountyCode);
    } else countyCodes = [];
    dispatch(getFilterLocationCities(countyCodes, value));
  };

  const filterLocationCitiesSearch = (value) => {
    if (value) {
      dispatch(getFilterLocationCitiesSearch(value));
    }
  };

  const filterLocationPostal = (searchValue) => {
    let filt;
    if (city[0] && city[0] !== "") {
      const temp = city.map((c) => c.value);
      filt = [{ column: "City", operator: "in", value: [...temp] }];
    } else filt = [];
    dispatch(getFilterLocationPostal(filt, searchValue));
  };

  const filterLocationPostalSearch = (value) => {
    if (value) {
      dispatch(getFilterLocationPostalSearch(value));
    }
  };

  let tempOrder = null;

  if (
    sortingValue?.orderBySort === "desc" ||
    sortingValue?.orderBySort === "asc"
  ) {
    tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
  }

  const searchApplyHandler = (item, field) => {
    const arrayData = [];
    const mlsObj = {
      mls: field === "mls" ? [item] : null,
      postal: field === "postal" ? [item] : null,
      cities: field === "cities" ? [item] : null,
      address: field === "address" ? [item] : null,
    };
    setMLS_AddressRes([mlsObj]);

    arrayData.push({
      column: item.label,
      operator: "eq",
      value: item.value,
    });
    const filtedrArr = toObjMLSFilterConverter(filters);
    // setIsApply(!isApply);
    // dispatch(updateIsOpenNarrowFilters(false));
    dispatch(
      getAllHouseCardsAction(1, [...arrayData, ...filtedrArr], tempOrder)
    );
    window.scrollTo(0, 0);
    if (currentPage !== 0) setCurrentPage(0);
  };

  const [firstLoadedRegion, setFirstLoadedRegion] = useState(false);

  useEffect(() => {
    if (firstLoadedRegion)
      if (region) {
        if (metro) setMetro(null);
        setFoundMetro([]);
        if (country) setCountry([]);
        setFoundCountry([]);
        if (city) setCity([]);
        setFoundCity([]);
        if (postal) setPostal([]);
        setFoundPostal([]);
      }
    setFirstLoadedRegion(true);
  }, [region]);

  const [firstLoadedMetro, setFirstLoadedMetro] = useState(false);

  useEffect(() => {
    if (firstLoadedMetro)
      if (metro) {
        if (country) setCountry([]);
        setFoundCountry([]);
        if (city) setCity([]);
        setFoundCity([]);
        if (postal) setPostal([]);
        setFoundPostal([]);
      }
    setFirstLoadedMetro(true);
  }, [metro]);

  const [firstLoadedCounty, setFirstLoadedCounty] = useState(false);

  useEffect(() => {
    if (firstLoadedCounty)
      if (country) {
        if (city) setCity([]);
        setFoundCity([]);
        if (postal) setPostal([]);
        setFoundPostal([]);
      }
    setFirstLoadedCounty(true);
  }, [country]);

  const [firstLoadedCity, setFirstLoadedCity] = useState(false);

  useEffect(() => {
    if (firstLoadedCity)
      if (city) {
        setPostal([]);
        setFoundPostal([]);
      }
    setFirstLoadedCity(true);
  }, [city]);

  // useEffect(() => {
  //   filterLocationRegions();
  //   filterLocationMetros();
  //   filterLocationCounties();
  // }, []);

  // const [twCities, setTwCities] = useState([]);
  // const [twCitiesPage, setTwCitiesPage] = useState(1);

  // const [twPostals, setTwPostals] = useState([]);
  // const [twPostalsPage, setTwPostalsPage] = useState(1);

  const observerList = (name, callback) => {
    const lastItem = document.querySelector(
      `.filters-page-multiple-active-input-list-wrapper-li-${name}:last-child`
    );
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // setTwCitiesPage((twCitiesPage) => twCitiesPage + 1);
          callback((c) => c + 1);
          observer.disconnect();
        }
      },
      { threshold: 0 }
    );
    observer.observe(lastItem);
  };

  const [cityInputOpened, setCityInputOpened] = useState(false);
  const [isSearchCity, setIsSearchCity] = useState(false);

  const [postalInputOpened, setPostalInputOpened] = useState(false);
  const [isSearchPostal, setIsSearchPostal] = useState(false);

  // useEffect(() => {
  //   if (!isSearchCity) {
  //     const lastItem = document.querySelector(
  //       ".filters-page-multiple-active-input-list-wrapper-li-cityList"
  //     );
  //     if (
  //       lastItem &&
  //       twCities?.length > 0
  //       // &&
  //       // (!country[0] || country[0] === "")
  //     )
  //       observerList("cityList", setTwCitiesPage);
  //   }
  // }, [twCities.length, cityInputOpened, isSearchCity]);

  // useEffect(() => {
  //   if (!isSearchPostal) {
  //     const lastItem = document.querySelector(
  //       ".filters-page-multiple-active-input-list-wrapper-li-postalList"
  //     );
  //     if (lastItem && twPostals?.length > 0
  //       // && (!city[0] || city[0] === "")
  //       )
  //       observerList("postalList", setTwPostalsPage);
  //   }
  // }, [twPostals.length, postalInputOpened, isSearchPostal]);

  // const setCitiesOrPostalsData = (page, name, top = 16) => {
  //   let data = JSON.stringify({
  //     top: top,
  //     filters: [],
  //     ["elastic-search"]: true,
  //     page: page,
  //     orderby: name === "cities" ? null : "ZipCode",
  //   });
  //   let url = `https://api.realtytexas.net/v1/${name}/cognitive-search`;
  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: url,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };
  //   axios
  //     .request(config)
  //     .then(function (res) {
  //       let arr;
  //       if (name === "cities") {
  //         arr = [...res.data.data.cities];
  //       } else arr = [...res.data.data.zipcodes];
  //       const temp = arr.map((c) => {
  //         if (name === "cities") {
  //           c.label = c["City"];
  //           c.value = c["City"];
  //         } else {
  //           c.label = c["ZipCode"];
  //           c.value = c["ZipCode"];
  //         }
  //         return c;
  //       });
  //       if (name === "cities") {
  //         setTwCities([...twCities, ...temp]);
  //       } else {
  //         setTwPostals([...twPostals, ...temp]);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error, "POST ERROR");
  //     });
  // };

  // useEffect(() => {
  //   setCitiesOrPostalsData(twCitiesPage, "cities");
  // }, [twCitiesPage]);

  // useEffect(() => {
  //   setCitiesOrPostalsData(twPostalsPage, "zip-codes", 100);
  // }, [twPostalsPage]);

  const [isExpanded, setIsExpanded] = useState(true);

  const onTittleHandle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      id="filters-block-filter-filter-location"
      className={isExpanded ? "" : "collapsed-filter-location"}
    >
      <div
        id="filters-block-filter-filter-location-title"
        onClick={onTittleHandle}
      >
        <div>
          <h4>Filter Location</h4>
        </div>
        <div className="filters-block-filter-filter-location-title-icon">
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </div>
      </div>
      <div
        className={
          isExpanded
            ? "filters-block-filter-filter-location-content-wrapper"
            : "filters-block-filter-filter-location-content-wrapper collapsedInput"
        }
      >
        <div className="filters-block-filter-filter-location-content">
          <div>
            <p>Texas Region</p>
          </div>
          <div className="filters-block-filter-filter-location-address-input">
            <BaseActiveInputList
              name="region"
              list={data.regions}
              placeholder="Select Region"
              listTop="49px"
              item={region}
              setItem={setRegion}
              found={foundRegions}
              setFound={setFoundRegions}
              field="region"
              height={50}
              //   parrentValue={false}
              //   parrentName="city"
              callback={filterLocationRegions}
              isLoading={isLoading}
              autocomplete="rssfutjfkde"
            />
          </div>
          {region && (
            <div className="filters-block-filter-filter-location-address-input-tooltip">
              <p>Click 'Apply' to confirm your changes.</p>
            </div>
          )}
        </div>
        <div className="filters-block-filter-filter-location-content">
          <div>
            <p>Metro</p>
          </div>
          <div className="filters-block-filter-filter-location-address-input">
            <BaseActiveInputList
              name="metro"
              list={data.metros}
              placeholder="Select Metro"
              listTop="49px"
              item={metro}
              setItem={setMetro}
              found={foundMetro}
              setFound={setFoundMetro}
              field="Metro"
              height={50}
              parrentValue={region}
              parrentName="region"
              qparent={queryParamsObject?.Region}
              qparentValue="subdomain"
              callback={filterLocationMetros}
              isLoading={isLoading}
              autocomplete="rllfutjfkde"
            />
          </div>
          {metro && (
            <div className="filters-block-filter-filter-location-address-input-tooltip">
              <p>Click 'Apply' to confirm your changes.</p>
            </div>
          )}
        </div>
        <div className="filters-block-filter-filter-location-content">
          <div>
            <p>County</p>
          </div>
          <div className="filters-block-filter-filter-location-address-input-multiple">
            <BaseMultipleActiveInput
              name="county"
              list={data.countries}
              placeholder="Select County"
              listTop="48px"
              item={country}
              setItem={setCountry}
              found={
                country
                  ? foundCountry.filter((obj1) => {
                      if (country) {
                        return !country.some((obj2) => obj1 === obj2);
                      } else {
                        return true;
                      }
                    })
                  : foundCountry
              }
              setFound={setFoundCountry}
              field="County"
              height={50}
              parrentValue={metro}
              parrentName="Metro"
              callback={filterLocationCounties}
              isLoading={isLoading}
              autocomplete="rddsfutjfkde"
            />
          </div>
          {country !== null && country && country?.length !== 0 && (
            <div className="filters-block-filter-filter-location-address-input-tooltip">
              <p>Click 'Apply' to confirm your changes.</p>
            </div>
          )}
        </div>
        <div className="filters-block-filter-filter-location-content">
          <div>
            <p>City</p>
          </div>
          <div className="filters-block-filter-filter-location-address-input-multiple">
            <BaseMultipleActiveInput2v
              name="c17y"
              list={
                (data?.cities && country && country[0] && country[0] !== "") ||
                isSearchCity
                  ? data?.cities
                  : // : filterLocationData?.cities || twCities
                    twCities
              }
              // list2={filterLocationData?.cities || twCities}
              list2={twCities}
              placeholder={
                country && country?.length > 0
                  ? "Select"
                  : "Type city to see results"
              }
              listTop="48px"
              item={city}
              setItem={setCity}
              found={
                city
                  ? foundCity?.filter((obj1) => {
                      if (city) {
                        return !city.some((obj2) => {
                          return obj1.subdomain === obj2.subdomain;
                        });
                      } else {
                        return true;
                      }
                    })
                  : foundCity
              }
              setFound={setFoundCity}
              field="City"
              height={50}
              parrentValue={country}
              parrentName="County"
              // parrentValue2={postal}
              // parrentName2="Postal"
              callback={
                country && country[0] && country[0] !== ""
                  ? filterLocationCities
                  : null
              }
              callback2={
                country && country[0] && country[0] !== ""
                  ? null
                  : filterLocationCities
              }
              isLoading={isLoading}
              classNameLi="cityList"
              setIsOpened={setCityInputOpened}
              isOpened={cityInputOpened}
              setIsSearch={setIsSearchCity}
              autocomplete="ruyrfutjfkde"
            />
          </div>
          {city && city[0] && (
            <div className="filters-block-filter-filter-location-address-input-tooltip">
              <p>Click 'Apply' to confirm your changes.</p>
            </div>
          )}
        </div>
        <div className="filters-block-filter-filter-location-content">
          <div>
            <p>Postal Code</p>
          </div>
          <div className="filters-block-filter-filter-location-address-input-multiple">
            <BaseMultipleActiveInput2v
              name="p057al"
              list={
                (data.postal && city && city[0] && city[0] !== "") ||
                isSearchPostal
                  ? data.postal
                  : // : filterLocationData?.zips || twPostals
                    twPostals
              }
              // list2={filterLocationData?.zips || twPostals}
              list2={twPostals}
              placeholder={
                city && city?.length > 0
                  ? "Select"
                  : "Type postal code to see results"
              }
              listTop="48px"
              item={postal}
              setItem={setPostal}
              found={foundPostal}
              setFound={setFoundPostal}
              field="ZipCode"
              height={50}
              parrentValue={city}
              parrentName="City"
              callback={
                city && city[0] && city[0] !== "" ? filterLocationPostal : null
              }
              callback2={
                city && city[0] && city[0] !== "" ? null : filterLocationPostal
              }
              isLoading={isLoading}
              // initialArrowHide={true}
              classNameLi="postalList"
              setIsOpened={setPostalInputOpened}
              isOpened={postalInputOpened}
              setIsSearch={setIsSearchPostal}
              autocomplete="jgerfutjfkde"
            />
          </div>
          {postal && postal[0] && (
            <div className="filters-block-filter-filter-location-address-input-tooltip">
              <p>Click 'Apply' to confirm your changes.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterLocation;
