import { houseCardsHeardSlice } from "../reducers/houseCardsHeardSlice";

export const updateHouseCardsHeard = (obj) => async (dispatch) => {
  // dispatch(houseCardsHeardSlice.actions.refreshData());
  dispatch(houseCardsHeardSlice.actions.houseCardsHeardFetching());
  dispatch(houseCardsHeardSlice.actions.updateHouseCardsHeard(obj));
};

export const updateHouseCardsHeardData = (obj) => async (dispatch) => {
  dispatch(houseCardsHeardSlice.actions.updateHouseCardsHeardData(obj));
};
