export const initialFilters = {
    quickSearch: null,
    propertyType: null,
    propertySubType: null,
    propertySubTypeList: null,
    filterLocation: {
        region: null,
        metro: null,
        country: null,
        city: null,
        postal: null,
    },
    homeFeatures: {
        sqft: {
            min: null,
            max: null,
        },
        yearBuilt: {
            min: null,
            max: null,
        },
        garagesSpaces: {
            min: null,
            max: null,
        },
        bath: {
            min: null,
            max: null,
        },
        levels: {
            min: null,
            max: null,
        },
        beds: {
            min: null,
            max: null,
        },
        minMaxPriceValues: {
            min: null,
            max: null,
        },
        setMinMaxPrice: {
            min: {
                value: null,
                label: null,
            },
            max: {
                value: null,
                label: null,
            },
        },
        lotSizeAcres: {
            min: null,
            max: null,
        },
        primaryBedOnMain: null,
        fireplace: null,
        accessibilityFeatures: null,
    },
    status: {
        commingSoon: true,
        active: true,
        activeUnderContract: null,
        pending: null,
        closed: null,
    },
    timeFrame: {
        oneDay: null,
        sevenDay: null,
        thirtyDay: null,
    },
    showOnly: {
        ourListings: false,
        openHouses: false,
    },
    map: {
        zoom: null,
        center: null
    }
}