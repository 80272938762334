import React from "react";
import "./pagination.css";
import ReactPaginate from "react-paginate";
import { LeftArrowIcon, RightArrowIcon } from "../../constants/icons.jsx";
import { colors } from "../../constants/colors";

export default function Pagination({
  initialPage,
  marginPagesDisplayed,
  pageCount,
  maxPageCount = 3334,
  pageRangeDisplayed,
  onPageChange,
  currentPage,
}) {
  return (
    <ReactPaginate
      onPageChange={onPageChange}
      breakLabel="..."
      initialPage={initialPage}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      pageCount={pageCount > maxPageCount ? maxPageCount : pageCount}
      containerClassName="pagination"
      activeClassName="pagination_active"
      pageLinkClassName="pagination_page_link"
      breakLinkClassName="pagination_break_link"
      nextLinkClassName="pagination_next_link"
      previousLinkClassName="pagination_prev_link"
      pageClassName="pagination_page_item"
      breakClassName="pagination_break_item"
      nextClassName="pagination_next_item"
      previousClassName="pagination_prev_item"
      nextLabel={<LeftArrowIcon color={colors.blue} />}
      previousLabel={<RightArrowIcon color={colors.blue} />}
      forcePage={currentPage}
    />
  );
}
