import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "../../../constants/colors";
import { AttributeIcon, CloseIcon } from "../../../constants/icons";
import { BaseInputList } from "../../../ui";
import "./HeaderLayerSpecs.css";

const HeaderLayerSpecs = ({
  isPopUpShowSpecs,
  setIsPopUpShowSpecs,
  setIsPopUpShowType,
  setIsPopUpShowLoc,
  applyHandler,
  bathMax,
  setBathMax,
  bathMin,
  setBathMin,
  levelsMin,
  setLevelsMin,
  levelsMax,
  setLevelsMax,
  bedsMin,
  setBedsMin,
  bedsMax,
  setBedsMax,
  sqftMin,
  setSqftMin,
  sqftMax,
  setSqftMax,
  garagesSpacesMin,
  setGaragesSpacesMin,
  garagesSpacesMax,
  setGaragesSpacesMax,
}) => {
  const [applied, setApplied] = useState(null);
  const { isOpenNarrowFilters } = useSelector((state) => state.isResetFilters);

  const headerLayerLocationHandler = () => {
    setIsPopUpShowSpecs(true);
    setIsPopUpShowType(false);
    setIsPopUpShowLoc(false);
  };

  const headerLayerPopupHandler = () => {
    setIsPopUpShowSpecs(false);
  };

  useEffect(() => {
    let counter = null;
    if (sqftMin !== "No Min") {
      counter++;
    }
    if (sqftMax !== "No Max") {
      counter++;
    }
    if (bathMin !== "No Min") {
      counter++;
    }
    if (bathMax !== "No Max") {
      counter++;
    }
    if (levelsMin !== "No Min") {
      counter++;
    }
    if (levelsMax !== "No Max") {
      counter++;
    }
    if (bedsMin !== "No Min") {
      counter++;
    }
    if (bedsMax !== "No Max") {
      counter++;
    }
    if (garagesSpacesMin !== "No Min") {
      counter++;
    }
    if (garagesSpacesMax !== "No Max") {
      counter++;
    }
    setApplied(counter);
  }, [isPopUpShowSpecs, isOpenNarrowFilters]);

  return (
    <div className="HeaderLayerSpecs-wrapper">
      <div
        className="HeaderLayerSpecs-wrapper-content"
        onClick={headerLayerLocationHandler}
      >
        <div className="HeaderLayerSpecs-wrapper-content-title">Specs</div>
        {applied && (
          <div className="HeaderLayerSpecs-wrapper-content-box">
            <div>{applied}</div>
          </div>
        )}
        <div className="HeaderLayerSpecs-wrapper-content-title-icon">
          <div>
            <AttributeIcon />
          </div>
        </div>
        {/* <div className="HeaderLayerSpecs-wrapper-content-box">
          {applied ? (
            `${applied} Applied`
          ) : (
            <span className="HeaderLayerSpecs-wrapper-content-box-none">
              None
            </span>
          )}
        </div> */}
      </div>
      {isPopUpShowSpecs && (
        <SpecsPopup
          headerLayerPopupHandler={headerLayerPopupHandler}
          applyHandler={applyHandler}
          bathMax={bathMax}
          setBathMax={setBathMax}
          bathMin={bathMin}
          setBathMin={setBathMin}
          levelsMin={levelsMin}
          setLevelsMin={setLevelsMin}
          levelsMax={levelsMax}
          setLevelsMax={setLevelsMax}
          bedsMin={bedsMin}
          setBedsMin={setBedsMin}
          bedsMax={bedsMax}
          setBedsMax={setBedsMax}
          sqftMin={sqftMin}
          setSqftMin={setSqftMin}
          sqftMax={sqftMax}
          setSqftMax={setSqftMax}
          garagesSpacesMin={garagesSpacesMin}
          setGaragesSpacesMin={setGaragesSpacesMin}
          garagesSpacesMax={garagesSpacesMax}
          setGaragesSpacesMax={setGaragesSpacesMax}
        />
      )}
    </div>
  );
};

export default HeaderLayerSpecs;

const SpecsPopup = ({
  headerLayerPopupHandler,
  applyHandler,
  bathMax,
  setBathMax,
  bathMin,
  setBathMin,
  levelsMin,
  setLevelsMin,
  levelsMax,
  setLevelsMax,
  bedsMin,
  setBedsMin,
  bedsMax,
  setBedsMax,
  sqftMin,
  setSqftMin,
  sqftMax,
  setSqftMax,
  garagesSpacesMin,
  setGaragesSpacesMin,
  garagesSpacesMax,
  setGaragesSpacesMax,
}) => {
  const resetLocationHandler = () => {
    setSqftMax("No Max");
    setGaragesSpacesMax("No Max");
    setBathMax("No Max");
    setLevelsMax("No Max");
    setBedsMax("No Max");
    setSqftMin("No Min");
    setGaragesSpacesMin("No Min");
    setBathMin("No Min");
    setLevelsMin("No Min");
    setBedsMin("No Min");
  };

  return (
    <div className="SpecsPopup-wrapper">
      <div className="SpecsPopup-wrapper-box">
        <div className="SpecsPopup-wrapper-header-wrapper">Specs Filter</div>
        <div
          className="SpecsPopup-wrapper-header-close"
          onClick={headerLayerPopupHandler}
        >
          <CloseIcon color={colors.mainBg} />
        </div>
        <div className="SpecsPopup-wrapper-header-content-block">
          <SpecsPopupBath
            bathMax={bathMax}
            setBathMax={setBathMax}
            bathMin={bathMin}
            setBathMin={setBathMin}
          />
          <SpecsPopupLevels
            levelsMin={levelsMin}
            setLevelsMin={setLevelsMin}
            levelsMax={levelsMax}
            setLevelsMax={setLevelsMax}
          />
          <SpecsPopupBeds
            bedsMin={bedsMin}
            setBedsMin={setBedsMin}
            bedsMax={bedsMax}
            setBedsMax={setBedsMax}
          />
          <SpecsPopupSqft
            sqftMin={sqftMin}
            setSqftMin={setSqftMin}
            sqftMax={sqftMax}
            setSqftMax={setSqftMax}
          />
          <SpecsPopupGaragesSpaces
            garagesSpacesMin={garagesSpacesMin}
            setGaragesSpacesMin={setGaragesSpacesMin}
            garagesSpacesMax={garagesSpacesMax}
            setGaragesSpacesMax={setGaragesSpacesMax}
          />
        </div>
        <div className="SpecsPopup-wrapper-header-btns-block">
          <SpecsReset
            resetLocationHandler={resetLocationHandler}
            headerLayerPopupHandler={headerLayerPopupHandler}
          />
          <SpecsApply
            applyHandler={applyHandler}
            headerLayerPopupHandler={headerLayerPopupHandler}
          />
        </div>
      </div>
    </div>
  );
};

const SpecsPopupBath = ({ bathMax, setBathMax, bathMin, setBathMin }) => {
  const mokedListBath = [1, 2, 3, 4, 5];
  const [bath, setBath] = useState(0);
  const [levels, setLevels] = useState(0);
  const [beds, setBeds] = useState(0);
  return (
    <div className="filters-block-filter-filter-location-content">
      <div className="filters-block-filter-details-input-list-block-title">
        <p>Bath</p>
      </div>
      <div className="filters-block-filter-details-input-list-block-content">
        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              bathMin !== "No Min"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              bathMax === "No Max"
                ? ["No Min", ...mokedListBath]
                : bathMax !== null
                ? ["No Min", ...mokedListBath.filter((item) => item <= bathMax)]
                : ["No Min", ...mokedListBath]
            }
            placeholder="No Min"
            listTop="48px"
            inputValue={bathMin}
            setInputValue={setBathMin}
            height={50}
          />
        </div>

        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              bathMax !== "No Max"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              bathMin === "No Min"
                ? ["No Max", ...mokedListBath]
                : bathMin !== null
                ? ["No Max", ...mokedListBath.filter((item) => item >= bathMin)]
                : ["No Max", ...mokedListBath]
            }
            placeholder="No Max"
            listTop="48px"
            inputValue={bathMax}
            setInputValue={setBathMax}
            height={50}
          />
        </div>
      </div>
    </div>
  );
};

const SpecsPopupLevels = ({
  levelsMin,
  setLevelsMin,
  levelsMax,
  setLevelsMax,
}) => {
  const mokedListLevels = [1, 1.5, 2, 3];

  return (
    <div className="filters-block-filter-filter-location-content">
      <div className="filters-block-filter-details-input-list-block-title">
        <p>Levels</p>
      </div>
      <div className="filters-block-filter-details-input-list-block-content">
        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              levelsMin !== "No Min"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              levelsMax === "No Max"
                ? ["No Min", ...mokedListLevels]
                : levelsMax !== null
                ? [
                    "No Min",
                    ...mokedListLevels.filter((item) => item <= levelsMax),
                  ]
                : ["No Min", ...mokedListLevels]
            }
            placeholder="No Min"
            listTop="48px"
            inputValue={levelsMin}
            setInputValue={setLevelsMin}
            height={50}
          />
        </div>

        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              levelsMax !== "No Max"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              levelsMin === "No Min"
                ? ["No Max", ...mokedListLevels]
                : levelsMin !== null
                ? [
                    "No Max",
                    ...mokedListLevels.filter((item) => item >= levelsMin),
                  ]
                : ["No Max", ...mokedListLevels]
            }
            placeholder="No Max"
            listTop="48px"
            inputValue={levelsMax}
            setInputValue={setLevelsMax}
            height={50}
          />
        </div>
      </div>
    </div>
  );
};

const SpecsPopupBeds = ({ bedsMin, setBedsMin, bedsMax, setBedsMax }) => {
  const mokedListBeds = [1, 2, 3, 4, 5, 6, 7];

  return (
    <div className="filters-block-filter-filter-location-content">
      <div className="filters-block-filter-details-input-list-block-title">
        <p>Beds</p>
      </div>
      <div className="filters-block-filter-details-input-list-block-content">
        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              bedsMin !== "No Min"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              bedsMax === "No Max"
                ? ["No Min", ...mokedListBeds]
                : bedsMax !== null
                ? ["No Min", ...mokedListBeds.filter((item) => item <= bedsMax)]
                : ["No Min", ...mokedListBeds]
            }
            placeholder="No Min"
            listTop="48px"
            inputValue={bedsMin}
            setInputValue={setBedsMin}
            height={50}
          />
        </div>

        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              bedsMax !== "No Max"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              bedsMin === "No Min"
                ? ["No Max", ...mokedListBeds]
                : bedsMin !== null
                ? ["No Max", ...mokedListBeds.filter((item) => item >= bedsMin)]
                : ["No Max", ...mokedListBeds]
            }
            placeholder="No Max"
            listTop="48px"
            inputValue={bedsMax}
            setInputValue={setBedsMax}
            height={50}
          />
        </div>
      </div>
    </div>
  );
};

const SpecsPopupSqft = ({ sqftMin, setSqftMin, sqftMax, setSqftMax }) => {
  const mokedListSqft = [];
  for (let index = 0; index < 30; index++) {
    mokedListSqft.push(250 + 250 * index);
  }
  return (
    <div className="filters-block-filter-filter-location-content">
      <div className="filters-block-filter-details-input-list-block-title">
        <p>Sqft</p>
      </div>
      <div className="filters-block-filter-details-input-list-block-content">
        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              sqftMin !== "No Min"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              sqftMax === "No Max"
                ? ["No Min", ...mokedListSqft]
                : sqftMax !== null
                ? ["No Min", ...mokedListSqft.filter((item) => item <= sqftMax)]
                : ["No Min", ...mokedListSqft]
            }
            placeholder="No Min"
            listTop="48px"
            inputValue={sqftMin}
            setInputValue={setSqftMin}
            height={50}
          />
        </div>

        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              sqftMax !== "No Max"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              sqftMin === "No Min"
                ? ["No Max", ...mokedListSqft]
                : sqftMin !== null
                ? ["No Max", ...mokedListSqft.filter((item) => item >= sqftMin)]
                : ["No Max", ...mokedListSqft]
            }
            placeholder="No Max"
            listTop="48px"
            inputValue={sqftMax}
            setInputValue={setSqftMax}
            height={50}
          />
        </div>
      </div>
    </div>
  );
};

const SpecsPopupGaragesSpaces = ({
  garagesSpacesMin,
  setGaragesSpacesMin,
  garagesSpacesMax,
  setGaragesSpacesMax,
}) => {
  const mokedListGaragesSpaces = [1, 2, 3, 4];

  return (
    <div className="filters-block-filter-filter-location-content">
      <div className="filters-block-filter-details-input-list-block-title">
        <p>Garages Spaces</p>
      </div>
      <div className="filters-block-filter-details-input-list-block-content">
        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              garagesSpacesMin !== "No Min"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              garagesSpacesMax === "No Max"
                ? ["No Min", ...mokedListGaragesSpaces]
                : garagesSpacesMax !== null
                ? [
                    "No Min",
                    ...mokedListGaragesSpaces.filter(
                      (item) => item <= garagesSpacesMax
                    ),
                  ]
                : ["No Min", ...mokedListGaragesSpaces]
            }
            placeholder="No Min"
            listTop="48px"
            inputValue={garagesSpacesMin}
            setInputValue={setGaragesSpacesMin}
            height={50}
          />
        </div>

        <div className="filters-block-filter-details-input-list-block-input">
          <BaseInputList
            blueList={true}
            className={`${
              garagesSpacesMax !== "No Max"
                ? "filters-block-filter-details-input-list-block-input-blue-color"
                : "filters-block-filter-details-input-list-block-input-gray-color"
            }`}
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={
              garagesSpacesMin === "No Min"
                ? ["No Max", ...mokedListGaragesSpaces]
                : garagesSpacesMin !== null
                ? [
                    "No Max",
                    ...mokedListGaragesSpaces.filter(
                      (item) => item >= garagesSpacesMin
                    ),
                  ]
                : ["No Max", ...mokedListGaragesSpaces]
            }
            placeholder="No Max"
            listTop="48px"
            inputValue={garagesSpacesMax}
            setInputValue={setGaragesSpacesMax}
            height={50}
          />
        </div>
      </div>
    </div>
  );
};

const SpecsApply = ({ applyHandler, headerLayerPopupHandler }) => {
  const locationApplyHandler = () => {
    applyHandler();
    headerLayerPopupHandler();
  };

  return (
    <div className="SpecsApply-wrapper" onClick={locationApplyHandler}>
      <div className="SpecsApply-apply-wrapper">
        <div>Apply</div>
      </div>
    </div>
  );
};

const SpecsReset = ({ headerLayerPopupHandler, resetLocationHandler }) => {
  const locationResetHandler = () => {
    resetLocationHandler();
    // headerLayerPopupHandler();
  };

  return (
    <div className="SpecsReset-wrapper" onClick={locationResetHandler}>
      <div className="SpecsReset-apply-wrapper">
        <div>Clear</div>
      </div>
    </div>
  );
};
