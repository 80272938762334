import {
    combineReducers,
    configureStore
} from "@reduxjs/toolkit";
import houseCardsReducer from "./reducers/houseCardsSlice";
import houseCardsMapReducer from "./reducers/houseCardsMapSlice";
import houseCardsHeardReducer from "./reducers/houseCardsHeardSlice";
import mapCoordinatesReducer from "./reducers/mapCoordimatesSlice";
import filterLocationListsReducer from "./reducers/filterLocationListsSlice";
import isResetFiltersReducer from "./reducers/isResetFiltersSlice";
import houseCardsHeardNotesReducer from "./reducers/houseCardsHeardNotesSlice";
import isMapReducer from "./reducers/isMapSlice";
import filterLocationDataReducer from "./reducers/filterLocationDataSlice";

const combinedReducer = combineReducers({
    houseCards: houseCardsReducer,
    houseCardsMap: houseCardsMapReducer,
    houseCardsHeard: houseCardsHeardReducer,
    mapCoordinates: mapCoordinatesReducer,
    filterLocationLists: filterLocationListsReducer,
    filterLocationData: filterLocationDataReducer,
    isResetFilters: isResetFiltersReducer,
    houseCardsHeardNotes: houseCardsHeardNotesReducer,
    isMap: isMapReducer,
});

const rootReducer = (state, action) => {
    return combinedReducer(state, action);
};

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
    });
};

// export const store = configureStore({
//   reducer: {
//     houseCards: houseCardsReducer,
//   },
// });