import React from "react";
import "./clickForPremiumAccess.css";

const ClickForPremiumAccess = () => {
  const clickHandle = (event) => {
    const RTUnlockPremiumFeaturesEvent = new CustomEvent(
      "RTUnlockPremiumFeatures",
      {
        detail: {
          element: event.currentTarget,
        },
      }
    );
    document.dispatchEvent(RTUnlockPremiumFeaturesEvent);
  };

  return (
    <div className="click-for-premium-access-wrapper" onClick={clickHandle}>
      Click for Premium Access
    </div>
  );
};

export default ClickForPremiumAccess;
