import React, { useEffect } from "react";
import { colors } from "../../constants/colors";
import { CloseIcon } from "../../constants/icons";
import { ClickOutside } from "../../ui";
import "./alertMapMessage.css";

const AlertMapMessage = ({
  header,
  content1,
  content2,
  onCloseClick = false,
  headerColor = false,
  contentColor = false,
  isCloseBtn = false,
}) => {
  const handleClickOutside = () => {
    if (onCloseClick) onCloseClick();
  };

  return (
    <div className="alert-map-message-wrapper">
      {isCloseBtn && (
        <div className="alert-map-message-close" onClick={handleClickOutside}>
          <CloseIcon width={24} height={24} />
        </div>
      )}
      <div className="alert-map-message-content">
        {header && (
          <div className="alert-map-message-content-header">
            <span
              style={{ color: headerColor ? headerColor : colors.bodyText }}
            >
              {header}
            </span>
          </div>
        )}
        <div className="alert-map-message-content-text-box">
          {content1 && (
            <div className="alert-map-message-content-text">
              <p
                style={{ color: contentColor ? contentColor : colors.bodyText }}
              >
                {content1}
              </p>
            </div>
          )}
          {content2 && (
            <div className="alert-map-message-content-text">
              <p
                style={{ color: contentColor ? contentColor : colors.bodyText }}
              >
                {content2}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertMapMessage;
