/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteIcon,
  FavoriteIcon,
  NoteIcon,
  PossibilityIcon,
} from "../../constants/icons.jsx";
import axios from "axios";
import { isUserLogin } from "../../constants/methods.js";
import { updateHouseCardsHeard } from "../../store/actions/updateHouseCardsHeard.js";
import "./houseCardHeardList.css";
import "./responsive.css";

const HouseCardHeardList = ({
  isHeardActive,
  setIsHeardActive,
  index,
  item,
  note,
  setNote,
  setAlertBox,
  setAlertNoUserBox,
  alertBox,
  alertNoUserBox,
  setNoteBox,
  alertNoUserMessageBox,
  setAlertNoUserMessageBox,
}) => {
  const { data } = useSelector((state) => state.houseCardsHeard);
  const isMap = useSelector((state) => state.isMap);
  const dispatch = useDispatch();

  const isUserLogined = isUserLogin();

  let click = 0;

  function clickUp() {
    click++;
    if (alertBox) setAlertBox(false);
    if (alertNoUserBox) setAlertNoUserBox(false);
  }

  function clickReset() {
    click = 0;
  }

  const eventCallBack = (eventName, val) => {
    dispatch(updateHouseCardsHeard({ id: index, value: val }));
    //we will send the data to backend server TODO
    const GatewaySavedEvent = new CustomEvent(eventName, {
      detail: {
        Listing: item,
      },
    });
    window.document.dispatchEvent(GatewaySavedEvent);
  };

  const setPostAction = (val, note = undefined) => {
    if (isUserLogined) {
      let postData = {
        action: val,
      };
      if (note !== undefined && note !== null && note !== "") {
        postData.note = note;
      }
      axios
        .post(
          `https://api.realtytexas.net/v1/clients/${isUserLogined}/listings/${item?.ListingKeyNumeric}/action`,
          postData
        )
        .then(function (response) {
          // console.log(response, "resp");
        })
        .catch(function (error) {
          console.log(error, "post error");
        });
    }
  };

  const setItemToLocStorage = (eventName, val) => {
    const listings = {
      id: `${item.ListingKeyNumeric.toString()}`,
      value: val,
    };
    const data = JSON.parse(localStorage.getItem("listingInteractions"));
    if (!isUserLogined) {
      if (data !== null) {
        const filtered = data?.listings?.filter((data, i) => {
          return data.id != listings.id;
        });
        localStorage.setItem(
          "listingInteractions",
          JSON.stringify({ userId: null, listings: [...filtered, listings] })
        );
      } else {
        localStorage.setItem(
          "listingInteractions",
          JSON.stringify({ userId: null, listings: [listings] })
        );
      }
      setAlertNoUserBox(true);
    } else {
      const myRTP = JSON?.parse(localStorage?.getItem("MyRTP"));
      const userId = myRTP?.SubjectID;
      if (data !== null) {
        const filtered = data?.listings?.filter((data, i) => {
          return data.id != listings.id;
        });
        localStorage.setItem(
          "listingInteractions",
          JSON.stringify({ userId: userId, listings: [...filtered, listings] })
        );
      } else {
        localStorage.setItem(
          "listingInteractions",
          JSON.stringify({ userId: userId, listings: [listings] })
        );
      }
    }
    eventCallBack(eventName, val);
  };

  function heardValueHandler(cls) {
    //close textarea on change note icon
    if (cls !== "filters-page-house-card-heard-list-note" && note === true) {
      setNote(false);
      setNoteBox(false);
    }
    switch (cls) {
      case "filters-page-house-card-heard-list-saved":
        setItemToLocStorage("GatewaySavedEvent", 1);
        setPostAction(1);
        break;
      case "filters-page-house-card-heard-list-favorite":
        setItemToLocStorage("GatewayFavoriteEvent", 2);
        setPostAction(2);
        break;
      case "filters-page-house-card-heard-list-possibility":
        setItemToLocStorage("GatewayPossibilityEvent", 3);
        setPostAction(3);
        break;
      case "filters-page-house-card-heard-list-delete":
        setItemToLocStorage("GatewayDeleteEvent", 4);
        setPostAction(4);
        break;
      case "filters-page-house-card-heard-list-note":
        let hasAction = data.find((itm) => itm.id === item.ListingKeyNumeric);
        if (hasAction.value !== undefined) {
          if (!isUserLogined) {
            if (!alertNoUserMessageBox) setAlertNoUserMessageBox(true);
          } else setNoteBox(true);
        } else {
          setAlertBox(true);
        }
        break;
      default:
        return;
    }
    setIsHeardActive(false);
    clickReset();
  }

  const onClickOutside = (e) => {
    clickUp();
    if (click > 1) {
      if (
        !e.target.classList.contains(
          "filters-page-house-card-heard-list-opened"
        )
      ) {
        setIsHeardActive(false);
        clickReset();
      } else {
        heardValueHandler(e.target.classList[0]);
      }
    }
  };

  useEffect(() => {
    if (isHeardActive === true) {
      document.addEventListener("click", onClickOutside);
      return () => {
        document.removeEventListener("click", onClickOutside);
      };
    }
  }, [isHeardActive]);
  return (
    <div
      className={
        isHeardActive
          ? isMap.data === false
            ? "filters-page-house-card-heard-list-wrapper-active-list filters-page-house-card-heard-list-wrapper-active filters-page-house-card-heard-list-opened"
            : "filters-page-house-card-heard-list-wrapper-active filters-page-house-card-heard-list-opened"
          : "filters-page-house-card-heard-list-wrapper filters-page-house-card-heard-list-opened"
      }
    >
      <div className="filters-page-house-card-heard-list-favorite filters-page-house-card-heard-list-ul filters-page-house-card-heard-list-opened">
        <div className="filters-page-house-card-heard-list-favorite filters-page-house-card-heard-list-opened">
          <FavoriteIcon />
        </div>
      </div>
      <div className="filters-page-house-card-heard-list-possibility filters-page-house-card-heard-list-ul filters-page-house-card-heard-list-opened">
        <div className="filters-page-house-card-heard-list-possibility filters-page-house-card-heard-list-opened">
          <PossibilityIcon />
        </div>
      </div>

      <div className="filters-page-house-card-heard-list-delete filters-page-house-card-heard-list-ul filters-page-house-card-heard-list-opened">
        <div className="filters-page-house-card-heard-list-delete filters-page-house-card-heard-list-opened">
          <DeleteIcon />
        </div>
      </div>
      <div className="filters-page-house-card-heard-list-note filters-page-house-card-heard-list-ul filters-page-house-card-heard-list-opened">
        <div className="filters-page-house-card-heard-list-note filters-page-house-card-heard-list-opened">
          <NoteIcon />
        </div>
      </div>
    </div>
  );
};

export default HouseCardHeardList;
