import React, { useState } from "react";
import { ClickOutside } from "../../ui";
import "./baWithTooltip.css";

const BAWithTooltip = ({ item }) => {
  const [isBA, setIsBA] = useState(false);

  const clickOutsideHandler = () => {
    setIsBA(false);
  };

  let status = false;
  if (
    item?.MlsStatus === "Active" ||
    item?.MlsStatus === "ActiveUnderContract" ||
    item?.MlsStatus === "ComingSoon" ||
    item?.MlsStatus === "Contingent" ||
    item?.MlsStatus === "Incomplete" ||
    item?.MlsStatus === "Pending"
  )
    status = true;
  if (item?.BuyerAgencyCompensation && item?.rtlist === true && status)
    return (
      <div className="house-card-BA-component-tooltip-wrapper">
        {isBA && (
          <>
            <div className="house-card-BA-component-tooltip">
              <p>
                Seller Offering {item?.BuyerAgencyCompensation} Toward Buyer
                Broker Fees.
              </p>
            </div>
            <div className="house-card-BA-component-tooltip-triangle" />
          </>
        )}

        <ClickOutside onClick={clickOutsideHandler}>
          <div
            className="house-card-BA-component"
            onClick={() => setIsBA(!isBA)}
          >
            <div className="house-card-BA-component-inner">
              <div className="house-card-BA-component-content">
                <p>
                  BA:
                  {item?.BuyerAgencyCompensation}
                </p>
              </div>
            </div>
          </div>
        </ClickOutside>
      </div>
    );
};

export default BAWithTooltip;
