/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { colors } from "../../constants/colors";
import { CloseIcon, DefaultAvatarIcon } from "../../constants/icons";
import { Spiner } from "../../ui";

import "./noteContentPopUp.css";

const NoteContentPopUp = ({
  isVisible,
  setIsVisible,
  noteData,
  notesLoading = null,
  narrowView = false,
}) => {
  const closeIconHandle = () => {
    setIsVisible(false);
  };

  const isMap = useSelector((state) => state.isMap);

  return (
    <div
      className={
        narrowView
          ? isVisible
            ? "filters-page-house-card-note-content-popup-narrow"
            : "filters-page-house-card-note-content-popup-disabled"
          : isVisible
          ? isMap.data === false
            ? "filters-page-house-card-note-content-popup filters-page-house-card-note-content-popup-list"
            : "filters-page-house-card-note-content-popup"
          : "filters-page-house-card-note-content-popup-disabled"
      }
    >
      <div
        className="filters-page-house-card-note-content-popup-close"
        onClick={closeIconHandle}
      >
        <CloseIcon
          color={colors.gray}
          width={narrowView ? "20px" : "18px"}
          height={narrowView ? "21px" : "19px"}
        />
      </div>
      {notesLoading === false && (
        <div className={narrowView ? "dd-narrow" : "dd"}>
          <div className="filters-page-house-card-note-content-popup-wrapper">
            <div className="filters-page-house-card-note-content-popup-content-wrapper">
              {noteData?.map((note, i) => {
                const dateTime = new Date(note?.created_at);
                const currentDate = new Date();
                const difference_In_Time =
                  currentDate.getTime() - dateTime.getTime();
                const difference_In_Days =
                  difference_In_Time / (1000 * 3600 * 24);
                const minutes = dateTime.getMinutes();
                const hours = dateTime.getHours();
                const day = dateTime.getDate();
                const month = dateTime.getMonth();
                const year = dateTime.getFullYear();
                return (
                  <div
                    className={
                      narrowView
                        ? note?.creator_is_client
                          ? "filters-page-house-card-note-content-popup-content-message-user-narrow filters-page-house-card-note-content-popup-content-message-wrapper-narrow"
                          : "filters-page-house-card-note-content-popup-content-message-agent-narrow filters-page-house-card-note-content-popup-content-message-wrapper-narrow"
                        : note?.creator_is_client
                        ? "filters-page-house-card-note-content-popup-content-message-user filters-page-house-card-note-content-popup-content-message-wrapper"
                        : "filters-page-house-card-note-content-popup-content-message-agent filters-page-house-card-note-content-popup-content-message-wrapper"
                    }
                    key={i}
                  >
                    <div
                      className={
                        narrowView
                          ? note?.creator_is_client
                            ? "filters-page-house-card-note-content-popup-content-user-avatar-narrow filters-page-house-card-note-content-popup-content-avatar-narrow"
                            : "filters-page-house-card-note-content-popup-content-agent-avatar-narrow filters-page-house-card-note-content-popup-content-avatar-narrow"
                          : note?.creator_is_client
                          ? "filters-page-house-card-note-content-popup-content-user-avatar filters-page-house-card-note-content-popup-content-avatar"
                          : "filters-page-house-card-note-content-popup-content-agent-avatar filters-page-house-card-note-content-popup-content-avatar"
                      }
                    >
                      <DefaultAvatarIcon />
                    </div>
                    <div className="filters-page-house-card-note-content-popup-content-text">
                      <p>{note?.note}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: "flex-end",
                        flexDirection: narrowView ? "column" : "row",
                      }}
                    >
                      {/* {difference_In_Days <= 1 && ( */}
                      <div className="filters-page-house-card-note-content-popup-content-date">
                        {/* {hours}:{minutes} */}
                        {note?.CreatedAtTime}
                      </div>
                      {/* )} */}
                      {/* {difference_In_Days > 1 && ( */}
                      <div className="filters-page-house-card-note-content-popup-content-date">
                        {/* {day < 10 ? "0" + day : day}.
                        {month < 10 ? "0" + month : month}.{year} */}
                        {note?.CreatedAtDate}
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {notesLoading === true && <Spiner />}
    </div>
  );
};

export default NoteContentPopUp;
