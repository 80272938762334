import React from "react";
import "./buttonsBlock.css";

const ButtonsBlock = ({ applyHandler, resetHandler, priceInfoLoading }) => {
  return (
    <div className="filters-block-filters-buttons-block-wrapper-sticky">
      <div id="filters-block-filters-buttons-block-wrapper">
        <button
          id="filters-block-filters-buttons-block-reset"
          onClick={resetHandler}
        >
          Reset
        </button>
        {!priceInfoLoading && (
          <button
            id="filters-block-filters-buttons-block-apply"
            onClick={applyHandler}
          >
            Apply
          </button>
        )}
        {priceInfoLoading && (
          <button id="filters-block-filters-buttons-block-apply-loading">
            Apply
          </button>
        )}
      </div>
    </div>
  );
};

export default ButtonsBlock;
