/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./map.css";
import "./responsive.css";
import GoogleMapReact from "google-map-react";
import { CloseIcon } from "../../constants/icons.jsx";
import { useDispatch, useSelector } from "react-redux";
import { updateMapCoordinates } from "../../store/actions/updateMapCoordinates";
import { colors } from "../../constants/colors";
import axios from "axios";
import { isUserLogin, toObjFilterConverter } from "../../constants/methods";
import supercluster from "supercluster";
import { useSearchParams, useLocation } from "react-router-dom";
import AlertMapMessage from "../AlertMapMessage/alertMapMessage";
import { BaseInputMapList, ClickOutside, Spiner } from "../../ui";
import { houseCardsMapSlice } from "../../store/reducers/houseCardsMapSlice";
import { mokedMapViewPagesList } from "../../constants/viewMapPagesList";
import { getAllBothViewMapHouseCards } from "../../store/actions/getAllBothViewMapHouseCards";
import TripleSwitcherSliderButton from "../../ui/TripleToggleViewButton/TripleSwitcherSliderButton";
import { updateIsOpenNarrowFilters } from "../../store/actions/updateIsOpenNarrowFilters";
import MapOverlaysBtn from "../MapOverlaysBtn/MapOverlaysBtn";
import DrawOnMap from "../DrawOnMap/DrawOnMap";
import {
  googleToPolygonClippingFormat,
  polygonClippingToGoogleFormat,
  polygonClippingToGoogleFormatLatLng,
} from "./polygonClipping";
// import polygonClipping from 'polygon-clipping';
import * as polygonClipping from "polygon-clipping";
import { resetMapCoordinates } from "../../store/actions/resetMapCoordinates";
import { getMapCoordinates } from "../../constants/mapFunctions";

export default function Map({
  filters,
  sortingValue,
  mapZoom,
  setMapZoom,
  currentCenter,
  setCurrentCenter,
  polygonData,
  setPolygonData,
  polygonsData,
  setPolygonsData,
  currentPolygon,
  setCurrentPolygon,
  currentPolygons,
  setCurrentPolygons,
  subdomainData,
  getSwitchAnimation,
  isApply,
  currentMap,
  setCurrentMap,
  currentMaps,
  setCurrentMaps,
  dataInRectPage,
  setDataInRectPage,
  state,
  setState,
  sliderState,
  screenSize,
  currentLayersPolygon,
  setCurrentLayersPolygon,
  currentLayersPolygons,
  setCurrentLayersPolygons,
  isDraw,
  setIsDraw,
  setIsMultyDraw,
  isMultyDraw,
  dataInPolyline,
  setDataInPolyline,
  dataInMultyPolygones,
  setDataInMultyPolygones,
  drawCoordinates,
  setDrawCoordinates,
  currentCenterCoord,
  setCurrentCenterCoord,
  setIsOurListings,
  isOurListings,
  applyHandler,
  setFilters,
  twCities,
  twPostals,
}) {
  const { data } = useSelector((state) => state.houseCards);
  const houseCardDataInRect = useSelector((state) => state.houseCardsMap);
  const isMap = useSelector((state) => state.isMap);
  const mapCoordinates = useSelector((state) => state.mapCoordinates);
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Convert all query parameters to an object
  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });

  let homeCoordinates = { lat: 30.266666, lng: -97.73333 };

  if (isMap !== false && subdomainData) {
    homeCoordinates = subdomainData?.center;
  }

  const [markerData, setMarkerData] = useState(null);
  // const [currentMaps, setCurrentMaps] = useState(null);
  const [clusters, setClusters] = useState([]);
  const [clustersOver15zoom, setClustersOver15zoom] = useState([]);
  const [clustersSaved, setClustersSaved] = useState(false);
  const [clustersSaved2, setClustersSaved2] = useState(false);

  const [isSatelliteMapId, setIsSatelliteMapId] = useState(false);

  const onMapLayerHandler = () => {
    setIsSatelliteMapId(!isSatelliteMapId);
  };

  // const [isDraw, setIsDraw] = useState(null)

  const [supC, setSupC] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentCenterLat, setCurrentCenterLat] = useState(null);
  const [currentCenterLng, setCurrentCenterLng] = useState(null);

  const setZoomToUrl = (zoom) => {
    searchParams.set("Zoom", zoom);
    setSearchParams(searchParams);
  };

  const setCenterLatToUrl = (lat) => {
    searchParams.set("Lat", lat);
    setSearchParams(searchParams);
    setCurrentCenterLat(lat);
  };

  const setCenterLngToUrl = (lng) => {
    searchParams.set("Lng", lng);
    setSearchParams(searchParams);
    setCurrentCenterLng(lng);
  };

  const toCloseMap = () => {
    getSwitchAnimation("left");
  };

  const isFilterLocation = () => {
    if (
      filters?.filterLocation?.postal &&
      filters?.filterLocation?.postal[0]?.Latitude
    ) {
      return {
        lat: +filters.filterLocation.postal[0].Latitude,
        lng: +filters.filterLocation.postal[0].Longitude,
      };
    }
    if (
      filters?.filterLocation?.city &&
      filters?.filterLocation?.city[0]?.Latitude
    ) {
      return {
        lat: +filters.filterLocation.city[0].Latitude,
        lng: +filters.filterLocation.city[0].Longitude,
      };
    }
    if (
      filters?.filterLocation?.country &&
      filters?.filterLocation?.country[0]?.Latitude
    ) {
      return {
        lat: +filters.filterLocation.country[0].Latitude,
        lng: +filters.filterLocation.country[0].Longitude,
      };
    }
    if (
      filters?.filterLocation?.metro &&
      filters?.filterLocation?.metro?.Latitude
    ) {
      return {
        lat: +filters.filterLocation.metro.Latitude,
        lng: +filters.filterLocation.metro.Longitude,
      };
    }
    if (
      filters?.filterLocation?.region &&
      filters?.filterLocation?.region?.Latitude
    ) {
      return {
        lat: +filters.filterLocation.region.Latitude,
        lng: +filters.filterLocation.region.Longitude,
      };
    }
    return null;
  };

  const isZoomLocation = () => {
    if (
      filters?.filterLocation?.postal &&
      filters?.filterLocation?.postal[0]?.map_zoom
    ) {
      return +filters?.filterLocation?.postal[0]?.map_zoom;
    }
    if (
      filters?.filterLocation?.city &&
      filters?.filterLocation?.city[0]?.map_zoom
    ) {
      return +filters?.filterLocation?.city[0]?.map_zoom;
    }
    if (
      filters?.filterLocation?.country &&
      filters?.filterLocation?.country[0]?.map_zoom
    ) {
      return +filters?.filterLocation?.country[0]?.map_zoom;
    }
    if (
      filters?.filterLocation?.metro &&
      filters?.filterLocation?.metro?.map_zoom
    ) {
      return +filters?.filterLocation?.metro?.map_zoom;
    }
    if (
      filters?.filterLocation?.region &&
      filters?.filterLocation?.region?.map_zoom
    ) {
      return +filters?.filterLocation?.region?.map_zoom;
    }
    return null;
  };

  const setCoord = () => {
    if (data?.listings[0]) {
      let obj = isFilterLocation();
      let currentZoom = isZoomLocation();
      if (!obj?.lat) {
        obj = {
          lat: +defaultProps.center.lat,
          lng: +defaultProps.center.lng,
        };
      }
      if (!queryParamsObject?.Lng && !queryParamsObject?.Lat) {
        setCurrentCenter(obj);
      }
      if (!queryParamsObject?.Zoom) {
        setMapZoom(currentZoom ? currentZoom : defaultProps.zoom);
      }
    }
  };

  useEffect(() => {
    if (data?.listings?.length > 0) {
      setCoord();
    }
  }, [data, isMap.data, houseCardDataInRect?.data]);

  const [newCoord, setNewCoord] = useState(null);

  useEffect(() => {
    if (
      newCoord?.EndPoint?.lat !== mapCoordinates?.data?.EndPoint?.lat &&
      newCoord?.EndPoint?.lng !== mapCoordinates?.data?.EndPoint?.lng
    ) {
      setNewCoord(mapCoordinates.data);
    }
  }, [mapCoordinates?.data]);

  // useEffect(() => {
  //   console.log(newCoord,'newCoord', mapCoordinates?.data)
  // }, [mapCoordinates?.data]);

  useEffect(() => {
    if (isMap.data !== false) {
      // setIsDraw(false)
      // setIsMultyDraw(false)
      setDataInRectPage({
        label: "Page 1",
        value: 1,
      });
    }
    if (isMap.data === false) {
      setIsDraw(null);
      setIsMultyDraw(null);
    }
  }, [isMap.data]);

  // const [isLoadedDataDisplaying, setIsLoadedDataDisplaying] = useState(false)

  // useEffect(()=>{
  //     setIsLoadedDataDisplaying(true)
  // },[isMap.data])

  // useEffect(() => {
  //       let tempOrder = null;
  //       if (
  //         sortingValue?.orderBySort === "desc" ||
  //         sortingValue?.orderBySort === "asc"
  //       ) {
  //         tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
  //       }
  //       const filtedrArr = toObjFilterConverter(filters);
  //       if (mapCoordinates?.data?.EndPoint?.lat && (
  //           mapCoordinates?.data?.NorthEast?.lat !== mapCoordinates?.data?.SouthWest?.lat)) {
  //         dispatch(
  //           getAllBothViewMapHouseCards(
  //             mapCoordinates?.data,
  //             filtedrArr,
  //             tempOrder,
  //             dataInRectPage.value,
  //             setIsLoadedDataDisplaying
  //           )
  //         )
  //       }
  // }, [mapCoordinates?.data?.EndPoint?.lat, mapCoordinates?.data?.EndPoint?.lng, dataInRectPage?.value]);

  const setRectangleMapCoordinates = (obj) => {
    dispatch(updateMapCoordinates(obj));
  };

  //   const stylesArray = [
  //     {
  //       featureType: "administrative.neighborhood",
  //       elementType: "labels",
  //       stylers: [
  //         {
  //           visibility: "off",
  //         },
  //       ],
  //     },
  //     {
  //       featureType: "poi",
  //       stylers: [{ visibility: "off" }],
  //     },
  //   ];

  const stylesArray = [
    {
      featureType: "poi.park",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  const defaultProps = {
    center: homeCoordinates,
    zoom: isMap !== false && subdomainData?.zoom ? subdomainData.zoom : 13,
  };

  // const getMapCoordinates = (map) => {
  //   if (map) {
  //     let bounds = map.getBounds();
  //     let NECornerMap = bounds.getNorthEast();
  //     let SWCornerMap = bounds.getSouthWest();
  //     let NECorner = { lat: NECornerMap.lat(), lng: NECornerMap.lng() };
  //     let SWCorner = { lat: SWCornerMap.lat(), lng: SWCornerMap.lng() };
  //     let NWCorner = { lat: NECorner.lat, lng: SWCorner.lng };
  //     let SECorner = { lat: SWCorner.lat, lng: NECorner.lng };
  //     return {
  //       NECorner: NECorner,
  //       SWCorner: SWCorner,
  //       NWCorner: NWCorner,
  //       SECorner: SECorner,
  //     };
  //   }
  // };

  useEffect(() => {
    if (currentMap) {
      currentMapCornersHandler();
    }
  }, [filters.map.center, currentMap]);

  const currentMapCornersHandler = () => {
    const currentMapCorners = getMapCoordinates(currentMap);
    setRectangleMapCoordinates({
      NorthEast: currentMapCorners.NECorner,
      SouthEast: currentMapCorners.SECorner,
      SouthWest: currentMapCorners.SWCorner,
      NorthWest: currentMapCorners.NWCorner,
      EndPoint: currentMapCorners.NWCorner,
    });
  };

  useEffect(() => {
    if (isDraw && !drawing) {
      // setDataInPolyline(null)
      setPolylineData(drawCoordinates);
    } else if (isMultyDraw && !multyDrawing) {
      // setDataInPolyline(null)
      const temp = allMultyDrawCoordinates?.map((p) => {
        return p.data;
      });
      setPolylineData(temp, true);
    } else if (currentMap) {
      currentMapCornersHandler();
    }
    // }, [currentMap, isApply, sortingValue]);
  }, [currentMap, sortingValue, isApply]);

  const handleOnZoomed = (currentZoom) => {
    if (!isDraw && !isMultyDraw) {
      setMapZoom(currentZoom);
      setZoomToUrl(currentZoom);
      let coordinates = getMapCoordinates(currentMap);
      setRectangleMapCoordinates({
        NorthEast: coordinates.NECorner,
        SouthEast: coordinates.SECorner,
        SouthWest: coordinates.SWCorner,
        NorthWest: coordinates.NWCorner,
        EndPoint: coordinates.NWCorner,
      });
    }
  };

  const handleDragEnd = (map) => {
    // setCurrentMap(map);
    if (!isDraw && !isMultyDraw) {
      let coordinates = getMapCoordinates(map);
      let centerLatCoordinates = map.getCenter().lat();
      let centerLngCoordinates = map.getCenter().lng();
      // setCurrentCenter({ lat: centerLatCoordinates, lng: centerLngCoordinates });
      setCenterLatToUrl(centerLatCoordinates);
      setCenterLngToUrl(centerLngCoordinates);
      setRectangleMapCoordinates({
        NorthEast: coordinates.NECorner,
        SouthEast: coordinates.SECorner,
        SouthWest: coordinates.SWCorner,
        NorthWest: coordinates.NWCorner,
        EndPoint: coordinates.NWCorner,
      });
    }
  };

  const [drawing, setDrawing] = useState(null);
  const [multyDrawing, setMultyDrawing] = useState(null);
  const [multyDrawCoordinates, setMultyDrawCoordinates] = useState(null);
  const [allMultyDrawCoordinates, setAllMultyDrawCoordinates] = useState([]);
  const [isAllMultyDrawApply, setIsAllMultyDrawApply] = useState(null);

  const handleApiLoaded = (map, maps) => {
    dispatch(resetMapCoordinates());
    setCurrentMap(map);
    setCurrentMaps(maps);
    let coordinates = getMapCoordinates(map);
    // console.log(coordinates.NWCorner, 'mapCoordinates2000 coordinates')
    setRectangleMapCoordinates({
      NorthEast: coordinates.NECorner,
      SouthEast: coordinates.SECorner,
      SouthWest: coordinates.SWCorner,
      NorthWest: coordinates.NWCorner,
      EndPoint: coordinates.NWCorner,
    });
  };

  // useEffect(()=>{
  //     if (currentMap) {
  //       currentMapCornersHandler()
  //     }
  // },[newCoord, currentMap, sliderState, ])

  useEffect(() => {
    if (currentMap && houseCardDataInRect?.data?.listings) {
      const geoObj = jsonToGeoJSON(houseCardDataInRect?.data?.listings);
      let sc = new supercluster({ minPoints: 2, radius: 120 });
      setClustersSaved2(false);
      let t = sc.load(geoObj.features);
      sc.load(geoObj.features);
      setSupC(sc);
      let coordinates = getMapCoordinates(currentMap);
      const bbox = [
        coordinates.SWCorner.lng,
        coordinates.SWCorner.lat,
        coordinates.NECorner.lng,
        coordinates.NECorner.lat,
      ];
      let tempZoom = currentMap.getZoom();
      function allEqual(a, b) {
        return (
          Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index])
        );
      }
      setClusters(sc.getClusters(bbox, tempZoom));
      setClusters(sc.getClusters(bbox, tempZoom));
    }
  }, [currentMap, mapZoom, data?.listings, houseCardDataInRect?.data]);

  function jsonToGeoJSON(jsonData) {
    // Assuming your JSON data is an array of objects
    return {
      type: "FeatureCollection",
      features: jsonData?.map((item) => {
        // Assuming each JSON object has 'latitude', 'longitude', and other properties
        let link;
        let subDom = window.location?.hostname?.split(".")[0];
        if (subDom == "realtytexas" || subDom == "localhost") {
          link = `https://realtytexas.com/${item?.subdomain}${item?.url}`;
        } else {
          link = `https://${
            window.location?.hostname?.split(".")[0]
          }.realtytexas.com/${item?.subdomain}${item?.url}`;
        }
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [item.Longitude, item.Latitude],
          },
          properties: {
            streetName: item?.StreetName,
            streetNumber: item?.StreetNumber,
            originatingSystemName: item?.OriginatingSystemName,
            unitNumber: item?.UnitNumber,
            address: item?.Address,
            price: item?.ListPrice,
            city: item?.City,
            stateOrProvince: item?.StateOrProvince,
            postalCode: item?.PostalCode,
            subdomain: item?.subdomain,
            url: item?.url,
            size: item?.AboveGradeFinishedArea,
            keyNum: item?.ListingKeyNumeric,
            link: link,
          },
        };
      }),
    };
  }

  const clusterStyles = [
    {
      height: 30,
      width: 30,
      textSize: 16,
      backgroundImage:
        "url(" +
        "https://rtcdn2.azureedge.net/public/assets/images/map/m1.png" +
        ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      color: "white",
    },
  ];

  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  useEffect(() => {
    setClustersSaved(false);
    const tempClustersArray = [];
    if (currentMap && currentMap.getZoom() === 16 && clusters.length) {
      clusters.map((claster) => {
        if (claster.properties.cluster) {
          tempClustersArray.push(claster);
        }
      });

      if (tempClustersArray.length)
        setClustersOver15zoom([...tempClustersArray]);
    }
  }, [clusters, currentMap]);

  useEffect(() => {
    if (clustersOver15zoom.length) {
      setClustersSaved(true);
    }
  }, [clustersOver15zoom]);

  useEffect(() => {
    if (supC) {
      setClustersSaved2(true);
    }
  }, [supC]);

  //create region, metro, county, city, postal area

  const createPolygon = (map, maps, polygonCoords, isArray = false) => {
    if (isArray === false) {
      setCurrentPolygons([]);
      setPolygonsData([]);
      let polygon = new maps.Polygon({
        paths: polygonCoords,
        strokeColor: "#4386DC",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#4386DC",
        fillOpacity: 0.35,
      });
      setCurrentPolygon(polygon);
      polygon.setMap(map);
    } else {
      setCurrentPolygons([]);
      setCurrentPolygon(null);
      setPolygonData(null);
      polygonCoords?.map((pol) => {
        let polygon = new maps.Polygon({
          paths: pol.polygonData,
          strokeColor: "#4386DC",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#4386DC",
          fillOpacity: 0.35,
        });
        setCurrentPolygons((currentPolygons) => [
          ...currentPolygons.filter((p) => p !== polygon),
          polygon,
        ]);
        polygon.setMap(map);
      });
    }
  };

  const removePolygon = () => {
    if (currentPolygon) currentPolygon.setMap(null);
  };

  const removePolygons = () => {
    if (currentPolygons && currentPolygons.length > 0) {
      currentPolygons?.map((pol) => {
        pol.setMap(null);
      });
    }
  };

  const removeLayersPolygon = () => {
    if (currentLayersPolygon) currentLayersPolygon.setMap(null);
  };

  const removeLayersPolygons = () => {
    if (currentLayersPolygons && currentLayersPolygons.length > 0) {
      currentLayersPolygons?.map((pol) => {
        pol.setMap(null);
      });
    }
  };

  const getPolygonData = (area, name, isArr = false, ind) => {
    if (isArr === false) {
      //it removes Polygons
      setPolygonsData([]);
      axios
        .get(
          `https://${window.location.host}/api/v1/map/polygon/${area}/${name}`
        )
        .then(function (res) {
          let type = res?.data?.geometry?.type;
          let temp = [];
          if (!type) {
            return null;
          }
          if (type === "Polygon") {
            res.data.geometry.coordinates[0].map((coord) =>
              temp.push({ lat: coord[1], lng: coord[0] })
            );
            res.data.polygonData = [...temp];
            setPolygonData(res.data);
          } else {
            res.data.geometry.coordinates[0][0].map((coord) =>
              temp.push({ lat: coord[1], lng: coord[0] })
            );
            res.data.polygonData = [...temp];
            setPolygonData(res.data);
          }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
    } else {
      //it removes Polygon
      setPolygonData(null);
      //it removes Polygons
      if (ind === 0) {
        setPolygonsData([]);
      }
      axios
        .get(
          `https://${window.location.host}/api/v1/map/polygon/${area}/${name}`
        )
        .then(function (res) {
          let type = res?.data?.geometry?.type;
          let temp = [];
          if (!type) {
            return null;
          }
          if (type === "Polygon") {
            res.data.geometry.coordinates[0].map((coord) =>
              temp.push({ lat: coord[1], lng: coord[0] })
            );
            res.data.polygonData = [...temp];
            setPolygonsData((polygonsData) => [...polygonsData, res.data]);
          } else {
            res.data.geometry.coordinates[0][0].map((coord) =>
              temp.push({ lat: coord[1], lng: coord[0] })
            );
            res.data.polygonData = [...temp];
            setPolygonsData((polygonsData) => [...polygonsData, res.data]);
          }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
    }
  };
  const [currentApply2, setCurrentApply2] = useState(null);

  useEffect(() => {
    if (!isDraw && !isMultyDraw) {
      setCurrentApply2(isApply);
      if (
        filters?.filterLocation?.region?.value &&
        !filters?.filterLocation?.metro?.subdomain &&
        !filters?.filterLocation?.country?.length > 0 &&
        !filters?.filterLocation?.city?.length > 0 &&
        !filters?.filterLocation?.postal?.length > 0
      ) {
        if (currentApply2 !== isApply || currentApply2 === null) {
          getPolygonData("region", filters?.filterLocation?.region?.subdomain);
        }
      }
      if (
        filters?.filterLocation?.metro?.subdomain &&
        !filters?.filterLocation?.country?.length > 0 &&
        !filters?.filterLocation?.city?.length > 0 &&
        !filters?.filterLocation?.postal?.length > 0
      ) {
        if (currentApply2 !== isApply || currentApply2 === null) {
          getPolygonData("metro", filters?.filterLocation?.metro?.subdomain);
        }
      }
      if (
        filters?.filterLocation?.country &&
        !filters?.filterLocation?.city?.length > 0 &&
        !filters?.filterLocation?.postal?.length > 0
      ) {
        if (currentApply2 !== isApply || currentApply2 === null) {
          if (filters?.filterLocation?.country?.length === 1) {
            getPolygonData(
              "county",
              filters?.filterLocation?.country[0]?.subdomain
            );
          } else {
            filters?.filterLocation?.country?.map((county, ind) =>
              getPolygonData("county", county?.subdomain, true, ind)
            );
          }
        }
      }
      if (
        filters?.filterLocation?.city &&
        !filters?.filterLocation?.postal?.length > 0
      ) {
        if (currentApply2 !== isApply || currentApply2 === null) {
          if (filters?.filterLocation?.city?.length === 1) {
            getPolygonData("city", filters?.filterLocation?.city[0]?.subdomain);
          } else {
            filters?.filterLocation?.city?.map((city, ind) =>
              getPolygonData("city", city?.subdomain, true, ind)
            );
          }
        }
      }
      if (
        filters?.filterLocation?.postal &&
        filters?.filterLocation?.postal[0] !== "" &&
        filters?.filterLocation?.postal?.length > 0
      ) {
        if (currentApply2 !== isApply || currentApply2 === null) {
          if (filters?.filterLocation?.postal?.length === 1) {
            getPolygonData("zip", filters?.filterLocation?.postal[0]?.value);
          } else {
            filters?.filterLocation?.postal?.map((postal, ind) =>
              getPolygonData("zip", postal?.value, true, ind)
            );
          }
        }
      }
    }
  }, [
    isApply,
    filters?.filterLocation?.region,
    filters?.filterLocation?.metro,
    filters?.filterLocation?.country,
    filters?.filterLocation?.city,
    filters?.filterLocation?.postal,
  ]);

  const [isNoDraws, setIsNowDraws] = useState(false);

  useEffect(() => {
    if (!isDraw && !isMultyDraw) {
      setIsNowDraws(true);
    } else {
      setIsNowDraws(false);
    }
  }, [isDraw, isMultyDraw]);

  useEffect(() => {
    if (isNoDraws) {
      if (
        polygonData &&
        polygonsData?.length === 0 &&
        currentMaps &&
        currentMap
      ) {
        removePolygon();
        removePolygons();
        createPolygon(currentMap, currentMaps, polygonData?.polygonData);
      }
    } else if (
      polygonData &&
      polygonsData?.length === 0 &&
      currentMaps &&
      currentMap
    ) {
      removePolygon();
      removePolygons();
    }
  }, [currentMap, currentMaps, polygonData, isNoDraws]);

  useEffect(() => {
    if (isNoDraws) {
      if (
        !polygonData &&
        polygonsData.length > 0 &&
        currentMaps &&
        currentMap
      ) {
        removePolygon();
        removePolygons();
        currentPolygons.map((pol) => pol.setMap(null));
        createPolygon(currentMap, currentMaps, polygonsData, true);
      } else if (
        !polygonData &&
        polygonsData.length > 0 &&
        currentMaps &&
        currentMap
      ) {
        removePolygon();
        removePolygons();
      }
    }
  }, [currentMap, currentMaps, polygonsData, isNoDraws]);

  const amountOfMarkersOnMap = 100;

  const openNarrowFiltersHandle = () => {
    dispatch(updateIsOpenNarrowFilters(true));
    window.scrollTo(0, 0);
  };

  const [layersPolygonsData, setLayersPolygonsData] = useState([]);
  const [layersPolygonData, setLayersPolygonData] = useState(null);

  const getLayersPolygonData = (area, name, isArr = false, ind) => {
    if (area !== "schoolDistrict") {
      if (isArr === false) {
        //it removes Polygons
        setLayersPolygonsData([]);
        axios
          .get(
            `https://${window.location.host}/api/v1/map/polygon/${area}/${name}`
            // `https://realtytexas.co/api/v1/map/polygon/${area}/${name}`
          )
          .then(function (res) {
            let type = res?.data?.geometry?.type;
            let temp = [];
            if (!type) {
              return null;
            }
            if (type === "Polygon") {
              res.data.geometry.coordinates[0].map((coord) =>
                temp.push({ lat: coord[1], lng: coord[0] })
              );
              res.data.polygonData = [...temp];
              setLayersPolygonData(res.data);
            } else {
              res.data.geometry.coordinates[0][0].map((coord) =>
                temp.push({ lat: coord[1], lng: coord[0] })
              );
              res.data.polygonData = [...temp];
              setLayersPolygonData(res.data);
            }
          })
          .catch(function (error) {
            console.log(error, "error");
          });
      } else {
        //it removes Polygon
        setLayersPolygonData(null);
        //it removes Polygons
        if (ind === 0) {
          setLayersPolygonsData([]);
        }
        axios
          .get(
            `https://${window.location.host}/api/v1/map/polygon/${area}/${name}`
            // `https://realtytexas.co/api/v1/map/polygon/${area}/${name}`
          )
          .then(function (res) {
            let type = res?.data?.geometry?.type;
            let temp = [];
            if (!type) {
              return null;
            }
            if (type === "Polygon") {
              res.data.geometry.coordinates[0].map((coord) =>
                temp.push({ lat: coord[1], lng: coord[0] })
              );
              res.data.polygonData = [...temp];
              setLayersPolygonsData((polygonsData) => [
                ...polygonsData,
                res.data,
              ]);
            } else {
              res.data.geometry.coordinates[0][0].map((coord) =>
                temp.push({ lat: coord[1], lng: coord[0] })
              );
              res.data.polygonData = [...temp];
              setLayersPolygonsData((polygonsData) => [
                ...polygonsData,
                res.data,
              ]);
            }
          })
          .catch(function (error) {
            console.log(error, "error");
          });
      }
    } else {
      if (isArr === false) {
        //it removes Polygons
        setLayersPolygonsData([]);
        axios
          .get(
            `https://${window.location.host}/api/v1/map/polygon/school-district/${name}`
            // `https://realtytexas.co/api/v1/map/polygon/school-district/${name}`
          )
          .then(function (res) {
            let type = res?.data?.geometry?.type;
            let temp = [];
            if (!type) {
              return null;
            }
            if (type === "Polygon") {
              res.data.geometry.coordinates[0].map((coord) =>
                temp.push({ lat: coord[1], lng: coord[0] })
              );
              res.data.polygonData = [...temp];
              setLayersPolygonData(res.data);
            } else {
              res.data.geometry.coordinates[0][0].map((coord) =>
                temp.push({ lat: coord[1], lng: coord[0] })
              );
              res.data.polygonData = [...temp];
              setLayersPolygonData(res.data);
            }
          })
          .catch(function (error) {
            console.log(error, "error");
          });
      } else {
        //it removes Polygon
        setLayersPolygonData(null);
        //it removes Polygons
        if (ind === 0) {
          setLayersPolygonsData([]);
        }
        axios
          .get(
            `https://${window.location.host}/api/v1/map/polygon/school-district/${name}`
            // `https://realtytexas.co/api/v1/map/polygon/school-district/${name}`
          )
          .then(function (res) {
            let type = res?.data?.geometry?.type;
            let temp = [];
            if (!type) {
              return null;
            }
            if (type === "Polygon") {
              res.data.geometry.coordinates[0].map((coord) =>
                temp.push({ lat: coord[1], lng: coord[0] })
              );
              res.data.polygonData = [...temp];
              setLayersPolygonsData((polygonsData) => [
                ...polygonsData,
                res.data,
              ]);
            } else {
              res.data.geometry.coordinates[0][0].map((coord) =>
                temp.push({ lat: coord[1], lng: coord[0] })
              );
              res.data.polygonData = [...temp];
              setLayersPolygonsData((polygonsData) => [
                ...polygonsData,
                res.data,
              ]);
            }
          })
          .catch(function (error) {
            console.log(error, "error");
          });
      }
    }
  };

  const createLayersPolygon = (map, maps, polygonCoords, isArray = false) => {
    if (isArray === false) {
      // currentLayersPolygon,
      // currentLayersPolygons,
      // setCurrentLayersPolygons,
      // setCurrentLayersPolygon,

      // setCurrentLayersPolygons([]);
      // setLayersPolygonsData([]);
      let polygon = new maps.Polygon({
        paths: polygonCoords,
        strokeColor: "#4386DC",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#4386DC",
        fillOpacity: 0.35,
        value: subValueOverlayersList.value,
      });
      setCurrentLayersPolygon(polygon);
      setCurrentLayersPolygons((currentPolygons) => [
        ...currentPolygons.filter((p) => p !== polygon),
        polygon,
      ]);
      polygon.setMap(map);
    }
  };

  const removeAllLayersPolygons = () => {
    setCurrentLayersPolygon(null);
    setCurrentLayersPolygons([]);
    removeLayersPolygon();
    removeLayersPolygons();
    setAllCheckedOverlayers([]);
  };

  const [isMapOverlaysList, setIsMapOverlaysList] = useState(false);
  const [valueMapOverlaysList, setValueMapOverlaysList] = useState(null);

  const [subValueOverlayersList, setSubValueOverlayersList] = useState([]);
  const [allCheckedOverlayers, setAllCheckedOverlayers] = useState([]);

  const [clickedCheckedOverlayer, setClickedCheckedOverlayer] = useState(null);

  const removeCurrentLayersPolygon = () => {
    if (currentLayersPolygons && currentLayersPolygons.length > 0) {
      currentLayersPolygons?.map((pol) => {
        if (pol.value === clickedCheckedOverlayer.value) pol.setMap(null);
      });
    }
  };

  useEffect(() => {
    removeCurrentLayersPolygon();
  }, [clickedCheckedOverlayer]);

  useEffect(() => {
    if (
      subValueOverlayersList &&
      subValueOverlayersList?.type &&
      (subValueOverlayersList.subdomain || subValueOverlayersList.value)
    ) {
      getLayersPolygonData(
        subValueOverlayersList.type,
        subValueOverlayersList.type === "schoolDistrict"
          ? subValueOverlayersList.value
          : subValueOverlayersList.subdomain ?? subValueOverlayersList.value
      );
    }
  }, [subValueOverlayersList]);

  useEffect(() => {
    if (
      layersPolygonData?.polygonData?.length > 0 &&
      currentMaps &&
      currentMap
    ) {
      // removePolygon();
      // removePolygons();
      // currentPolygons.map((pol) => pol.setMap(null));
      createLayersPolygon(
        currentMap,
        currentMaps,
        layersPolygonData?.polygonData
      );
    }
  }, [currentMap, currentMaps, layersPolygonData]);

  useEffect(() => {
    removeAllLayersPolygons();
  }, [isDraw]);

  const [isOpenAlarmToRemoveAllRegions, setIsOpenAlarmToRemoveAllRegions] =
    useState(false);

  const AlarmToRemoveAllRegionsHandler = () => {
    setIsOpenAlarmToRemoveAllRegions(false);
  };

  const [isSubOpenList, setIsSubOpenList] = useState(false);
  const [isSubListData, setIsSubListData] = useState([]);

  const alarmToRemoveAllRegionsConfirm = () => {
    setIsSubOpenList(false);
    setIsMapOverlaysList(false);
    removeAllLayersPolygons();
    setSubValueOverlayersList([]);
    setIsSubListData([]);
    setIsOpenAlarmToRemoveAllRegions(false);
  };

  // const [dataInPolyline, setDataInPolyline] = useState(null)
  const [currentFlightPath, setCurrentFlightPath] = useState(null);
  const [currentFlightLastPath, setCurrentFlightLastPath] = useState(null);
  const [currentMultyFlightPath, setCurrentMultyFlightPath] = useState(null);
  const [currentMultyFlightLastPath, setCurrentMultyFlightLastPath] =
    useState(null);
  const [allCurrentMulty, setAllCurrentMulty] = useState([]);
  const [allCurrentMultyIntersecting, setAllCurrentMultyIntersecting] =
    useState([]);
  const [isPolygon, setIsPolygon] = useState(false);
  const [isMultyPolygons, setIsMultyPolygons] = useState(false);

  useEffect(() => {
    if (drawing && isDraw && currentMap) {
      if (currentFlightPath) {
        currentFlightPath.setMap(null);
        currentFlightLastPath?.setMap(null);
      }
      const flightPath = new currentMaps.Polyline({
        path: drawCoordinates,
        // geodesic: true,
        strokeColor: colors.blue,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: currentMap,
        zIndex: 40,
      });
      setCurrentFlightPath(flightPath);
      flightPath.setMap(currentMap);
      setIsPolygon(true);
    }
  }, [drawCoordinates, currentMap]);

  useEffect(() => {
    if (multyDrawing && isMultyDraw && currentMap) {
      if (currentMultyFlightPath) {
        currentMultyFlightPath.setMap(null);
      }
      const flightPath = new currentMaps.Polyline({
        path: multyDrawCoordinates,
        // geodesic: true,
        strokeColor: colors.blue,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: currentMap,
        zIndex: 40,
      });
      setCurrentMultyFlightPath(flightPath);
      flightPath.setMap(currentMap);
      setIsMultyPolygons(true);
    }
  }, [multyDrawCoordinates, currentMap]);

  useEffect(() => {
    if (!drawing && isDraw && isPolygon) {
      if (currentFlightPath) currentFlightPath.setMap(null);
      const flightPath = new currentMaps.Polygon({
        path: drawCoordinates,
        // geodesic: true,
        // editable: true,
        strokeColor: colors.blue,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: currentMap,
        zIndex: 4,
      });
      setCurrentFlightLastPath(flightPath);
      flightPath.setMap(currentMap);
      setIsPolygon(false);
      setDrawCoordinates([...drawCoordinates, drawCoordinates[0]]);
    }
  }, [drawing, isPolygon]);

  useEffect(() => {
    if (!multyDrawing && isMultyDraw && isMultyPolygons) {
      if (currentMultyFlightPath) currentMultyFlightPath.setMap(null);
      const flightPath = new currentMaps.Polygon({
        path: multyDrawCoordinates,
        // geodesic: true,
        // editable: true,
        strokeColor: colors.blue,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: currentMap,
        zIndex: 4,
      });
      allCurrentMulty.push(flightPath);
      setCurrentMultyFlightLastPath(flightPath);
      flightPath.setMap(currentMap);
      setIsMultyPolygons(false);

      if (multyDrawCoordinates?.length > 0) {
        const temp = [...multyDrawCoordinates, multyDrawCoordinates[0]]?.map(
          (data, i) => {
            return {
              id: i,
              lng: data?.lng,
              lat: data?.lat,
            };
          }
        );
        const temp2 = {
          polygon: allMultyDrawCoordinates?.length
            ? allMultyDrawCoordinates?.length + 1
            : 1,
          data: [...temp],
        };
        setAllMultyDrawCoordinates([...allMultyDrawCoordinates, temp2]);
      }

      setMultyDrawCoordinates([
        ...multyDrawCoordinates,
        multyDrawCoordinates[0],
      ]);
    }
  }, [multyDrawing, isMultyPolygons]);

  useEffect(() => {
    if (!isPolygon && currentMap) {
      if (currentFlightPath) currentFlightPath.setMap(null);
    }
  }, [currentFlightPath, currentMap]);

  // useEffect(()=>{
  //   if (!isMultyPolygons && currentMap) {
  //     if (currentMultyFlightPath) currentMultyFlightPath.setMap(null);
  //   }
  // },[currentMultyFlightPath, currentMap])

  const [isContinueDrawing, setIsContinueDrawing] = useState(false);
  const [isContinueMultyDrawing, setIsContinueMultyDrawing] = useState(false);

  useEffect(() => {
    if (isMultyDraw && multyDrawing && isContinueMultyDrawing?.latLng?.lat()) {
      if (multyDrawCoordinates?.length > 0) {
        setMultyDrawCoordinates((prev) => [
          ...prev,
          {
            lng: isContinueMultyDrawing.latLng.lng(),
            lat: isContinueMultyDrawing.latLng.lat(),
          },
        ]);
      } else {
        setMultyDrawCoordinates([
          {
            lng: isContinueMultyDrawing.latLng.lng(),
            lat: isContinueMultyDrawing.latLng.lat(),
          },
        ]);
      }
    }
  }, [multyDrawing, isContinueMultyDrawing, isMultyDraw]);

  useEffect(() => {
    if (isDraw && drawing && isContinueDrawing?.latLng?.lat()) {
      if (drawCoordinates.length > 0) {
        setDrawCoordinates((prev) => [
          ...prev,
          {
            lng: isContinueDrawing.latLng.lng(),
            lat: isContinueDrawing.latLng.lat(),
          },
        ]);
      } else {
        setDrawCoordinates([
          {
            lng: isContinueDrawing.latLng.lng(),
            lat: isContinueDrawing.latLng.lat(),
          },
        ]);
      }
    }
  }, [drawing, isContinueDrawing, isDraw]);

  const startDrawing = (e) => {
    setDrawing(true);
    if (!isDraw)
      setDrawCoordinates([{ lng: e.latLng.lng(), lat: e.latLng.lat() }]);
  };

  const continueDrawing = (e) => {
    setIsContinueDrawing(e);
  };

  const stopDrawing = (e) => {
    setDrawing(false);
  };

  const startMultyDrawing = (e) => {
    setMultyDrawCoordinates(null);
    setMultyDrawing(true);
    if (!isMultyDraw)
      setMultyDrawCoordinates([{ lng: e.latLng.lng(), lat: e.latLng.lat() }]);
  };

  const continueMultyDrawing = (e) => {
    setIsContinueMultyDrawing(e);
  };

  const stopMultyDrawing = (e) => {
    setIsContinueMultyDrawing(null);
    setMultyDrawing(false);
    // [...allMultyDrawCoordinates, ...multyDrawCoordinates]
  };

  useEffect(() => {
    if (!isDraw && currentFlightLastPath) {
      currentFlightLastPath?.setMap(null);
      currentFlightPath?.setMap(null);
    }
    if (!isDraw) {
      // currentMaps?.event.clearListeners(currentMap, 'mousedown');
      // currentMaps?.event.clearListeners(currentMap, 'mousemove');
    } else {
      setIsContinueDrawing(null);
      currentMap?.addListener("mousedown", startDrawing);
      currentMap?.addListener("mousemove", continueDrawing);
    }
  }, [isDraw]);

  useEffect(() => {
    if (!isMultyDraw && !isAllMultyDrawApply) {
      currentMultyFlightPath?.setMap(null);
      allCurrentMulty?.map((p) => p?.setMap(null));
      allCurrentMultyIntersecting?.map((p) => p?.setMap(null));
      setAllCurrentMulty([]);
      setAllCurrentMultyIntersecting([]);
    }
    if (!isMultyDraw) {
      // currentMaps?.event.clearListeners(currentMap, 'mousedown');
      // currentMaps?.event.clearListeners(currentMap, 'mousemove');
      // console.log(isMultyDraw,'isMultyDraw false')
    } else {
      // setIsContinueMultyDrawing(null)
      currentMap?.addListener("mousedown", startMultyDrawing);
      currentMap?.addListener("mousemove", continueMultyDrawing);
    }
  }, [isMultyDraw]);

  useEffect(() => {
    if (isDraw && !drawing) {
      // setDataInPolyline(null)
      setPolylineData(drawCoordinates);
    }
  }, [isDraw, drawing, dataInRectPage]);

  const [mergedMultyCoords, setMergedMultyCoords] = useState([]);

  const mergeIntersectingPolygons = (polygons, maps, map, colors) => {
    const polygons1 = polygons?.map((p) => googleToPolygonClippingFormat(p));
    const intersectionResult = polygonClipping.union(polygons1);

    const coordinates = intersectionResult.map((polygon) => {
      return polygon.map((point) => ({ lat: point[0], lng: point[1] }));
    });

    setMergedMultyCoords([
      ...intersectionResult.map((res) =>
        res.map((path) => polygonClippingToGoogleFormatLatLng(path))
      ),
    ]);

    const googleIntersectionResult = intersectionResult.map((res) => {
      const polygon = new maps.Polygon({
        paths: res.map((path) => polygonClippingToGoogleFormat(path, maps)),
        /* Additional polygon options */
        strokeColor: colors,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: map,
        zIndex: 4,
      });
      allCurrentMultyIntersecting.push(polygon);
      return polygon;
    });
    return googleIntersectionResult;
  };

  useEffect(() => {
    if (isMultyDraw && isAllMultyDrawApply) {
      // setDataInPolyline(null)
      currentMultyFlightPath?.setMap(null);
      ///////////////////// IntersectingPolygons block
      allCurrentMulty?.map((p) => p?.setMap(null));
      const mergedPolygons = mergeIntersectingPolygons(
        [...allCurrentMulty],
        currentMaps,
        currentMap,
        colors.blue
      );
      mergedPolygons?.map((m) => m?.setMap(currentMap));
      ///////////////////

      const temp = allMultyDrawCoordinates?.map((p) => {
        return p.data;
      });
      // const temp2 = temp.map(p=>{return { lng: p.lng, lat: p.lat,  }})
      // console.log(mergedMultyCoords,'temp mergedMultyCoords')
      setPolylineData(temp, true);
    }
  }, [isMultyDraw, dataInRectPage, isAllMultyDrawApply]);

  useEffect(() => {
    if (!isDraw) {
      setDataInPolyline(null);
      setDrawCoordinates([]);
    }
  }, [isDraw]);

  useEffect(() => {
    if (!isMultyDraw) {
      setDataInMultyPolygones(null);
      setMultyDrawCoordinates([]);
      setAllMultyDrawCoordinates([]);
    }
  }, [isMultyDraw]);

  useEffect(() => {
    if (drawing && currentMap) {
      currentMap?.addListener("mousemove", continueDrawing);
      document?.addEventListener("mouseup", stopDrawing);
      document?.addEventListener("touchend", stopDrawing);
    }
  }, [drawing]);

  // useEffect(()=>{
  //   if ((
  //   (isDraw === null && isMultyDraw === false) ||
  //   (isDraw === false && isMultyDraw === null) ||
  //   (isDraw === false && isMultyDraw === false)
  //   ) && currentMap) {
  //     let coordinates = getMapCoordinates(currentMap);
  //     const obj = {
  //     NorthEast: coordinates.NECorner,
  //     SouthEast: coordinates.SECorner,
  //     SouthWest: coordinates.SWCorner,
  //     NorthWest: coordinates.NWCorner,
  //     EndPoint: coordinates.NWCorner,
  //   }
  //   // setRectangleMapCoordinates(obj);

  //   let tempOrder = null;
  //       if (
  //         sortingValue?.orderBySort === "desc" ||
  //         sortingValue?.orderBySort === "asc"
  //       ) {
  //         tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
  //       }
  //       const filtedrArr = toObjFilterConverter(filters);
  //   dispatch(
  //           getAllBothViewMapHouseCards(
  //             obj,
  //             filtedrArr,
  //             tempOrder,
  //             // dataInRectPage.value
  //             1
  //           )
  //         );
  //   }
  // },[isDraw, isMultyDraw])

  useEffect(() => {
    if (drawing && currentMap) {
      currentMap?.addListener("mousemove", continueDrawing);
      document?.addEventListener("mouseup", stopDrawing);
      document?.addEventListener("touchend", stopDrawing);
    }
  }, [drawing]);

  useEffect(() => {
    if (multyDrawing && currentMap) {
      currentMap?.addListener("mousemove", continueMultyDrawing);
      document?.addEventListener("mouseup", stopMultyDrawing);
      document?.addEventListener("touchend", stopMultyDrawing);
    }
  }, [multyDrawing]);

  const setPolylineData = (data, isMulty = false) => {
    const isUserLogined = isUserLogin();
    let tempOrder = null;
    if (
      sortingValue?.orderBySort === "desc" ||
      sortingValue?.orderBySort === "asc"
    ) {
      tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
    }
    const filtedrArr = toObjFilterConverter(filters);
    if (data?.length > 0) {
      axios
        .post(`https://api.realtytexas.net/v2/listings`, {
          top: 100,
          count: true,
          maxPrice: 5000000,
          minPrice: 0,
          filters: filtedrArr
            ? [
                ...filtedrArr,
                {
                  column: "geo",
                  operator: isMulty ? "in" : "eq",
                  value: isMulty ? data : [...data, data[0]],
                },
              ]
            : null,
          select: null,
          page: dataInRectPage?.value ? dataInRectPage.value : 1,
          orderby: tempOrder
            ? `rtlist desc,${tempOrder}`
            : "rtlist desc, ListingContractDate desc, ListPrice desc",
          client_hash_id: isUserLogined ? isUserLogined : null,
        })
        .then(function (res) {
          if (!isMulty) setDataInPolyline(res.data.data);
          currentMaps?.event.clearListeners(currentMap, "mousedown");
          currentMaps?.event.clearListeners(currentMap, "mousemove");
          document?.removeEventListener("mouseup", stopDrawing);
          document?.removeEventListener("touchend", stopDrawing);
          dispatch(houseCardsMapSlice.actions.houseCardsMapFetching());
          dispatch(
            houseCardsMapSlice.actions.houseCardsMapFetchingSuccess(
              res.data.data
            )
          );
          if (isMulty) {
            // setIsAllMultyDrawApply(false)
            setDataInMultyPolygones(res.data.data);
            // setAllMultyDrawCoordinates([])
            currentMaps?.event.clearListeners(currentMap, "mousedown");
            currentMaps?.event.clearListeners(currentMap, "mousemove");
            document?.removeEventListener("mouseup", stopDrawing);
            document?.removeEventListener("touchend", stopDrawing);
            dispatch(houseCardsMapSlice.actions.houseCardsMapFetching());
            dispatch(
              houseCardsMapSlice.actions.houseCardsMapFetchingSuccess(
                res.data.data
              )
            );
          }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
    }
  };

  const [isCrosshair, setIsCrosshair] = useState(false);

  useEffect(() => {
    if (
      (isDraw && (dataInPolyline?.length === 0 || dataInPolyline === null)) ||
      (isMultyDraw && !isAllMultyDrawApply)
    ) {
      setIsCrosshair(true);
    } else {
      setIsCrosshair(false);
    }
  }, [isDraw, dataInPolyline, isMultyDraw, isAllMultyDrawApply]);

  const [isLoadedData, setIsLoadedData] = useState(false);

  useEffect(() => {
    if (
      houseCardDataInRect?.data?.listings &&
      (isMap.data === null || isMap.data === true)
    ) {
      setIsLoadedData(true);
    }
  }, [isMap?.data, houseCardDataInRect]);

  // const [currentCenterCoord, setCurrentCenterCoord] = useState(null)

  useEffect(() => {
    if (currentCenterLat && currentCenterLng) {
      setCurrentCenterCoord({ lat: currentCenterLat, lng: currentCenterLng });
    }
  }, [currentCenterLat, currentCenterLng]);

  const mapComponent = (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: "AIzaSyC3g7PD56m3_3XcU2D2pZEeYutyhDsnR2g",
        libraries: "drawing",
      }}
      zoom={filters.map.zoom ? filters.map.zoom : defaultProps.zoom}
      options={{
        scaleControl: true,
        clickableIcons: isDraw || isMultyDraw ? false : true,
        // scaleControlOptions: {
        //   position: "bottom-right"
        // },
        // streetViewControl: false,
        draggableCursor: isCrosshair ? "crosshair" : "default",
        mapTypeId: isSatelliteMapId ? "satellite" : "roadmap",
        styles: stylesArray,
        // disableDefaultUI: true, // Disable all default UI controls
        keyboardShortcuts: false, // Disable keyboard shortcuts control
        gestureHandling:
          (isDraw && drawing === null) ||
          (isDraw && drawing === true) ||
          (isMultyDraw && !isAllMultyDrawApply)
            ? "none"
            : "greedy",
      }}
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      onDragEnd={(map) => handleDragEnd(map)}
      onZoomAnimationEnd={(currentZoom) => handleOnZoomed(currentZoom)}
      yesIWantToUseGoogleMapApiInternals={true}
      center={
        currentCenterCoord?.lat
          ? currentCenterCoord
          : filters.map.center
          ? filters.map.center
          : defaultProps.center
      }
      //   center={filters.map.center ? filters.map.center : defaultProps.center}
    >
      {!(isMap.data !== false && houseCardDataInRect?.isLoading === true) &&
        isLoadedData &&
        !dataInPolyline?.listings?.length > 0 &&
        !dataInMultyPolygones?.listings?.length > 0 &&
        isMap?.data !== false &&
        data?.listings[0] &&
        houseCardDataInRect?.data?.listings?.map((item, i) => {
          return (
            <RoundedMarker
              key={generateKey("marker") + i}
              lat={item?.geo?.coordinates[1]}
              lng={item?.geo?.coordinates[0]}
              address={item?.Address}
              price={item?.ListPrice}
              city={item?.City}
              stateOrProvince={item?.StateOrProvince}
              postalCode={item?.PostalCode}
              subdomain={item?.subdomain}
              link={item?.url}
              size={item?.AboveGradeFinishedArea}
              keyNum={item?.ListingKeyNumeric}
              color={item?.color}
              status={item?.Status}
              zoom={mapZoom}
              isCrosshair={isCrosshair}
              isLoadedData={isLoadedData}
            />
          );
        })}

      {!(isMap.data !== false && houseCardDataInRect?.isLoading === true) &&
        dataInPolyline?.listings?.map((item, i) => {
          return (
            <RoundedMarker
              key={generateKey("marker") + i}
              lat={item?.geo?.coordinates[1]}
              lng={item?.geo?.coordinates[0]}
              address={item?.Address}
              price={item?.ListPrice}
              city={item?.City}
              stateOrProvince={item?.StateOrProvince}
              postalCode={item?.PostalCode}
              subdomain={item?.subdomain}
              link={item?.url}
              size={item?.AboveGradeFinishedArea}
              keyNum={item?.ListingKeyNumeric}
              color={item?.color}
              status={item?.Status}
              zoom={mapZoom}
              isCrosshair={isCrosshair}
              isLoadedData={isLoadedData}
            />
          );
        })}

      {!(isMap.data !== false && houseCardDataInRect?.isLoading === true) &&
        dataInMultyPolygones?.listings?.map((item, i) => {
          return (
            <RoundedMarker
              key={generateKey("marker") + i}
              lat={item?.geo?.coordinates[1]}
              lng={item?.geo?.coordinates[0]}
              address={item?.Address}
              price={item?.ListPrice}
              city={item?.City}
              stateOrProvince={item?.StateOrProvince}
              postalCode={item?.PostalCode}
              subdomain={item?.subdomain}
              link={item?.url}
              size={item?.AboveGradeFinishedArea}
              keyNum={item?.ListingKeyNumeric}
              color={item?.color}
              status={item?.Status}
              zoom={mapZoom}
              isCrosshair={isCrosshair}
              isLoadedData={isLoadedData}
            />
          );
        })}
    </GoogleMapReact>
  );

  const [currentZoomMap, setCurrentZoomMap] = useState(null);
  const [isOurListingsClicked, setIsOurListingsClicked] = useState(false);
  const [isAllOurListingsClicked, setIsAllOurListingsClicked] = useState(false);
  const [isAllOurListings, setIsAllOurListings] = useState(null);

  const ourListingsBaseHandle = () => {
    setIsAllOurListings(!isAllOurListings);
  };

  useEffect(() => {
    if (isAllOurListings) {
      ourListingsHandle();
    }
    if (isAllOurListings === false) {
      ourAllListingsHandle();
    }
  }, [isAllOurListings]);

  const ourListingsHandle = () => {
    setIsOurListingsClicked(true);

    setIsOurListings(true);
    setCurrentZoomMap(mapZoom);
    setMapZoom(6);
    // setZoomToUrl(6);
    setFilters({
      ...filters,
      showOnly: {
        ...filters.showOnly,
        ourListings: true,
      },
      map: {
        ...filters.map,
        zoom: 6,
      },
    });
  };

  const ourAllListingsHandle = () => {
    setIsAllOurListingsClicked(true);

    if (currentZoomMap) {
      setMapZoom(currentZoomMap);
    }
    setIsOurListings(false);
    setFilters({
      ...filters,
      showOnly: {
        ...filters.showOnly,
        ourListings: false,
      },
      map: {
        ...filters.map,
        zoom: currentZoomMap ? currentZoomMap : null,
      },
    });
  };

  useEffect(() => {
    if (
      isOurListingsClicked &&
      isOurListings &&
      filters?.showOnly?.ourListings
    ) {
      applyHandler();
      setIsOurListingsClicked(false);
    }
  }, [isOurListingsClicked, filters?.showOnly?.ourListings, isOurListings]);

  useEffect(() => {
    if (
      isAllOurListingsClicked &&
      !filters?.showOnly?.ourListings &&
      filters?.map?.zoom === currentZoomMap
    ) {
      applyHandler();
      setIsAllOurListingsClicked(false);
    }
  }, [
    isAllOurListingsClicked,
    filters?.showOnly?.ourListings,
    filters?.map?.zoom,
  ]);

  return (
    <div
      id="filters-page-map-wrapper"
      style={{
        display: isMap.data !== false ? "block" : "none",
        // top: isMap.data === true ? screenSize[1] > 430 ? 77 : 0 : 0
      }}
    >
      <div
        className={
          isSatelliteMapId
            ? mapZoom > 12
              ? "filters-page-map-box filters-page-map-box-satellite filters-page-map-box-satellite-12Less"
              : "filters-page-map-box filters-page-map-box-satellite filters-page-map-box-satellite-12More"
            : "filters-page-map-box"
        }
        // style={{
        //   height:
        //     isMap.data === null
        //       ? "calc(100svh - 148px)"
        //       : "calc(100svh - 148px)",
        // }}
      >
        {((!isLoadedData && isMap.data === true) ||
          (isMap.data === true && houseCardDataInRect?.isLoading === true)) && (
          <div className="filters-page-map-spiner-wrapper">
            <Spiner />
          </div>
        )}
        <div
          className="filters-page-map-overlays-alarm-wrapper"
          style={{
            display: isOpenAlarmToRemoveAllRegions ? "flex" : "none",
          }}
        >
          <div className="filters-page-map-overlays-alarm-wrapper-title">
            Alarm
            <div
              className="filters-page-map-overlays-alarm-wrapper-close"
              onClick={AlarmToRemoveAllRegionsHandler}
            >
              <CloseIcon color={colors.white} height={14} width={14} />
            </div>
          </div>
          <div>
            <span style={{ opacity: 0 }}>__</span>
            <span>Do you want to remove all regions?</span>
          </div>
          <div className="filters-page-map-overlays-alarm-wrapper-yes-no">
            <div
              className="filters-page-map-overlays-alarm-yes"
              onClick={alarmToRemoveAllRegionsConfirm}
            >
              Yes
            </div>
            <div
              className="filters-page-map-overlays-alarm-yes"
              onClick={AlarmToRemoveAllRegionsHandler}
            >
              No
            </div>
          </div>
        </div>
        {/* {isLoadedData && isMap.data === true && (
          <div className="sorting-block-icon-map-list-map-view">
            <TripleSwitcherSliderButton
              getSwitchAnimation={getSwitchAnimation}
              state={state}
              setState={setState}
            />
            <div
              id="sorting-block-icon-2_5_black"
              onClick={ourListingsBaseHandle}
            >
              <div className="sorting-block-icon-2_5-our-listings_black">
                <div className="sorting-block-icon-2_5-our-listings-realtytexas-icon_black"></div>
                <div className="sorting-block-icon-2_5-our-listings-content_black">
                  {isAllOurListings ? "All Listings" : "Listings"}
                </div>
              </div>
            </div>
            <div
              className="sorting-block-icon-map-list-map-view-filters"
              onClick={openNarrowFiltersHandle}
            >
              Filters
            </div>
          </div>
        )} */}
        {isLoadedData && screenSize[0] > 768 && (
          <div
            id="filters-page-map-wrapper-satellite-wrapper-id"
            className={
              isMap.data === null && screenSize[0] <= 1500
                ? // ((screenSize[0] <= 1080 && screenSize[0] > 800) ||
                  //   (screenSize[0] <= 1390 && screenSize[0] >= 1081))
                  "filters-page-map-wrapper-satellite-wrapper-both"
                : "filters-page-map-wrapper-satellite-wrapper"
            }
            style={{
              display:
                isMap.data === true || isMap.data === null ? "flex" : "none",
            }}
            onClick={onMapLayerHandler}
          >
            {isSatelliteMapId ? "Satellite" : "Default"}
          </div>
        )}
        {isLoadedData && screenSize[0] <= 768 && (
          <div
            id="filters-page-map-wrapper-satellite-wrapper-id"
            className={
              isSatelliteMapId
                ? "filters-page-map-wrapper-satellite-mobile-wrapper filters-page-map-wrapper-terrain-mobile"
                : "filters-page-map-wrapper-satellite-mobile-wrapper filters-page-map-wrapper-satellite-mobile"
            }
            style={{
              display:
                isMap.data === true || isMap.data === null ? "flex" : "none",
            }}
            onClick={onMapLayerHandler}
          >
            <div style={{ position: "relative", width: "100%" }}>
              <div className="filters-page-map-wrapper-satellite-mobile-text">
                {isSatelliteMapId ? "Satellite" : "Default"}
              </div>
            </div>
          </div>
        )}
        {isLoadedData && (
          <div
            id="filters-page-map-overlays-wrapper-id"
            className={
              isMap.data === null && screenSize[0] <= 1500
                ? // ((screenSize[0] <= 1080 && screenSize[0] > 800) ||
                  //   (screenSize[0] <= 1380 && screenSize[0] >= 1280))
                  "filters-page-map-overlays-wrapper-both"
                : "filters-page-map-overlays-wrapper"
            }
            style={{
              display:
                isMap.data === true || isMap.data === null ? "flex" : "none",
            }}
          >
            <MapOverlaysBtn
              isSubOpenList={isSubOpenList}
              setIsSubOpenList={setIsSubOpenList}
              isSubListData={isSubListData}
              setIsSubListData={setIsSubListData}
              setIsOpenAlarmToRemoveAllRegions={
                setIsOpenAlarmToRemoveAllRegions
              }
              currentLayersPolygons={currentLayersPolygons}
              setToRemovePolygon={setClickedCheckedOverlayer}
              allChecked={allCheckedOverlayers}
              setAllChecked={setAllCheckedOverlayers}
              removeAllPolygons={removeAllLayersPolygons}
              subValue={subValueOverlayersList}
              setSubValue={setSubValueOverlayersList}
              isOpened={isMapOverlaysList}
              setIsOpened={setIsMapOverlaysList}
              // title="Overlays"
              value={valueMapOverlaysList}
              setValue={setValueMapOverlaysList}
              isDraw={isDraw}
              twCities={twCities}
              twPostals={twPostals}
            />
          </div>
        )}
        {isLoadedData && (
          <DrawOnMap
            setIsDraw={setIsDraw}
            isDraw={isDraw}
            setIsMultyDraw={setIsMultyDraw}
            isMultyDraw={isMultyDraw}
            isAllMultyDrawApply={isAllMultyDrawApply}
            setIsAllMultyDrawApply={setIsAllMultyDrawApply}
            allMultyDrawCoordinates={allMultyDrawCoordinates}
            multyDrawing={multyDrawing}
            screenSize={screenSize}
          />
        )}
        {isLoadedData && (
          <div
            className="filters-page-map-wrapper-alert-map-message"
            id="filters-page-map-wrapper-alert-map-message-id"
          >
            <div style={{ position: "relative" }}>
              {houseCardDataInRect?.data?.listings?.length > 0 && (
                <AlertMapMessage
                  content1={
                    houseCardDataInRect?.data.total
                      ? // ? isLoadedDataDisplaying ? 'Displaying ...' : `Displaying ${houseCardDataInRect?.data?.listings?.length} of ${houseCardDataInRect?.data?.total.toLocaleString('en-US')} listings.`
                        `Displaying ${
                          houseCardDataInRect?.data?.listings?.length
                        } of ${houseCardDataInRect?.data?.total.toLocaleString(
                          "en-US"
                        )} listings.`
                      : `Displaying ${houseCardDataInRect?.data?.listings?.length} listings.`
                  }
                  content2={
                    houseCardDataInRect?.data.total
                      ? "Zoom in to see more."
                      : ""
                  }
                />
              )}
              {houseCardDataInRect?.data?.listings?.length === 0 && (
                <AlertMapMessage
                  content1={`Displaying 0 listings.`}
                  content2={""}
                />
              )}
              {houseCardDataInRect?.data?.listings?.length > 0 &&
                houseCardDataInRect?.data?.listings?.length !==
                  houseCardDataInRect?.data?.total &&
                houseCardDataInRect?.data?.total <= 10000 &&
                isMap.data === true && (
                  <div className="filters-page-map-wrapper-alert-map-message-list-pagination">
                    <BaseInputMapList
                      colorEndIcon={colors.white}
                      blueList={true}
                      readonly={true}
                      list={mokedMapViewPagesList.filter((item) => {
                        if (
                          item.value <=
                          Math.ceil(
                            houseCardDataInRect?.data?.total /
                              amountOfMarkersOnMap
                          )
                        )
                          return item;
                      })}
                      setInputValue={setDataInRectPage}
                      inputValue={dataInRectPage}
                      field="label"
                      className={`${
                        sortingValue !== ""
                          ? "filters-block-filter-details-input-list-block-input-blue-color"
                          : "filters-block-filter-details-input-list-block-input-gray-color"
                      }`}
                    />
                  </div>
                )}
            </div>
          </div>
        )}
        {isMap.data === true && mapComponent}
        {isMap.data === null && mapComponent}
      </div>
    </div>
  );
}

const MarkerClaster = ({
  count,
  clusterStyle,
  cluster_id,
  map,
  sc,
  setData,
  data,
  mapZoom,
  wasMoved,
}) => {
  const [markerClasterVisible, setMarkerClasterVisible] = useState(0);

  const clusterHandler = () => {
    let leaves = sc.getLeaves(cluster_id, Infinity, 0).filter((cluster) => {
      if (data.includes(cluster)) return false;
      return true;
    });

    let temp = [...data, ...leaves];
    setData(temp);
    setMarkerClasterVisible((v) => v + 1);
  };

  return (
    markerClasterVisible === 0 && (
      <div
        className="filters-page-map-cluster-wrapper"
        style={{
          ...clusterStyle,
        }}
        onClick={() => {
          clusterHandler();
        }}
      >
        <div className="filters-page-map-cluster-counter-wrapper">{count}</div>
      </div>
    )
  );
};

const Marker = ({
  price = null,
  address,
  city,
  stateOrProvince,
  postalCode,
  size,
  keyNum,
  link,
}) => {
  const [isLabelVisible, setIsLabelVisible] = useState(false);
  const [image, setImage] = useState(null);

  const getImage = () => {
    setImage(null);
    axios
      .get(`https://realtytexas.com/api/v1/listings/${keyNum}/images`)
      .then(function (response) {
        setImage(response?.data?.data ? response?.data?.data[0] : null);
      })
      .catch(function (error) {
        setImage(null);
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (keyNum && isLabelVisible) {
      getImage();
    }
  }, [isLabelVisible]);

  const nFormatter = (num, digits) => {
    let tempNum = String(num);
    tempNum = tempNum.replaceAll(",", "");

    const lookup = [
      { value: 1, symbol: "" },

      { value: 1e3, symbol: "k" },

      { value: 1e6, symbol: "M" },

      { value: 1e9, symbol: "B" },

      { value: 1e12, symbol: "T" },

      { value: 1e15, symbol: "P" },

      { value: 1e18, symbol: "E" },
    ];

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return tempNum >= item.value;
      });

    return item
      ? (tempNum / item.value).toFixed().replace(rx, "$1") + item.symbol
      : "0";
  };

  let markerPrice = nFormatter(price);

  const defaultMediaUrl =
    "https://realtytexas.com/assets/images/placeholder-image.jpg";

  return (
    <div
      className="filters-page-map-marker-wrapper"
      style={{
        width: "52px",
        height: "52px",
        position: "relative",
      }}
    >
      <div
        className="filters-page-map-marker-label-wrapper"
        style={{
          display: isLabelVisible ? "flex" : "none",
        }}
      >
        <div className="filters-page-map-marker-label-box">
          <div
            className="filters-page-map-marker-label-close"
            onClick={() => setIsLabelVisible(false)}
          >
            <CloseIcon />
          </div>
          <a href={link} target="_blank">
            <div className="filters-page-map-marker-label-image">
              {isLabelVisible && (
                <img
                  src={image?.MediaURL ? image?.MediaURL : defaultMediaUrl}
                  onError={(e) => {
                    e.currentTarget.src = defaultMediaUrl;
                  }}
                />
              )}
            </div>
          </a>
          <a href={link} target="_blank">
            <div className="filters-page-map-marker-label-content-box">
              <div className="filters-page-map-marker-label-price">
                <h4>
                  {price ? "$" : ""}
                  {price ? price?.toLocaleString("en-US") : "No Price"}
                </h4>
              </div>
              <div className="filters-page-map-marker-label-size">
                <span>Property Size {size} ft</span>
                <span>2</span>
              </div>
              <div className="filters-page-map-marker-label-adress-1">
                <p>{address ? address.trim() : ""},</p>
              </div>
              <div className="filters-page-map-marker-label-adress-2">
                <p>
                  {city} {stateOrProvince} {postalCode}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div
        className="filters-page-map-marker-label-bottom-arrow"
        style={{
          display: isLabelVisible ? "flex" : "none",
        }}
      />
      <div
        className="filters-page-map-marker-icon-content"
        onClick={() => setIsLabelVisible(!isLabelVisible)}
      >
        {price ? markerPrice : "No Price"}
      </div>
      <div style={{ zIndex: 1 }}>
        <div className="filters-page-map-marker-icon-wrapper">
          {/* <MatkerIcon /> */}
          {/* <GreenMatkerIcon /> */}
          {/* {price} */}
        </div>
      </div>
    </div>
  );
};

const RoundedMarker = ({
  price = null,
  address,
  city,
  stateOrProvince,
  postalCode,
  size,
  keyNum,
  link,
  subdomain,
  status,
  zoom,
  isCrosshair,
  isLoadedData,
}) => {
  const { data } = useSelector((state) => state.houseCardsMap);
  const [isLabelVisible, setIsLabelVisible] = useState(false);
  const [image, setImage] = useState(null);

  const displayLabelBlock = (str) => {
    const labelEl = document?.getElementById(
      "filters-page-map-wrapper-alert-map-message-id"
    );
    labelEl?.style.setProperty("display", str, "important");
    const drawLabel = document?.getElementById("map-drawOnMap-wrapper");
    drawLabel?.style.setProperty("display", str, "important");
    const layersLabel = document?.getElementById(
      "filters-page-map-overlays-wrapper-id"
    );
    layersLabel?.style.setProperty("display", str, "important");
    const mapViewLabel = document?.getElementById(
      "filters-page-map-wrapper-satellite-wrapper-id"
    );
    mapViewLabel?.style.setProperty("display", str, "important");
  };

  let color = null;
  let priceClass = null;
  if (status === "Active") {
    color = colors.blue;
    priceClass = "rounded-marker-price-active";
  }
  if (status === "Closed") {
    color = colors.mainBg;
    priceClass = "rounded-marker-price-closed";
  }
  if (status === "Pending") {
    color = colors.red;
    priceClass = "rounded-marker-price-pending";
  }

  const getImage = () => {
    setImage(null);
    let tempImg = data.listings.filter(
      (card) => card.ListingKeyNumeric === keyNum
    )[0]?.Media;
    if (tempImg) {
      setImage(tempImg);
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  // useEffect(() => {
  //   if (keyNum && isLabelVisible) {
  //     getImage();
  //   }
  // }, [isLabelVisible]);

  const nFormatter = (num, digits) => {
    let tempNum = String(num);
    tempNum = tempNum.replaceAll(",", "");

    const lookup = [
      { value: 1, symbol: "" },

      { value: 1e3, symbol: "k" },

      { value: 1e6, symbol: "M" },

      { value: 1e9, symbol: "B" },

      { value: 1e12, symbol: "T" },

      { value: 1e15, symbol: "P" },

      { value: 1e18, symbol: "E" },
    ];

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return tempNum >= item.value;
      });

    return item
      ? (tempNum / item.value).toFixed().replace(rx, "$1") + item.symbol
      : "0";
  };

  let markerPrice = nFormatter(price);

  const defaultMediaUrl =
    "https://realtytexas.com/assets/images/placeholder-image.jpg";

  const handleClickOutside = () => {
    setIsLabelVisible(false);
  };

  const handleOnHoverMarker = () => {
    const el = document.getElementById(`marker-${keyNum}`);
    const priceEl = document.getElementById(`rounded-marker-price-${keyNum}`);
    if (el) {
      el.style.setProperty(
        "border",
        `2px solid ${color ? color : colors.gray}`,
        "important"
      );
      el.style.setProperty("backgroundColor", colors.white, "important");
      // el.classList.add(priceClass)
      if (priceEl) {
        priceEl.style.setProperty("zIndex", 10, "important");
      }
    }
  };

  const handleResetOnHoverMarker = () => {
    const el = document.getElementById(`marker-${keyNum}`);
    const priceEl = document.getElementById(`rounded-marker-price-${keyNum}`);
    if (el) {
      el.style.setProperty("border", `2px solid ${colors.white}`, "important");
      el.style.setProperty(
        "backgroundColor",
        color ? color : colors.gray,
        "important"
      );
      if (priceEl) {
        priceEl.style.setProperty("zIndex", 0, "important");
      }
    }
  };

  useEffect(() => {
    if (isLabelVisible && isLoadedData) {
      displayLabelBlock("none");
    } else {
      displayLabelBlock("flex");
    }
  }, [isLabelVisible, isLoadedData]);

  return (
    <div style={{ position: "relative" }}>
      <div
        className="filters-page-map-rounded-marker-label-close"
        style={{ display: isLabelVisible ? "block" : "none" }}
        // onClick={() => setIsLabelVisible(false)}
      >
        <CloseIcon />
      </div>
      <ClickOutside onClick={handleClickOutside}>
        <div
          id={`rounded-marker-${keyNum}`}
          className={
            isCrosshair
              ? "filters-page-map-rounded-marker-wrapper"
              : "filters-page-map-rounded-marker-wrapper filters-page-map-rounded-marker-wrapper-hovered"
          }
          onClick={() => setIsLabelVisible(true)}
          style={{
            height: isLabelVisible ? "18px" : "12px",
            width: isLabelVisible ? "18px" : "12px",
            border: `2px solid ${
              isLabelVisible ? (color ? color : colors.gray) : colors.white
            }`,
            backgroundColor: isLabelVisible
              ? colors.white
              : color
              ? color
              : colors.gray,
            cursor: isCrosshair ? "crosshair" : "pointer",
            // zIndex: isLabelVisible ? 1 : 0,
          }}
          // onMouseEnter={handleOnHoverMarker}
          // onMouseLeave={handleResetOnHoverMarker}
        >
          <div
            className="filters-page-map-rounded-marker-label-wrapper"
            style={{
              display: isLabelVisible ? "flex" : "none",
            }}
          >
            <div className="filters-page-map-rounded-marker-label-box">
              <a href={`/${subdomain}${link}`} target="_blank">
                <div className="filters-page-map-marker-label-image">
                  {isLabelVisible && (
                    <img
                      src={
                        image?.length > 0
                          ? `https://propertyimages.realtytexas.com${image[0]?.MediaURL}`
                          : defaultMediaUrl
                      }
                      onError={(e) => {
                        e.currentTarget.src = defaultMediaUrl;
                      }}
                    />
                  )}
                </div>
              </a>
              <a href={`/${subdomain}${link}`} target="_blank">
                <div className="filters-page-map-marker-label-price">
                  <div>
                    <h4>
                      {price ? "$" : ""}
                      {price
                        ? price?.toLocaleString("en-US") + " | "
                        : "No Price | "}
                    </h4>
                  </div>
                  <div className="filters-page-map-marker-label-size-wrapper">
                    <span>{size} ft</span>
                    <span className="filters-page-map-marker-label-size2">
                      2
                    </span>
                  </div>
                </div>
                <div className="filters-page-map-marker-label-content-box">
                  <div className="filters-page-map-marker-label-adress-1">
                    <p>{address ? address.trim() : ""},</p>
                  </div>
                  <div className="filters-page-map-marker-label-adress-2">
                    <p>
                      {city} {stateOrProvince} {postalCode}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div
            id={`rounded-marker-price-${keyNum}`}
            className={
              zoom > 15
                ? "filters-page-map-rounded-marker-icon-content filters-page-map-rounded-marker-colapsed filters-page-map-rounded-marker-icon-content-enabled"
                : isCrosshair
                ? "filters-page-map-rounded-marker-icon-content filters-page-map-rounded-marker-colapsed filters-page-map-rounded-marker-icon-content-disabled-isCrossed"
                : "filters-page-map-rounded-marker-icon-content filters-page-map-rounded-marker-colapsed filters-page-map-rounded-marker-icon-content-disabled"
            }
            onClick={() => setIsLabelVisible(!isLabelVisible)}
            style={{
              borderColor: color ? color : colors.gray,
              color: color ? color : colors.gray,
              zIndex: 2,
            }}
          >
            {price ? markerPrice : "No Price"}
          </div>
        </div>
      </ClickOutside>
    </div>
  );
};
