import React, { useState } from "react";
import { colors } from "../../../constants/colors";
import { ArrowDown, ArrowUp } from "../../../constants/icons";
import ClickOutside from "../../clickOutside/clickoutSideComponent";
import "./baseInputMapList.css";

const BaseInputMapList = ({
  readonly = false,
  name = "sorting",
  className,
  classNameList,
  onClick,
  list,
  placeholder = "Sort By Default",
  colorEndIcon = colors.mainBg,
  height,
  listTop = "29px",
  padding,
  field = null,
  setInputValue,
  inputValue,
  setInValue2,
  blueList = false,
  width = "80px",
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const inputHandler = () => {
    setIsVisible(true);
  };

  const clickOutsideHandler = () => {
    setIsVisible(false);
  };

  const onIconItem = (item) => {
    setInputValue(item);
    setIsVisible(false);
    if (setInValue2) setInValue2(item);
  };

  return (
    <div
      className="filters-page-base-input-map-list-wrapper"
      style={{ width: width }}
      onClick={() => {
        inputHandler();
        if (onClick) onClick();
      }}
    >
      <div className="filters-page-base-input-map-list-end-icon-wrapper">
        <ArrowUp
          width={10}
          height={10}
          color={colorEndIcon}
          style={{ display: !isVisible ? "none" : "block" }}
        />
      </div>
      <ClickOutside onClick={clickOutsideHandler} style={{ height: height }}>
        <div className="filters-page-base-input-map-list-end-icon-wrapper">
          <ArrowDown
            width={10}
            height={10}
            color={colorEndIcon}
            style={{ display: isVisible ? "none" : "block" }}
          />
        </div>
        <input
          name={name}
          type="text"
          placeholder={placeholder}
          className={className}
          readOnly={readonly}
          value={inputValue[field] ? inputValue[field] : inputValue}
          style={{
            width: "100%",
            height: height,
            // borderBottomRightRadius: isVisible ? 0 : 6,
            // borderBottomLeftRadius: isVisible ? 0 : 6,
            padding: padding ? padding : "19px 24px",
          }}
        />
      </ClickOutside>
      <ul style={{ display: isVisible ? "block" : "none", top: listTop }}>
        {list.map((item, i) => {
          if (field) {
            return (
              <li
                key={i}
                onClick={() => onIconItem(item)}
                className={classNameList}
              >
                <p
                  className={`${
                    item[field] === inputValue[field] && blueList
                      ? "filters-page-base-input-map-list-blue-wrapper"
                      : ""
                  }`}
                >
                  {item[field]}
                </p>
              </li>
            );
          } else {
            return (
              <li
                key={i}
                onClick={() => onIconItem(item)}
                className={classNameList}
              >
                <p
                  className={`${
                    item === inputValue && blueList
                      ? "filters-page-base-input-map-list-blue-wrapper"
                      : ""
                  }`}
                  // style={{
                  //   color: item === inputValue && blueList ? "blue" : "gray",
                  // }}
                >
                  {item}
                </p>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default BaseInputMapList;
