/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toObjMLSFilterConverter } from "../../../constants/methods";
import { getAllHouseCardsAction } from "../../../store/actions/getAllHouseCards";
import { getFilterLocationMLS_addressAction } from "../../../store/actions/getFilterLocationMLS_address";
import { BaseSearchInputList } from "../../../ui";
import { ExpandIcon, CollapseIcon } from "../../../constants/icons";
import "./quickSearch.css";

const QuickSearch = ({
  filters,
  setFilters,
  sortingValue,
  currentPage,
  setCurrentPage,
  MLS_address,
  MLS_addressRes,
  foundMLS_address,
  setMLS_Address,
  setMLS_AddressRes,
  setFoundMLS_address,
}) => {
  const { data, isLoading } = useSelector((state) => state.filterLocationLists);
  const dispatch = useDispatch();

  const filterLocationMLS_Address = (MLS_address) => {
    dispatch(getFilterLocationMLS_addressAction(MLS_address));
  };

  useEffect(() => {
    //temporary. cant found the problem
    setFilters({
      ...filters,
      quickSearch: MLS_addressRes && MLS_addressRes[0] ? MLS_addressRes : null,
    });
  }, [MLS_address]);

  let tempOrder = null;

  if (
    sortingValue?.orderBySort === "desc" ||
    sortingValue?.orderBySort === "asc"
  ) {
    tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
  }

  const searchApplyHandler = (item, field) => {
    const arrayData = [];
    const mlsObj = {
      mls: field === "mls" ? [item] : null,
      postal: field === "postal" ? [item] : null,
      cities: field === "cities" ? [item] : null,
      address: field === "address" ? [item] : null,
    };
    setMLS_AddressRes([mlsObj]);

    arrayData.push({
      column: item.label,
      operator: "eq",
      value: item.value,
    });
    const filtedrArr = toObjMLSFilterConverter(filters);
    // setIsApply(!isApply);
    // dispatch(updateIsOpenNarrowFilters(false));
    dispatch(
      getAllHouseCardsAction(1, [...arrayData, ...filtedrArr], tempOrder)
    );
    window.scrollTo(0, 0);
    if (currentPage !== 0) setCurrentPage(0);
  };

  const [isExpanded, setIsExpanded] = useState(true);

  const onTittleHandle = () => {
    setIsExpanded(!isExpanded);
  };

  const isMap = useSelector((state) => state.isMap);

  return (
    <div
      id="filters-block-filter-quick-search"
      className={isExpanded ? "" : "collapsed-quick-search"}
      style={{ paddingTop: isMap.data === null ? "20px" : "20px" }}
    >
      <div
        id="filters-block-filter-quick-search-title"
        onClick={onTittleHandle}
      >
        <div>
          <h4>Quick Search</h4>
        </div>
        <div className="filters-block-filter-quick-search-title-icon">
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </div>
      </div>
      <div className="filters-block-filter-quick-search-content-wrapper">
        <div
          className={
            isExpanded
              ? "filters-block-filter-quick-search-address-input"
              : "filters-block-filter-quick-search-address-input collapsedInput"
          }
        >
          <BaseSearchInputList
            name="MLS_address"
            list={data?.quickSearch}
            placeholder="Type an Address or MLS#"
            listTop="49px"
            item={MLS_address}
            setItem={setMLS_Address}
            found={foundMLS_address}
            setFound={setFoundMLS_address}
            field="value"
            height={50}
            callback={filterLocationMLS_Address}
            isLoading={isLoading}
            searchApplyHandler={searchApplyHandler}
            filters={filters}
            autocomplete="rutjssfkde"
          />
        </div>
      </div>
    </div>
  );
};

export default QuickSearch;
