export const mokedMapViewPagesList = [
  { label: "Page 1", value: 1 },
  { label: "Page 2", value: 2 },
  { label: "Page 3", value: 3 },
  { label: "Page 4", value: 4 },
  { label: "Page 5", value: 5 },
  { label: "Page 6", value: 6 },
  { label: "Page 7", value: 7 },
  { label: "Page 8", value: 8 },
  { label: "Page 9", value: 9 },
  { label: "Page 10", value: 10 },
  { label: "Page 11", value: 11 },
  { label: "Page 12", value: 12 },
  { label: "Page 13", value: 13 },
  { label: "Page 14", value: 14 },
  { label: "Page 15", value: 15 },
  { label: "Page 16", value: 16 },
  { label: "Page 17", value: 17 },
  { label: "Page 18", value: 18 },
  { label: "Page 19", value: 19 },
  { label: "Page 20", value: 20 },
  { label: "Page 21", value: 21 },
  { label: "Page 22", value: 22 },
  { label: "Page 23", value: 23 },
  { label: "Page 24", value: 24 },
  { label: "Page 25", value: 25 },
  { label: "Page 26", value: 26 },
  { label: "Page 27", value: 27 },
  { label: "Page 28", value: 28 },
  { label: "Page 29", value: 29 },
  { label: "Page 30", value: 30 },
  { label: "Page 31", value: 31 },
  { label: "Page 32", value: 32 },
  { label: "Page 33", value: 33 },
  { label: "Page 34", value: 34 },
  { label: "Page 35", value: 35 },
  { label: "Page 36", value: 36 },
  { label: "Page 37", value: 37 },
  { label: "Page 38", value: 38 },
  { label: "Page 39", value: 39 },
  { label: "Page 40", value: 40 },
  { label: "Page 41", value: 41 },
  { label: "Page 42", value: 42 },
  { label: "Page 43", value: 43 },
  { label: "Page 44", value: 44 },
  { label: "Page 45", value: 45 },
  { label: "Page 46", value: 46 },
  { label: "Page 47", value: 47 },
  { label: "Page 48", value: 48 },
  { label: "Page 49", value: 49 },
  { label: "Page 50", value: 50 },
  { label: "Page 51", value: 51 },
  { label: "Page 52", value: 52 },
  { label: "Page 53", value: 53 },
  { label: "Page 54", value: 54 },
  { label: "Page 55", value: 55 },
  { label: "Page 56", value: 56 },
  { label: "Page 57", value: 57 },
  { label: "Page 58", value: 58 },
  { label: "Page 59", value: 59 },
  { label: "Page 60", value: 60 },
  { label: "Page 61", value: 61 },
  { label: "Page 62", value: 62 },
  { label: "Page 63", value: 63 },
  { label: "Page 64", value: 64 },
  { label: "Page 65", value: 65 },
  { label: "Page 66", value: 66 },
  { label: "Page 67", value: 67 },
  { label: "Page 68", value: 68 },
  { label: "Page 69", value: 69 },
  { label: "Page 70", value: 70 },
  { label: "Page 71", value: 71 },
  { label: "Page 72", value: 72 },
  { label: "Page 73", value: 73 },
  { label: "Page 74", value: 74 },
  { label: "Page 75", value: 75 },
  { label: "Page 76", value: 76 },
  { label: "Page 77", value: 77 },
  { label: "Page 78", value: 78 },
  { label: "Page 79", value: 79 },
  { label: "Page 80", value: 80 },
  { label: "Page 81", value: 81 },
  { label: "Page 82", value: 82 },
  { label: "Page 83", value: 83 },
  { label: "Page 84", value: 84 },
  { label: "Page 85", value: 85 },
  { label: "Page 86", value: 86 },
  { label: "Page 87", value: 87 },
  { label: "Page 88", value: 88 },
  { label: "Page 89", value: 89 },
  { label: "Page 90", value: 90 },
  { label: "Page 91", value: 91 },
  { label: "Page 92", value: 92 },
  { label: "Page 93", value: 93 },
  { label: "Page 94", value: 94 },
  { label: "Page 95", value: 95 },
  { label: "Page 96", value: 96 },
  { label: "Page 97", value: 97 },
  { label: "Page 98", value: 98 },
  { label: "Page 99", value: 99 },
  { label: "Page 100", value: 100 },
  { label: "Page 101", value: 101 },
  { label: "Page 102", value: 102 },
  { label: "Page 103", value: 103 },
  { label: "Page 104", value: 104 },
  { label: "Page 105", value: 105 },
  { label: "Page 106", value: 106 },
  { label: "Page 107", value: 107 },
  { label: "Page 108", value: 108 },
  { label: "Page 109", value: 109 },
  { label: "Page 110", value: 110 },
  { label: "Page 111", value: 111 },
  { label: "Page 112", value: 112 },
  { label: "Page 113", value: 113 },
  { label: "Page 114", value: 114 },
  { label: "Page 115", value: 115 },
  { label: "Page 116", value: 116 },
  { label: "Page 117", value: 117 },
  { label: "Page 118", value: 118 },
  { label: "Page 119", value: 119 },
  { label: "Page 120", value: 120 },
  { label: "Page 121", value: 121 },
  { label: "Page 122", value: 122 },
  { label: "Page 123", value: 123 },
  { label: "Page 124", value: 124 },
  { label: "Page 125", value: 125 },
  { label: "Page 126", value: 126 },
  { label: "Page 127", value: 127 },
  { label: "Page 128", value: 128 },
  { label: "Page 129", value: 129 },
  { label: "Page 130", value: 130 },
  { label: "Page 131", value: 131 },
  { label: "Page 132", value: 132 },
  { label: "Page 133", value: 133 },
  { label: "Page 134", value: 134 },
  { label: "Page 135", value: 135 },
  { label: "Page 136", value: 136 },
  { label: "Page 137", value: 137 },
  { label: "Page 138", value: 138 },
  { label: "Page 139", value: 139 },
  { label: "Page 140", value: 140 },
  { label: "Page 141", value: 141 },
  { label: "Page 142", value: 142 },
  { label: "Page 143", value: 143 },
  { label: "Page 144", value: 144 },
  { label: "Page 145", value: 145 },
  { label: "Page 146", value: 146 },
  { label: "Page 147", value: 147 },
  { label: "Page 148", value: 148 },
  { label: "Page 149", value: 149 },
  { label: "Page 150", value: 150 },
  { label: "Page 151", value: 151 },
  { label: "Page 152", value: 152 },
  { label: "Page 153", value: 153 },
  { label: "Page 154", value: 154 },
  { label: "Page 155", value: 155 },
  { label: "Page 156", value: 156 },
  { label: "Page 157", value: 157 },
  { label: "Page 158", value: 158 },
  { label: "Page 159", value: 159 },
  { label: "Page 160", value: 160 },
  { label: "Page 161", value: 161 },
  { label: "Page 162", value: 162 },
  { label: "Page 163", value: 163 },
  { label: "Page 164", value: 164 },
  { label: "Page 165", value: 165 },
  { label: "Page 166", value: 166 },
  { label: "Page 167", value: 167 },
  { label: "Page 168", value: 168 },
  { label: "Page 169", value: 169 },
  { label: "Page 170", value: 170 },
  { label: "Page 171", value: 171 },
  { label: "Page 172", value: 172 },
  { label: "Page 173", value: 173 },
  { label: "Page 174", value: 174 },
  { label: "Page 175", value: 175 },
  { label: "Page 176", value: 176 },
  { label: "Page 177", value: 177 },
  { label: "Page 178", value: 178 },
  { label: "Page 179", value: 179 },
  { label: "Page 180", value: 180 },
  { label: "Page 181", value: 181 },
  { label: "Page 182", value: 182 },
  { label: "Page 183", value: 183 },
  { label: "Page 184", value: 184 },
  { label: "Page 185", value: 185 },
  { label: "Page 186", value: 186 },
  { label: "Page 187", value: 187 },
  { label: "Page 188", value: 188 },
  { label: "Page 189", value: 189 },
  { label: "Page 190", value: 190 },
  { label: "Page 191", value: 191 },
  { label: "Page 192", value: 192 },
  { label: "Page 193", value: 193 },
  { label: "Page 194", value: 194 },
  { label: "Page 195", value: 195 },
  { label: "Page 196", value: 196 },
  { label: "Page 197", value: 197 },
  { label: "Page 198", value: 198 },
  { label: "Page 199", value: 199 },
  { label: "Page 200", value: 200 },
  { label: "Page 201", value: 201 },
  { label: "Page 202", value: 202 },
  { label: "Page 203", value: 203 },
  { label: "Page 204", value: 204 },
  { label: "Page 205", value: 205 },
  { label: "Page 206", value: 206 },
  { label: "Page 207", value: 207 },
  { label: "Page 208", value: 208 },
  { label: "Page 209", value: 209 },
  { label: "Page 210", value: 210 },
  { label: "Page 211", value: 211 },
  { label: "Page 212", value: 212 },
  { label: "Page 213", value: 213 },
  { label: "Page 214", value: 214 },
  { label: "Page 215", value: 215 },
  { label: "Page 216", value: 216 },
  { label: "Page 217", value: 217 },
  { label: "Page 218", value: 218 },
  { label: "Page 219", value: 219 },
  { label: "Page 220", value: 220 },
  { label: "Page 221", value: 221 },
  { label: "Page 222", value: 222 },
  { label: "Page 223", value: 223 },
  { label: "Page 224", value: 224 },
  { label: "Page 225", value: 225 },
  { label: "Page 226", value: 226 },
  { label: "Page 227", value: 227 },
  { label: "Page 228", value: 228 },
  { label: "Page 229", value: 229 },
  { label: "Page 230", value: 230 },
  { label: "Page 231", value: 231 },
  { label: "Page 232", value: 232 },
  { label: "Page 233", value: 233 },
  { label: "Page 234", value: 234 },
  { label: "Page 235", value: 235 },
  { label: "Page 236", value: 236 },
  { label: "Page 237", value: 237 },
  { label: "Page 238", value: 238 },
  { label: "Page 239", value: 239 },
  { label: "Page 240", value: 240 },
  { label: "Page 241", value: 241 },
  { label: "Page 242", value: 242 },
  { label: "Page 243", value: 243 },
  { label: "Page 244", value: 244 },
  { label: "Page 245", value: 245 },
  { label: "Page 246", value: 246 },
  { label: "Page 247", value: 247 },
  { label: "Page 248", value: 248 },
  { label: "Page 249", value: 249 },
  { label: "Page 250", value: 250 },
  { label: "Page 251", value: 251 },
  { label: "Page 252", value: 252 },
  { label: "Page 253", value: 253 },
  { label: "Page 254", value: 254 },
  { label: "Page 255", value: 255 },
  { label: "Page 256", value: 256 },
  { label: "Page 257", value: 257 },
  { label: "Page 258", value: 258 },
  { label: "Page 259", value: 259 },
  { label: "Page 260", value: 260 },
  { label: "Page 261", value: 261 },
  { label: "Page 262", value: 262 },
  { label: "Page 263", value: 263 },
  { label: "Page 264", value: 264 },
  { label: "Page 265", value: 265 },
  { label: "Page 266", value: 266 },
  { label: "Page 267", value: 267 },
  { label: "Page 268", value: 268 },
  { label: "Page 269", value: 269 },
  { label: "Page 270", value: 270 },
  { label: "Page 271", value: 271 },
  { label: "Page 272", value: 272 },
  { label: "Page 273", value: 273 },
  { label: "Page 274", value: 274 },
  { label: "Page 275", value: 275 },
  { label: "Page 276", value: 276 },
  { label: "Page 277", value: 277 },
  { label: "Page 278", value: 278 },
  { label: "Page 279", value: 279 },
  { label: "Page 280", value: 280 },
  { label: "Page 281", value: 281 },
  { label: "Page 282", value: 282 },
  { label: "Page 283", value: 283 },
  { label: "Page 284", value: 284 },
  { label: "Page 285", value: 285 },
  { label: "Page 286", value: 286 },
  { label: "Page 287", value: 287 },
  { label: "Page 288", value: 288 },
  { label: "Page 289", value: 289 },
  { label: "Page 290", value: 290 },
  { label: "Page 291", value: 291 },
  { label: "Page 292", value: 292 },
  { label: "Page 293", value: 293 },
  { label: "Page 294", value: 294 },
  { label: "Page 295", value: 295 },
  { label: "Page 296", value: 296 },
  { label: "Page 297", value: 297 },
  { label: "Page 298", value: 298 },
  { label: "Page 299", value: 299 },
  { label: "Page 300", value: 300 },
  { label: "Page 301", value: 301 },
  { label: "Page 302", value: 302 },
  { label: "Page 303", value: 303 },
  { label: "Page 304", value: 304 },
  { label: "Page 305", value: 305 },
  { label: "Page 306", value: 306 },
  { label: "Page 307", value: 307 },
  { label: "Page 308", value: 308 },
  { label: "Page 309", value: 309 },
  { label: "Page 310", value: 310 },
  { label: "Page 311", value: 311 },
  { label: "Page 312", value: 312 },
  { label: "Page 313", value: 313 },
  { label: "Page 314", value: 314 },
  { label: "Page 315", value: 315 },
  { label: "Page 316", value: 316 },
  { label: "Page 317", value: 317 },
  { label: "Page 318", value: 318 },
  { label: "Page 319", value: 319 },
  { label: "Page 320", value: 320 },
  { label: "Page 321", value: 321 },
  { label: "Page 322", value: 322 },
  { label: "Page 323", value: 323 },
  { label: "Page 324", value: 324 },
  { label: "Page 325", value: 325 },
  { label: "Page 326", value: 326 },
  { label: "Page 327", value: 327 },
  { label: "Page 328", value: 328 },
  { label: "Page 329", value: 329 },
  { label: "Page 330", value: 330 },
  { label: "Page 331", value: 331 },
  { label: "Page 332", value: 332 },
  { label: "Page 333", value: 333 },
  { label: "Page 334", value: 334 },
  { label: "Page 335", value: 335 },
  { label: "Page 336", value: 336 },
  { label: "Page 337", value: 337 },
  { label: "Page 338", value: 338 },
  { label: "Page 339", value: 339 },
  { label: "Page 340", value: 340 },
  { label: "Page 341", value: 341 },
  { label: "Page 342", value: 342 },
  { label: "Page 343", value: 343 },
  { label: "Page 344", value: 344 },
  { label: "Page 345", value: 345 },
  { label: "Page 346", value: 346 },
  { label: "Page 347", value: 347 },
  { label: "Page 348", value: 348 },
  { label: "Page 349", value: 349 },
  { label: "Page 350", value: 350 },
  { label: "Page 351", value: 351 },
  { label: "Page 352", value: 352 },
  { label: "Page 353", value: 353 },
  { label: "Page 354", value: 354 },
  { label: "Page 355", value: 355 },
  { label: "Page 356", value: 356 },
  { label: "Page 357", value: 357 },
  { label: "Page 358", value: 358 },
  { label: "Page 359", value: 359 },
  { label: "Page 360", value: 360 },
  { label: "Page 361", value: 361 },
  { label: "Page 362", value: 362 },
  { label: "Page 363", value: 363 },
  { label: "Page 364", value: 364 },
  { label: "Page 365", value: 365 },
  { label: "Page 366", value: 366 },
  { label: "Page 367", value: 367 },
  { label: "Page 368", value: 368 },
  { label: "Page 369", value: 369 },
  { label: "Page 370", value: 370 },
  { label: "Page 371", value: 371 },
  { label: "Page 372", value: 372 },
  { label: "Page 373", value: 373 },
  { label: "Page 374", value: 374 },
  { label: "Page 375", value: 375 },
  { label: "Page 376", value: 376 },
  { label: "Page 377", value: 377 },
  { label: "Page 378", value: 378 },
  { label: "Page 379", value: 379 },
  { label: "Page 380", value: 380 },
  { label: "Page 381", value: 381 },
  { label: "Page 382", value: 382 },
  { label: "Page 383", value: 383 },
  { label: "Page 384", value: 384 },
  { label: "Page 385", value: 385 },
  { label: "Page 386", value: 386 },
  { label: "Page 387", value: 387 },
  { label: "Page 388", value: 388 },
  { label: "Page 389", value: 389 },
  { label: "Page 390", value: 390 },
  { label: "Page 391", value: 391 },
  { label: "Page 392", value: 392 },
  { label: "Page 393", value: 393 },
  { label: "Page 394", value: 394 },
  { label: "Page 395", value: 395 },
  { label: "Page 396", value: 396 },
  { label: "Page 397", value: 397 },
  { label: "Page 398", value: 398 },
  { label: "Page 399", value: 399 },
  { label: "Page 400", value: 400 },
  { label: "Page 401", value: 401 },
  { label: "Page 402", value: 402 },
  { label: "Page 403", value: 403 },
  { label: "Page 404", value: 404 },
  { label: "Page 405", value: 405 },
  { label: "Page 406", value: 406 },
  { label: "Page 407", value: 407 },
  { label: "Page 408", value: 408 },
  { label: "Page 409", value: 409 },
  { label: "Page 410", value: 410 },
  { label: "Page 411", value: 411 },
  { label: "Page 412", value: 412 },
  { label: "Page 413", value: 413 },
  { label: "Page 414", value: 414 },
  { label: "Page 415", value: 415 },
  { label: "Page 416", value: 416 },
  { label: "Page 417", value: 417 },
  { label: "Page 418", value: 418 },
  { label: "Page 419", value: 419 },
  { label: "Page 420", value: 420 },
  { label: "Page 421", value: 421 },
  { label: "Page 422", value: 422 },
  { label: "Page 423", value: 423 },
  { label: "Page 424", value: 424 },
  { label: "Page 425", value: 425 },
  { label: "Page 426", value: 426 },
  { label: "Page 427", value: 427 },
  { label: "Page 428", value: 428 },
  { label: "Page 429", value: 429 },
  { label: "Page 430", value: 430 },
  { label: "Page 431", value: 431 },
  { label: "Page 432", value: 432 },
  { label: "Page 433", value: 433 },
  { label: "Page 434", value: 434 },
  { label: "Page 435", value: 435 },
  { label: "Page 436", value: 436 },
  { label: "Page 437", value: 437 },
  { label: "Page 438", value: 438 },
  { label: "Page 439", value: 439 },
  { label: "Page 440", value: 440 },
  { label: "Page 441", value: 441 },
  { label: "Page 442", value: 442 },
  { label: "Page 443", value: 443 },
  { label: "Page 444", value: 444 },
  { label: "Page 445", value: 445 },
  { label: "Page 446", value: 446 },
  { label: "Page 447", value: 447 },
  { label: "Page 448", value: 448 },
  { label: "Page 449", value: 449 },
  { label: "Page 450", value: 450 },
  { label: "Page 451", value: 451 },
  { label: "Page 452", value: 452 },
  { label: "Page 453", value: 453 },
  { label: "Page 454", value: 454 },
  { label: "Page 455", value: 455 },
  { label: "Page 456", value: 456 },
  { label: "Page 457", value: 457 },
  { label: "Page 458", value: 458 },
  { label: "Page 459", value: 459 },
  { label: "Page 460", value: 460 },
  { label: "Page 461", value: 461 },
  { label: "Page 462", value: 462 },
  { label: "Page 463", value: 463 },
  { label: "Page 464", value: 464 },
  { label: "Page 465", value: 465 },
  { label: "Page 466", value: 466 },
  { label: "Page 467", value: 467 },
  { label: "Page 468", value: 468 },
  { label: "Page 469", value: 469 },
  { label: "Page 470", value: 470 },
  { label: "Page 471", value: 471 },
  { label: "Page 472", value: 472 },
  { label: "Page 473", value: 473 },
  { label: "Page 474", value: 474 },
  { label: "Page 475", value: 475 },
  { label: "Page 476", value: 476 },
  { label: "Page 477", value: 477 },
  { label: "Page 478", value: 478 },
  { label: "Page 479", value: 479 },
  { label: "Page 480", value: 480 },
  { label: "Page 481", value: 481 },
  { label: "Page 482", value: 482 },
  { label: "Page 483", value: 483 },
  { label: "Page 484", value: 484 },
  { label: "Page 485", value: 485 },
  { label: "Page 486", value: 486 },
  { label: "Page 487", value: 487 },
  { label: "Page 488", value: 488 },
  { label: "Page 489", value: 489 },
  { label: "Page 490", value: 490 },
  { label: "Page 491", value: 491 },
  { label: "Page 492", value: 492 },
  { label: "Page 493", value: 493 },
  { label: "Page 494", value: 494 },
  { label: "Page 495", value: 495 },
  { label: "Page 496", value: 496 },
  { label: "Page 497", value: 497 },
  { label: "Page 498", value: 498 },
  { label: "Page 499", value: 499 },
  { label: "Page 500", value: 500 },
  { label: "Page 501", value: 501 },
  { label: "Page 502", value: 502 },
  { label: "Page 503", value: 503 },
  { label: "Page 504", value: 504 },
  { label: "Page 505", value: 505 },
  { label: "Page 506", value: 506 },
  { label: "Page 507", value: 507 },
  { label: "Page 508", value: 508 },
  { label: "Page 509", value: 509 },
  { label: "Page 510", value: 510 },
  { label: "Page 511", value: 511 },
  { label: "Page 512", value: 512 },
  { label: "Page 513", value: 513 },
  { label: "Page 514", value: 514 },
  { label: "Page 515", value: 515 },
  { label: "Page 516", value: 516 },
  { label: "Page 517", value: 517 },
  { label: "Page 518", value: 518 },
  { label: "Page 519", value: 519 },
  { label: "Page 520", value: 520 },
  { label: "Page 521", value: 521 },
  { label: "Page 522", value: 522 },
  { label: "Page 523", value: 523 },
  { label: "Page 524", value: 524 },
  { label: "Page 525", value: 525 },
  { label: "Page 526", value: 526 },
  { label: "Page 527", value: 527 },
  { label: "Page 528", value: 528 },
  { label: "Page 529", value: 529 },
  { label: "Page 530", value: 530 },
  { label: "Page 531", value: 531 },
  { label: "Page 532", value: 532 },
  { label: "Page 533", value: 533 },
  { label: "Page 534", value: 534 },
  { label: "Page 535", value: 535 },
  { label: "Page 536", value: 536 },
  { label: "Page 537", value: 537 },
  { label: "Page 538", value: 538 },
  { label: "Page 539", value: 539 },
  { label: "Page 540", value: 540 },
  { label: "Page 541", value: 541 },
  { label: "Page 542", value: 542 },
  { label: "Page 543", value: 543 },
  { label: "Page 544", value: 544 },
  { label: "Page 545", value: 545 },
  { label: "Page 546", value: 546 },
  { label: "Page 547", value: 547 },
  { label: "Page 548", value: 548 },
  { label: "Page 549", value: 549 },
  { label: "Page 550", value: 550 },
  { label: "Page 551", value: 551 },
  { label: "Page 552", value: 552 },
  { label: "Page 553", value: 553 },
  { label: "Page 554", value: 554 },
  { label: "Page 555", value: 555 },
  { label: "Page 556", value: 556 },
  { label: "Page 557", value: 557 },
  { label: "Page 558", value: 558 },
  { label: "Page 559", value: 559 },
  { label: "Page 560", value: 560 },
  { label: "Page 561", value: 561 },
  { label: "Page 562", value: 562 },
  { label: "Page 563", value: 563 },
  { label: "Page 564", value: 564 },
  { label: "Page 565", value: 565 },
  { label: "Page 566", value: 566 },
  { label: "Page 567", value: 567 },
  { label: "Page 568", value: 568 },
  { label: "Page 569", value: 569 },
  { label: "Page 570", value: 570 },
  { label: "Page 571", value: 571 },
  { label: "Page 572", value: 572 },
  { label: "Page 573", value: 573 },
  { label: "Page 574", value: 574 },
  { label: "Page 575", value: 575 },
  { label: "Page 576", value: 576 },
  { label: "Page 577", value: 577 },
  { label: "Page 578", value: 578 },
  { label: "Page 579", value: 579 },
  { label: "Page 580", value: 580 },
  { label: "Page 581", value: 581 },
  { label: "Page 582", value: 582 },
  { label: "Page 583", value: 583 },
  { label: "Page 584", value: 584 },
  { label: "Page 585", value: 585 },
  { label: "Page 586", value: 586 },
  { label: "Page 587", value: 587 },
  { label: "Page 588", value: 588 },
  { label: "Page 589", value: 589 },
  { label: "Page 590", value: 590 },
  { label: "Page 591", value: 591 },
  { label: "Page 592", value: 592 },
  { label: "Page 593", value: 593 },
  { label: "Page 594", value: 594 },
  { label: "Page 595", value: 595 },
  { label: "Page 596", value: 596 },
  { label: "Page 597", value: 597 },
  { label: "Page 598", value: 598 },
  { label: "Page 599", value: 599 },
  { label: "Page 600", value: 600 },
];

