import React, { useState } from "react";
import { colors } from "../../constants/colors";
import { LeftArrowIcon, RightArrowIcon } from "../../constants/icons";
import { mokedMapViewPagesList } from "../../constants/viewMapPagesList";
import { BaseInputMapList } from "../../ui";
import "./sortingHeaderPagination.css";
import "./responsive.css";

function SortingHeaderPagination({
  houseCardData,
  setDataInRectPage,
  dataInRectPage,
  sortingValue,
  screenWidth
}) {
  const amountOfMarkersOnMap = 200;

  const list = mokedMapViewPagesList.filter((item) => {
    if (item.value <= Math.ceil(houseCardData?.total / amountOfMarkersOnMap))
      return item;
  });

  const initialPage = [
    {
      label: "Page 1",
      value: 1,
    },
  ];

  const rightArrowHandle = () => {
    if (dataInRectPage.value !== 1) {
      let prev;
      if (dataInRectPage?.value) {
        prev = list.filter((item) => item.value === dataInRectPage.value - 1);
      } else {
        prev = list.filter((item) => item.value === initialPage.value - 1);
      }
      setDataInRectPage(prev[0]);
    }
  };

  const leftArrowHandle = () => {
    if (dataInRectPage.value !== list.length) {
      let next;
      if (dataInRectPage?.value) {
        next = list.filter((item) => item.value === dataInRectPage.value + 1);
      } else {
        next = list.filter((item) => item.value === initialPage.value + 1);
      }
      setDataInRectPage(next[0]);
    }
  };

  return (
    <div className="sorting-header-pagination-wrapper">
      <div
        className={
          dataInRectPage?.length === 0 || dataInRectPage?.value === 1
            ? "sorting-header-pagination-arrow-grey"
            : "sorting-header-pagination-arrow sorting-header-pagination-arrow-hover"
        }
        onClick={rightArrowHandle}
      >
        <RightArrowIcon />
      </div>
      <BaseInputMapList
        colorEndIcon={colors.secondBg}
        blueList={true}
        readonly={true}
        list={list}
        setInputValue={setDataInRectPage}
        inputValue={dataInRectPage}
        field="label"
        width={dataInRectPage?.value > 99 || (screenWidth > 1439) ? "85px" : "76px"}
        className={`${
          sortingValue !== ""
            ? "sorting-header-pagination-wrapper-input-list-block-input-blue-color"
            : "sorting-header-pagination-wrapper-list-block-input-gray-color"
        }`}
      />
      <div className="sorting-header-pagination-list-length">
        <p>of {list.length}</p>
      </div>
      <div
        className={
          dataInRectPage?.value === list.length
            ? "sorting-header-pagination-arrow-grey"
            : "sorting-header-pagination-arrow sorting-header-pagination-arrow-hover"
        }
        onClick={leftArrowHandle}
      >
        <LeftArrowIcon />
      </div>
    </div>
  );
}

export default SortingHeaderPagination;
