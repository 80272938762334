/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { colors } from "../../../constants/colors";
import { ArrowDown, ArrowUp, CloseIcon } from "../../../constants/icons";
import ClickOutside from "../../clickOutside/clickoutSideComponent";
import Spiner from "../../spiner/spiner";
import "./baseMultipleActiveInput.css";

const BaseMultipleActiveInput2v = ({
  name,
  classNameLi = "",
  className,
  onClick,
  list,
  list2 = null,
  placeholder,
  colorEndIcon = colors.mainBg,
  height = "45px",
  listTop = "54px",
  padding,
  setItem,
  item,
  setFound,
  found,
  isSearch = false,
  field = null,
  clearList = false,
  parrentValue = null,
  parrentName = null,
  parrentValue2 = null,
  parrentName2 = null,
  callback = null,
  callback2 = null,
  isLoading = false,
  stringValue = false,
  setChangedInit = false,
  initialArrowHide = false,
  setIsOpened = null,
  isOpened = null,
  setIsSearch = null,
  LocationClassName = "filters-page-multiple",
  autocomplete = null,
}) => {
  useEffect(() => {
    if (list && list?.length > 0) {
      setFound(list);
    } else {
      setFound(list2);
    }
  }, [list, list2]);

  const [firstLoaded, setFirstLoaded] = useState(0);

  useEffect(() => {
    if (parrentValue === null && firstLoaded > 0) {
      setItem(null);
      setFound([]);
    }
  }, [parrentValue]);

  useEffect(() => {
    if (parrentValue && firstLoaded > 0) {
      setItem([]);
      setFound([]);
      setTempSearch("");
    }
    setFirstLoaded((c) => c + 1);
  }, [parrentValue && parrentValue[parrentName]]);

  useEffect(() => {
    if (parrentValue2?.length) {
      setItem([]);
      setFound([]);
    }
  }, [parrentValue2]);

  const [temp, setTemp] = useState("");
  const [tempSearch, setTempSearch] = useState("");

  // useEffect(() => {
  //   if (callback2) {
  //     if (tempSearch === "") {
  //       setIsSearch(false);
  //       setFound(list2);
  //     } else {
  //       setIsSearch(true);
  //       callback2(tempSearch);
  //     }
  //   }
  // }, [tempSearch]);

  const scrollSmoothlyToBottom = (cls) => {
    const element = document.getElementsByClassName(`${cls}`)[0];
    if (element)
      element.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
  };

  const tempHandler = (v) => {
    const filtered = temp.filter((item) => item !== v);
    const result = list.filter((k) => {
      return k[field]
        ?.toString()
        .toLowerCase()
        ?.includes(v.toString().toLowerCase());
    });

    const newFound = [...result, ...found].sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (b.value < a.value) {
        return 1;
      }
      return 0;
    });
    let newItemResult;
    if (stringValue !== true) {
      newItemResult = item.filter((i) => i.value.toString() !== v.toString());
    } else {
      newItemResult = item.filter((i) => {
        return i[field].toString() !== v.toString();
      });
    }
    setFound(newFound);
    setItem(newItemResult);
    setTemp(filtered);
  };

  useEffect(() => {
    let k = item?.map((v) => v[field] || v.label);
    setTemp(k);
  }, [item]);

  const filter = (e) => {
    if (setChangedInit) setChangedInit(true);
    const keyword = e.target.value;
    setTempSearch(e.target.value);
    if (keyword !== "") {
      let r = list?.filter((l) => {
        return !item.includes(l) && l !== e.target.value;
      });
      const results = r?.filter((k) => {
        if (field) {
          return k[field]
            ?.toString()
            .toLowerCase()
            ?.includes(keyword?.toLowerCase());
        } else {
          return k?.toString().toLowerCase()?.includes(keyword.toLowerCase());
        }
      });
      setFound(results);
      inputHandler();
    } else {
      setFound(list);
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  const inputHandler = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    if (isVisible) scrollSmoothlyToBottom("my-saved-search-new-form-content");
  }, [isVisible]);

  const clickOutsideHandler = () => {
    setIsVisible(false);
  };

  const onIconItem = (i) => {
    if (item === "" || item === null || item?.length === 0) {
      setItem([i]);
    } else {
      let k = item.filter((v) => v !== "");
      let c2 = item.filter((v) => v.label !== i.label);
      if (!callback2) {
        setItem([...k, i]);
      } else {
        setItem([...c2, i]);
      }
      setIsVisible(false);
    }
    const res = list.filter((l) => {
      return !item.includes(l) && l !== i;
    });
    setFound(res);
    setTempSearch("");
  };

  return (
    <div>
      <div
        className="filters-page-multiple-active-input-values-wrapper"
        style={{
          border: temp && temp[0] ? `1px solid ${colors.gray}` : "none",
        }}
      >
        <div
          className="filters-page-multiple-active-input-values-scroll"
          style={{
            margin: temp && temp[0] ? "8px" : "0px",
          }}
        >
          {temp &&
            temp[0] &&
            temp.map((v, i) => (
              <div
                key={i}
                style={{
                  width: "100%",
                }}
                className="filters-page-multiple-active-input-value"
              >
                <div className="filters-page-multiple-active-input-value-content">
                  <p>{v}</p>
                </div>
                <div
                  className="filters-page-multiple-active-input-value-close-icon"
                  onClick={() => tempHandler(v)}
                >
                  <CloseIcon height={14} width={14} color={colors.mainBg} />
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        className="filters-page-multiple-active-input-list-wrapper"
        onClick={() => {
          inputHandler();
          if (onClick) onClick();
        }}
      >
        <div className="filters-page-multiple-active-input-list-outside-wrapper">
          {!callback2 && (
            <ArrowUp
              color={colorEndIcon}
              style={{
                display: !isVisible ? "none" : "block",
              }}
            />
          )}
          {callback2 && (
            <ArrowUp
              color={colorEndIcon}
              style={{
                display: !isVisible ? "none" : "block",
                // display:
                //   tempSearch !== "" ? (isVisible ? "block" : "none") : "none",
              }}
            />
          )}
        </div>
        <ClickOutside onClick={clickOutsideHandler}>
          <div
            className="filters-page-multiple-active-input-list-outside-wrapper"
            // onClick={() => {
            //   if (callback && found.length === 0) {
            //     callback();
            //   }
            // }}
          >
            {(!initialArrowHide || tempSearch !== "") && (
              <ArrowDown
                color={colorEndIcon}
                style={{
                  display: isVisible ? "none" : "block",
                  // display: !callback2
                  //   ? isVisible
                  //     ? "none"
                  //     : "block"
                  //   : isVisible && tempSearch !== ""
                  //   ? "none"
                  //   : "block",
                }}
              />
            )}
          </div>

          <input
            readOnly={isSearch}
            // name={name}
            type="text"
            placeholder={placeholder}
            className={className}
            style={{
              width: "100%",
              height: height,
              borderBottomRightRadius:
                isLoading === true || (isVisible && found.length) ? 0 : 10,
              borderBottomLeftRadius:
                isLoading === true || (isVisible && found.length) ? 0 : 10,
              borderTopRightRadius: temp && temp[0] ? 0 : 10,
              borderTopLeftRadius: temp && temp[0] ? 0 : 10,
              marginTop: temp && temp[0] ? "-1px" : 0,
              padding: padding ? padding : "19px 24px",
            }}
            value={tempSearch}
            onChange={filter}
            onClick={() => {
              setIsOpened(!isOpened);
              if (found?.length === 0 && tempSearch === "") setFound(list2);
              // if (callback && found.length === 0) {
              //   callback();
              // }
            }}
            autoComplete={autocomplete ? autocomplete : null}
          />
        </ClickOutside>
        <ul
          style={{
            display: isVisible ? "block" : "none",
            top: listTop,
            border:
              isLoading === true
                ? "1px solid #908F8F"
                : found.length
                ? "1px solid #908F8F"
                : "none",
            width: "inherit",
          }}
        >
          {isLoading === true && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "20px 0",
              }}
            >
              <Spiner />
            </div>
          )}
          {isLoading === false &&
            found
              ?.filter((obj1) => {
                if (item) {
                  return !item.some((obj2) => {
                    return obj1.label === obj2.label;
                  });
                } else return true;
              })
              ?.sort((a, b) =>
                a[field].toLowerCase().localeCompare(b[field].toLowerCase())
              )
              .map((itemObj, i) => {
                if (field) {
                  return (
                    <li
                      key={i}
                      onClick={() => onIconItem(itemObj)}
                      className={`${LocationClassName}-active-input-list-wrapper-li-${classNameLi}`}
                    >
                      <p>{itemObj[field] || itemObj.label}</p>
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={i}
                      onClick={() => onIconItem(itemObj)}
                      className={`${LocationClassName}-active-input-list-wrapper-li-${classNameLi}`}
                    >
                      <p>{itemObj || itemObj.label}</p>
                    </li>
                  );
                }
              })}
        </ul>
      </div>
    </div>
  );
};

export default BaseMultipleActiveInput2v;
