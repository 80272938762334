import React, { useEffect, useState } from "react";
import "./sortingBlock.css";
import { colors } from "../../constants/colors";
import { SortingInputBlock } from "../../components/index.js";
import HouseCardsBlock from "../HouseCardsBlock/HouseCardsBlock.jsx";
import {
  CubeIcon,
  FiltersIcon,
  ListIcon,
  MapIcon,
} from "../../constants/icons.jsx";
import { useDispatch, useSelector } from "react-redux";
import { updateIsOpenNarrowFilters } from "../../store/actions/updateIsOpenNarrowFilters";
import { ClickOutside } from "../../ui";

const SortingBlock = ({
  filters,
  isApply,
  container,
  sortingValue,
  setSortingValue,
  currentPage,
  setCurrentPage,
  isActive1,
  isActive2,
  isActive3,
  isPicturesFeature,
  dataInRectPage,
  setDataInRectPage,
  isFirstLoadedMinMaxQPar,
  queryParamsObject,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}) => {
  return (
    <div id="sorting-block-wrapper">
      <HouseCardsBlock
        isActive={isActive1}
        isActive2={isActive2}
        isActive3={isActive3}
        filters={filters}
        isApply={isApply}
        container={container}
        sortingValue={sortingValue}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isPicturesFeature={isPicturesFeature}
        dataInRectPage={dataInRectPage}
        setDataInRectPage={setDataInRectPage}
        isFirstLoadedMinMaxQPar={isFirstLoadedMinMaxQPar}
        queryParamsObject={queryParamsObject}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
      />
    </div>
  );
};

export default SortingBlock;
