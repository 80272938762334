/* eslint-disable array-callback-return */
import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  data: [
    { id: -1, value: -1 },
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
  ],
  isLoading: false,
  error: "",
};

export const houseCardsHeardSlice = createSlice({
  name: "houseCardsHeard",
  initialState,
  reducers: {
    houseCardsHeardFetching(state) {
      state.isLoading = true;
      state.error = "";
    },
    setIsLoading(state, actions) {
      state.isLoading = actions.payload;
    },
    refreshData(state) {
      state.data = [];
    },
    setUserHouseCardsHeard(state, actions) {
      state.isLoading = false;
      state.error = "";
      state.data = actions.payload;
    },
    setHouseCardsHeard(state, actions) {
      state.isLoading = false;
      state.error = "";
      state.data = [
        ...state.data,
        { id: actions.payload.id, value: actions.payload.value },
      ];
    },
    updateHouseCardsHeard(state, actions) {
      const { id, value } = actions.payload;
      const data = [...current(state.data)];
      let k = 0;
      data.map((obj) => {
        if (obj.id === id) {
          k = 1;
        } else return obj;
      });
      if (k) {
        state.data = [
          ...data.map((obj) => {
            if (obj.id === id) {
              return { id: obj.id, value: value };
            } else return obj;
          }),
        ];
      } else state.data = [...state.data, { id: id, value: value }];
      state.isLoading = false;
      state.error = "";
    },
    updateHouseCardsHeardData(state, actions) {
      const { id, value } = actions.payload;
      if (!state.data.find((itm) => itm.id === id)) {
        state.data = [...state.data, { id: id, value: value }];
        state.error = "";
      }
    },
    houseCardsHeardError(state, actions) {
      state.isLoading = false;
      state.error = actions.payload;
    },
  },
});

export default houseCardsHeardSlice.reducer;
