import { filterLocationListsSlice } from "../reducers/filterLocationListsSlice";
import axios from "axios";

export const getFilterLocationCities =
  (countyCodes = [], searchValue = "") =>
  async (dispatch) => {
    dispatch(filterLocationListsSlice.actions.filterLocationFetching());
    if (countyCodes.length > 0) {
      axios
        .post(
          `https://api.realtytexas.net/v1/city-county-applied/cognitive-search`,
          {
            top: 3000,
            count: "true",
            maxPrice: 50000000,
            minPrice: 0,
            filters: [
              {
                column: "CountyCode",
                operator: "in",
                value: countyCodes,
              },
            ],
            select: null,
            page: 1,
          }
        )
        .then((response) => {
          let citiesId = response?.data?.data?.citycountyapplied?.map((c) =>
            c.CityID.toString()
          );
          if (!citiesId?.length) return;
          axios
            .post(
              `https://api.realtytexas.net/v1/cities/cognitive-search/${searchValue}`,
              {
                top: 3000,
                count: "true",
                maxPrice: 50000000,
                minPrice: 0,
                filters: [
                  {
                    column: "CityID",
                    operator: "in",
                    value: citiesId,
                  },
                ],
                select: null,
                page: 1,
                orderby: "subdomain asc",
                ["elastic-search"]: true,
                ["elastic-search-fields"]: "City",
              }
            )
            .then((response) => {
              const temp = [...response.data.data.cities];
              const res = temp.map((c) => {
                c.label = c["City"];
                c.value = c["City"];
                return c;
              });
              dispatch(
                filterLocationListsSlice.actions.getFilterLocationCitiesSuccess(
                  res
                )
              );
            })
            .catch(function (error) {
              console.log(error, "POST ERROR");
              dispatch(
                filterLocationListsSlice.actions.filterLocationCitiesFetchingError(
                  error
                )
              );
            });
        })
        .catch(function (error) {
          console.log(error, "citiesId error");
        });
    } else {
      axios
        .post(
          `https://api.realtytexas.net/v1/cities/cognitive-search/${searchValue}`,
          {
            top: 3000,
            count: "true",
            maxPrice: 50000000,
            minPrice: 0,
            filters: [],
            select: null,
            page: 1,
            orderby: "subdomain asc",
            ["elastic-search"]: true,
            ["elastic-search-fields"]: "City",
          }
        )
        .then(function (response) {
          const temp = [...response.data.data.cities];
          const res = temp.map((c) => {
            c.label = c["City"];
            c.value = c["City"];
            return c;
          });
          dispatch(
            filterLocationListsSlice.actions.getFilterLocationCitiesSuccess(res)
          );
        })
        .catch(function (error) {
          console.log(error, "POST ERROR");
          dispatch(
            filterLocationListsSlice.actions.filterLocationCitiesFetchingError(
              error
            )
          );
        });
    }
  };
