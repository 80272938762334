import { filterLocationListsSlice } from "../reducers/filterLocationListsSlice";
import axios from "axios";

export const getFilterLocationCountries =
  (metroCodes = null, qparam = false) =>
  async (dispatch) => {
    dispatch(filterLocationListsSlice.actions.filterLocationFetching());
    axios
      .post(`https://api.realtytexas.net/v1/counties/cognitive-search`, {
        top: 3000,
        count: "true",
        maxPrice: 50000000,
        minPrice: 0,
        filters:
          metroCodes && !qparam
            ? [
                {
                  column: "Metro",
                  operator: "in",
                  value: [metroCodes],
                },
              ]
            : [],
        select: null,
        page: 1,
        orderby: "County asc",
      })
      .then(function (response) {
        const temp = [...response?.data?.data?.counties];
        const res = temp.map((c) => {
          c.label = c["County"];
          c.value = c["County"];
          return c;
        });
        dispatch(
          filterLocationListsSlice.actions.getFilterLocationCountriesSuccess(
            res
          )
        );
      })
      .catch(function (error) {
        console.log(error, "POST ERROR");
        dispatch(
          filterLocationListsSlice.actions.filterLocationCountriesFetchingError(
            error
          )
        );
      });
  };
