export const FilterTemplate = {
  PropertyType: "residential",
  PropertySubType: [],
  Region: null,
  Metro: null,
  County: [],
  City: [],
  PostalCode: [],
  ListPriceMin: 0,
  ListPriceMax: 50000000,
  BathroomsFullMin: null,
  BathroomsFullMax: null,
  LevelsMin: null,
  LevelsMax: null,
  BedroomsTotalMin: null,
  BedroomsTotalMax: null,
  AboveGradeFinishedAreaMin: null,
  AboveGradeFinishedAreaMax: null,
  YearBuiltMin: null,
  YearBuiltMax: null,
  GarageSpacesMin: null,
  GarageSpacesMax: null,
  LotSizeAcresMin: null,
  LotSizeAcresMax: null,
  MainLevelPrimary: null,
  Fireplace: null,
  AccessibilityFeatures: null,
  Status: ["Active", "CommingSoon"],
  Timeframe: null,
  ShowOnly: [],
  Zoom: null,
  Lng: null,
  PoolPrivateYN: null,
};
