import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isReset: false,
  isOpenNarrowFilters: false,
};

export const isResetFiltersSlice = createSlice({
  name: "isResetFilter",
  initialState,
  reducers: {
    updateIsResetFilters(state, actions) {
      state.isReset = actions.payload;
    },
    updateIsOpenNarrowFilters(state, actions) {
      state.isOpenNarrowFilters = actions.payload;
    },
  },
});

export default isResetFiltersSlice.reducer;
