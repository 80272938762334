import {
    createSlice
} from "@reduxjs/toolkit";

const initialState = {
    cities: [],
    zips: []
};

export const filterLocationDataSlice = createSlice({
    name: "FilterLocationData",
    initialState,
    reducers: {
        setFilterLocationCitiesData(state, actions) {
            state.cities = actions.payload;
        },
        setFilterLocationZipsData(state, actions) {
            state.zips = actions.payload;
        },
    },
});

export default filterLocationDataSlice.reducer;