import { filterLocationListsSlice } from "../reducers/filterLocationListsSlice";
import axios from "axios";

export const getFilterLocationMetrosAction =
  (regionCodes = null) =>
  async (dispatch) => {
    dispatch(filterLocationListsSlice.actions.filterLocationFetching());
    axios
      .post(`https://api.realtytexas.net/v1/metros/cognitive-search`, {
        top: 3000,
        count: "true",
        maxPrice: 50000000,
        minPrice: 0,
        filters: regionCodes
          ? [
              {
                column: "Region",
                operator: "eq",
                value: regionCodes,
              },
            ]
          : [],
        select: null,
        page: 1,
        orderby: "Metro asc",
      })
      .then(function (response) {
        const temp = [...response.data.data.metros].filter(
          (c) => c.display !== false
        );

        const res = temp.map((c) => {
          c.label = c["Metro"];
          c.value = c["metro"];
          return c;
        });
        dispatch(
          filterLocationListsSlice.actions.getFilterLocationMetrosSuccess(res)
        );
      })
      .catch(function (error) {
        console.log(error, "POST ERROR");
        dispatch(
          filterLocationListsSlice.actions.filterLocationMetrosFetchingError(
            error
          )
        );
      });
  };
