import React, { useEffect, useState } from "react";
// import "./App.css";
import { useSearchParams, useLocation } from "react-router-dom";
import Filters from "./pages";
import axios from "axios";
import { useSelector } from "react-redux";

function App({ container }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });

  const isMap = useSelector((state) => state.isMap);
  const { isOpenNarrowFilters } = useSelector((state) => state.isResetFilters);

  const [searchParams, setSearchParams] = useSearchParams();
  let picturesFeature = container.getAttribute("pictures-feature");
  if (picturesFeature !== "true") picturesFeature = "false";
  const [isPicturesFeature, setIsPicturesFeature] = useState(picturesFeature);

  const mutationCallback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (
        mutation.type !== "attributes" ||
        mutation.attributeName !== "pictures-feature"
      ) {
        return;
      }
      setIsPicturesFeature(mutation.target.getAttribute("pictures-feature"));
    }
  };

  const observer = new MutationObserver(mutationCallback);
  observer.observe(container, { attributes: true });

  document.addEventListener("RTUnlockPremiumFeatures", function (event) {
    setIsPicturesFeature("true");
  });

  function getCurrentSubdomain() {
    const parts = window.location.hostname.split(".");
    if (parts.length >= 3 && parts[0]!=="www") {
      return parts[0]; // Subdomain is the first part of the hostname
    } else {
      return null; // No subdomain or localhost
    }
  }

  const subdomain = getCurrentSubdomain();

  const setShowOnlyOpenHouses = () => {
    searchParams.set("ShowOnly", "OpenHouses");
    setSearchParams(searchParams);
    setShowOnlyQPar(true);
  };

  const setZoomToUrl = (zoom) => {
    searchParams.set("Zoom", zoom);
    setSearchParams(searchParams);
  };

  const setCenterLatToUrl = (lat) => {
    searchParams.set("Lat", lat);
    setSearchParams(searchParams);
  };

  const setCenterLngToUrl = (lng) => {
    searchParams.set("Lng", lng);
    setSearchParams(searchParams);
  };

  const setMetroToUrl = (metro) => {
    searchParams.set("Metro", metro);
    setSearchParams(searchParams);
  };

  const [subdomainData, setSubdomainData] = useState(null);
  const [showOnlyQPar, setShowOnlyQPar] = useState(null);

  const getSubdomainData = (subdomain) => {
    setSubdomainData(true);
    axios
      .post(`https://api.realtytexas.net/v1/metros/cognitive-search`, {
        top: 3000,
        count: "true",
        maxPrice: 50000000,
        minPrice: 0,
        filters: [],
        select: null,
        page: 1,
        orderby: "Metro asc",
      })
      .then(function (response) {
        const temp = [...response.data.data.metros].filter(
          (c) => c.display !== false
        );

        const list = temp.map((c) => {
          c.label = c["Metro"];
          c.value = c["metro"];
          return c;
        });
        axios
          .get(`https://api.realtytexas.net/v1/agents/${subdomain}/settings`)
          .then(function (res) {
            let metro = list.filter(
              (item) => item.label === res.data.data.Metro
            )[0];
            setSubdomainData({
              data: res.data,
              metro: metro,
              zoom: res.data.data.map_zoom,
              center: {
                lat: res.data.data.Latitude,
                lng: res.data.data.Longitude,
              },
            });
            if (Object.keys(queryParamsObject).length === 0) {
              setMetroToUrl(metro.subdomain);
            }
            if (Object.keys(queryParamsObject).length === 0) {
              setZoomToUrl(res.data.data.map_zoom);
            }
            if (Object.keys(queryParamsObject).length === 0) {
              setCenterLatToUrl(res.data.data.Latitude);
              setCenterLngToUrl(res.data.data.Longitude);
            }
          })
          .catch(function (error) {
            console.log(error, "ERROR");
          });
      })
      .catch(function (error) {
        console.log(error, "POST ERROR");
      });
  };

  if (subdomain && !subdomainData) {
    getSubdomainData(subdomain);
  }
  let isOpenHousesUrl = null;
  isOpenHousesUrl = window.location.href.includes("/open-houses");

  useEffect(() => {
    if (isOpenHousesUrl === true) {
      setShowOnlyOpenHouses();
    }
    if (isOpenHousesUrl === false) {
      setShowOnlyQPar(false);
    }
  }, [isOpenHousesUrl]);

  useEffect(() => {
    const container = document?.body;
    // const header_ = document?.getElementById("header-wrapper");
    const footer_ = document?.getElementById("footer");
    if (isMap.data === true && container) {
      container.classList.add("map-view-open");
      //   if (header_) header_.style.display = "none";
      if (footer_) footer_.style.display = "none";
    } else {
      if (container) container.classList.remove("map-view-open");
      //   if (header_) header_.style.display = "block";
      if (footer_) footer_.style.display = "block";
    }
  }, [isMap.data]);

  useEffect(() => {
    const footer_ = document?.getElementById("footer");
    if (isOpenNarrowFilters) {
      if (footer_) footer_.style.display = "none";
    } else {
      if (footer_) footer_.style.display = "block";
    }
  }, [isOpenNarrowFilters]);

  useEffect(() => {
    const container = document?.body;
    if (isMap.data === true && isOpenNarrowFilters && container) {
      if (container) container.classList.remove("map-view-open");
    }
    if (
      isMap.data === true &&
      // !isOpenNarrowFilters &&
      container &&
      !container.classList.contains("map-view-open")
    ) {
      container.classList.add("map-view-open");
    }
  }, [isOpenNarrowFilters, isMap.data]);

  return (
    <div className="App">
      {(subdomainData?.data?.data?.map_zoom || subdomain == null) &&
        showOnlyQPar !== null && (
          <Filters
            container={container}
            isPicturesFeature={isPicturesFeature}
            subdomainData={subdomainData}
            isOpenHousesUrl={isOpenHousesUrl}
          />
        )}
    </div>
  );
}

export default App;
