/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactSlider from "react-slider";
import axios from "axios";
import {
  BathroomIcon,
  BedIcon,
  CheckBoxActiveIcon,
  CheckBoxIcon,
  CollapseIcon,
  ExpandIcon,
  InfoIcon,
  LevelsIcon,
} from "../../../constants/icons";
import { BaseInputList, ClickOutside } from "../../../ui";
import IncDecBlock from "../../../ui/incDecBlock/incDecBlock";
import "./homeFeatures.css";
import { colors } from "../../../constants/colors";
import { TooltipMessage } from "../..";
import { useLocation } from "react-router-dom";

const mokedListSqft = [];
for (let index = 0; index < 30; index++) {
  mokedListSqft.push(250 + 250 * index);
}

const mokedListYearBuild = [];
for (let index = 1900; index < 2023; index++) {
  mokedListYearBuild.push(index);
}

const mokedListBath = [1, 2, 3, 4, 5];

const mokedListLevels = [1, 1.5, 2, 3];

const mokedListBeds = [1, 2, 3, 4, 5, 6, 7];

const mokedListGaragesSpaces = [1, 2, 3, 4];

const mokedListLotSizeAcres2 = [
  // { value: 2000, label: "2000 sq.ft" },
  // { value: 4500, label: "4500 sq.ft" },
  // { value: 6500, label: "6500 sq.ft" },
  // { value: 8000, label: "8000 sq.ft" },
  { value: 10890, label: "0.25 acres" },
  { value: 21780, label: "0.5 acres" },
  { value: 32670, label: "0.75 acres" },
  { value: 43560, label: "1 acres" },
  { value: 87120, label: "2 acres" },
  { value: 130680, label: "3 acres" },
  { value: 174240, label: "4 acres" },
  { value: 217800, label: "5 acres" },
  { value: 435600, label: "10 acres" },
  { value: 1742400, label: "40 acres" },
  { value: 4356000, label: "100 acres" },
];

const mokedListMinMaxPrices = [
  { value: 75000, label: "$75,000" },
  { value: 100000, label: "$100,000" },
  { value: 125000, label: "$125,000" },
  { value: 150000, label: "$150,000" },
  { value: 175000, label: "$175,000" },
  { value: 200000, label: "$200,000" },
  { value: 225000, label: "$225,000" },
  { value: 250000, label: "$250,000" },
  { value: 300000, label: "$300,000" },
  { value: 350000, label: "$350,000" },
  { value: 400000, label: "$400,000" },
  { value: 450000, label: "$450,000" },
  { value: 500000, label: "$500,000" },
  { value: 550000, label: "$550,000" },
  { value: 600000, label: "$600,000" },
  { value: 650000, label: "$650,000" },
  { value: 700000, label: "$700,000" },
  { value: 750000, label: "$750,000" },
  { value: 800000, label: "$800,000" },
  { value: 850000, label: "$850,000" },
  { value: 900000, label: "$900,000" },
  { value: 950000, label: "$950,000" },
  { value: 1000000, label: "$1,000,000" },
  { value: 2000000, label: "$2,000,000" },
  { value: 3000000, label: "$3,000,000" },
  { value: 4000000, label: "$4,000,000" },
  { value: 5000000, label: "$5,000,000" },
  { value: 6000000, label: "$6,000,000" },
  { value: 7000000, label: "$7,000,000" },
  { value: 8000000, label: "$8,000,000" },
  { value: 9000000, label: "$9,000,000" },
  { value: 10000000, label: "$10,000,000" },
  { value: 15000000, label: "$15,000,000" },
  { value: 20000000, label: "$20,000,000" },
  { value: 25000000, label: "$25,000,000" },
  { value: 30000000, label: "$30,000,000" },
  { value: 35000000, label: "$35,000,000" },
  { value: 40000000, label: "$40,000,000" },
  { value: 45000000, label: "$45,000,000" },
  { value: 50000000, label: "$50,000,000" },
];

const HomeFeatures = ({
  filters,
  setFilters,
  sqftMin,
  setSqftMin,
  yearBuiltMin,
  setYearBuiltMin,
  garagesSpacesMin,
  setGaragesSpacesMin,
  bathMin,
  setBathMin,
  levelsMin,
  setLevelsMin,
  bedsMin,
  setBedsMin,
  lotSizeAcresMin,
  setLotSizeAcresMin,
  sqftMax,
  setSqftMax,
  yearBuiltMax,
  setYearBuiltMax,
  garagesSpacesMax,
  setGaragesSpacesMax,
  bathMax,
  setBathMax,
  levelsMax,
  setLevelsMax,
  bedsMax,
  setBedsMax,
  lotSizeAcresMax,
  setLotSizeAcresMax,
  isPrimaryBedOnMain,
  setIsPrimaryBedOnMain,
  isFireplace,
  setIsFireplace,
  isAccessibilityFeatures,
  setIsAccessibilityFeatures,
  isPoolFeatures,
  setIsPoolFeatures,
  minMaxPriceMin,
  setMinMaxPriceMin,
  minMaxPriceMax,
  setMinMaxPriceMax,
  priceValue,
  setPriceValue,
  isFirstLoadedMinMaxQPar,
  setIsFirstLoadedMinMaxQPar,
  priceInfoLoading,
  setPriceInfoLoading,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });

  const onTittleHandle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div
      id="filters-block-filter-home-features-wrapper"
      className={isExpanded ? "" : "collapsed-home-features"}
    >
      <div id="filters-block-filter-home-features">
        <div
          id="filters-block-filter-home-features-title"
          onClick={onTittleHandle}
        >
          <div>
            <h4>Home Features</h4>
          </div>
          <div className="filters-block-filter-home-features-title-icon">
            {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
          </div>
        </div>
        <div
          className={
            isExpanded
              ? "filters-block-filter-home-features-content-wrapper"
              : "filters-block-filter-home-features-content-wrapper collapsed-home-features-content"
          }
        >
          <HomeFeaturesDetails
            filters={filters}
            setFilters={setFilters}
            sqftMin={sqftMin}
            setSqftMin={setSqftMin}
            yearBuiltMin={yearBuiltMin}
            setYearBuiltMin={setYearBuiltMin}
            garagesSpacesMin={garagesSpacesMin}
            setGaragesSpacesMin={setGaragesSpacesMin}
            bathMin={bathMin}
            setBathMin={setBathMin}
            levelsMin={levelsMin}
            setLevelsMin={setLevelsMin}
            bedsMin={bedsMin}
            setBedsMin={setBedsMin}
            lotSizeAcresMin={lotSizeAcresMin}
            setLotSizeAcresMin={setLotSizeAcresMin}
            sqftMax={sqftMax}
            setSqftMax={setSqftMax}
            yearBuiltMax={yearBuiltMax}
            setYearBuiltMax={setYearBuiltMax}
            garagesSpacesMax={garagesSpacesMax}
            setGaragesSpacesMax={setGaragesSpacesMax}
            bathMax={bathMax}
            setBathMax={setBathMax}
            levelsMax={levelsMax}
            setLevelsMax={setLevelsMax}
            bedsMax={bedsMax}
            setBedsMax={setBedsMax}
            lotSizeAcresMax={lotSizeAcresMax}
            setLotSizeAcresMax={setLotSizeAcresMax}
            isPrimaryBedOnMain={isPrimaryBedOnMain}
            setIsPrimaryBedOnMain={setIsPrimaryBedOnMain}
            isFireplace={isFireplace}
            setIsFireplace={setIsFireplace}
            isAccessibilityFeatures={isAccessibilityFeatures}
            setIsAccessibilityFeatures={setIsAccessibilityFeatures}
            isPoolFeatures={isPoolFeatures}
            setIsPoolFeatures={setIsPoolFeatures}
            minMaxPriceMin={minMaxPriceMin}
            setMinMaxPriceMin={setMinMaxPriceMin}
            minMaxPriceMax={minMaxPriceMax}
            setMinMaxPriceMax={setMinMaxPriceMax}
            priceValue={priceValue}
            setPriceValue={setPriceValue}
            queryParamsObject={queryParamsObject}
            isFirstLoadedMinMaxQPar={isFirstLoadedMinMaxQPar}
            setIsFirstLoadedMinMaxQPar={setIsFirstLoadedMinMaxQPar}
            priceInfoLoading={priceInfoLoading}
            setPriceInfoLoading={setPriceInfoLoading}
          />
        </div>
      </div>
    </div>
  );
};

const HomeFeaturesBathLevelsBeds = ({ filters, setFilters }) => {
  const { isReset } = useSelector((state) => state.isResetFilters);
  const [bath, setBath] = useState(0);
  const [levels, setLevels] = useState(0);
  const [beds, setBeds] = useState(0);

  return (
    <div className="filters-block-filter-bath-levels-beds-input-list-block">
      <div className="filters-block-filter-bath-levels-beds-input-list-block-div-row">
        <div className="filters-block-filter-bath-levels-beds-input-list-block-icon-block filters-block-filter-bath-levels-beds-input-list-block-div">
          <div>
            <BathroomIcon />
          </div>
          <div>
            <p>Bath</p>
          </div>
        </div>
        <div className="filters-block-filter-details-input-list-block-input filters-block-filter-bath-levels-beds-input-list-block-div">
          <IncDecBlock value={bath} setValue={setBath} min={0} max={5} />
        </div>
      </div>
      <div className="filters-block-filter-bath-levels-beds-input-list-block-div-row">
        <div className="filters-block-filter-bath-levels-beds-input-list-block-icon-block filters-block-filter-bath-levels-beds-input-list-block-div">
          <div>
            <LevelsIcon />
          </div>
          <div>
            <p>Levels</p>
          </div>
        </div>
        <div className="filters-block-filter-details-input-list-block-input filters-block-filter-bath-levels-beds-input-list-block-div">
          <IncDecBlock value={levels} setValue={setLevels} min={0} max={3} />
        </div>
      </div>
      <div className="filters-block-filter-bath-levels-beds-input-list-block-div-row">
        <div className="filters-block-filter-bath-levels-beds-input-list-block-icon-block filters-block-filter-bath-levels-beds-input-list-block-div">
          <div>
            <BedIcon />
          </div>
          <div>
            <p>Beds</p>
          </div>
        </div>
        <div className="filters-block-filter-details-input-list-block-input filters-block-filter-bath-levels-beds-input-list-block-div">
          <IncDecBlock value={beds} setValue={setBeds} min={0} max={7} />
        </div>
      </div>
    </div>
  );
};

const HomeFeaturesMinMaxPrice = ({
  filters,
  setFilters,
  minMaxPriceMin,
  setMinMaxPriceMin,
  minMaxPriceMax,
  setMinMaxPriceMax,
  priceValue,
  setPriceValue,
  queryParamsObject,
  isFirstLoadedMinMaxQPar,
  setIsFirstLoadedMinMaxQPar,
  priceInfoLoading,
  setPriceInfoLoading,
}) => {
  const { isReset } = useSelector((state) => state.isResetFilters);

  useEffect(() => {
    if (minMaxPriceMin.value !== null && minMaxPriceMax.value !== null)
      setPriceValue([minMaxPriceMin.value, minMaxPriceMax.value]);
    if (minMaxPriceMin.value !== null && minMaxPriceMax.value === null)
      setPriceValue([minMaxPriceMin.value, 50000000]);
    if (minMaxPriceMin.value === null && minMaxPriceMax.value !== null)
      setPriceValue([0, minMaxPriceMax.value]);
  }, [minMaxPriceMin, minMaxPriceMax]);

  const [minValues, setMinValues] = useState(mokedListMinMaxPrices);
  const [maxValues, setMaxValues] = useState(mokedListMinMaxPrices);

  const [minValue, setMinValue] = useState(mokedListMinMaxPrices[0]?.value);
  const [maxValue, setMaxValue] = useState(
    mokedListMinMaxPrices[mokedListMinMaxPrices.length - 1]?.value
  );

  const [sliderValues, setSliderValues] = useState([0, 1000000]);
  const [sliderDefaultValues, setSliderDefaultValues] = useState([0, 9900000]);
  const [stepValue, setStepValue] = useState(1);
  const [propertyTypePrice, setPropertyTypePrice] = useState([]);
  useEffect(() => {
    setMinValue(mokedListMinMaxPrices[0]?.value);
    setMaxValue(mokedListMinMaxPrices[mokedListMinMaxPrices.length - 1]?.value);
    setSliderValues([minValue, maxValue]);
  }, [mokedListMinMaxPrices]);

  useEffect(() => {
    setMinValue(minMaxPriceMin.value);
    if (propertyTypePrice?.step_value_table?.length > 0) {
      //TODO we need to get the STEP VALUE
      propertyTypePrice?.step_value_table.map((table) => {
        if (table.max === minMaxPriceMax.value) {
          setStepValue(table.step_value);
        }
      });
    }
  }, [minMaxPriceMin]);

  useEffect(() => {
    setMaxValue(minMaxPriceMax.value);
    if (propertyTypePrice?.step_value_table?.length > 0) {
      //TODO we need to get the STEP VALUE
      propertyTypePrice?.step_value_table.map((table) => {
        if (table.max === minMaxPriceMin.value) {
          setStepValue(table.step_value);
        }
      });
    }
  }, [minMaxPriceMax]);

  const handleSliderChange = (newValues) => {
    setSliderValues([newValues[0], newValues[1]]);
    setPriceValue([newValues[0], newValues[1]]);
  };

  // const [priceInfoLoading, setPriceInfoLoading] = useState(false);

  const getPriceInfoByPropertyType = () => {
    setPriceInfoLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://www.realtytexas.com/api/v1/price/min-max/${
        filters.propertyType[0].PropertyType
          ? filters.propertyType[0].PropertyType
          : "Residential"
      }`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data) {
          if (response?.data?.prices) {
            // queryParamsObject?.Region;ListPriceMin=75000&ListPriceMax=175000
            let propertytypePrices = response?.data?.prices.map((p) => {
              return { value: p, label: `$${p?.toLocaleString("en-US")}` };
            });
            let step_value_table = [];
            setPriceInfoLoading(false);
            for (let prop in response?.data?.step_value_table) {
              step_value_table.push(response?.data?.step_value_table[prop]);
            }
            response.data.step_value_table = step_value_table;
            setPropertyTypePrice(response?.data);
            setMinValues(propertytypePrices);
            setMaxValues(propertytypePrices);
            setMaxValue(
              queryParamsObject?.ListPriceMin && !isFirstLoadedMinMaxQPar
                ? Number(queryParamsObject?.ListPriceMax)
                : propertytypePrices[propertytypePrices.length - 1].value
            );
            setSliderDefaultValues([
              queryParamsObject?.ListPriceMin && !isFirstLoadedMinMaxQPar
                ? Number(queryParamsObject?.ListPriceMin)
                : propertytypePrices[0].value,
              queryParamsObject?.ListPriceMax && !isFirstLoadedMinMaxQPar
                ? Number(queryParamsObject?.ListPriceMax)
                : propertytypePrices[propertytypePrices.length - 1].value,
            ]);
            setSliderValues([
              queryParamsObject?.ListPriceMin && !isFirstLoadedMinMaxQPar
                ? Number(queryParamsObject?.ListPriceMin)
                : propertytypePrices[0].value,
              queryParamsObject?.ListPriceMin && !isFirstLoadedMinMaxQPar
                ? Number(queryParamsObject?.ListPriceMax)
                : propertytypePrices[propertytypePrices.length - 1].value,
            ]);
            setMinMaxPriceMin({
              value:
                queryParamsObject?.ListPriceMin &&
                !isFirstLoadedMinMaxQPar &&
                !isFirstLoadedMinMaxQPar
                  ? Number(queryParamsObject?.ListPriceMin)
                  : propertytypePrices[0].value,
              label: `$${
                queryParamsObject?.ListPriceMin &&
                !isFirstLoadedMinMaxQPar &&
                !isFirstLoadedMinMaxQPar
                  ? Number(queryParamsObject?.ListPriceMin)?.toLocaleString(
                      "en-US"
                    )
                  : propertytypePrices[0].value?.toLocaleString("en-US")
              }`,
            });
            if (filters.propertyType[0].PropertyType === "Residential") {
              setMinMaxPriceMax({
                value:
                  queryParamsObject?.ListPriceMax && !isFirstLoadedMinMaxQPar
                    ? Number(queryParamsObject?.ListPriceMax)
                    : 1000000,
                label:
                  queryParamsObject?.ListPriceMax && !isFirstLoadedMinMaxQPar
                    ? Number(queryParamsObject?.ListPriceMax)?.toLocaleString(
                        "en-US"
                      )
                    : `$1,000,000`,
              });
            } else {
              setMinMaxPriceMax({
                value:
                  queryParamsObject?.ListPriceMax && !isFirstLoadedMinMaxQPar
                    ? Number(queryParamsObject?.ListPriceMax)
                    : propertytypePrices[propertytypePrices.length - 1].value,
                label: `$${
                  queryParamsObject?.ListPriceMax && !isFirstLoadedMinMaxQPar
                    ? Number(queryParamsObject?.ListPriceMax)?.toLocaleString(
                        "en-US"
                      )
                    : propertytypePrices[
                        propertytypePrices.length - 1
                      ].value?.toLocaleString("en-US")
                }`,
              });
            }
            setStepValue(response?.data?.step_value_table[0]?.step_value);
            setIsFirstLoadedMinMaxQPar(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setPriceInfoLoading(false);
      });
  };

  useEffect(() => {
    getPriceInfoByPropertyType();
  }, [filters.propertyType[0].PropertyType]);

  useEffect(() => {
    setSliderValues([minMaxPriceMin.value, sliderValues[1]]);
  }, [minMaxPriceMin]);

  useEffect(() => {
    setSliderValues([sliderValues[0], minMaxPriceMax.value]);
  }, [minMaxPriceMax]);

  return (
    <>
      <div className="filters-block-filter-details-input-list-block-title">
        <p>Price</p>
      </div>

      <div className="filters-block-filter-details-input-list-block-price-values">
        {priceValue[0] === 0 && priceValue[1] === 50000000
          ? "Any Price"
          : `$${
              sliderValues[0]
                ? sliderValues[0].toLocaleString("en-US")
                : priceValue[0]?.toLocaleString("en-US")
            } - $${
              sliderValues[1]
                ? sliderValues[1].toLocaleString("en-US")
                : priceValue[1]?.toLocaleString("en-US")
            }`}
      </div>
      <div>
        <ReactSlider
          disabled={priceInfoLoading ? true : false}
          min={minValue}
          max={maxValue}
          value={sliderValues}
          onChange={handleSliderChange}
          step={stepValue}
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={sliderDefaultValues}
          minDistance={10}
        />
      </div>
      <div className="filters-block-filter-min-max-Price-input-list-block">
        <div className="filters-block-filter-min-max-Price-list-block-content">
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Set Min</p>
          </div>
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Set Max</p>
          </div>
        </div>
      </div>
      <div className="filters-block-filter-min-max-Price-input-list-block">
        <div className="filters-block-filter-min-max-Price-list-block-content">
          <div className="filters-block-filter-details-input-list-block-input">
            <BaseInputList
              blueList={true}
              className={`${
                minMaxPriceMin.value !== null || minMaxPriceMin.value === 0
                  ? "filters-block-filter-details-input-list-block-input-blue-color"
                  : "filters-block-filter-details-input-list-block-input-gray-color"
              }`}
              classNameList="filters-block-filter-details-input-list-block-input-gray-color"
              readonly={true}
              list={
                minMaxPriceMax.value !== null
                  ? [
                      { value: 0, label: "$0" },
                      ...minValues.filter(
                        (item) => item.value <= minMaxPriceMax.value
                      ),
                    ]
                  : [{ value: 0, label: "$0" }, ...minValues]
              }
              placeholder="No Min"
              listTop="48px"
              field="label"
              inputValue={minMaxPriceMin}
              setInputValue={setMinMaxPriceMin}
              height={50}
              isLoading={priceInfoLoading}
              toLocaleString={true}
            />
          </div>
          <div className="filters-block-filter-details-input-list-block-input">
            <BaseInputList
              blueList={true}
              className={`${
                minMaxPriceMax.value !== null
                  ? "filters-block-filter-details-input-list-block-input-blue-color"
                  : "filters-block-filter-details-input-list-block-input-gray-color"
              }`}
              classNameList="filters-block-filter-details-input-list-block-input-gray-color"
              readonly={true}
              list={[
                ...maxValues.filter(
                  (item) => item.value >= minMaxPriceMin.value
                ),
                { value: null, label: "Any Price" },
              ]}
              listTop="48px"
              field="label"
              inputValue={minMaxPriceMax}
              setInputValue={setMinMaxPriceMax}
              height={50}
              isLoading={priceInfoLoading}
              toLocaleString={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFeatures;

const HomeFeaturesDetails = ({
  filters,
  setFilters,
  sqftMin,
  setSqftMin,
  yearBuiltMin,
  setYearBuiltMin,
  garagesSpacesMin,
  setGaragesSpacesMin,
  bathMin,
  setBathMin,
  levelsMin,
  setLevelsMin,
  bedsMin,
  setBedsMin,
  lotSizeAcresMin,
  setLotSizeAcresMin,
  sqftMax,
  setSqftMax,
  yearBuiltMax,
  setYearBuiltMax,
  garagesSpacesMax,
  setGaragesSpacesMax,
  bathMax,
  setBathMax,
  levelsMax,
  setLevelsMax,
  bedsMax,
  setBedsMax,
  lotSizeAcresMax,
  setLotSizeAcresMax,
  isPrimaryBedOnMain,
  setIsPrimaryBedOnMain,
  isFireplace,
  setIsFireplace,
  isAccessibilityFeatures,
  setIsAccessibilityFeatures,
  isPoolFeatures,
  setIsPoolFeatures,
  minMaxPriceMin,
  setMinMaxPriceMin,
  minMaxPriceMax,
  setMinMaxPriceMax,
  priceValue,
  setPriceValue,
  queryParamsObject,
  isFirstLoadedMinMaxQPar,
  setIsFirstLoadedMinMaxQPar,
  priceInfoLoading,
  setPriceInfoLoading,
}) => {
  const { isReset } = useSelector((state) => state.isResetFilters);

  return (
    <div id="filters-block-filter-details" style={{ marginTop: "24px" }}>
      <div id="filters-block-filter-details-1">
        <HomeFeaturesMinMaxPrice
          setFilters={setFilters}
          filters={filters}
          isReset={isReset}
          minMaxPriceMin={minMaxPriceMin}
          setMinMaxPriceMin={setMinMaxPriceMin}
          minMaxPriceMax={minMaxPriceMax}
          setMinMaxPriceMax={setMinMaxPriceMax}
          priceValue={priceValue}
          setPriceValue={setPriceValue}
          queryParamsObject={queryParamsObject}
          isFirstLoadedMinMaxQPar={isFirstLoadedMinMaxQPar}
          setIsFirstLoadedMinMaxQPar={setIsFirstLoadedMinMaxQPar}
          priceInfoLoading={priceInfoLoading}
          setPriceInfoLoading={setPriceInfoLoading}
        />
        <div className="filters-block-filter-details-input-list-block">
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Bath</p>
          </div>
          <div className="filters-block-filter-details-input-list-block-content">
            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  bathMin !== "No Min"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  bathMax === "No Max"
                    ? ["No Min", ...mokedListBath]
                    : bathMax !== null
                    ? [
                        "No Min",
                        ...mokedListBath.filter((item) => item <= bathMax),
                      ]
                    : ["No Min", ...mokedListBath]
                }
                placeholder="No Min"
                listTop="48px"
                inputValue={bathMin}
                setInputValue={setBathMin}
                height={50}
              />
            </div>

            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  bathMax !== "No Max"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  bathMin === "No Min"
                    ? ["No Max", ...mokedListBath]
                    : bathMin !== null
                    ? [
                        "No Max",
                        ...mokedListBath.filter((item) => item >= bathMin),
                      ]
                    : ["No Max", ...mokedListBath]
                }
                placeholder="No Max"
                listTop="48px"
                inputValue={bathMax}
                setInputValue={setBathMax}
                height={50}
              />
            </div>
          </div>
        </div>
        <div className="filters-block-filter-details-input-list-block">
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Levels</p>
          </div>
          <div className="filters-block-filter-details-input-list-block-content">
            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  levelsMin !== "No Min"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  levelsMax === "No Max"
                    ? ["No Min", ...mokedListLevels]
                    : levelsMax !== null
                    ? [
                        "No Min",
                        ...mokedListLevels.filter((item) => item <= levelsMax),
                      ]
                    : ["No Min", ...mokedListLevels]
                }
                placeholder="No Min"
                listTop="48px"
                inputValue={levelsMin}
                setInputValue={setLevelsMin}
                height={50}
              />
            </div>

            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  levelsMax !== "No Max"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  levelsMin === "No Min"
                    ? ["No Max", ...mokedListLevels]
                    : levelsMin !== null
                    ? [
                        "No Max",
                        ...mokedListLevels.filter((item) => item >= levelsMin),
                      ]
                    : ["No Max", ...mokedListLevels]
                }
                placeholder="No Max"
                listTop="48px"
                inputValue={levelsMax}
                setInputValue={setLevelsMax}
                height={50}
              />
            </div>
          </div>
        </div>
        <div className="filters-block-filter-details-input-list-block">
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Beds</p>
          </div>
          <div className="filters-block-filter-details-input-list-block-content">
            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  bedsMin !== "No Min"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  bedsMax === "No Max"
                    ? ["No Min", ...mokedListBeds]
                    : bedsMax !== null
                    ? [
                        "No Min",
                        ...mokedListBeds.filter((item) => item <= bedsMax),
                      ]
                    : ["No Min", ...mokedListBeds]
                }
                placeholder="No Min"
                listTop="48px"
                inputValue={bedsMin}
                setInputValue={setBedsMin}
                height={50}
              />
            </div>

            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  bedsMax !== "No Max"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  bedsMin === "No Min"
                    ? ["No Max", ...mokedListBeds]
                    : bedsMin !== null
                    ? [
                        "No Max",
                        ...mokedListBeds.filter((item) => item >= bedsMin),
                      ]
                    : ["No Max", ...mokedListBeds]
                }
                placeholder="No Max"
                listTop="48px"
                inputValue={bedsMax}
                setInputValue={setBedsMax}
                height={50}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="filters-block-filter-details-2">
        <div className="filters-block-filter-details-input-list-block">
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Sqft</p>
          </div>
          <div className="filters-block-filter-details-input-list-block-content">
            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  sqftMin !== "No Min"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  sqftMax === "No Max"
                    ? ["No Min", ...mokedListSqft]
                    : sqftMax !== null
                    ? [
                        "No Min",
                        ...mokedListSqft.filter((item) => item <= sqftMax),
                      ]
                    : ["No Min", ...mokedListSqft]
                }
                placeholder="No Min"
                listTop="48px"
                inputValue={sqftMin}
                setInputValue={setSqftMin}
                height={50}
              />
            </div>

            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  sqftMax !== "No Max"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  sqftMin === "No Min"
                    ? ["No Max", ...mokedListSqft]
                    : sqftMin !== null
                    ? [
                        "No Max",
                        ...mokedListSqft.filter((item) => item >= sqftMin),
                      ]
                    : ["No Max", ...mokedListSqft]
                }
                placeholder="No Max"
                listTop="48px"
                inputValue={sqftMax}
                setInputValue={setSqftMax}
                height={50}
              />
            </div>
          </div>
        </div>
        <div className="filters-block-filter-details-input-list-block">
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Year Built</p>
          </div>
          <div className="filters-block-filter-details-input-list-block-content">
            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  yearBuiltMin !== "No Min"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  yearBuiltMax === "No Max"
                    ? ["No Min", ...mokedListYearBuild]
                    : yearBuiltMax !== null
                    ? [
                        "No Min",
                        ...mokedListYearBuild.filter(
                          (item) => item <= yearBuiltMax
                        ),
                      ]
                    : ["No Min", ...mokedListYearBuild]
                }
                placeholder="No Min"
                listTop="48px"
                inputValue={yearBuiltMin}
                setInputValue={setYearBuiltMin}
                height={50}
              />
            </div>

            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  yearBuiltMax !== "No Max"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  yearBuiltMin === "No Min"
                    ? ["No Max", ...mokedListYearBuild]
                    : yearBuiltMin !== null
                    ? [
                        "No Max",
                        ...mokedListYearBuild.filter(
                          (item) => item >= yearBuiltMin
                        ),
                      ]
                    : ["No Max", ...mokedListYearBuild]
                }
                placeholder="No Max"
                listTop="48px"
                inputValue={yearBuiltMax}
                setInputValue={setYearBuiltMax}
                height={50}
              />
            </div>
          </div>
        </div>
        <div className="filters-block-filter-details-input-list-block">
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Garages Spaces</p>
          </div>
          <div className="filters-block-filter-details-input-list-block-content">
            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  garagesSpacesMin !== "No Min"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  garagesSpacesMax === "No Max"
                    ? ["No Min", ...mokedListGaragesSpaces]
                    : garagesSpacesMax !== null
                    ? [
                        "No Min",
                        ...mokedListGaragesSpaces.filter(
                          (item) => item <= garagesSpacesMax
                        ),
                      ]
                    : ["No Min", ...mokedListGaragesSpaces]
                }
                placeholder="No Min"
                listTop="48px"
                inputValue={garagesSpacesMin}
                setInputValue={setGaragesSpacesMin}
                height={50}
              />
            </div>

            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  garagesSpacesMax !== "No Max"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  garagesSpacesMin === "No Min"
                    ? ["No Max", ...mokedListGaragesSpaces]
                    : garagesSpacesMin !== null
                    ? [
                        "No Max",
                        ...mokedListGaragesSpaces.filter(
                          (item) => item >= garagesSpacesMin
                        ),
                      ]
                    : ["No Max", ...mokedListGaragesSpaces]
                }
                placeholder="No Max"
                listTop="48px"
                inputValue={garagesSpacesMax}
                setInputValue={setGaragesSpacesMax}
                height={50}
              />
            </div>
          </div>
        </div>
        <div className="filters-block-filter-details-input-list-block">
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Lot Size Acres</p>
          </div>
          <div className="filters-block-filter-details-input-list-block-content">
            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  lotSizeAcresMin.label !== "No Min"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  lotSizeAcresMax.value !== null
                    ? [
                        { value: null, label: "No Min" },
                        ...mokedListLotSizeAcres2.filter(
                          (item) => item.value <= lotSizeAcresMax.value
                        ),
                      ]
                    : [
                        { value: null, label: "No Min" },
                        ...mokedListLotSizeAcres2,
                      ]
                }
                placeholder="No Min"
                listTop="48px"
                field="label"
                inputValue={lotSizeAcresMin}
                setInputValue={setLotSizeAcresMin}
                height={50}
              />
            </div>

            <div className="filters-block-filter-details-input-list-block-input">
              <BaseInputList
                blueList={true}
                className={`${
                  lotSizeAcresMax.label !== "No Max"
                    ? "filters-block-filter-details-input-list-block-input-blue-color"
                    : "filters-block-filter-details-input-list-block-input-gray-color"
                }`}
                classNameList="filters-block-filter-details-input-list-block-input-gray-color"
                readonly={true}
                list={
                  lotSizeAcresMin.value !== null
                    ? [
                        { value: null, label: "No Max" },
                        ...mokedListLotSizeAcres2.filter(
                          (item) => item.value >= lotSizeAcresMin.value
                        ),
                      ]
                    : [
                        { value: null, label: "No Max" },
                        ...mokedListLotSizeAcres2,
                      ]
                }
                placeholder="No Max"
                listTop="48px"
                field="label"
                inputValue={lotSizeAcresMax}
                setInputValue={setLotSizeAcresMax}
                height={50}
              />
            </div>
          </div>
        </div>
        <HomeFeaturesCheckBoxes
          filters={filters}
          setFilters={setFilters}
          isPrimaryBedOnMain={isPrimaryBedOnMain}
          setIsPrimaryBedOnMain={setIsPrimaryBedOnMain}
          isFireplace={isFireplace}
          setIsFireplace={setIsFireplace}
          isAccessibilityFeatures={isAccessibilityFeatures}
          setIsAccessibilityFeatures={setIsAccessibilityFeatures}
          isPoolFeatures={isPoolFeatures}
          setIsPoolFeatures={setIsPoolFeatures}
        />
      </div>
    </div>
  );
};

const HomeFeaturesCheckBoxes = ({
  filters,
  setFilters,
  isPrimaryBedOnMain,
  setIsPrimaryBedOnMain,
  isFireplace,
  setIsFireplace,
  isAccessibilityFeatures,
  setIsAccessibilityFeatures,
  isPoolFeatures,
  setIsPoolFeatures,
}) => {
  const primaryBedOnMainHandler = () => {
    setIsPrimaryBedOnMain(!isPrimaryBedOnMain);
  };

  const fireplaceHandler = () => {
    setIsFireplace(!isFireplace);
  };

  const accessibilityFeaturesHandler = () => {
    setIsAccessibilityFeatures(!isAccessibilityFeatures);
  };

  const poolFeaturesHandler = () => {
    setIsPoolFeatures(!isPoolFeatures);
  };

  const [isInfoIcon, setIsInfoIcon] = useState(false);

  const handleClickOutsideLabelInfoIcon = () => {
    setIsInfoIcon(false);
  };

  return (
    <div id="filters-block-filter-home-features-content">
      <div className="filters-block-filter-home-features-content-checkbox">
        <div
          id="filters-block-filter-home-features-content-checkbox-1"
          onClick={primaryBedOnMainHandler}
        >
          {isPrimaryBedOnMain ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
        </div>
        <div>
          <p
            style={{
              color: isPrimaryBedOnMain ? colors.blue : colors.secondBg,
            }}
          >
            Primary Bedroom on Main
          </p>
        </div>
        <div className="filters-block-filter-home-features-content-info-icon-box">
          <ClickOutside onClick={handleClickOutsideLabelInfoIcon}>
            <span
              className="filters-block-filter-home-features-content-info-icon"
              onClick={() => setIsInfoIcon(true)}
            >
              <InfoIcon onClick />
            </span>
            {isInfoIcon && (
              <div className="filters-block-filter-home-features-content-info-icon-tooltip">
                <TooltipMessage content="Selecting this option will limit the results to all properties where the primary bedroom is on the main floor" />
              </div>
            )}
          </ClickOutside>
        </div>
      </div>
      <div className="filters-block-filter-home-features-content-checkbox">
        <div
          id="filters-block-filter-home-features-content-checkbox-2"
          onClick={fireplaceHandler}
        >
          {isFireplace ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
        </div>
        <div>
          <p style={{ color: isFireplace ? colors.blue : colors.secondBg }}>
            Fireplace
          </p>
        </div>
      </div>
      <div className="filters-block-filter-home-features-content-checkbox">
        <div
          id="filters-block-filter-home-features-content-checkbox-3"
          onClick={accessibilityFeaturesHandler}
        >
          {isAccessibilityFeatures ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
        </div>
        <div>
          <p
            style={{
              color: isAccessibilityFeatures ? colors.blue : colors.secondBg,
            }}
          >
            Accessibility Features
          </p>
        </div>
      </div>
      <div className="filters-block-filter-home-features-content-checkbox">
        <div
          id="filters-block-filter-home-features-content-checkbox-3"
          onClick={poolFeaturesHandler}
        >
          {isPoolFeatures ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
        </div>
        <div>
          <p
            style={{
              color: isPoolFeatures ? colors.blue : colors.secondBg,
            }}
          >
            Pool
          </p>
        </div>
      </div>
    </div>
  );
};
