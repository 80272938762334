import React, { useEffect, useState } from "react";
import {
  BathroomIcon,
  BedIcon,
  FavoriteIcon,
  HeardIcon,
  LeftArrowIcon,
  NoteIcon,
  PossibilityIcon,
  RightArrowIcon,
  RulerCombined,
  SavedIcon,
} from "../../constants/icons.jsx";
import "./houseCard.css";
import "./responsive.css";
import Slider from "react-slick";
import axios from "axios";
import Spiner from "../../ui/spiner/spiner.jsx";
import HouseCardHeardList from "../HouseCardHeardList/HouseCardHeardList.jsx";
import { useDispatch, useSelector } from "react-redux";
import { updateHouseCardsHeardData } from "../../store/actions/updateHouseCardsHeard.js";
import AlertNote from "../../ui/alertNote/AlertNote.jsx";
import ShareIconList from "../ShareIconList/ShareIconList.jsx";
import NotesBox from "../NotesBox/NotesBox";
import ClickForPremiumAccess from "../ClickForPremiumAccess/ClickForPremiumAccess";
import { ClickOutside, LinkWrapper } from "../../ui";
import ActiveContentHouseCardBox from "./activeContentHouseCardBox.jsx";
import { colors } from "../../constants/colors.js";
import BAWithTooltip from "../BAWithTooltip/BAWithTooltip.jsx";

const HouseCard = ({
  item,
  isActive,
  currentPage,
  container,
  isUserLogin,
  picturesFeature,
  rootRef,
  inView,
  isMap,
  ind,
}) => {
  let k = <HeardIcon />;
  const { data } = useSelector((state) => state.houseCardsHeard);
  const [isHeardActive, setIsHeardActive] = useState(false);
  const [images, setImages] = useState(null);
  const [imagesError, setImagesError] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [note, setNote] = useState(null);
  const [noteBox, setNoteBox] = useState(null);
  const [alertBox, setAlertBox] = useState(false);
  const [alertNoUserBox, setAlertNoUserBox] = useState(false);
  const [alertNoUserMessageBox, setAlertNoUserMessageBox] = useState(false);
  const [isNoteContentBox, setIsNoteContentBox] = useState(false);
  const [isNoteIconShow, setIsNoteIconShow] = useState(false);
  const [isDataHeart, setIsDataHeart] = useState(false);

  const dataIsShareIcon = container.getAttribute("data-share-icon");

  // Function to parse a date string in UTC-6 format
  function parseDateInUTC6(dateString) {
    const now = new Date();
    const currentTime = now.getTime();
    const currentDate = new Date(currentTime);
    const date = new Date(dateString);
    const utc6Offset = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
    const utc6Time = date.getTime() + utc6Offset; //(should I increase or decreace hours by 6?? )
    const differenceInMilliseconds = utc6Time - currentDate;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }

  const [diffInDays, setDiffInDays] = useState(null);

  useEffect(() => {
    const specifiedDateString = item?.ListingContractDate;
    const difference_In_Days = -1 * parseDateInUTC6(specifiedDateString);
    if (difference_In_Days < 4) {
      setDiffInDays(difference_In_Days);
      // console.log(item?.ListingContractDate, "item?.DOM", difference_In_Days);
    }
  }, []);

  const handleMapMarker = () => {
    const el = document.getElementById(
      `rounded-marker-${item?.ListingKeyNumeric}`
    );
    const priceEl = document.getElementById(
      `rounded-marker-price-${item?.ListingKeyNumeric}`
    );
    if (el) {
      if (priceEl) {
        priceEl.classList.add("filters-page-map-rounded-marker-price-hovered");
      }
      el.classList.add("filters-page-map-rounded-marker-wrapper-hovered");
      el.style.height = "18px";
      el.style.width = "18px";
      el.style.zIndex = 1;
    }
  };

  const handleResetMapMarker = () => {
    const el = document.getElementById(
      `rounded-marker-${item?.ListingKeyNumeric}`
    );
    if (el) {
      const priceEl = document.getElementById(
        `rounded-marker-price-${item?.ListingKeyNumeric}`
      );
      if (priceEl) {
        priceEl.classList.remove(
          "filters-page-map-rounded-marker-price-hovered"
        );
      }
      el.classList.remove("filters-page-map-rounded-marker-wrapper-hovered");
      el.style.height = "12px";
      el.style.width = "12px";
      el.style.zIndex = 0;
    }
  };

  useEffect(() => {
    const isData = container.getAttribute("data-heart-icon");
    setIsDataHeart(isData === "false" ? false : true);
  }, []);

  const onNoteContentIconHandler = () => {
    setNoteBox(true);
  };

  const onHeardIconHandler = () => {
    setIsHeardActive(true);
    setIsNoteContentBox(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentSlide(1);
    setIsNoteIconShow(false);
  }, [currentPage]);

  useEffect(() => {
    setCurrentSlide(1);
    setIsNoteIconShow(false);
  }, [data]);

  useEffect(() => {
    if (item.total_notes > 0) {
      setIsNoteIconShow(true);
    } else {
      setIsNoteIconShow(false);
    }
  }, [data]);

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => {
    if (currentSlide) {
      setCurrentSlide(currentSlide + 1);
    }
    return (
      <button
        {...props}
        className={
          isActive
            ? "slick-arrow filters-page-house-card-slider-left-arrow-active" +
              (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            : "slick-arrow filters-page-house-card-slider-left-arrow" +
              (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        // aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
        aria-label="Previous image button"
      >
        <LeftArrowIcon color="white" />
      </button>
    );
  };
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        isActive
          ? "slick-arrow filters-page-house-card-slider-right-arrow-active" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
          : "slick-arrow filters-page-house-card-slider-right-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      //   aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
      aria-label="Next image button"
    >
      <RightArrowIcon color="white" />
    </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: true,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  };

  const [currentItemLoaded, setCurrentItemLoaded] = useState(null);

  // useEffect(() => {
  //   if (
  //     inView &&
  //     item?.ListingKeyNumeric &&
  //     (!currentItemLoaded ||
  //       (currentItemLoaded &&
  //         currentItemLoaded.ListingKeyNumeric !== item.ListingKeyNumeric))
  //   ) {
  //     getImages();
  //   }
  // }, [inView, item]);

  useEffect(() => {
    if (item?.GatewayDescID === 1) {
      k = <SavedIcon />;
    } else {
      if (item?.GatewayDescID === 2) {
        k = <FavoriteIcon />;
      } else {
        if (item?.GatewayDescID === 3) {
          k = <PossibilityIcon />;
        } else {
          if (item?.GatewayDescID === -1) {
            k = <NoteIcon />;
          }
        }
      }
    }
    if (item.ListingKeyNumeric && item.GatewayDescID !== null) {
      dispatch(
        updateHouseCardsHeardData({
          id: item.ListingKeyNumeric,
          value: item.GatewayDescID,
        })
      );
    }
  }, [item]);

  const defaultMediaUrl =
    "https://realtytexas.com/assets/images/placeholder-image.jpg";

  const defaultImgData = {
    MediaURL: defaultMediaUrl,
    order: 1,
    LongDescription: null,
  };

  useEffect(() => {
    if (
      item &&
      item?.Media?.length > 0 &&
      inView &&
      item?.ListingKeyNumeric &&
      (!currentItemLoaded ||
        (currentItemLoaded &&
          currentItemLoaded.ListingKeyNumeric !== item.ListingKeyNumeric))
    ) {
      const temp = item?.Media?.map((url) => {
        return {
          MediaURL: "https://propertyimages.realtytexas.com" + url?.MediaURL,
          order: url?.Order,
        };
      });
      setImages([...temp]);
    }
    if (item && (!item?.Media || item?.Media?.length === 0) && inView) {
      setImages([defaultImgData]);
    }
  }, [item, inView]);

  // const getImages = () => {
  //   setImages(null);
  //   axios
  //     .get(
  //       `https://d1c4wot07153w7.cloudfront.net/api/v1/listings/${item.ListingKeyNumeric}/images`
  //     )
  //     .then(function (response) {
  //       setImages([...response.data.data]);
  //       setCurrentItemLoaded(item);
  //     })
  //     .catch(function (error) {
  //       setImagesError(error.response?.status);
  //       setImages([defaultImgData]);
  //       console.log(error, "get image error");
  //     });
  // };

  const getIcon = () => {
    if (data)
      data.map((ob, i) => {
        if (ob?.id === item?.ListingKeyNumeric) {
          if (ob.value === 1) {
            k = <SavedIcon key={i} />;
          } else {
            if (ob.value === 2) {
              k = <FavoriteIcon key={i} />;
            } else {
              if (ob.value === 3) {
                k = <PossibilityIcon key={i} />;
              } else {
                if (ob.value === -1) {
                  k = <NoteIcon key={i} />;
                } else {
                  k = <HeardIcon key={i} />;
                }
              }
            }
          }
        }
        if (item.ListingKeyNumeric && item.GatewayDescID !== null) {
          dispatch(
            updateHouseCardsHeardData({
              id: item.ListingKeyNumeric,
              value: item.GatewayDescID,
            })
          );
        }
      });
    return k;
  };

  let link = `/${item.subdomain}${item.url}`;

  const logInLinkHandler = () => {
    if (document?.getElementById("root-react-login-form"))
      document
        .getElementById("root-react-login-form")
        .setAttribute("visible", "true");
    setAlertNoUserBox(false);
    setAlertNoUserMessageBox(false);
  };

  function initHeardHandler() {
    const myRTP = JSON?.parse(localStorage?.getItem("MyRTP"));
    if (isUserLogin || myRTP?.SubjectID) {
      onHeardIconHandler();
    } else {
      logInLinkHandler();
    }
  }

  const [isRI, setIsRI] = useState(false);

  const clickRealtyIconHandler = () => {
    setIsRI(!isRI);
  };

  const clickOutsideHandler = () => {
    setIsRI(false);
  };

  let closeDate = "";
  if (item?.MlsStatus === "Closed") {
    const currentDate = new Date(item?.CloseDate);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    closeDate = `${month}-${day}-${year}`;
  }
  // ? `${item?.MlsStatus} - ${item?.CloseDate}`
  // : item?.MlsStatus}

  const handleImageLoad = (
    index,
    picturesFeature,
    // acceptLoginBtnHandler,
    isLink
  ) => {
    let link = `/${item.subdomain}${item.url}`;

    //START LOAD THE NEXT ONE AND PREV ONE IMG
    const imgArray = [
      ...images?.sort((a, b) => parseFloat(+a.order) - parseFloat(+b.order)),
    ];
    const mainWrapper = document.querySelectorAll(
      ".filters-page-house-cards-block-wrapper"
    )[ind];
    const imgWrapper = document.createElement("div");
    const imgWrapperPrev = document.createElement("div");

    const imgA = document.createElement("a");
    const imgAPrev = document.createElement("a");
    imgA.href = link;
    imgA.target = "_blank";
    imgAPrev.href = link;
    imgAPrev.target = "_blank";

    imgWrapper.className = "fake-img";
    imgWrapperPrev.className = "fake-img";
    const imgDiv = document.createElement("div");
    const imgDivPrev = document.createElement("div");
    imgDiv.className = "filters-page-house-card-img-container";
    imgDivPrev.className = "filters-page-house-card-img-container";
    const imgNext = document.createElement("img");
    const imgPrev = document.createElement("img");

    if (picturesFeature !== "true") {
      imgDiv.classList.add("filters-page-house-card--blur-wrapper");
      imgDivPrev.classList.add("filters-page-house-card--blur-wrapper");

      const noLoginBtnDiv = document.createElement("div");
      noLoginBtnDiv.classList.add("click-for-premium-access-wrapper");
      noLoginBtnDiv.innerHTML += "Click for Premium Access";
      // noLoginBtnDiv.onclick = acceptLoginBtnHandler;

      const noLoginBtnDivPrev = document.createElement("div");
      noLoginBtnDivPrev.classList.add("click-for-premium-access-wrapper");
      noLoginBtnDivPrev.innerHTML += "Click for Premium Access";
      // noLoginBtnDivPrev.onclick = acceptLoginBtnHandler;

      imgDiv.append(noLoginBtnDiv);
      imgDivPrev.append(noLoginBtnDivPrev);
    }

    //next
    const nextSrc = imgArray[index + 1]?.MediaURL;
    if (nextSrc) {
      imgNext.src = nextSrc;
      imgNext.loading = "eager";
      imgDiv.append(imgNext);
      if (isLink) {
        imgA.append(imgDiv);
        imgWrapper.append(imgA);
      } else {
        imgWrapper.append(imgDiv);
      }

      const currentDivNext =
        mainWrapper?.querySelectorAll(".slick-current")[0]?.nextElementSibling;

      if (currentDivNext) {
        const el = currentDivNext?.querySelectorAll(".fake-img")[0];
        if (!el) {
          currentDivNext.append(imgWrapper);
        } else {
          el.remove();
          currentDivNext.append(imgWrapper);
        }
      }
    }

    //prev

    if (index !== 0) {
      imgPrev.src = imgArray[index - 1]?.MediaURL;
    } else {
      imgPrev.src = imgArray[imgArray.length - 1]?.MediaURL;
    }

    imgPrev.loading = "eager";
    imgDivPrev.append(imgPrev);
    if (isLink) {
      imgAPrev.append(imgDivPrev);
      imgWrapperPrev.append(imgAPrev);
    } else {
      imgWrapperPrev.append(imgDivPrev);
    }

    let currentDivPrev =
      mainWrapper?.querySelectorAll(".slick-current")[0]
        ?.previousElementSibling;

    if (index === 0) {
      currentDivPrev =
        mainWrapper?.querySelectorAll(".slick-cloned")[1]
          ?.previousElementSibling;
    }

    if (currentDivPrev) {
      const el = currentDivPrev?.querySelectorAll(".fake-img")[0];
      if (!el) {
        currentDivPrev.append(imgWrapperPrev);
      } else {
        el.remove();
        currentDivPrev.append(imgWrapperPrev);
      }
    }
  };

  return (
    <div
      className={
        isMap.data === false
          ? isActive
            ? "filters-page-house-card-wrapper-active-list"
            : "filters-page-house-card-wrapper-list"
          : isActive
          ? "filters-page-house-card-wrapper-active"
          : "filters-page-house-card-wrapper"
      }
      onMouseEnter={handleMapMarker}
      onMouseLeave={handleResetMapMarker}
    >
      <NotesBox
        currentPage={currentPage}
        isVisible={noteBox}
        setIsVisible={setNoteBox}
        setIsNoteIconShow={setIsNoteIconShow}
        item={item}
        isNoteIconShow={isNoteIconShow}
      />
      <div
        className={
          isActive
            ? "filters-page-house-card-img-active"
            : "filters-page-house-card-img"
        }
      >
        <AlertNote isVisible={alertBox} setIsVisible={setAlertBox} />
        <AlertNote
          isVisible={alertNoUserBox}
          setIsVisible={setAlertNoUserBox}
          text={
            <>
              To synchronize your listings actions across all devices, please{" "}
              <span
                className="filters-page-house-card-note-link"
                onClick={() => logInLinkHandler()}
              >
                create
              </span>{" "}
              an account or{" "}
              <span
                className="filters-page-house-card-note-link"
                onClick={() => logInLinkHandler()}
              >
                log in
              </span>
              .
            </>
          }
        />
        <AlertNote
          isVisible={alertNoUserMessageBox}
          setIsVisible={setAlertNoUserMessageBox}
          text={
            <>
              To add a note to this listing, you must{" "}
              <span
                className="filters-page-house-card-note-link"
                onClick={() => logInLinkHandler()}
              >
                create
              </span>{" "}
              an account or{" "}
              <span
                className="filters-page-house-card-note-link"
                onClick={() => logInLinkHandler()}
              >
                log in
              </span>
              . We look forward to providing you with a personalized experience!
            </>
          }
        />

        {/* <NotesBox
          currentPage={currentPage}
          isVisible={noteBox}
          setIsVisible={setNoteBox}
          setIsNoteIconShow={setIsNoteIconShow}
          item={item}
          isNoteIconShow={isNoteIconShow}
        /> */}

        <div ref={rootRef}>
          {images?.length > 0 && (
            <Slider {...settings}>
              {images
                ?.sort((a, b) => parseFloat(+a.order) - parseFloat(+b.order))
                ?.map((img, i) => {
                  if (picturesFeature !== "true") {
                    return (
                      <div
                        className={
                          picturesFeature !== "true"
                            ? "filters-page-house-card--blur-wrapper filters-page-house-card-img-container"
                            : "filters-page-house-card-img-container"
                        }
                        key={i}
                      >
                        <img
                          src={img?.MediaURL ? img?.MediaURL : defaultMediaUrl}
                          onError={(img) => {
                            img.src = defaultMediaUrl;
                          }}
                          onLoad={() => {
                            if (inView) {
                              handleImageLoad(
                                i,
                                picturesFeature
                                // acceptLoginBtnHandler
                              );
                            }
                          }}
                        />
                        {picturesFeature !== "true" && (
                          <ClickForPremiumAccess />
                        )}
                      </div>
                    );
                  } else
                    return (
                      <LinkWrapper link={link} key={i}>
                        <div
                          className={
                            picturesFeature !== "true"
                              ? "filters-page-house-card--blur-wrapper filters-page-house-card-img-container"
                              : "filters-page-house-card-img-container"
                          }
                        >
                          <img
                            src={
                              img?.MediaURL ? img?.MediaURL : defaultMediaUrl
                            }
                            alt={
                              item?.Address
                                ? `${item?.Address.trim()}, ${item?.City} ${
                                    item?.StateOrProvince
                                  } ${item?.PostalCode}`
                                : ""
                            }
                            onError={(img) => {
                              img.src = defaultMediaUrl;
                            }}
                            onLoad={() => {
                              if (inView) {
                                handleImageLoad(
                                  i,
                                  picturesFeature
                                  // acceptLoginBtnHandler
                                );
                              }
                            }}
                          />
                          {picturesFeature !== "true" && (
                            <ClickForPremiumAccess />
                          )}
                        </div>
                      </LinkWrapper>
                    );
                })}

              {images?.length < 1 && (
                <a href={link} target="_blank">
                  <div className="filters-page-house-card-img-container">
                    <img src={defaultMediaUrl} />
                  </div>
                </a>
              )}
            </Slider>
          )}
        </div>
        <div
          className={
            item?.OpenHouseDate !== undefined || item?.oh
              ? isActive
                ? "filters-page-house-card-menu-open-house-icon-active"
                : "filters-page-house-card-menu-open-house-icon"
              : "filters-page-house-card-menu-label-disabled-1"
          }
        >
          <img src="https://realtytexas.azureedge.net/resources/images/open-house-200.png" />
        </div>

        {images && (
          <a href={link} target="_blank">
            <div className="filters-page-house-card-img-slide-counter">{`${currentSlide}/${images.length}`}</div>
          </a>
        )}
        {!images && (
          <div className="filters-page-house-card-img-spiner">
            <Spiner />
          </div>
        )}
        <HouseCardHeardList
          isHeardActive={isHeardActive}
          setIsHeardActive={setIsHeardActive}
          index={item.ListingKeyNumeric}
          currentPage={currentPage}
          item={item}
          note={note}
          setNote={setNote}
          setAlertBox={setAlertBox}
          setAlertNoUserBox={setAlertNoUserBox}
          alertBox={alertBox}
          alertNoUserBox={alertNoUserBox}
          setNoteBox={setNoteBox}
          setAlertNoUserMessageBox={setAlertNoUserMessageBox}
          alertNoUserMessageBox={alertNoUserMessageBox}
        />

        {images && (
          <div className="filters-page-house-card-menu">
            <div className="filters-page-house-card-menu-labels">
              {item?.rtlist === true && (
                <ClickOutside onClick={clickOutsideHandler}>
                  <div
                    className="filters-page-house-card-menu-realtytexas-icon"
                    onClick={clickRealtyIconHandler}
                  >
                    {isRI && (
                      <>
                        <div className="house-card-realty-icon-tooltip">
                          <p>Listed by Realty Texas</p>
                        </div>
                        <div className="house-card-realty-icon-tooltip-triangle" />
                      </>
                    )}
                  </div>
                </ClickOutside>
              )}

              <BAWithTooltip item={item} />
              <div className="filters-page-house-card-menu-label-2">
                <p
                  style={{
                    backgroundColor: item?.color ? item?.color : "#2078BD",
                  }}
                >
                  {item?.MlsStatus === "Closed"
                    ? `${item?.MlsStatus} - ${closeDate}` //`${item?.MlsStatus} - ${item?.CloseDate}`
                    : item?.MlsStatus}
                </p>
              </div>
              <div
                className={
                  diffInDays && diffInDays < 4
                    ? "filters-page-house-card-menu-label-1"
                    : "filters-page-house-card-menu-label-disabled-1"
                }
                style={{ position: "initial" }}
              >
                <p>
                  {(diffInDays && diffInDays === 3) ||
                  (diffInDays && diffInDays === 2)
                    ? "Just Listed"
                    : diffInDays && diffInDays === 1
                    ? "Listed Yesterday"
                    : "Listed Today"}
                </p>
              </div>
            </div>
            <div className="filters-page-house-card-menu-labels-right">
              <div
                className={
                  isNoteIconShow
                    ? "filters-page-house-card-menu-note-content-icon"
                    : "filters-page-house-card-menu-note-content-icon-disabled"
                }
                onClick={onNoteContentIconHandler}
              >
                <NoteIcon className="filters-page-house-card-menu-note-content-icon-main" />
              </div>
              {isDataHeart && (
                <div
                  className="filters-page-house-card-menu-heard-icon"
                  onClick={initHeardHandler}
                >
                  {getIcon()}
                </div>
              )}
              {dataIsShareIcon === "false" ? null : (
                <ShareIconList
                  data={item}
                  isHeardIconVisible={setIsHeardActive}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <a href={link} target="_blank">
        {!isActive && (
          <div
            className={
              isActive
                ? "filters-page-house-card-content-active"
                : "filters-page-house-card-content"
            }
          >
            <div className="filters-page-house-card-content-price">
              <h4>
                {item.ListPrice ? "$" : ""}
                {item.ListPrice
                  ? item.ListPrice.toLocaleString("en-US").replaceAll("$", "")
                  : "No Price"}
              </h4>
            </div>
            <div className="filters-page-house-card-content-residence">
              <div>
                <p>{item?.PropertyType.replace(/([A-Z])/g, " $1")}</p>
              </div>

              {item?.OriginatingSystemName && (
                <div className="filters-page-house-card-menu-originating-system-name">
                  <p>{item?.OriginatingSystemName}</p>
                </div>
              )}
            </div>
            {item?.PropertyType !== "Land" ? (
              <div className="filters-page-house-card-content-bd-ba">
                <div>
                  <div style={{ height: "24px" }}>
                    <BedIcon color="#F2F2F2" />
                  </div>
                  <div>
                    <p>{item?.BedroomsTotal} bd</p>
                  </div>
                </div>
                <div>
                  <div>
                    <BathroomIcon color="#F2F2F2" />
                  </div>
                  <div>
                    <p>{item?.BathroomsFull} ba</p>
                  </div>
                </div>
                {item?.AboveGradeFinishedArea ? (
                  <div className="filters-page-house-card-content-area">
                    <div style={{ height: "24px" }}>
                      <RulerCombined color="#F2F2F2" />
                    </div>
                    <div>
                      <p>
                        {item?.AboveGradeFinishedArea
                          ? `${item?.AboveGradeFinishedArea} ft²`
                          : ""}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div>
                <div className="filters-page-house-card-content-bd-ba">
                  <div>
                    <div style={{ height: "24px" }}>
                      <RulerCombined color="#F2F2F2" />
                    </div>
                    <div>
                      <p>
                        {item?.LotSizeAcres
                          ? `${item?.LotSizeAcres.toFixed(2)} acres`
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="filters-page-house-card-content-adress">
              <p>
                {item?.Address ? item?.Address.trim() : ""}, {item?.City}{" "}
                {item?.StateOrProvince} {item?.PostalCode}
              </p>
            </div>
          </div>
        )}
        {/* {isActive && <ActiveContentHouseCardBox item={item} />} */}
      </a>
      {isActive && <ActiveContentHouseCardBox item={item} link={link} />}
    </div>
  );
};

export default HouseCard;
