import { filterLocationListsSlice } from "../reducers/filterLocationListsSlice";
import axios from "axios";

export const getFilterLocationRegionsAction =
  (filters = []) =>
  async (dispatch) => {
    dispatch(filterLocationListsSlice.actions.filterLocationFetching());
    axios
      .post(`https://api.realtytexas.net/v1/regions/cognitive-search`, {
        top: 3000,
        count: "true",
        maxPrice: 50000000,
        minPrice: 0,
        filters: filters,
        select: null,
        page: 1,
        orderby: "region asc",
      })
      .then(function (response) {
        const temp = [...response.data.data.regions];
        const res = temp.map((c) => {
          c.label = c["region"];
          c.value = c["region"];
          return c;
        });
        dispatch(
          filterLocationListsSlice.actions.getFilterLocationRegionsSuccess(res)
        );
      })
      .catch(function (error) {
        console.log(error, "POST ERROR");
        dispatch(
          filterLocationListsSlice.actions.filterLocationRegionsFetchingError(
            error
          )
        );
      });
  };
