export const getMapCoordinates = (map) => {
  if (map) {
    let bounds = map.getBounds();
    let NECornerMap = bounds.getNorthEast();
    let SWCornerMap = bounds.getSouthWest();
    let NECorner = { lat: NECornerMap.lat(), lng: NECornerMap.lng() };
    let SWCorner = { lat: SWCornerMap.lat(), lng: SWCornerMap.lng() };
    let NWCorner = { lat: NECorner.lat, lng: SWCorner.lng };
    let SECorner = { lat: SWCorner.lat, lng: NECorner.lng };
    return {
      NECorner: NECorner,
      SWCorner: SWCorner,
      NWCorner: NWCorner,
      SECorner: SECorner,
    };
  }
};
