import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiltersPageBlock } from "../../modules/index.js";
import { Spiner } from "../../ui/index.js";
import axios from "axios";
import "./filters.css";
import { useLocation, useNavigate } from "react-router";
import {
  setFiltersFromUrl,
  toObjFilterConverter,
  toQParamsObjFilterConverter,
} from "../../constants/methods.js";
import { updateIsOpenNarrowFilters } from "../../store/actions/updateIsOpenNarrowFilters.js";
import { getAllHouseCardsAction } from "../../store/actions/getAllHouseCards.js";
import { FilterTemplate } from "../../constants/FiltersTemplate.js";
import { initialFilters } from "./initialFilters.js";
import { getFilterLocationRegionsAction } from "../../store/actions/getFilterLocationRegions.js";
import { getFilterLocationMetrosAction } from "../../store/actions/getFilterLocationMetros.js";
import { getFilterLocationCountries } from "../../store/actions/getFilterLocationCountries.js";
import { getFilterLocationCities } from "../../store/actions/getFilterLocationCities.js";
import { filterLocationListsSlice } from "../../store/reducers/filterLocationListsSlice.js";
import { updateIsResetFilters } from "../../store/actions/updateIsResetFilters.js";
import { getAllHouseCardsMapAction } from "../../store/actions/getAllHouseCardsMap.js";
import { getAllBothViewMapHouseCards } from "../../store/actions/getAllBothViewMapHouseCards.js";
import { resetMapCoordinates } from "../../store/actions/resetMapCoordinates.js";
import { getFilterLocationPostal } from "../../store/actions/getFilterLocationPostal.js";
import { useSearchParams } from "react-router-dom";
import MainHeaderLayer from "../../layers/MainHeaderLayer/MainHeaderLayer.jsx";
import { houseCardsMapSlice } from "../../store/reducers/houseCardsMapSlice.js";

const Filters = ({
  container,
  isPicturesFeature,
  subdomainData,
  isOpenHousesUrl,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Convert all query parameters to an object
  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });
  const [filters, setFilters] = useState(initialFilters);

  const [isFirstLoadedMinMaxQPar, setIsFirstLoadedMinMaxQPar] = useState(false);

  const { isLoading, data, error } = useSelector((state) => state.houseCards);
  const [currentPropertyType, setCurrentPropertyType] = useState(null);
  const mapCoordinates = useSelector((state) => state.mapCoordinates);
  const [sortingValue, setSortingValue] = useState("");
  // const [sortingValue, setSortingValue] = useState({
  //   label: "Sort By Default",
  //   value: "rtlist desc, ListingContractDate desc, ListPrice desc",
  //   orderBySort: "",
  // });
  const [currentPage, setCurrentPage] = useState(0);
  const [isApply, setIsApply] = useState(false);

  //map
  const [currentMap, setCurrentMap] = useState(null);
  const [currentMaps, setCurrentMaps] = useState(null);
  const [currentCenterCoord, setCurrentCenterCoord] = useState(null);

  //Property Type
  const [allPropertyTypes, setAllPropertyTypes] = useState(null);
  //Property SubType
  const [allSubPropertyTypes, setAllSubPropertyTypes] = useState(null);
  //Property SubType from qparam
  const [allQparamsDataLoaded, setAllQparamsDataLoaded] = useState({
    processed: false,
  });

  const [residentialSubType, setResidentialSubType] = useState([]);
  const [foundResidentialSubType, setFoundResidentialSubType] = useState([]);
  //Filter Location
  // // the value of the search field
  const [MLS_address, setMLS_Address] = useState("");

  // the search result
  const [MLS_addressRes, setMLS_AddressRes] = useState([]);
  const [foundMLS_address, setFoundMLS_address] = useState([]);

  // the value of the search field
  const [region, setRegion] = useState("");
  const [metro, setMetro] = useState("");
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [postal, setPostal] = useState([]);
  // the search result
  const [foundRegions, setFoundRegions] = useState([]);
  const [foundMetro, setFoundMetro] = useState([]);
  const [foundCountry, setFoundCountry] = useState([]);
  const [foundCity, setFoundCity] = useState([]);
  const [foundPostal, setFoundPostal] = useState([]);

  //locals
  const [twCities, setTwCities] = useState([]);
  const [twPostals, setTwPostals] = useState([]);
  const [twCitiesPage, setTwCitiesPage] = useState(1);
  const [twPostalsPage, setTwPostalsPage] = useState(1);

  //home features block
  const [minMaxPriceMin, setMinMaxPriceMin] = useState({
    value: null,
    label: "$0",
  });
  const [minMaxPriceMax, setMinMaxPriceMax] = useState({
    value: null,
    label: "$50,000,000",
  });
  const [priceInfoLoading, setPriceInfoLoading] = useState(false);

  const [priceValue, setPriceValue] = useState([0, 50000000]);
  const [sqftMin, setSqftMin] = useState("No Min");
  const [yearBuiltMin, setYearBuiltMin] = useState("No Min");
  const [garagesSpacesMin, setGaragesSpacesMin] = useState("No Min");
  const [bathMin, setBathMin] = useState("No Min");
  const [levelsMin, setLevelsMin] = useState("No Min");
  const [bedsMin, setBedsMin] = useState("No Min");
  const [lotSizeAcresMin, setLotSizeAcresMin] = useState({
    value: null,
    label: "No Min",
  });
  const [sqftMax, setSqftMax] = useState("No Max");
  const [yearBuiltMax, setYearBuiltMax] = useState("No Max");
  const [garagesSpacesMax, setGaragesSpacesMax] = useState("No Max");
  const [bathMax, setBathMax] = useState("No Max");
  const [levelsMax, setLevelsMax] = useState("No Max");
  const [bedsMax, setBedsMax] = useState("No Max");
  const [lotSizeAcresMax, setLotSizeAcresMax] = useState({
    value: null,
    label: "No Max",
  });
  const [isPrimaryBedOnMain, setIsPrimaryBedOnMain] = useState(false);
  const [isFireplace, setIsFireplace] = useState(false);
  const [isAccessibilityFeatures, setIsAccessibilityFeatures] = useState(false);
  const [isPoolFeatures, setIsPoolFeatures] = useState(false);

  //Status
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [isCommingSoon, setIsCommingSoon] = useState(
    queryParamsObject?.Status?.includes("Closed") ? false : true
  );
  const [isActive, setIsActive] = useState(
    queryParamsObject?.Status?.includes("Closed") ? false : true
  );
  const [isActiveUnderContract, setIsActiveUnderContract] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  //Timeframe
  const [oneDay, setOneDay] = useState(false);
  const [sevenDay, setSevenDay] = useState(false);
  const [thirtyDay, setThirtyDay] = useState(false);

  //showOnly
  const [isOurListings, setIsOurListings] = useState(false);
  const [isOpenHouses, setIsOpenHouses] = useState(false);

  //Map
  const [mapZoom, setMapZoom] = useState(null);
  const [currentCenter, setCurrentCenter] = useState(null);
  const [dataInRectPage, setDataInRectPage] = useState({
    label: "Page 1",
    value: 1,
  });
  const [isDraw, setIsDraw] = useState(null);
  const [isMultyDraw, setIsMultyDraw] = useState(null);

  const [dataInPolyline, setDataInPolyline] = useState(null);
  const [dataInMultyPolygones, setDataInMultyPolygones] = useState(null);
  const [drawCoordinates, setDrawCoordinates] = useState([]);

  //Polygons
  const [polygonData, setPolygonData] = useState(null);
  const [polygonsData, setPolygonsData] = useState([]);
  const [currentPolygon, setCurrentPolygon] = useState(null);
  const [currentPolygons, setCurrentPolygons] = useState([]);

  const [currentLayersPolygon, setCurrentLayersPolygon] = useState(null);
  const [currentLayersPolygons, setCurrentLayersPolygons] = useState([]);
  //////////////////////////////

  const [appReady, setAppReady] = useState(false);

  const [pathname, setPathname] = useState("");

  const setQParams = (queryParamsObject) => {
    const qparams = toQParamsObjFilterConverter(filters, subdomainData);
    const currentPath = location.pathname;
    const qzoom = queryParamsObject?.Zoom;
    const qLat = queryParamsObject?.Lat;
    const qLng = queryParamsObject?.Lng;
    setPathname(currentPath);
    if (qparams !== null) {
      let updatedPath = `${currentPath}${qparams}`;
      if (qzoom && qLat) {
        updatedPath = `${currentPath}${qparams}&Zoom=${qzoom}&Lat=${qLat}&Lng=${qLng}`;
      }
      if (qzoom && !qLat) {
        updatedPath = `${currentPath}${qparams}&Zoom=${qzoom}`;
      }
      navigate(updatedPath, { replace: true });
    }
  };

  const toIsoFormat = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const clearQParams = () => {
    if (pathname === "") {
      navigate(`${pathname}/buy`);
    } else {
      navigate(`${pathname}`);
    }
  };

  // IS RESET BLOCK

  const resetHandler = () => {
    dispatch(updateIsResetFilters(!isReset));
    // window.scrollTo(0, 0);
  };

  const { isReset } = useSelector((state) => state.isResetFilters);

  const [isResetFirst, setIsResetFirst] = useState(false);

  useEffect(() => {
    //search block
    if (isResetFirst === false) setIsResetFirst(true);
    if (isResetFirst) {
      setMLS_Address("");
      setMLS_AddressRes([]);
      setFoundMLS_address([]);

      //propertySubtype block
      setResidentialSubType(null);
      const temp = allPropertyTypes?.map((type) => {
        if (type?.PropertyType === "Residential") {
          return { ...type, show: true };
        } else return { ...type, show: false };
      });
      setAllPropertyTypes(temp);

      //filter location block
      setRegion("");
      setMetro(
        subdomainData?.metro
          ? subdomainData?.metro?.subdomain
            ? subdomainData?.metro
            : ""
          : ""
      );
      setCountry([]);
      setCity([]);
      setPostal([]);
      setFoundRegions([]);
      setFoundMetro([]);
      setFoundCountry([]);
      setFoundCity([]);
      setFoundPostal([]);

      //home feature block
      setMinMaxPriceMin({
        value: 75000,
        label: "$75,000",
      });
      setMinMaxPriceMax({
        value: 1000000,
        label: "$1,000,000",
      });
      setPriceValue([0, 50000000]);

      setSqftMin("No Min");
      setYearBuiltMin("No Min");
      setGaragesSpacesMin("No Min");
      setBathMin("No Min");
      setLevelsMin("No Min");
      setBedsMin("No Min");
      setLotSizeAcresMin({
        value: null,
        label: "No Min",
      });
      setSqftMax("No Max");
      setYearBuiltMax("No Max");
      setGaragesSpacesMax("No Max");
      setBathMax("No Max");
      setLevelsMax("No Max");
      setBedsMax("No Max");
      setLotSizeAcresMax({
        value: null,
        label: "No Max",
      });

      setIsPrimaryBedOnMain(false);
      setIsFireplace(false);
      setIsAccessibilityFeatures(false);
      setIsPoolFeatures(false)
      //status block
      setIsCommingSoon(true);
      setIsActive(true);
      setIsActiveUnderContract(false);
      setIsPending(false);
      setIsClosed(false);

      const currentDate = new Date();

      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setDateTo(toIsoFormat(currentDate));
      setDateFrom(toIsoFormat(new Date(formattedDate).fp_incr(-180)));

      //timeframe block
      setOneDay(false);
      setSevenDay(false);
      setThirtyDay(false);

      //showOnly block
      setIsOurListings(false);
      setIsOpenHouses(isOpenHousesUrl ? true : false);

      setMapZoom(
        queryParamsObject?.Zoom
          ? queryParamsObject?.Zoom
          : subdomainData?.zoom
          ? subdomainData?.zoom
          : null
      );
      setCurrentCenter(subdomainData?.center ? subdomainData?.center : null);
    }
    // }
  }, [isReset]);

  const isMap = useSelector((state) => state.isMap);

  useEffect(() => {
    if (
      isMap.data !== false &&
      (filters.filterLocation.region === null ||
        filters.filterLocation.region === "") &&
      (filters.filterLocation.metro === null ||
        filters.filterLocation.metro === "") &&
      (filters.filterLocation.country === null ||
        filters.filterLocation.country === []) &&
      (filters.filterLocation.city === null ||
        filters.filterLocation.city === []) &&
      (filters.filterLocation.postal === null ||
        filters.filterLocation.postal === [])
    ) {
      const removePolygon = () => {
        if (currentPolygon) currentPolygon.setMap(null);
      };

      const removePolygons = () => {
        if (currentPolygons && currentPolygons.length > 0) {
          currentPolygons?.map((pol) => {
            pol.setMap(null);
          });
        }
      };
      removePolygon();
      removePolygons();
    }
  }, [isApply]);

  useEffect(() => {
    const newPropertyType = allPropertyTypes?.filter((type) => type.show);
    const newPropertySubTypeList = allSubPropertyTypes?.filter(
      (subType) => subType.PropertyType === newPropertyType[0].PropertyType
    );

    setFilters({
      ...filters,
      propertyType: allPropertyTypes?.length > 0 ? newPropertyType : null,
      propertySubType:
        residentialSubType &&
        residentialSubType?.length > 0 &&
        allPropertyTypes.filter((type) => type.show)[0]?.PropertyType ===
          residentialSubType[0]?.PropertyType
          ? [...residentialSubType]
          : null,
      propertySubTypeList:
        allSubPropertyTypes?.length > 0 ? newPropertySubTypeList : null,
      filterLocation: {
        region: region?.subdomain ? region : null,
        metro: metro?.subdomain ? metro : null,
        country:
          country && country[0] !== "" && country?.length > 0 ? country : null,
        city: city && city[0] !== "" && city?.length > 0 ? city : null,
        postal:
          postal && postal[0] !== "" && postal?.length > 0 ? postal : null,
      },
      homeFeatures: {
        ...filters.homeFeatures,
        minMaxPriceValues: {
          min: priceValue[0],
          max: priceValue[1],
        },
        setMinMaxPrice: {
          min: minMaxPriceMin.value === null ? null : minMaxPriceMin,
          max: minMaxPriceMax.value === null ? null : minMaxPriceMax,
        },
        sqft: {
          min: sqftMin === "No Min" ? null : sqftMin,
          max: sqftMax === "No Max" ? null : sqftMax,
        },
        yearBuilt: {
          min: yearBuiltMin === "No Min" ? null : yearBuiltMin,
          max: yearBuiltMax === "No Max" ? null : yearBuiltMax,
        },
        garagesSpaces: {
          min: garagesSpacesMin === "No Min" ? null : garagesSpacesMin,
          max: garagesSpacesMax === "No Max" ? null : garagesSpacesMax,
        },
        bath: {
          min: bathMin === "No Min" ? null : bathMin,
          max: bathMax === "No Max" ? null : bathMax,
        },
        levels: {
          min: levelsMin === "No Min" ? null : levelsMin,
          max: levelsMax === "No Max" ? null : levelsMax,
        },
        beds: {
          min: bedsMin === "No Min" ? null : bedsMin,
          max: bedsMax === "No Max" ? null : bedsMax,
        },
        lotSizeAcres: {
          min: lotSizeAcresMin.value === null ? null : lotSizeAcresMin,
          max: lotSizeAcresMax.value === null ? null : lotSizeAcresMax,
        },
        primaryBedOnMain: isPrimaryBedOnMain,
        fireplace: isFireplace,
        accessibilityFeatures: isAccessibilityFeatures,
        poolFeatures: isPoolFeatures,
      },
      status: {
        commingSoon: isCommingSoon,
        active: isActive,
        activeUnderContract: isActiveUnderContract,
        pending: isPending,
        closed: isClosed,
        closedDateTo: dateTo,
        closedDateFrom: dateFrom,
      },
      timeFrame: {
        oneDay: oneDay,
        sevenDay: sevenDay,
        thirtyDay: thirtyDay,
      },
      showOnly: {
        ourListings: isOurListings,
        openHouses: isOpenHouses,
      },
      map: {
        zoom: mapZoom,
        center: currentCenter,
      },
    });
  }, [
    postal,
    city,
    country,
    metro,
    region,
    sqftMin,
    sqftMax,
    yearBuiltMin,
    yearBuiltMax,
    garagesSpacesMin,
    garagesSpacesMax,
    bathMin,
    bathMax,
    levelsMin,
    levelsMax,
    bedsMin,
    bedsMax,
    lotSizeAcresMin,
    lotSizeAcresMax,
    isPrimaryBedOnMain,
    isFireplace,
    isAccessibilityFeatures,
    isPoolFeatures,
    priceValue,
    minMaxPriceMin,
    minMaxPriceMax,
    isOurListings,
    isOpenHouses,
    oneDay,
    sevenDay,
    thirtyDay,
    isCommingSoon,
    isActive,
    isActiveUnderContract,
    isPending,
    isClosed,
    allPropertyTypes,
    allSubPropertyTypes,
    residentialSubType,
    mapZoom,
    currentCenter,
    dateTo,
    dateFrom,
  ]);

  const applyHandler = (loaded = true) => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    }
    let tempOrder = null;
    if (
      sortingValue?.orderBySort === "desc" ||
      sortingValue?.orderBySort === "asc"
    ) {
      tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
    }
    if (loaded) {
      clearQParams();
      setQParams(queryParamsObject);
    }

    const filtedrArr = toObjFilterConverter(filters);
    setIsApply(!isApply);
    dispatch(updateIsOpenNarrowFilters(false));

    dispatch(getAllHouseCardsAction(1, filtedrArr, tempOrder));
    // console.log(mapCoordinates?.data, "mapCoordinates?.data", currentMap);
    // dispatch(
    //   getAllHouseCardsMapAction(1, mapCoordinates?.data, filtedrArr, tempOrder)
    // );

    // if (!isDraw && !isMultyDraw) {
    dispatch(
      getAllBothViewMapHouseCards(
        mapCoordinates?.data,
        filtedrArr,
        tempOrder,
        dataInRectPage.value
      )
    );
    // }
    // }
    window.scrollTo(0, 0);
  };

  const filterLocationLists = useSelector((state) => state.filterLocationLists);

  //get lists functions

  useEffect(() => {
    if (queryParamsObject?.Region) {
      dispatch(getFilterLocationRegionsAction());
    } else {
      filterLocationRegionsLocal();
    }
  }, []);

  const filterLocationMetros = () => {
    if (queryParamsObject?.Region) {
      // get All Regions
      axios
        .post(`https://api.realtytexas.net/v1/regions/cognitive-search`, {
          top: 3000,
          count: "true",
          maxPrice: 50000000,
          minPrice: 0,
          filters: [],
          select: null,
          page: 1,
          orderby: "region asc",
        })
        .then(function (response) {
          const temp = [...response.data.data.regions];
          const currentRegion = temp.filter(
            (reg) => reg.subdomain === queryParamsObject?.Region
          );
          dispatch(getFilterLocationMetrosAction(currentRegion[0].region));
        })
        .catch(function (error) {
          console.log(error, "POST ERROR");
        });
    } else dispatch(getFilterLocationMetrosAction());
  };

  useEffect(() => {
    if (queryParamsObject?.Metro) {
      filterLocationMetros();
    }
    if (!queryParamsObject?.Region && !queryParamsObject?.Metro) {
      filterLocationMetrosLocal();
    }
  }, []);

  const filterLocationCounties = () => {
    let metroCodes = null;
    if (queryParamsObject?.Metro) {
      metroCodes = queryParamsObject?.Metro;
      dispatch(getFilterLocationCountries(metroCodes, true));
    } else dispatch(getFilterLocationCountries());
  };

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  useEffect(() => {
    if (queryParamsObject?.County) {
      filterLocationCounties();
    }
    if (!queryParamsObject?.Metro && !queryParamsObject?.County) {
      filterLocationCountiesLocal();
    }
  }, []);
  const [allCitiesList, setAllCitiesList] = useState([]);

  const filterLocationCities = () => {
    if (queryParamsObject?.County) {
      axios
        .post(`https://api.realtytexas.net/v1/counties/cognitive-search`, {
          top: 3000,
          count: "true",
          maxPrice: 50000000,
          minPrice: 0,
          filters: [],
          select: null,
          page: 1,
          orderby: "County asc",
        })
        .then(function (response) {
          const temp = [...response?.data?.data?.counties];
          let tempCountyList = [];
          if (queryParamsObject?.County.indexOf(",") <= -1) {
            tempCountyList.push(queryParamsObject?.County);
          } else {
            tempCountyList = [...queryParamsObject?.County.split(",")];
          }

          const countyCodes = temp
            .filter((obj1) =>
              tempCountyList.some((obj2) => obj1.subdomain === obj2)
            )
            .map((c) => +c.CountyCode);
          dispatch(getFilterLocationCities(countyCodes));
        })
        .catch(function (error) {
          console.log(error, "POST ERROR COUNTY CODES");
        });
    } else {
      let tempCity = [];
      if (queryParamsObject?.City.indexOf(",") <= -1) {
        tempCity.push(queryParamsObject?.City);
      } else {
        tempCity = [...queryParamsObject?.City?.split(",")];
      }

      const firstLetters = [...tempCity.map((item) => item[0])];
      const uniqueFirstLetters = firstLetters.filter(onlyUnique);
      uniqueFirstLetters.map((item) => {
        axios
          .post(
            `https://api.realtytexas.net/v1/cities/cognitive-search/${item[0]}`,
            {
              top: 3000,
              count: "true",
              maxPrice: 50000000,
              minPrice: 0,
              filters: [],
              select: null,
              page: 1,
              orderby: "subdomain asc",
              ["elastic-search"]: true,
              ["elastic-search-fields"]: "City",
            }
          )
          .then(function (response) {
            const temp = [...response.data.data.cities];
            const res = temp.map((c) => {
              c.label = c["City"];
              c.value = c["City"];
              return c;
            });

            setAllCitiesList((allCitiesList) => [...allCitiesList, res]);
          })
          .catch(function (error) {
            console.log(error, "POST ERROR");
          });
        return null;
      });
    }
  };

  useEffect(() => {
    if (queryParamsObject?.City) {
      filterLocationCities();
    } else if (!queryParamsObject?.County && !queryParamsObject?.City) {
      setCitiesOrPostalsData(1, "cities");
    }
  }, []);

  useEffect(() => {
    if (twCities?.length > 0) {
      if (Number.isInteger(twCities.length / 1000)) {
        setTwCitiesPage(twCitiesPage + 1);
      }
    }
  }, [twCities?.length]);

  useEffect(() => {
    if (twCitiesPage !== 0) {
      setCitiesOrPostalsData(twCitiesPage, "cities");
    }
  }, [twCitiesPage]);

  useEffect(() => {
    if (queryParamsObject?.City) {
      let tempCity = [];
      if (queryParamsObject?.City.indexOf(",") <= -1) {
        tempCity.push(queryParamsObject?.City);
      } else {
        tempCity = [...queryParamsObject?.City?.split(",")];
      }
      const firstLetters = [...tempCity.map((item) => item[0])];
      const uniqueFirstLetters = firstLetters.filter(onlyUnique);

      if (allCitiesList?.length === uniqueFirstLetters?.length) {
        dispatch(
          filterLocationListsSlice.actions.getFilterLocationCitiesSuccess(
            allCitiesList.flat(1)
          )
        );
      }
    }
  }, [allCitiesList]);

  const [allPostalsList, setAllPostalsList] = useState([]);

  const filterLocationPostals = () => {
    let tempPostal = [];
    if (queryParamsObject?.PostalCode.indexOf(",") <= -1) {
      tempPostal.push(queryParamsObject?.PostalCode);
    } else {
      tempPostal = [...queryParamsObject?.PostalCode?.split(",")];
    }
    tempPostal.map((item) =>
      axios
        .post(
          `https://api.realtytexas.net/v1/zip-codes/cognitive-search/${item}`,
          {
            top: 3000,
            count: "true",
            maxPrice: 50000000,
            minPrice: 0,
            filters: [],
            select: null,
            page: 1,
            orderby: "ZipCode asc",
            ["elastic-search"]: true,
            ["elastic-search-fields"]: "ZipCode",
          }
        )
        .then(function (response) {
          const temp = [...response.data.data.zipcodes];
          const res = temp.map((c) => {
            c.label = c["ZipCode"];
            c.value = c["ZipCode"];
            return c;
          });
          setAllPostalsList((allPostalsList) => [...allPostalsList, res]);
        })
        .catch(function (error) {
          console.log(error, "POST ERROR");
        })
    );
  };
  const [postalList, setPostalList] = useState(null);
  const [isPostalList, setIsPostalList] = useState(null);

  useEffect(() => {
    if (queryParamsObject?.PostalCode) {
      let tempPostalCode = [];
      if (queryParamsObject?.PostalCode.indexOf(",") <= -1) {
        tempPostalCode.push(queryParamsObject?.PostalCode);
      } else {
        tempPostalCode = [...queryParamsObject?.PostalCode?.split(",")];
      }
      if (allPostalsList?.length === tempPostalCode?.length) {
        setPostalList([...allPostalsList.flat(1)]);
      }
    }
  }, [allPostalsList]);

  useEffect(() => {
    if (queryParamsObject?.PostalCode) {
      if (postalList?.length > 0) setIsPostalList(true);
    } else setIsPostalList(true);
  }, [postalList]);

  useEffect(() => {
    if (queryParamsObject?.PostalCode) {
      filterLocationPostals();
    } else if (!queryParamsObject?.City && !queryParamsObject?.PostalCode) {
      setCitiesOrPostalsData(1, "zip-codes");
    }
  }, []);

  ///////

  useEffect(() => {
    if (twPostals?.length > 0) {
      if (Number.isInteger(twPostals.length / 1000)) {
        setTwPostalsPage(twPostalsPage + 1);
      }
    }
  }, [twPostals?.length]);

  useEffect(() => {
    if (twPostalsPage !== 0) {
      setCitiesOrPostalsData(twPostalsPage, "zip-codes");
    }
  }, [twPostalsPage]);

  /////////

  // useEffect(() => {
  //   setCitiesOrPostalsData(twPostalsPage, "zip-codes");
  // }, [twPostalsPage]);

  const getDataFromQParams = (obj, options) => {
    const setFunctions = {
      setAllPropertyTypes: setAllPropertyTypes,
      setResidentialSubType: setResidentialSubType,
      setIsOurListings: setIsOurListings,
      setIsOpenHouses: setIsOpenHouses,
      setRegion: setRegion,
      setMetro: setMetro,
      setCountry: setCountry,
      setCity: setCity,
      setPostal: setPostal,
      setFoundRegions: setFoundRegions,
      setFoundMetro: setFoundMetro,
      setFoundCountry: setFoundCountry,
      setFoundCity: setFoundCity,
      setFoundPostal: setFoundPostal,
      //home features func
      setBathMin: setBathMin,
      setBathMax: setBathMax,
      setLevelsMin: setLevelsMin,
      setLevelsMax: setLevelsMax,
      setBedsMin: setBedsMin,
      setBedsMax: setBedsMax,
      setSqftMin: setSqftMin,
      setSqftMax: setSqftMax,
      setYearBuiltMin: setYearBuiltMin,
      setYearBuiltMax: setYearBuiltMax,
      setGaragesSpacesMin: setGaragesSpacesMin,
      setGaragesSpacesMax: setGaragesSpacesMax,
      setLotSizeAcresMin: setLotSizeAcresMin,
      setLotSizeAcresMax: setLotSizeAcresMax,
      setIsPrimaryBedOnMain: setIsPrimaryBedOnMain,
      setIsFireplace: setIsFireplace,
      setIsAccessibilityFeatures: setIsAccessibilityFeatures,
      setIsPoolFeatures: setIsPoolFeatures,
      setMinMaxPriceMin: setMinMaxPriceMin,
      setMinMaxPriceMax: setMinMaxPriceMax,
      setPriceValue: setPriceValue,
      setOneDay: setOneDay,
      setSevenDay: setSevenDay,
      setThirtyDay: setThirtyDay,
      setIsCommingSoon: setIsCommingSoon,
      setIsActive: setIsActive,
      setIsActiveUnderContract: setIsActiveUnderContract,
      setIsPending: setIsPending,
      setIsClosed: setIsClosed,
      setMapZoom: setMapZoom,
      setCurrentCenter: setCurrentCenter,
      setDateFrom: setDateFrom,
      setDateTo: setDateTo,
    };
    for (let prop in FilterTemplate) {
      if (obj.hasOwnProperty(prop)) {
        if (obj[prop]) {
          //add this to the filter
          setFiltersFromUrl(
            prop,
            obj[prop],
            filters,
            setFilters,
            options,
            setFunctions,
            obj
          );
        }
      }
    }
  };

  //IS URL DATA LOADED BLOCK//////////////////////////////

  const [isQPropTypeLoaded, setIsQPropTypeLoaded] = useState(false);

  useEffect(() => {
    if (!isQPropTypeLoaded) {
      if (queryParamsObject?.PropertyType) {
        if (
          filters.propertyType?.length > 0 &&
          filters.propertySubTypeList?.length > 0
        ) {
          setIsQPropTypeLoaded(true);
        }
      } else setIsQPropTypeLoaded(true);
    }
  }, [filters.propertyType, filters.propertySubTypeList]);

  const [isQPropSubTypeLoaded, setIsQPropSubTypeLoaded] = useState(false);

  useEffect(() => {
    if (!isQPropSubTypeLoaded) {
      if (queryParamsObject?.PropertySubType) {
        if (filters.propertySubType?.length > 0) setIsQPropSubTypeLoaded(true);
      } else setIsQPropSubTypeLoaded(true);
    }
  }, [
    filters.propertyType,
    filters.propertySubTypeList,
    filters.propertySubType,
  ]);

  const [isQPropRegionLoaded, setIsQPropRegionLoaded] = useState(false);

  useEffect(() => {
    if (!isQPropRegionLoaded) {
      if (queryParamsObject?.Region) {
        if (filters.filterLocation.region) setIsQPropRegionLoaded(true);
      } else setIsQPropRegionLoaded(true);
    }
  }, [filters.filterLocation.region]);

  useEffect(() => {
    if (
      queryParamsObject?.Region &&
      isQPropRegionLoaded &&
      !(queryParamsObject?.Metro && isQPropMetroLoaded)
    ) {
      if (filters.filterLocation.region) filterLocationMetrosLocal();
    }
  }, [isQPropRegionLoaded]);

  const [isQPropMetroLoaded, setIsQPropMetroLoaded] = useState(false);

  useEffect(() => {
    if (!isQPropMetroLoaded) {
      if (queryParamsObject?.Metro) {
        if (filters.filterLocation.metro) setIsQPropMetroLoaded(true);
      } else setIsQPropMetroLoaded(true);
    }
  }, [filters.filterLocation.metro]);

  useEffect(() => {
    if (
      queryParamsObject?.Metro &&
      isQPropMetroLoaded &&
      !(queryParamsObject?.County && isQPropCountyLoaded)
    ) {
      if (filters.filterLocation.metro) filterLocationCountiesLocal();
    }
  }, [isQPropMetroLoaded]);

  const [isQPropCountyLoaded, setIsQPropCountyLoaded] = useState(false);

  useEffect(() => {
    if (!isQPropCountyLoaded) {
      if (queryParamsObject?.County) {
        if (filters.filterLocation.country) setIsQPropCountyLoaded(true);
      } else setIsQPropCountyLoaded(true);
    }
  }, [filters.filterLocation.country]);

  useEffect(() => {
    if (
      queryParamsObject?.County &&
      isQPropCountyLoaded &&
      !(queryParamsObject?.City && isQPropCityLoaded)
    ) {
      if (filters.filterLocation.country) {
        filterLocationCities();
      }
    }
  }, [isQPropCountyLoaded]);

  const [isQPropCityLoaded, setIsQPropCityLoaded] = useState(false);

  useEffect(() => {
    if (!isQPropCityLoaded) {
      if (queryParamsObject?.City) {
        if (filters.filterLocation.city) setIsQPropCityLoaded(true);
      } else setIsQPropCityLoaded(true);
    }
  }, [filters.filterLocation.city]);

  const [isQPropPostalCodeLoaded, setIsQPropPostalCodeLoaded] = useState(false);

  useEffect(() => {
    if (!isQPropPostalCodeLoaded) {
      if (queryParamsObject?.PostalCode) {
        if (filters.filterLocation.postal) setIsQPropPostalCodeLoaded(true);
      } else setIsQPropPostalCodeLoaded(true);
    }
  }, [filters.filterLocation.postal]);

  useEffect(() => {
    if (
      queryParamsObject?.City &&
      isQPropCityLoaded &&
      !(queryParamsObject?.PostalCode && isQPropPostalCodeLoaded)
    ) {
      if (filters.filterLocation.city) filterLocationPostal("");
    }
  }, [isQPropCityLoaded]);

  const [isQShowOnlyLoaded, setIsQShowOnlyLoaded] = useState(false);

  useEffect(() => {
    if (!isQShowOnlyLoaded) {
      if (queryParamsObject?.ShowOnly) {
        let temp = [];
        if (queryParamsObject?.ShowOnly.indexOf(",") <= -1) {
          temp.push(queryParamsObject?.ShowOnly);
        } else {
          temp = [...queryParamsObject?.ShowOnly.split(",")];
        }
        if (temp.length === 2) {
          if (
            filters.showOnly.ourListings === true &&
            filters.showOnly.openHouses === true
          ) {
            setIsQShowOnlyLoaded(true);
          }
        } else {
          if (temp[0] === "OurListings" && filters.showOnly.ourListings)
            setIsQShowOnlyLoaded(true);
          if (temp[0] === "OpenHouses" && filters.showOnly.openHouses)
            setIsQShowOnlyLoaded(true);
        }
      } else setIsQShowOnlyLoaded(true);
    }
  }, [filters.showOnly.ourListings, filters.showOnly.openHouses]);

  const [isQBathMinLoaded, setIsQBathMinLoaded] = useState(false);
  const [isQBathMaxLoaded, setIsQBathMaxLoaded] = useState(false);

  useEffect(() => {
    if (!isQBathMinLoaded) {
      if (queryParamsObject?.BathroomsFullMin) {
        if (filters.homeFeatures.bath.min !== null) {
          setIsQBathMinLoaded(true);
        }
      } else setIsQBathMinLoaded(true);
    }
  }, [filters.homeFeatures.bath.min]);

  useEffect(() => {
    if (!isQBathMaxLoaded) {
      if (queryParamsObject?.BathroomsFullMax) {
        if (filters.homeFeatures.bath.max !== null) {
          setIsQBathMaxLoaded(true);
        }
      } else setIsQBathMaxLoaded(true);
    }
  }, [filters.homeFeatures.bath.max]);

  const [isQLevelsMinLoaded, setIsQLevelsMinLoaded] = useState(false);
  const [isQLevelsMaxLoaded, setIsQLevelsMaxLoaded] = useState(false);

  useEffect(() => {
    if (!isQLevelsMinLoaded) {
      if (queryParamsObject?.LevelsMin) {
        if (filters.homeFeatures.levels.min !== null) {
          setIsQLevelsMinLoaded(true);
        }
      } else setIsQLevelsMinLoaded(true);
    }
  }, [filters.homeFeatures.levels.min]);

  useEffect(() => {
    if (!isQLevelsMaxLoaded) {
      if (queryParamsObject?.LevelsMax) {
        if (filters.homeFeatures.levels.max !== null) {
          setIsQLevelsMaxLoaded(true);
        }
      } else setIsQLevelsMaxLoaded(true);
    }
  }, [filters.homeFeatures.levels.max]);

  const [isQBedsMinLoaded, setIsQBedsMinLoaded] = useState(false);
  const [isQBedsMaxLoaded, setIsQBedsMaxLoaded] = useState(false);

  useEffect(() => {
    if (!isQBedsMinLoaded) {
      if (queryParamsObject?.BedroomsTotalMin) {
        if (filters.homeFeatures.beds.min !== null) {
          setIsQBedsMinLoaded(true);
        }
      } else setIsQBedsMinLoaded(true);
    }
  }, [filters.homeFeatures.beds.min]);

  useEffect(() => {
    if (!isQBedsMaxLoaded) {
      if (queryParamsObject?.BedroomsTotalMax) {
        if (filters.homeFeatures.beds.max !== null) {
          setIsQBedsMaxLoaded(true);
        }
      } else setIsQBedsMaxLoaded(true);
    }
  }, [filters.homeFeatures.beds.max]);

  const [isQSqftMinLoaded, setIsQSqftMinLoaded] = useState(false);
  const [isQSqftMaxLoaded, setIsQSqftMaxLoaded] = useState(false);

  useEffect(() => {
    if (!isQSqftMinLoaded) {
      if (queryParamsObject?.AboveGradeFinishedAreaMin) {
        if (filters.homeFeatures.sqft.min !== null) {
          setIsQSqftMinLoaded(true);
        }
      } else setIsQSqftMinLoaded(true);
    }
  }, [filters.homeFeatures.sqft.min]);

  useEffect(() => {
    if (!isQSqftMaxLoaded) {
      if (queryParamsObject?.AboveGradeFinishedAreaMax) {
        if (filters.homeFeatures.sqft.max !== null) {
          setIsQSqftMaxLoaded(true);
        }
      } else setIsQSqftMaxLoaded(true);
    }
  }, [filters.homeFeatures.sqft.max]);

  const [isQGarageSpacesMinLoaded, setIsQGarageSpacesMinLoaded] =
    useState(false);
  const [isQGarageSpacesMaxLoaded, setIsQGarageSpacesMaxLoaded] =
    useState(false);

  useEffect(() => {
    if (!isQGarageSpacesMinLoaded) {
      if (queryParamsObject?.GarageSpacesMin) {
        if (filters.homeFeatures.garagesSpaces.min !== null) {
          setIsQGarageSpacesMinLoaded(true);
        }
      } else setIsQGarageSpacesMinLoaded(true);
    }
  }, [filters.homeFeatures.garagesSpaces.min]);

  useEffect(() => {
    if (!isQGarageSpacesMaxLoaded) {
      if (queryParamsObject?.GarageSpacesMax) {
        if (filters.homeFeatures.garagesSpaces.max !== null) {
          setIsQGarageSpacesMaxLoaded(true);
        }
      } else setIsQGarageSpacesMaxLoaded(true);
    }
  }, [filters.homeFeatures.garagesSpaces.max]);

  const [isQYearBuiltMinLoaded, setIsQYearBuiltMinLoaded] = useState(false);
  const [isQYearBuiltMaxLoaded, setIsQYearBuiltMaxLoaded] = useState(false);

  useEffect(() => {
    if (!isQYearBuiltMinLoaded) {
      if (queryParamsObject?.YearBuiltMin) {
        if (filters.homeFeatures.yearBuilt.min !== null) {
          setIsQYearBuiltMinLoaded(true);
        }
      } else setIsQYearBuiltMinLoaded(true);
    }
  }, [filters.homeFeatures.yearBuilt.min]);

  useEffect(() => {
    if (!isQYearBuiltMaxLoaded) {
      if (queryParamsObject?.YearBuiltMax) {
        if (filters.homeFeatures.yearBuilt.max !== null) {
          setIsQYearBuiltMaxLoaded(true);
        }
      } else setIsQYearBuiltMaxLoaded(true);
    }
  }, [filters.homeFeatures.yearBuilt.max]);

  const [isQLotSizeAcresMinLoaded, setIsQLotSizeAcresMinLoaded] =
    useState(false);
  const [isQLotSizeAcresMaxLoaded, setIsQLotSizeAcresMaxLoaded] =
    useState(false);

  useEffect(() => {
    if (!isQLotSizeAcresMinLoaded) {
      if (queryParamsObject?.LotSizeAcresMin) {
        if (filters.homeFeatures.lotSizeAcres.min !== null) {
          setIsQLotSizeAcresMinLoaded(true);
        }
      } else setIsQLotSizeAcresMinLoaded(true);
    }
  }, [filters.homeFeatures.lotSizeAcres.min]);

  useEffect(() => {
    if (!isQLotSizeAcresMaxLoaded) {
      if (queryParamsObject?.LotSizeAcresMax) {
        if (filters.homeFeatures.lotSizeAcres.max !== null) {
          setIsQLotSizeAcresMaxLoaded(true);
        }
      } else setIsQLotSizeAcresMaxLoaded(true);
    }
  }, [filters.homeFeatures.lotSizeAcres.max]);

  const [isQMainLevelPrimaryLoaded, setIsQMainLevelPrimaryLoaded] =
    useState(false);

  useEffect(() => {
    if (!isQMainLevelPrimaryLoaded) {
      if (queryParamsObject?.MainLevelPrimary) {
        if (filters.homeFeatures.primaryBedOnMain === true) {
          setIsQMainLevelPrimaryLoaded(true);
        }
      } else setIsQMainLevelPrimaryLoaded(true);
    }
  }, [filters.homeFeatures.primaryBedOnMain]);

  const [isQFireplaceLoaded, setIsQFireplaceLoaded] = useState(false);

  useEffect(() => {
    if (!isQFireplaceLoaded) {
      if (queryParamsObject?.Fireplace) {
        if (filters.homeFeatures.fireplace === true) {
          setIsQFireplaceLoaded(true);
        }
      } else setIsQFireplaceLoaded(true);
    }
  }, [filters.homeFeatures.fireplace]);

  const [isQAccessibilityFeaturesLoaded, setIsQAccessibilityFeaturesLoaded] =
    useState(false);

  useEffect(() => {
    if (!isQAccessibilityFeaturesLoaded) {
      if (queryParamsObject?.AccessibilityFeatures) {
        if (filters.homeFeatures.accessibilityFeatures === true) {
          setIsQAccessibilityFeaturesLoaded(true);
        }
      } else setIsQAccessibilityFeaturesLoaded(true);
    }
  }, [filters.homeFeatures.accessibilityFeatures]);

  const [isQPoolFeaturesLoaded, setIsQPoolFeaturesLoaded] = useState(false);

  useEffect(() => {
    if (!isQPoolFeaturesLoaded) {
      if (queryParamsObject?.PoolPrivateYN) {
        if (filters.homeFeatures.poolFeatures === true) {
          setIsQPoolFeaturesLoaded(true);
        }
      } else setIsQPoolFeaturesLoaded(true);
    }
  }, [filters.homeFeatures.poolFeatures]);

  const [isQPriceMinLoaded, setIsQPriceMinLoaded] = useState(false);
  const [isQPriceMaxLoaded, setIsQPriceMaxLoaded] = useState(false);

  useEffect(() => {
    if (!isQPriceMinLoaded) {
      if (queryParamsObject?.ListPriceMin) {
        if (
          filters.homeFeatures.setMinMaxPrice?.min?.label !== null &&
          filters.minMaxPriceValues?.min !== null
        ) {
          setIsQPriceMinLoaded(true);
        }
      } else setIsQPriceMinLoaded(true);
    }
  }, [filters.homeFeatures.setMinMaxPrice, filters.minMaxPriceValues]);

  useEffect(() => {
    if (!isQPriceMaxLoaded) {
      if (queryParamsObject?.ListPriceMax) {
        if (
          filters.homeFeatures.setMinMaxPrice?.max?.label !== null &&
          filters.minMaxPriceValues?.max !== null
        ) {
          setIsQPriceMaxLoaded(true);
        }
      } else setIsQPriceMaxLoaded(true);
    }
  }, [filters.homeFeatures.setMinMaxPrice, filters.minMaxPriceValues]);

  const [isQTimeFrameLoaded, setIsQTimeFrameLoaded] = useState(false);

  useEffect(() => {
    if (!isQTimeFrameLoaded) {
      if (queryParamsObject?.Timeframe) {
        if (
          filters.timeFrame.oneDay === true ||
          filters.timeFrame.sevenDay === true ||
          filters.timeFrame.thirtyDay === true
        ) {
          setIsQTimeFrameLoaded(true);
        }
      } else setIsQTimeFrameLoaded(true);
    }
  }, [filters.timeFrame]);

  const [isQStatusLoaded, setIsQStatusLoaded] = useState(false);

  useEffect(() => {
    if (!isQStatusLoaded) {
      if (queryParamsObject?.Status) {
        let k = 0;
        if (queryParamsObject?.Status.includes("Active")) {
          if (filters.status.active === true) {
            k++;
          }
        } else {
          k++;
        }
        if (queryParamsObject?.Status.includes("ActiveUnderContract")) {
          if (filters.status.activeUnderContract === true) {
            k++;
          }
        } else {
          k++;
        }
        if (queryParamsObject?.Status.includes("Closed")) {
          if (
            filters.status.closed === true &&
            queryParamsObject?.CloseDateFrom &&
            queryParamsObject?.CloseDateTo
          ) {
            k++;
          }
        } else {
          k++;
        }
        if (queryParamsObject?.Status.includes("CommingSoon")) {
          if (filters.status.commingSoon === true) {
            k++;
          }
        } else {
          k++;
        }
        if (queryParamsObject?.Status.includes("Pending")) {
          if (filters.status.pending === true) {
            k++;
          }
        } else {
          k++;
        }
        if (k === 5) {
          setIsQStatusLoaded(true);
        }
      } else setIsQStatusLoaded(true);
    }
  }, [filters.status]);

  const [centerMapCoordLoaded, setCenterMapCoordLoaded] = useState(false);

  useEffect(() => {
    if (!centerMapCoordLoaded) {
      if (queryParamsObject?.Lat && queryParamsObject?.Lng) {
        if (filters.map.center?.lat && filters.map.center?.lng) {
          setCenterMapCoordLoaded(true);
        }
      } else setCenterMapCoordLoaded(true);
    }
  }, [filters.map.center]);

  /////////////////////////////////////

  useEffect(() => {
    if (!allQparamsDataLoaded.processed) {
      let res =
        isQShowOnlyLoaded &&
        isQPropSubTypeLoaded &&
        isQPropTypeLoaded &&
        isQPropRegionLoaded &&
        isQPropMetroLoaded &&
        isQPropCountyLoaded &&
        isQPropCityLoaded &&
        isQBathMinLoaded &&
        isQBathMaxLoaded &&
        isQLevelsMinLoaded &&
        isQLevelsMaxLoaded &&
        isQBedsMaxLoaded &&
        isQBedsMinLoaded &&
        isQSqftMinLoaded &&
        isQSqftMaxLoaded &&
        isQYearBuiltMinLoaded &&
        isQYearBuiltMaxLoaded &&
        isQGarageSpacesMinLoaded &&
        isQGarageSpacesMaxLoaded &&
        isQLotSizeAcresMinLoaded &&
        isQLotSizeAcresMaxLoaded &&
        isQAccessibilityFeaturesLoaded &&
        isQPoolFeaturesLoaded &&
        isQFireplaceLoaded &&
        isQMainLevelPrimaryLoaded &&
        isQPriceMinLoaded &&
        isQPriceMaxLoaded &&
        isQTimeFrameLoaded &&
        isQStatusLoaded &&
        centerMapCoordLoaded;
      if (res) {
        setAllQparamsDataLoaded({ processed: true });
      }
    }
  }, [
    isQShowOnlyLoaded,
    isQPropSubTypeLoaded,
    isQPropTypeLoaded,
    isQPropRegionLoaded,
    isQPropMetroLoaded,
    isQPropCountyLoaded,
    isQPropCityLoaded,
    isQBathMinLoaded,
    isQBathMaxLoaded,
    isQLevelsMinLoaded,
    isQLevelsMaxLoaded,
    isQBedsMaxLoaded,
    isQBedsMinLoaded,
    isQSqftMinLoaded,
    isQSqftMaxLoaded,
    isQYearBuiltMinLoaded,
    isQYearBuiltMaxLoaded,
    isQGarageSpacesMinLoaded,
    isQGarageSpacesMaxLoaded,
    isQLotSizeAcresMinLoaded,
    isQLotSizeAcresMaxLoaded,
    isQAccessibilityFeaturesLoaded,
    isQPoolFeaturesLoaded,
    isQFireplaceLoaded,
    isQMainLevelPrimaryLoaded,
    isQPriceMinLoaded,
    isQPriceMaxLoaded,
    isQTimeFrameLoaded,
    isQStatusLoaded,
    centerMapCoordLoaded,
  ]);

  useEffect(() => {
    if (
      allSubPropertyTypes &&
      allPropertyTypes &&
      filters?.propertyType &&
      filters?.propertySubTypeList &&
      allQparamsDataLoaded.processed
    ) {
      setAppReady(true);
    }
  }, [
    allPropertyTypes,
    allSubPropertyTypes,
    filters?.propertyType,
    filters?.propertySubTypeList,
    allQparamsDataLoaded.processed,
  ]);

  const [firstQParamsDataLoaded, setFirstQParamsDataLoaded] = useState(false);

  const [regionList, setRegionList] = useState(false);

  // useEffect(() => {
  //   console.log(filterLocationLists.data, "filterLocationLists");
  // }, [filterLocationLists.data]);

  useEffect(() => {
    if (!regionList)
      if (queryParamsObject?.Region) {
        if (filterLocationLists.data?.regions?.length > 0) {
          setRegionList(true);
        }
      } else setRegionList(true);
  }, [filterLocationLists.data.regions]);

  const [metroList, setMetroList] = useState(false);

  useEffect(() => {
    if (!metroList)
      if (queryParamsObject?.Metro) {
        if (filterLocationLists.data?.metros?.length > 0) {
          setMetroList(true);
        }
      } else setMetroList(true);
  }, [filterLocationLists.data.metros]);

  const [countyList, setCountyList] = useState(false);

  useEffect(() => {
    if (!countyList)
      if (queryParamsObject?.County) {
        if (filterLocationLists.data?.countries?.length > 0) {
          setCountyList(true);
        }
      } else setCountyList(true);
  }, [filterLocationLists.data.countries]);

  const [cityList, setCityList] = useState(false);

  useEffect(() => {
    if (!cityList) {
      if (queryParamsObject?.City) {
        if (filterLocationLists.data?.cities?.length > 0) {
          setCityList(true);
        }
      } else {
        setCityList(true);
      }
    }
  }, [filterLocationLists.data.cities]);

  useEffect(() => {
    if (firstQParamsDataLoaded) {
      getDataFromQParams(queryParamsObject, {
        allPropertyTypes: allPropertyTypes,
        allSubPropertyTypes: allSubPropertyTypes,
        filterLocationLists: filterLocationLists.data,
        postalList: postalList ? [...postalList] : [],
        setRegion: setRegion,
      });
    }
  }, [firstQParamsDataLoaded]);

  useEffect(() => {
    if (!firstQParamsDataLoaded) {
      if (
        allPropertyTypes &&
        allSubPropertyTypes &&
        regionList &&
        metroList &&
        countyList &&
        cityList &&
        isPostalList
      ) {
        setFirstQParamsDataLoaded(true);
      }
    }
  }, [
    allPropertyTypes,
    allSubPropertyTypes,
    regionList,
    metroList,
    countyList,
    cityList,
    isPostalList,
  ]);

  const setLeasePTQParam = () => {
    searchParams.set("PropertyType", "ResidentialLease");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    axios
      .get(`https://api.realtytexas.net/v1/property-type/cognitive-search`)
      .then(function (response) {
        let temp = response.data.data["property-types"]?.map((type) => {
          if (type.PropertyType === "Residential") {
            return { ...type, show: true };
          } else return { ...type, show: false };
        });

        let temp2 = response.data.data["property-type-groups"]?.map((type) => {
          if (type.PropertyType === "Residential") {
            return {
              ...type,
              show: true,
              value: type.PropertySubType,
              label: type.PropertySubTypeDisplay,
            };
          } else
            return {
              ...type,
              show: false,
              value: type.PropertySubType,
              label: type.PropertySubTypeDisplay,
            };
        });

        const currentUrl = window.location.href;
        const urlLeasingStr = currentUrl.split("?")[0].slice(-8);
        if (urlLeasingStr !== "/leasing") {
          setAllPropertyTypes(temp.sort((a, b) => a.sort - b.sort));
        } else {
          const tempLease = temp
            ?.filter(
              (type) =>
                type.PropertyType === "ResidentialLease" ||
                type.PropertyType === "CommercialLease"
            )
            ?.map((type) => {
              if (type.PropertyType === "ResidentialLease") {
                return { ...type, show: true };
              } else if (type.PropertyType === "CommercialLease") {
                return { ...type, show: false };
              }
            });

          setAllPropertyTypes(tempLease.sort((a, b) => a.sort - b.sort));
          setLeasePTQParam();
        }
        setAllSubPropertyTypes(
          temp2.sort((a, b) =>
            a.PropertySubType.localeCompare(b.PropertySubType)
          )
        );
      })
      .catch(function (error) {
        console.log(error, "POST ERROR");
      });
  }, []);

  useEffect(() => {
    if (mapCoordinates.data) {
      let footer = document.querySelector("#footer");
      let reactApp = document.querySelector("#filters-page-block-wrapper");
      if (reactApp) reactApp.append(footer);
    }
  }, [mapCoordinates.data]);

  useEffect(() => {
    dispatch(resetMapCoordinates());
  }, [isMap.data]);

  //FILTER LOCATION

  const filterLocationRegionsLocal = () => {
    dispatch(getFilterLocationRegionsAction());
  };

  const filterLocationMetrosLocal = (initValue = false) => {
    let regionCodes = null;
    if (region?.region || initValue) {
      regionCodes = region?.region;
      dispatch(getFilterLocationMetrosAction(regionCodes));
    } else dispatch(getFilterLocationMetrosAction());
  };

  const filterLocationCountiesLocal = () => {
    let metroCodes = null;
    if (metro?.Metro) {
      //metroCodes = metro?.subdomain;
      metroCodes = metro?.Metro;
      dispatch(getFilterLocationCountries(metroCodes));
    } else dispatch(getFilterLocationCountries());
  };

  const setCitiesOrPostalsData = (page, name, top = 1000) => {
    let data = JSON.stringify({
      top: top,
      filters: [],
      ["elastic-search"]: true,
      page: page,
      // page: 4,
      orderby: name === "cities" ? null : "ZipCode",
    });
    let url = `https://api.realtytexas.net/v1/${name}/cognitive-search`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then(function (res) {
        let arr;
        if (name === "cities") {
          arr = [...res.data.data.cities];
        } else arr = [...res.data.data.zipcodes];
        const temp = arr.map((c) => {
          if (name === "cities") {
            c.label = c["City"];
            c.value = c["City"];
          } else {
            c.label = c["ZipCode"];
            c.value = c["ZipCode"];
          }
          return c;
        });
        if (name === "cities") {
          setTwCities([...twCities, ...temp]);
        } else {
          setTwPostals([...twPostals, ...temp]);
        }
      })
      .catch(function (error) {
        console.log(error, "POST ERROR");
      });
  };

  const filterLocationPostal = (searchValue) => {
    let filt;
    if (city[0] && city[0] !== "") {
      const temp = city.map((c) => c.value);
      filt = [{ column: "City", operator: "in", value: [...temp] }];
    } else filt = [];
    dispatch(getFilterLocationPostal(filt, searchValue));
  };

  // useEffect(() => {
  //   setCitiesOrPostalsData(twCitiesPage, "cities");
  // }, [twCitiesPage]);

  // useEffect(() => {
  //   if (!queryParamsObject?.PostalCode) {
  //     setCitiesOrPostalsData(twPostalsPage, "zip-codes", 100);
  //   }
  // }, [twPostalsPage]);

  useEffect(() => {
    dispatch(updateIsOpenNarrowFilters(false));
  }, [isMap.data]);

  const [state, setState] = useState({
    switchPosition:
      queryParamsObject?.View && queryParamsObject?.View === "list"
        ? "left"
        : queryParamsObject?.View && queryParamsObject?.View === "map"
        ? "center"
        : queryParamsObject?.View && queryParamsObject?.View === "both"
        ? "right"
        : window.innerWidth > 1199
        ? "right"
        : "left",
    animation: null,
  });

  const getSwitchAnimation = (value) => {
    const { switchPosition } = state;
    let animation = null;
    if (value === "center" && switchPosition === "left") {
      animation = "left-to-center";
    } else if (value === "right" && switchPosition === "center") {
      animation = "center-to-right";
    } else if (value === "center" && switchPosition === "right") {
      animation = "right-to-center";
    } else if (value === "left" && switchPosition === "center") {
      animation = "center-to-left";
    } else if (value === "right" && switchPosition === "left") {
      animation = "left-to-right";
    } else if (value === "left" && switchPosition === "right") {
      animation = "right-to-left";
    }
    // onChange(value);
    setState({ switchPosition: value, animation });
  };

  const [isAllOurListings, setIsAllOurListings] = useState(null);
  const visibleHouseCards = useMemo(() => {
    return data?.listings;
  }, [data?.listings, isApply]);

  useEffect(() => {
    if (isMap.data !== null) {
      dispatch(houseCardsMapSlice.actions.houseCardsMapFetchingSuccess([]));
    }
  }, [isMap.data]);

  return (
    <>
      <div
        className="home-wrapper"
        style={{
          height: data !== null ? "100%" : "100vh",
          // width: data !== null ? "100%" : "100svw",
        }}
      >
        {/* &&
          filters.homeFeatures.setMinMaxPrice.min !== null &&
          filters.homeFeatures.setMinMaxPrice.max !== null &&  */}
        {appReady && (
          /* <div style={{ display: data !== null ? "block" : "none" }}> */
          <MainHeaderLayer
            state={state}
            setState={setState}
            getSwitchAnimation={getSwitchAnimation}
            isAllOurListings={isAllOurListings}
            setIsAllOurListings={setIsAllOurListings}
            sortingValue={sortingValue}
            setSortingValue={setSortingValue}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            dataInRectPage={dataInRectPage}
            setDataInRectPage={setDataInRectPage}
            isDraw={isDraw}
            isMultyDraw={isMultyDraw}
            dataInPolyline={dataInPolyline}
            setDataInPolyline={setDataInPolyline}
            dataInMultyPolygones={dataInMultyPolygones}
            setDataInMultyPolygones={setDataInMultyPolygones}
            drawCoordinates={drawCoordinates}
            setDrawCoordinates={setDrawCoordinates}
            visibleHouseCards={visibleHouseCards}
            city={city}
            setCity={setCity}
            foundCity={foundCity}
            setFoundCity={setFoundCity}
            country={country}
            setCountry={setCountry}
            twCities={twCities}
            setTwCities={setTwCities}
            twCitiesPage={twCitiesPage}
            setTwCitiesPage={setTwCitiesPage}
            applyHandler={applyHandler}
            filterLocationCounties={filterLocationCounties}
            foundCountry={foundCountry}
            setFoundCountry={setFoundCountry}
            metro={metro}
            twPostals={twPostals}
            setTwPostals={setTwPostals}
            postal={postal}
            setPostal={setPostal}
            foundPostal={foundPostal}
            setFoundPostal={setFoundPostal}
            setTwPostalsPage={setTwPostalsPage}
            allPropertyTypes={allPropertyTypes}
            setAllPropertyTypes={setAllPropertyTypes}
            setFilters={setFilters}
            filters={filters}
            residentialSubType={residentialSubType}
            setResidentialSubType={setResidentialSubType}
            foundResidentialSubType={foundResidentialSubType}
            setFoundResidentialSubType={setFoundResidentialSubType}
            allSubPropertyTypes={allSubPropertyTypes}
            //
            bathMax={bathMax}
            setBathMax={setBathMax}
            bathMin={bathMin}
            setBathMin={setBathMin}
            levelsMin={levelsMin}
            setLevelsMin={setLevelsMin}
            levelsMax={levelsMax}
            setLevelsMax={setLevelsMax}
            bedsMin={bedsMin}
            setBedsMin={setBedsMin}
            bedsMax={bedsMax}
            setBedsMax={setBedsMax}
            sqftMin={sqftMin}
            setSqftMin={setSqftMin}
            sqftMax={sqftMax}
            setSqftMax={setSqftMax}
            garagesSpacesMin={garagesSpacesMin}
            setGaragesSpacesMin={setGaragesSpacesMin}
            garagesSpacesMax={garagesSpacesMax}
            setGaragesSpacesMax={setGaragesSpacesMax}
            //
            isOurListings={isOurListings}
            setIsOurListings={setIsOurListings}
            mapZoom={mapZoom}
            setMapZoom={setMapZoom}
          >
            <FiltersPageBlock
              container={container}
              isPicturesFeature={isPicturesFeature}
              allPropertyTypes={allPropertyTypes}
              allSubPropertyTypes={allSubPropertyTypes}
              setAllPropertyTypes={setAllPropertyTypes}
              setCurrentPropertyType={setCurrentPropertyType}
              filters={filters}
              setFilters={setFilters}
              residentialSubType={residentialSubType}
              setResidentialSubType={setResidentialSubType}
              foundResidentialSubType={foundResidentialSubType}
              setFoundResidentialSubType={setFoundResidentialSubType}
              sortingValue={sortingValue}
              setSortingValue={setSortingValue}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isApply={isApply}
              setIsApply={setIsApply}
              applyHandler={applyHandler}
              isOpenHouses={isOpenHouses}
              setIsOpenHouses={setIsOpenHouses}
              isOurListings={isOurListings}
              setIsOurListings={setIsOurListings}
              MLS_address={MLS_address}
              setMLS_Address={setMLS_Address}
              MLS_addressRes={MLS_addressRes}
              setMLS_AddressRes={setMLS_AddressRes}
              foundMLS_address={foundMLS_address}
              setFoundMLS_address={setFoundMLS_address}
              region={region}
              setRegion={setRegion}
              metro={metro}
              setMetro={setMetro}
              country={country}
              setCountry={setCountry}
              city={city}
              setCity={setCity}
              postal={postal}
              setPostal={setPostal}
              foundRegions={foundRegions}
              setFoundRegions={setFoundRegions}
              foundMetro={foundMetro}
              setFoundMetro={setFoundMetro}
              foundCountry={foundCountry}
              setFoundCountry={setFoundCountry}
              foundCity={foundCity}
              setFoundCity={setFoundCity}
              foundPostal={foundPostal}
              setFoundPostal={setFoundPostal}
              sqftMin={sqftMin}
              setSqftMin={setSqftMin}
              yearBuiltMin={yearBuiltMin}
              setYearBuiltMin={setYearBuiltMin}
              garagesSpacesMin={garagesSpacesMin}
              setGaragesSpacesMin={setGaragesSpacesMin}
              bathMin={bathMin}
              setBathMin={setBathMin}
              levelsMin={levelsMin}
              setLevelsMin={setLevelsMin}
              bedsMin={bedsMin}
              setBedsMin={setBedsMin}
              lotSizeAcresMin={lotSizeAcresMin}
              setLotSizeAcresMin={setLotSizeAcresMin}
              sqftMax={sqftMax}
              setSqftMax={setSqftMax}
              yearBuiltMax={yearBuiltMax}
              setYearBuiltMax={setYearBuiltMax}
              garagesSpacesMax={garagesSpacesMax}
              setGaragesSpacesMax={setGaragesSpacesMax}
              bathMax={bathMax}
              setBathMax={setBathMax}
              levelsMax={levelsMax}
              setLevelsMax={setLevelsMax}
              bedsMax={bedsMax}
              setBedsMax={setBedsMax}
              lotSizeAcresMax={lotSizeAcresMax}
              setLotSizeAcresMax={setLotSizeAcresMax}
              isPrimaryBedOnMain={isPrimaryBedOnMain}
              setIsPrimaryBedOnMain={setIsPrimaryBedOnMain}
              isFireplace={isFireplace}
              setIsFireplace={setIsFireplace}
              isAccessibilityFeatures={isAccessibilityFeatures}
              setIsAccessibilityFeatures={setIsAccessibilityFeatures}
              isPoolFeatures={isPoolFeatures}
              setIsPoolFeatures={setIsPoolFeatures}
              minMaxPriceMin={minMaxPriceMin}
              setMinMaxPriceMin={setMinMaxPriceMin}
              minMaxPriceMax={minMaxPriceMax}
              setMinMaxPriceMax={setMinMaxPriceMax}
              priceValue={priceValue}
              setPriceValue={setPriceValue}
              oneDay={oneDay}
              setOneDay={setOneDay}
              sevenDay={sevenDay}
              setSevenDay={setSevenDay}
              thirtyDay={thirtyDay}
              setThirtyDay={setThirtyDay}
              isCommingSoon={isCommingSoon}
              setIsCommingSoon={setIsCommingSoon}
              isActive={isActive}
              setIsActive={setIsActive}
              isActiveUnderContract={isActiveUnderContract}
              setIsActiveUnderContract={setIsActiveUnderContract}
              isPending={isPending}
              setIsPending={setIsPending}
              isClosed={isClosed}
              setIsClosed={setIsClosed}
              resetHandler={resetHandler}
              mapZoom={mapZoom}
              setMapZoom={setMapZoom}
              currentCenter={currentCenter}
              setCurrentCenter={setCurrentCenter}
              polygonData={polygonData}
              setPolygonData={setPolygonData}
              polygonsData={polygonsData}
              setPolygonsData={setPolygonsData}
              currentPolygon={currentPolygon}
              setCurrentPolygon={setCurrentPolygon}
              currentPolygons={currentPolygons}
              setCurrentPolygons={setCurrentPolygons}
              subdomainData={subdomainData}
              isOpenHousesUrl={isOpenHousesUrl}
              dataInRectPage={dataInRectPage}
              setDataInRectPage={setDataInRectPage}
              currentLayersPolygon={currentLayersPolygon}
              setCurrentLayersPolygon={setCurrentLayersPolygon}
              currentLayersPolygons={currentLayersPolygons}
              setCurrentLayersPolygons={setCurrentLayersPolygons}
              isDraw={isDraw}
              setIsDraw={setIsDraw}
              dataInPolyline={dataInPolyline}
              setDataInPolyline={setDataInPolyline}
              dataInMultyPolygones={dataInMultyPolygones}
              setDataInMultyPolygones={setDataInMultyPolygones}
              drawCoordinates={drawCoordinates}
              setDrawCoordinates={setDrawCoordinates}
              setIsMultyDraw={setIsMultyDraw}
              isMultyDraw={isMultyDraw}
              isFirstLoadedMinMaxQPar={isFirstLoadedMinMaxQPar}
              setIsFirstLoadedMinMaxQPar={setIsFirstLoadedMinMaxQPar}
              queryParamsObject={queryParamsObject}
              filterLocationRegions={filterLocationRegionsLocal}
              filterLocationMetros={filterLocationMetrosLocal}
              filterLocationCounties={filterLocationCountiesLocal}
              twCities={twCities}
              setTwCities={setTwCities}
              twPostals={twPostals}
              setTwPostals={setTwPostals}
              twCitiesPage={twCitiesPage}
              setTwCitiesPage={setTwCitiesPage}
              twPostalsPage={twPostalsPage}
              setTwPostalsPage={setTwPostalsPage}
              currentMap={currentMap}
              setCurrentMap={setCurrentMap}
              currentMaps={currentMaps}
              setCurrentMaps={setCurrentMaps}
              priceInfoLoading={priceInfoLoading}
              setPriceInfoLoading={setPriceInfoLoading}
              currentCenterCoord={currentCenterCoord}
              setCurrentCenterCoord={setCurrentCenterCoord}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
              state={state}
              setState={setState}
              getSwitchAnimation={getSwitchAnimation}
              isAllOurListings={isAllOurListings}
              setIsAllOurListings={setIsAllOurListings}
              visibleHouseCards={visibleHouseCards}
            />
          </MainHeaderLayer>
        )}
        {/* <div id="new-footer"></div> */}
        {!appReady && (
          <div
            className="home-wrapper-spiner-body"
            style={{
              display: isLoading === true || data === null ? "block" : "none",
            }}
          >
            <Spiner />
          </div>
        )}
        {!appReady && (
          <div
            className="home-wrapper-spiner-gray-wrapper"
            style={{ display: isLoading === true ? "block" : "none" }}
          />
        )}
      </div>
    </>
  );
};

export default Filters;
