/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { colors } from "../../../constants/colors.js";
import {
  CheckBoxActiveIcon,
  CheckBoxIcon,
  CollapseIcon,
  ExpandIcon,
} from "../../../constants/icons.jsx";
import "./showOnly.css";

const ShowOnly = ({
  isOpenHouses,
  setIsOpenHouses,
  isOurListings,
  setIsOurListings,
  isOpenHousesUrl,
}) => {
  const ourListingsHandler = () => {
    setIsOurListings(!isOurListings);
  };

  const openHousesHandler = () => {
    const currentUrl = window.location.href;
    if (
      isOpenHousesUrl ||
      currentUrl.includes("/open-houses") ||
      currentUrl.includes("/buy") ||
      currentUrl.includes("/listings")
    ) {
      setIsOpenHouses(!isOpenHouses);
    }
  };

  const [isExpanded, setIsExpanded] = useState(true);

  const onTittleHandle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      id="filters-block-filter-show-only"
      className={isExpanded ? "" : "collapsed-filter-show-only"}
    >
      <div id="filters-block-filter-show-only-title" onClick={onTittleHandle}>
        <div>
          <h4>Show Only</h4>
        </div>
        <div className="filters-block-filter-show-only-title-icon">
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </div>
      </div>
      <div
        id="filters-block-filter-show-only-content"
        className={
          isExpanded
            ? "filters-block-filter-show-only-content-wrapper"
            : "filters-block-filter-show-only-content collapsed-filter-show-only-content"
        }
      >
        <div className="filters-block-filter-show-only-content-checkbox">
          <div
            id="filters-block-filter-show-only-content-checkbox-1"
            onClick={ourListingsHandler}
          >
            {isOurListings ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
          </div>
          <div>
            <p
              style={{
                color: isOurListings ? colors.blue : colors.secondBg,
              }}
            >
              Our Listings
            </p>
          </div>
        </div>
        <div className="filters-block-filter-show-only-content-checkbox">
          <div
            id="filters-block-filter-show-only-content-checkbox-2"
            onClick={openHousesHandler}
          >
            {isOpenHouses ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
          </div>
          <div>
            <p
              style={{
                color: isOpenHouses ? colors.blue : colors.secondBg,
              }}
            >
              Open Houses
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowOnly;
