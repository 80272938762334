import React, { useState, useEffect, useRef } from "react";
import { useOutsideClick } from "../../hooks";
import ShareIconComponent from "../ShareIconComponent/ShareIconComponent.jsx";
import { ShareIcon } from "../../constants/icons.jsx";
import { colors } from "../../constants/colors";
import "./ShareIconList.css";
import ClickOutside from "../../ui/clickOutside/clickoutSideComponent";

const ShareIconList = ({ data, isHeardIconVisible }) => {
  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleShareDropDown = () => {
    closeAllShareLists();
    setIsDivVisible(!isDivVisible);
  };

  const listShareItem = () => {
    setIsDivVisible(!isDivVisible);
  };

  const handleClickOutside = (event) => {
    setIsDivVisible(false);
  };

  const closeAllShareLists = () => {
    const allDivs = document.getElementsByClassName("rt-share-dropdown");
    for (let index = 0; index < allDivs.length; index++) {
      const div = allDivs[index];
      div.style.display = "none";
    }
  };

  const propertyUrl = `https://${window.location.host}/${data?.subdomain}${data?.url}`;
  return (
    <div
      className={"rt-share-container"}
      onClick={(event) => {
        event.stopPropagation();
        isHeardIconVisible(false);
      }}
    >
      <button className="rt-share-button" onClick={toggleShareDropDown} aria-label="Share">
        <ShareIcon color={colors.bodyText} />
      </button>
      {isDivVisible && (
        <ClickOutside onClick={handleClickOutside}>
          <div className="rt-share-dropdown rt-dropdown-content">
            <ShareIconComponent
              type="sms"
              url={propertyUrl}
              onClick={listShareItem}
            />
            <ShareIconComponent
              type="whatsapp"
              url={propertyUrl}
              onClick={listShareItem}
            />
            <ShareIconComponent
              type="email"
              url={propertyUrl}
              onClick={listShareItem}
            />
          </div>
        </ClickOutside>
      )}
    </div>
  );
};

export default ShareIconList;
