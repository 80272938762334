import {
    createSlice
} from "@reduxjs/toolkit";

const initialState = {
    data: {
        quickSearch: [],
        MLS_address: [],
        regions: [],
        metros: [],
        cities: [],
        countries: [],
        postal: [],
    },
    isLoading: false,
    error: {
        quickSearch: "",
        MLS_address: "",
        regions: "",
        metros: "",
        cities: "",
        countries: "",
        postal: "",
    },
};

export const filterLocationListsSlice = createSlice({
    name: "houseCards",
    initialState,
    reducers: {
        filterLocationFetching(state) {
            state.isLoading = true;
            state.error = "";
        },

        getFilterLocationMLS_addressSuccess(state, actions) {
            state.isLoading = false;
            state.error = "";
            state.data = {
                ...state.data,
                quickSearch: actions.payload
            };
        },
        filterLocationMLS_addressFetchingError(state, actions) {
            state.isLoading = false;
            state.error = {
                ...state.error,
                quickSearch: actions.payload
            };
        },

        getFilterLocationRegionsSuccess(state, actions) {
            state.isLoading = false;
            state.error = "";
            state.data = {
                ...state.data,
                regions: actions.payload
            };
        },
        filterLocationRegionsFetchingError(state, actions) {
            state.isLoading = false;
            state.error = {
                ...state.error,
                regions: actions.payload
            };
        },
        getFilterLocationMetrosSuccess(state, actions) {
            state.isLoading = false;
            state.error = "";
            state.data = {
                ...state.data,
                metros: actions.payload
            };
        },
        filterLocationMetrosFetchingError(state, actions) {
            state.isLoading = false;
            state.error = {
                ...state.error,
                metros: actions.payload
            };
        },
        getFilterLocationCountriesSuccess(state, actions) {
            state.isLoading = false;
            state.error = "";
            state.data = {
                ...state.data,
                countries: actions.payload
            };
        },
        filterLocationCountriesFetchingError(state, actions) {
            state.isLoading = false;
            state.error = {
                ...state.error,
                countries: actions.payload
            };
        },
        getFilterLocationCitiesSuccess(state, actions) {
            state.isLoading = false;
            state.error = "";
            state.data = {
                ...state.data,
                cities: actions.payload
            };
        },
        filterLocationCitiesFetchingError(state, actions) {
            state.isLoading = false;
            state.error = {
                ...state.error,
                cities: actions.payload
            };
        },
        getFilterLocationPostalSuccess(state, actions) {
            state.isLoading = false;
            state.error = "";
            state.data = {
                ...state.data,
                postal: actions.payload
            };
        },
        filterLocationPostalFetchingError(state, actions) {
            state.isLoading = false;
            state.error = {
                ...state.error,
                postal: actions.payload
            };
        },
    },
});

export default filterLocationListsSlice.reducer;