/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HeardTextarea from "../HeardTextarea/HeardTextarea";
import NoteContentPopUp from "../NoteContentPopUp/NoteContentPopUp";
import axios from "axios";

import "./notesBox.css";
import { isUserLogin } from "../../constants/methods";

const NotesBox = ({
  currentPage,
  isVisible,
  setIsVisible,
  setIsNoteIconShow,
  item,
  narrowView = false,
  isNoteIconShow,
}) => {
  const [notesData, setNotesData] = useState(null);
  const [notesLoading, setNotesLoading] = useState(null);
  const [notesError, setNotesError] = useState(null);

  const isUserLogined = isUserLogin();

  useEffect(() => {
    if (isUserLogined && isNoteIconShow) {
      getNotes();
    }
  }, [currentPage, isNoteIconShow]);

  const getNotes = () => {
    if (isUserLogined) {
      setNotesLoading(true);
      axios
        .get(
          `https://api.realtytexas.net/v1/clients/${isUserLogined}/listings/${item?.ListingKeyNumeric}/notes`
        )
        .then(function (response) {
          setNotesLoading(false);
          if (response?.data?.length > 0) {
            setNotesData(response.data);
          } else setNotesData(null);
        })
        .catch(function (error) {
          setNotesLoading(false);
          setNotesError(error.response.status);
          console.log(error, "get note error");
        });
    }
  };

  return (
    <div
      className={
        narrowView
          ? isVisible
            ? "filters-page-house-card-notes-box-narrow"
            : "filters-page-house-card-notes-box-disabled"
          : isVisible
          ? "filters-page-house-card-notes-box"
          : "filters-page-house-card-notes-box-disabled"
      }
    >
      <NoteContentPopUp
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        noteData={notesData}
        notesLoading={notesLoading}
        narrowView={narrowView}
      />
      <HeardTextarea
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        setIsNoteIconShow={setIsNoteIconShow}
        item={item}
        getNotes={getNotes}
        narrowView={narrowView}
      />
    </div>
  );
};

export default NotesBox;
