/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  CheckBoxActiveIcon,
  CheckBoxIcon,
  CollapseIcon,
  ExpandIcon,
} from "../../../constants/icons.jsx";
import "./propertyType.css";
import "./responsive.css";

const PropertyType = ({
  filters,
  setFilters,
  isReset,
  allPropertyTypes,
  allSubPropertyTypes,
  setAllPropertyTypes,
  residentialSubType,
  setResidentialSubType,
  onTittleHandle,
  isExpanded,
}) => {
  const propertiesHandler = (currentType) => {
    if (residentialSubType) {
      setResidentialSubType(null);
      setFilters({
        ...filters,
        propertySubType: null,
      });
    }
    const temp = allPropertyTypes.map((type) => {
      if (type?.PropertyType === currentType?.PropertyType) {
        return { ...type, show: true };
      } else return { ...type, show: false };
    });

    setAllPropertyTypes(temp);
  };

  return (
    <div id="filters-block-filter-PropertyType">
      <div
        id="filters-block-filter-PropertyType-title"
        onClick={onTittleHandle}
      >
        <div>
          <h4>Property Types</h4>
        </div>
        <div className="filters-block-filter-PropertyType-title-icon">
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </div>
      </div>
      <div
        className={
          isExpanded
            ? "filters-block-filter-PropertyType-content-wrapper"
            : "filters-block-filter-PropertyType-content-wrapper collapsed-PropertyType-content"
        }
      >
        {allPropertyTypes &&
          allPropertyTypes.map((propertyType, i) => {
            return (
              <div
                className="filters-block-filter-PropertyType-content"
                key={i}
              >
                <div className="filters-block-filter-PropertyType-content-checkbox">
                  <div
                    className="filters-block-filter-PropertyType-content-checkbox-0"
                    onClick={() => propertiesHandler(propertyType)}
                  >
                    {propertyType?.show ? (
                      <CheckBoxActiveIcon />
                    ) : (
                      <CheckBoxIcon />
                    )}
                  </div>
                  <div>
                    <p
                      style={{ color: propertyType?.show ? "var(--blue)" : "" }}
                    >
                      {propertyType?.PropertyTypeDisplay}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PropertyType;
