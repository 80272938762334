import { houseCardsMapSlice } from "../reducers/houseCardsMapSlice";
import axios from "axios";
import { isUserLogin } from "../../constants/methods";

export const getAllBothViewMapHouseCards =
  (
    obj,
    filters = null,
    orderby = null,
    page = 1,
    setIsLoadedDataDisplaying = false
  ) =>
  async (dispatch) => {
    dispatch(houseCardsMapSlice.actions.houseCardsMapFetching());
    const isUserLogined = isUserLogin();
    // console.log("getAllBothViewMapHouseCards GET LISTINGS DATA");
    const minPrice = filters.filter(
      (item) => item.column === "ListPrice" && item.operator === "min"
    )[0];
    const maxPrice = filters.filter(
      (item) => item.column === "ListPrice" && item.operator === "max"
    )[0];
    let k = filters.filter((f) => f.column === "CloseDate");
    // t = k?.value?.filter((v) => v === "Closed")[0];
    if (k?.length > 0) {
      axios
        .post(`https://api.realtytexas.net/v2/listings/sold`, {
          top: 30,
          count: "true",
          maxPrice: maxPrice ? maxPrice.value : 50000000,
          minPrice: minPrice ? minPrice.value : 0,
          filters: [
            ...filters,
            // {
            //   column: "rtlist",
            //   operator: "eq",
            //   // value: "true",
            // },
            {
              column: "geo",
              operator: "eq",
              value: obj,
            },
          ],
          select: ["ListingKeyNumeric", "PropertyType"],
          page: page,
          // page: 3336,
          orderby: "rtlist desc, ListingContractDate desc, ListPrice desc",
        })
        .then(function (response) {
          dispatch(
            houseCardsMapSlice.actions.houseCardsMapFetchingSuccess(
              response.data.data
            )
          );
          if (setIsLoadedDataDisplaying) {
            setIsLoadedDataDisplaying(false);
          }
        })
        .catch(function (error) {
          console.log(error, "all cards error");
        });
    } else {
      axios
        .post(`https://api.realtytexas.net/v2/listings`, {
          top: 100,
          count: "true",
          maxPrice: maxPrice ? maxPrice.value : 50000000,
          minPrice: minPrice ? minPrice.value : 0,
          filters: filters
            ? [
                ...filters,
                {
                  column: "geo",
                  operator: "eq",
                  value: obj,
                },
              ]
            : null,
          select: null,
          page: page,
          // page: 3334,
          orderby: orderby
            ? `rtlist desc,${orderby}`
            : "rtlist desc, ListingContractDate desc, ListPrice desc",
          client_hash_id: isUserLogined ? isUserLogined : null,
        })
        .then(function (res) {
          dispatch(
            houseCardsMapSlice.actions.houseCardsMapFetchingSuccess(
              res.data.data
            )
          );
          if (setIsLoadedDataDisplaying) {
            setIsLoadedDataDisplaying(false);
          }
        })
        .catch(function (error) {
          console.log(error, "error");
        });
    }
  };

// const getDataInRect = (obj, filters = null, orderby = null, page = 1) => {
//   if (obj?.EndPoint && data?.listings[0]) {
//     console.log("getDataInRect GET LISTINGS DATA");
//   }
// };
