import React, { useEffect } from "react";
import { colors } from "../../constants/colors";
import { CloseIcon } from "../../constants/icons";
import { ClickOutside } from "../../ui";
import "./tooltipMessage.css";

const TooltipMessage = ({
  header,
  content,
  onCloseClick = false,
  headerColor = false,
  contentColor = false,
  isCloseBtn = false,
  bottom = false,
}) => {
  const handleClickOutside = () => {
    if (onCloseClick) onCloseClick();
  };

  return (
    <div className="tooltip-message-wrapper">
      {isCloseBtn && (
        <div className="tooltip-message-close" onClick={handleClickOutside}>
          <CloseIcon width={24} height={24} />
        </div>
      )}
      <div
        className={
          bottom
            ? "tooltip-message-triangle-bottom"
            : "tooltip-message-triangle"
        }
      ></div>
      <div className="tooltip-message-content">
        {header && (
          <div className="tooltip-message-content-header">
            <span
              style={{ color: headerColor ? headerColor : colors.bodyText }}
            >
              {header}
            </span>
          </div>
        )}
        {content && (
          <div className="tooltip-message-content-text">
            <span
              style={{ color: contentColor ? contentColor : colors.bodyText }}
            >
              {content}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TooltipMessage;
