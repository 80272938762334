/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "../../constants/colors";
import { CloseIcon, DrawCursorIcon } from "../../constants/icons";
import { ClickOutside } from "../../ui";
import "./drawOnMap.css";

const DrawOnMap = ({
  setIsDraw,
  isDraw,
  setIsMultyDraw,
  isMultyDraw,
  isAllMultyDrawApply,
  setIsAllMultyDrawApply,
  allMultyDrawCoordinates,
  multyDrawing,
  screenSize,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [counter, setCounter] = useState(false);
  // const [wasMultyDrawing, setWasMultyDrawing] = useState(false);

  // useEffect(() => {
  //   if (isMultyDraw && multyDrawing && !wasMultyDrawing) {
  //     setWasMultyDrawing(true);
  //   }
  // }, [multyDrawing]);

  const handlePoligonOnClick = () => {
    setIsDraw(true);
  };

  const handleMultyPoligonsOnClick = () => {
    setIsMultyDraw(true);
  };

  const handleOnClick = () => {
    if (!isDraw && !isMultyDraw) setIsOpen(true);
    if (isDraw) setIsDraw(false);
    if (isMultyDraw) {
      // if (counter || !wasMultyDrawing) {
      if (counter) {
        setIsMultyDraw(false);
        setCounter(false);
        setIsAllMultyDrawApply(false);
        // setWasMultyDrawing(false)
      } else {
        setCounter(true);
        setIsAllMultyDrawApply(true);
      }
    }
  };

  const handleOnClickOutside = () => {
    setIsOpen(false);
  };

  const isMap = useSelector((state) => state.isMap);

  useEffect(() => {
    // if (isMultyDraw) {
    // if (isMap.data === false) {
    setIsMultyDraw(null);
    setIsDraw(null);
    // } else {
    //   setIsMultyDraw(false);
    //   setIsDraw(false);
    // }
    setCounter(false);
    setIsAllMultyDrawApply(false);
    // }
  }, [isMap.data]);

  return (
    <div
      id={
        isMap.data === null && screenSize[0] <= 1500
          ? // ((screenSize[0] <= 1080 && screenSize[0] > 800) ||
            //   (screenSize[0] <= 1380 && screenSize[0] >= 1280))
            "map-drawOnMap-wrapper-both"
          : "map-drawOnMap-wrapper"
      }
      className={
        isDraw || isMultyDraw
          ? "map-drawOnMap-wrapper-active"
          : "map-drawOnMap-wrapper-notActive"
      }
    >
      <ClickOutside onClick={handleOnClickOutside}>
        <div className="map-drawOnMap-wrapper-title" onClick={handleOnClick}>
          <div className="map-drawOnMap-wrapper-title-draw">
            {!counter && isMultyDraw && allMultyDrawCoordinates?.length > 0
              ? "Apply"
              : "Draw"}
          </div>
          {isDraw ||
          (isMultyDraw && allMultyDrawCoordinates?.length === 0) ||
          (counter && isMultyDraw) ? (
            <div className="map-drawOnMap-wrapper-closeIcon-wrapper">
              <CloseIcon
                width={14}
                height={14}
                color={isDraw || isMultyDraw ? colors.mainBg : colors.white}
              />
            </div>
          ) : (
            <div className="map-drawOnMap-wrapper-closeIcon-wrapper-cursor-Icon">
              <DrawCursorIcon />
            </div>
          )}
        </div>
      </ClickOutside>
      <div
        className={
          isOpen
            ? "map-drawOnMap-wrapper-list"
            : "map-drawOnMap-wrapper-list-disable"
        }
      >
        <div
          className="map-drawOnMap-wrapper-list-li"
          onClick={handlePoligonOnClick}
        >
          <p>Single Map Drawing</p>
        </div>
        <div className="map-drawOnMap-wrapper-list-li-separator"></div>
        <div
          className="map-drawOnMap-wrapper-list-li"
          onClick={handleMultyPoligonsOnClick}
        >
          <p>Multiple Map Drawings</p>
        </div>
      </div>
    </div>
  );
};

export default DrawOnMap;
