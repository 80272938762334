import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  isLoading: false,
  error: "",
};

export const houseCardsSlice = createSlice({
  name: "houseCards",
  initialState,
  reducers: {
    houseCardsFetching(state) {
      state.isLoading = true;
      state.error = "";
    },
    setIsLoading(state, actions) {
      state.isLoading = actions.payload;
    },
    houseCardsFetchingSuccess(state, actions) {
      state.isLoading = false;
      state.error = "";
      state.data = actions.payload;
    },
    houseCardsFetchingError(state, actions) {
      state.isLoading = false;
      state.error = actions.payload;
    },
  },
});

export default houseCardsSlice.reducer;
