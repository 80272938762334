import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: false,
};

export const isMapSlice = createSlice({
  name: "isMap",
  initialState,
  reducers: {
    setIsMap(state, actions) {
      state.data = actions.payload;
    },
  },
});

export default isMapSlice.reducer;
