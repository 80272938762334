/* eslint-disable no-const-assign */
/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import "./heardTextarea.css";
import axios from "axios";
import { isUserLogin } from "../../constants/methods";

const HeardTextarea = ({
  isVisible,
  item,
  setIsNoteIconShow,
  getNotes,
  narrowView = false,
}) => {
  const [postContent, setPostContent] = useState("");

  const listings = { id: `${item.ListingKeyNumeric.toString()}`, value: "4" };
  const isUserLogined = isUserLogin();

  const setPostAction = (val, note = undefined) => {
    if (isUserLogined) {
      let postData = {
        action: val,
      };
      if (note !== undefined && note !== null && note !== "") {
        postData.note = note;
      }
      axios
        .post(
          `https://api.realtytexas.net/v1/clients/${isUserLogined}/listings/${item?.ListingKeyNumeric}/action`,
          postData
        )
        .then(function (response) {
          getNotes();
        })
        .catch(function (error) {
          console.log(error, "post error");
        });
    }
  };

  const setItemToLocStorage = () => {
    if (!isUserLogined) {
      const data = JSON.parse(localStorage.getItem("listingInteractions"));
      if (data !== null) {
        const filtered = data?.listings?.filter((data, i) => {
          return data.id != listings.id;
        });
        localStorage.setItem(
          "listingInteractions",
          JSON.stringify({
            userId: isUserLogined ? isUserLogined : "undefined",
            listings: [...filtered, listings],
          })
        );
      } else
        localStorage.setItem(
          "listingInteractions",
          JSON.stringify({
            userId: isUserLogined ? isUserLogined : "undefined",
            listings: [listings],
          })
        );
    }
  };

  const acceptBtnHandler = () => {
    setPostContent("");
    setIsNoteIconShow(true);
    setItemToLocStorage();
    setPostAction(-1, postContent);
  };

  return (
    <div
      className={
        narrowView
          ? isVisible
            ? "filters-page-house-card-note-textarea-narrow"
            : "filters-page-house-card-note-textarea-disabled"
          : isVisible
          ? "filters-page-house-card-note-textarea"
          : "filters-page-house-card-note-textarea-disabled"
      }
    >
      <textarea
        maxLength={255}
        value={postContent}
        placeholder="Please Type Your Message"
        onChange={(e) => setPostContent(e.target.value)}
      />
      <div className="filters-page-house-card-note-textarea-buttons-box">
        <div
          className="filters-page-house-card-note-textarea-button-2"
          onClick={acceptBtnHandler}
        >
          Post
        </div>
      </div>
    </div>
  );
};

export default HeardTextarea;
