/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { colors } from "../../constants/colors";
import { CloseIcon } from "../../constants/icons";
import "./alertNote.css";

const AlertNote = ({
  width = "250px",
  isVisible,
  setIsVisible,
  text = "You must first tag this listing as a discard, favorite, or possibility before you can add a note.",
}) => {
  const closeIconHandle = () => {
    setIsVisible(false);
  };
  return (
    <div
      className={
        isVisible
          ? "filters-page-house-card-alert-note"
          : "filters-page-house-card-alert-note-disabled"
      }
      style={{ width: width }}
    >
      <div className="filters-page-house-card-alert-note-wrapper">
        <div
          className="filters-page-house-card-alert-note-close"
          onClick={closeIconHandle}
        >
          <CloseIcon color={colors.gray} />
        </div>
        <div className="filters-page-house-card-alert-note-content">
          <div>
            <span className="filters-page-house-card-alert-note-content-paragraph">
              _
            </span>
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertNote;
