import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  isLoading: false,
  error: "",
};

export const mapCoordimatesSlice = createSlice({
  name: "houseCards",
  initialState,
  reducers: {
    mapCoordimatesFetching(state) {
      state.isLoading = true;
      state.error = "";
    },
    updateMapCoordimatesSuccess(state, actions) {
      state.isLoading = false;
      state.error = "";
      state.data = actions.payload;
    },
    mapCoordimatesFetchingError(state, actions) {
      state.isLoading = false;
      state.error = actions.payload;
    },
    mapCoordimatesReset(state) {
      state.isLoading = false;
      state.error = "";
      state.data = null;
    },
  },
});

export default mapCoordimatesSlice.reducer;
