import {
    createSlice
} from "@reduxjs/toolkit";

const initialState = {
    data: null,
    isLoading: false,
    error: "",
};

export const houseCardsMapSlice = createSlice({
    name: "houseCardsMap",
    initialState,
    reducers: {
        houseCardsMapFetching(state) {
            state.isLoading = true;
            state.error = "";
        },
        setIsLoading(state, actions) {
            state.isLoading = actions.payload;
        },
        houseCardsMapFetchingSuccess(state, actions) {
            state.isLoading = false;
            state.error = "";
            state.data = actions.payload;
        },
        houseCardsMapFetchingError(state, actions) {
            state.isLoading = false;
            state.error = actions.payload;
        },
    },
});

export default houseCardsMapSlice.reducer;