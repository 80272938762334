import { filterLocationListsSlice } from "../reducers/filterLocationListsSlice";
import axios from "axios";

export const getFilterLocationPostal =
  (filters = [], searchValue = "") =>
  async (dispatch) => {
    dispatch(filterLocationListsSlice.actions.filterLocationFetching());
    axios
      .post(
        `https://api.realtytexas.net/v1/zip-codes/cognitive-search/${searchValue}`,
        {
          top: 3000,
          count: "true",
          maxPrice: 50000000,
          minPrice: 0,
          filters: filters,
          select: null,
          page: 1,
          orderby: "ZipCode asc",
          ["elastic-search"]: true,
          ["elastic-search-fields"]: "ZipCode",
        }
      )
      .then(function (response) {
        const temp = [...response.data.data.zipcodes];
        const res = temp.map((c) => {
          c.label = c["ZipCode"];
          c.value = c["ZipCode"];
          return c;
        });
        dispatch(
          filterLocationListsSlice.actions.getFilterLocationPostalSuccess(res)
        );
      })
      .catch(function (error) {
        console.log(error, "POST ERROR");
        dispatch(
          filterLocationListsSlice.actions.filterLocationPostalFetchingError(
            error
          )
        );
      });
  };
