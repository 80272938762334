/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "../../../constants/colors.js";
import {
  CheckBoxActiveIcon,
  CheckBoxIcon,
  CloseIcon,
  CollapseIcon,
  ExpandIcon,
} from "../../../constants/icons.jsx";
import { isUserLogin } from "../../../constants/methods.js";
import AlertMessage from "../../AlertMessage/alertMessage.jsx";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "./status.css";
import { useLocation } from "react-router-dom";

const Status = ({
  filters,
  setFilters,
  isCommingSoon,
  setIsCommingSoon,
  isActive,
  setIsActive,
  isActiveUnderContract,
  setIsActiveUnderContract,
  isPending,
  setIsPending,
  isClosed,
  setIsClosed,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });

  const isUserLogined = isUserLogin();

  const datePickerFromRef = useRef(null);
  const datePickerToRef = useRef(null);

  const flatpickrInstanceTo = useRef(null);
  const flatpickrInstanceFrom = useRef(null);

  const ourListingsHandler = () => {
    setIsCommingSoon(!isCommingSoon);
    setIsClosed(false);
  };

  const openIsCommingSoonHandler = () => {
    setIsActive(!isActive);
    setIsClosed(false);
  };

  const isActiveUnderContractHandler = () => {
    setIsActiveUnderContract(!isActiveUnderContract);
    setIsClosed(false);
  };

  const isPendingHandler = () => {
    setIsPending(!isPending);
    setIsClosed(false);
  };

  const isClosedHandler = () => {
    setIsCommingSoon(false);
    setIsActive(false);
    setIsActiveUnderContract(false);
    setIsPending(false);
    setIsClosed(true);
    setIsClicked(true);
  };

  const [isClicked, setIsClicked] = useState(false);
  const [isAlertMessage, setIsAlertMessage] = useState("Login First Please!");

  const closeSuccessHandler = () => {
    // setIsClicked(false);
    setIsUserLoginedClicked(false);
  };

  const [isExpanded, setIsExpanded] = useState(true);
  const [isUserLoginedClicked, setIsUserLoginedClicked] = useState(false);

  const onTittleHandle = () => {
    setIsExpanded(!isExpanded);
  };

  // const [dateFrom, setDateFrom] = useState(null);
  // const [dateTo, setDateTo] = useState(null);
  const [datePrevTo, setDatePrevTo] = useState(null);

  const handleDateFromChange = (selectedDates) => {
    setDateFrom(toIsoFormat(new Date(selectedDates[0])));
    if (dateTo) {
      let dateToDays = new Date(dateTo);
      let dateFromDays = new Date(selectedDates[0]);
      let dateDiff = Math.floor(
        (dateFromDays - dateToDays) / (1000 * 60 * 60 * 24)
      );
      if (dateDiff < 0) dateDiff = -1 * dateDiff;
      if (dateDiff > 180) {
        setDateTo(toIsoFormat(new Date(selectedDates[0]).fp_incr(180)));
      }
      if (dateDiff <= 180 && dateToDays < dateFromDays) {
        setDateTo(toIsoFormat(new Date(selectedDates[0])));
        setDateFrom(toIsoFormat(new Date(dateTo)));
      }
    }
  };

  const handleDateToChange = (selectedDates) => {
    setDateTo(toIsoFormat(new Date(selectedDates[0])));
    if (dateFrom) {
      let dateToDays = new Date(selectedDates[0]);
      let dateFromDays = new Date(dateFrom);
      let dateDiff = Math.floor(
        (dateFromDays - dateToDays) / (1000 * 60 * 60 * 24)
      );
      if (dateDiff < 0) dateDiff = -1 * dateDiff;
      if (dateDiff > 180) {
        setDateFrom(toIsoFormat(new Date(selectedDates[0]).fp_incr(-180)));
      }
      if (dateDiff <= 180 && dateToDays < dateFromDays) {
        setDateTo(toIsoFormat(new Date(dateFrom)));
        setDateFrom(toIsoFormat(new Date(selectedDates[0])));
      }
      // console.log(dateDiff, "<---from differense to---> 55555");
    }
  };

  const toIsoFormat = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    if (queryParamsObject?.CloseDateTo) {
      setDateTo(queryParamsObject?.CloseDateTo);
    } else {
      setDateTo(toIsoFormat(currentDate));
    }
    if (queryParamsObject?.CloseDateFrom) {
      setDateFrom(queryParamsObject?.CloseDateFrom);
    } else {
      setDateFrom(toIsoFormat(new Date(formattedDate).fp_incr(-180)));
    }
  }, []);

  // useEffect(() => {
  //   if (dateFrom && dateTo) {
  //     const obj = {
  //       from: dateFrom,
  //       to: dateTo,
  //     };
  //     console.log(obj, "<---from differense to--->");
  //   }
  // }, [dateFrom, dateTo]);

  useEffect(() => {
    if (isUserLoginedClicked) {
      document.dispatchEvent(new Event("RTUnlockPremiumFeatures"));
      setIsUserLoginedClicked(false);
    }
  }, [isUserLoginedClicked]);

  return (
    <>
      {/* {isUserLoginedClicked && (
        <AlertMessage
          header="Error!"
          content={isAlertMessage}
          onClick={closeSuccessHandler}
        />
      )} */}

      <div
        id="filters-block-filter-status"
        className={isExpanded ? "" : "collapsed-filter-status"}
        style={{ height: isClosed ? 352 : 272 }}
      >
        <div id="filters-block-filter-status-title" onClick={onTittleHandle}>
          <div>
            <h4>Status</h4>
          </div>
          <div className="filters-block-filter-status-title-icon">
            {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
          </div>
        </div>
        <div
          id="filters-block-filter-status-content"
          className={
            isExpanded
              ? "filters-block-filter-status-content-wrapper"
              : "filters-block-filter-status-content-wrapper collapsed-filter-status-content"
          }
        >
          <div className="filters-block-filter-status-content-checkbox">
            <div
              id="filters-block-filter-status-content-checkbox-1"
              onClick={ourListingsHandler}
            >
              {isCommingSoon ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
            </div>
            <div>
              <p
                style={{
                  color: isCommingSoon ? colors.blue : colors.secondBg,
                }}
              >
                Coming Soon
              </p>
            </div>
          </div>
          <div className="filters-block-filter-status-content-checkbox">
            <div
              id="filters-block-filter-status-content-checkbox-2"
              onClick={openIsCommingSoonHandler}
            >
              {isActive ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
            </div>
            <div>
              <p
                style={{
                  color: isActive ? colors.blue : colors.secondBg,
                }}
              >
                Active
              </p>
            </div>
          </div>
          <div className="filters-block-filter-status-content-checkbox">
            <div
              id="filters-block-filter-status-content-checkbox-3"
              onClick={isActiveUnderContractHandler}
            >
              {isActiveUnderContract ? (
                <CheckBoxActiveIcon />
              ) : (
                <CheckBoxIcon />
              )}
            </div>
            <div>
              <p
                style={{
                  color: isActiveUnderContract ? colors.blue : colors.secondBg,
                }}
              >
                Active Under Contract
              </p>
            </div>
          </div>
          <div className="filters-block-filter-status-content-checkbox">
            <div
              id="filters-block-filter-status-content-checkbox-4"
              onClick={isPendingHandler}
            >
              {isPending ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
            </div>
            <div>
              <p
                style={{
                  color: isPending ? colors.blue : colors.secondBg,
                }}
              >
                Pending
              </p>
            </div>
          </div>
          <div className="filters-block-filter-status-content-checkbox-closed">
            <div className="filters-block-filter-status-content-checkbox">
              <div
                id="filters-block-filter-status-content-checkbox-5"
                onClick={
                  isUserLogined //TODO Closed Status logged in
                    ? () => isClosedHandler()
                    : () => setIsUserLoginedClicked(true)
                }
                // onClick={() => isClosedHandler()}
              >
                {isClosed ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
              </div>
              <div>
                <p
                  style={{
                    color: isClosed ? colors.blue : colors.secondBg,
                  }}
                >
                  Closed
                </p>
              </div>
            </div>
            {isClosed && (
              <div className="filters-block-filter-status-content-checkbox-closed-DatePicker-wrapper">
                <DatePicker
                  options={{
                    dateFormat: "m-d-Y",
                    // minDate: dateTo ? new Date(dateTo).fp_incr(-180) : null,
                    // maxDate: dateTo ? new Date(dateTo).fp_incr(90) : null,
                    // maxDate: dateTo ? new Date(dateTo) : null,
                  }}
                  onChange={handleDateFromChange}
                  datePickerRef={datePickerFromRef}
                  title="From"
                  addClass={"DatePicker-block-close-from-wrapper"}
                  flatpickrInstance={flatpickrInstanceFrom}
                  initialValue={dateFrom ? new Date(dateFrom) : null}
                  data={dateTo}
                  currentData={dateFrom}
                />

                <DatePicker
                  options={{
                    dateFormat: "m-d-Y",
                    // minDate: dateFrom ? new Date(dateFrom) : null,
                    // minDate: dateFrom ? new Date(dateFrom).fp_incr(-90) : null,
                    // maxDate: dateFrom ? new Date(dateFrom).fp_incr(180) : null,
                  }}
                  onChange={handleDateToChange}
                  datePickerRef={datePickerToRef}
                  title="To"
                  addClass={"DatePicker-block-close-to-wrapper"}
                  flatpickrInstance={flatpickrInstanceTo}
                  initialValue={dateTo ? new Date(dateTo) : null}
                  data={dateFrom}
                  currentData={dateTo}
                  incPlaceholder={true}
                  // disabled={dateFrom ? false : true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Status;

const DatePicker = ({
  options,
  onChange,
  datePickerRef,
  title,
  disabled,
  addClass,
  flatpickrInstance,
  initialValue,
  data,
  currentData,
  incPlaceholder = false,
}) => {
  useEffect(() => {
    // Initialize flatpickr on the input element
    flatpickrInstance.current = flatpickr(datePickerRef.current, {
      ...options,
      onChange: onChange, // Pass the onChange handler if provided
    });

    // Set initial value if provided
    if (initialValue) {
      flatpickrInstance.current.setDate(initialValue);
    }

    // Cleanup function to destroy flatpickr instance on component unmount
    return () => {
      flatpickrInstance.current.destroy();
    };
  }, [options, onChange, initialValue]);

  const clearDate = () => {
    if (flatpickrInstance.current) {
      flatpickrInstance.current.clear();
    }
  };

  function getFormattedDate(d) {
    let year = d.getFullYear();

    let month = (1 + d.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = d.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "-" + day + "-" + year;
  }

  const [initPlaceHolder, setInitPlaceHolder] = useState(null);

  useEffect(() => {
    if (data && !currentData) {
      setInitPlaceHolder(
        getFormattedDate(new Date(data).fp_incr(incPlaceholder ? 180 : -180))
      );
    }
    if (!data && !currentData) {
      setInitPlaceHolder("");
    }
  }, [data, currentData]);

  return (
    <div
      className={`filters-block-filter-status-content-checkbox-closed-DatePicker-block  ${addClass}`}
    >
      <div className="filters-block-filter-status-content-checkbox-closed-DatePicker-title">
        <p>{title}</p>
      </div>

      <input
        ref={datePickerRef}
        type="text"
        // placeholder={initPlaceHolder ? initPlaceHolder : title}
        disabled={disabled}
      />
      {/* <div
        className="filters-block-filter-status-content-checkbox-closed-DatePicker-block-close"
        onClick={clearDate}
      >
        <CloseIcon height={14} width={14} color={colors.mainBg} />
      </div> */}
    </div>
  );
};
