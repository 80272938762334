import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useScreenSize } from "../../hooks";
import { setIsMap } from "../../store/actions/setIsMap";
import "./tripleSwitcherSliderButton.css";

const labels = {
  left: {
    title: "Gallery",
    value: "left",
  },
  center: {
    title: "Map",
    value: "center",
  },
  right: {
    title: "Both",
    value: "right",
  },
};

const TripleSwitcherSliderButton = ({ getSwitchAnimation, state }) => {
  const screenSize = useScreenSize();

  const isMap = useSelector((state) => state.isMap);

  const [initDate, setInitDate] = useState("");
  const [initDateLoaded, setInitDateLoaded] = useState(false);

  useEffect(() => {
    if (initDateLoaded) {
      setInitDate(isMap?.data);
    } else {
      setInitDateLoaded(true);
      if (window.innerWidth > 1199) {
        setInitDate(false);
      } else {
        setInitDate(null);
      }
    }
  }, [isMap?.data]);

  return (
    <div className="main-container-wrapper">
      <div className="main-container">
        <div
          className={`switch ${state.animation} ${state.switchPosition}-position`}
        ></div>
        <input
          defaultChecked={initDate ? true : false}
          onChange={(e) => {
            getSwitchAnimation(e.target.value);
          }}
          name="map-switch"
          id="left"
          type="radio"
          value="left"
          checked={isMap?.data === false ? true : false}
        />
        <label
          className={`left-label ${
            state.switchPosition === "left" && "black-font"
          }`}
          htmlFor="left"
        >
          <p>{labels.left.title}</p>
        </label>

        <input
          onChange={(e) => getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="center"
          type="radio"
          value="center"
          checked={isMap?.data === true ? true : false}
        />
        <label
          className={`center-label ${
            state.switchPosition === "center" && "black-font"
          }`}
          htmlFor="center"
        >
          <p>{labels.center.title}</p>
        </label>

        {screenSize[0] > 1079 && (
          <>
            <input
              defaultChecked={initDate ? true : false}
              onChange={(e) => getSwitchAnimation(e.target.value)}
              name="map-switch"
              id="right"
              type="radio"
              value="right"
              checked={isMap?.data === null ? true : false}
            />
            <label
              className={`right-label ${
                state.switchPosition === "right" && "black-font"
              }`}
              htmlFor="right"
            >
              <p>{labels.right.title}</p>
            </label>
          </>
        )}
      </div>
    </div>
  );
};
export default TripleSwitcherSliderButton;
