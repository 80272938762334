import React, { useEffect } from "react";
import BaseInputList from "../../ui/inputs/BaseInputList/BaseInputList.jsx";
import "./sortingInputBlock.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllHouseCardsAction } from "../../store/actions/getAllHouseCards.js";
import { isUserLogin, toObjFilterConverter } from "../../constants/methods.js";
import { getAllHouseCardsMapAction } from "../../store/actions/getAllHouseCardsMap.js";
import { getAllBothViewMapHouseCards } from "../../store/actions/getAllBothViewMapHouseCards.js";
import axios from "axios";

const mokedList = [
  {
    label: "Sort By Default",
    value: "rtlist desc, ListingContractDate desc, ListPrice desc",
    orderBySort: "",
  },
  { label: "Recently Listed", value: "DOM", orderBySort: "desc" },
  { label: "Price Hight", value: "ListPrice", orderBySort: "desc" },
  { label: "Price Low", value: "ListPrice", orderBySort: "asc" },
  { label: "Max Beds", value: "BedroomsTotal", orderBySort: "desc" },
  { label: "Min Beds", value: "BedroomsTotal", orderBySort: "asc" },
  {
    label: "Largest Home",
    value: "AboveGradeFinishedArea",
    orderBySort: "desc",
  },
  {
    label: "Smallest Home",
    value: "AboveGradeFinishedArea",
    orderBySort: "asc",
  },
  { label: "Newest Built", value: "YearBuilt", orderBySort: "desc" },
  { label: "Oldest Built", value: "YearBuilt", orderBySort: "asc" },
];

const SortingInputBlock = ({ sortingValue, setSortingValue }) => {
  return (
    <form id="filters-page-sorting-input-block">
      <BaseInputList
        initValue
        blueList={true}
        readonly={true}
        list={mokedList}
        setInputValue={setSortingValue}
        inputValue={sortingValue}
        field="label"
        className={`${
          sortingValue !== ""
            ? "filters-block-filter-details-input-list-block-input-blue-color"
            : "filters-block-filter-details-input-list-block-input-gray-color"
        }`}
      />
    </form>
  );
};

export default SortingInputBlock;
