import { houseCardsSlice } from "../reducers/houseCardsSlice";
import axios from "axios";
import { isUserLogin } from "../../constants/methods";
import { houseCardsMapSlice } from "../reducers/houseCardsMapSlice";

export const getAllHouseCardsAction =
  (page = 1, filters = null, orderby = null) =>
  async (dispatch) => {
    dispatch(houseCardsSlice.actions.houseCardsFetching());
    const isUserLogined = isUserLogin();
    const minPrice = filters.filter(
      (item) => item.column === "ListPrice" && item.operator === "min"
    )[0];
    const maxPrice = filters.filter(
      (item) => item.column === "ListPrice" && item.operator === "max"
    )[0];
    let k = filters.filter((f) => f.column === "CloseDate");
    // t = k?.value?.filter((v) => v === "Closed")[0];
    if (k?.length > 0) {
      axios
        .post(`https://api.realtytexas.net/v2/listings/sold`, {
          top: 30,
          count: "true",
          maxPrice: maxPrice ? maxPrice.value : 50000000,
          minPrice: minPrice ? minPrice.value : 0,
          filters: [
            ...filters,
            // {
            //   column: "rtlist",
            //   operator: "eq",
            //   value: "true",
            // },
          ],
          select: ["ListingKeyNumeric", "PropertyType"],
          page: page,
          // page: 3336,
          orderby: "rtlist desc, ListingContractDate desc, ListPrice desc",
        })
        .then(function (response) {
          dispatch(
            houseCardsSlice.actions.houseCardsFetchingSuccess(
              response.data.data
            )
          );
          dispatch(
            houseCardsMapSlice.actions.houseCardsMapFetchingSuccess(
              response.data.data
            )
          );
          console.log(response.data.data, "response.data.data");
        })
        .catch(function (error) {
          console.log(error, "all cards error");
          dispatch(houseCardsSlice.actions.houseCardsFetchingError(error));
        });
    } else {
      axios
        .post(`https://api.realtytexas.net/v2/listings`, {
          top: 30,
          count: "true",
          maxPrice: maxPrice ? maxPrice.value : 50000000,
          minPrice: minPrice ? minPrice.value : 0,
          filters: filters,
          select: null,
          // page: 3334,
          page: page,
          orderby: orderby
            ? `rtlist desc,${orderby}`
            : "rtlist desc, ListingContractDate desc, ListPrice desc",
          client_hash_id: isUserLogined ? isUserLogined : null,
        })
        .then(function (response) {
          // console.log(response.data.data, "response.data.data");
          dispatch(
            houseCardsSlice.actions.houseCardsFetchingSuccess(
              response.data.data
            )
          );
        })
        .catch(function (error) {
          console.log(error, "all cards error");
          dispatch(houseCardsSlice.actions.houseCardsFetchingError(error));
        });
    }
  };
