import React from "react";
import { colors } from "../../constants/colors";
import { SMSIcon, WhatsappIcon, EmailIcon } from "../../constants/icons";
import "./shareIconComponent.css";

const ShareIcon = ({ type, url, onClick }) => {
  const shareViaSMS = (url) => {
    const encodedUrl = encodeURIComponent(url);
    const smsLink = `sms:?&body=Check this property: ${encodedUrl}`;
    window.location.href = smsLink;
  };

  const shareViaWhatsApp = (url) => {
    // const currentUrl = window.location.href;
    const encodedUrl = encodeURIComponent(url);
    const whatsappLink = `https://api.whatsapp.com/send?text=${encodedUrl}`;
    window.location.href = whatsappLink;
  };

  const shareViaEmail = (url) => {
    const currentUrl = url;
    const emailSubject = encodeURIComponent("Check this property");
    const emailBody = encodeURIComponent(
      `Check out this property: ${currentUrl}`
    );
    const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
    window.location.href = emailLink;
  };

  const sharehandler = () => {
    switch (type) {
      case "sms":
        shareViaSMS(url);
        break;
      case "whatsapp":
        shareViaWhatsApp(url);
        break;
      case "whastapp":
      default:
        shareViaEmail(url);
        break;
    }
  };

  const getIcon = () => {
    switch (type) {
      case "sms":
        return <SMSIcon color={colors.blue} />;
      case "whatsapp":
        return <WhatsappIcon color={colors.green} />;
      case "email":
      default:
        return <EmailIcon color={colors.red} />;
    }
  };

  return (
    <div className="rt-share-icon" onClick={onClick}>
      <button className="rt-share-icon-btn" onClick={sharehandler}>
        {getIcon()}
      </button>
    </div>
  );
};

export default ShareIcon;
