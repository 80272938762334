/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { colors } from "../../../constants/colors.js";
import {
  CheckBoxActiveIcon,
  CheckBoxIcon,
  CollapseIcon,
  ExpandIcon,
} from "../../../constants/icons.jsx";
import "./timeframe.css";

const Timeframe = ({
  filters,
  setFilters,
  oneDay,
  setOneDay,
  sevenDay,
  setSevenDay,
  thirtyDay,
  setThirtyDay,
}) => {
  const oneDayHandler = () => {
    setOneDay(!oneDay);
    // setIsNoPreference(false);
    setSevenDay(false);
    setThirtyDay(false);
  };

  const sevenDayHandler = () => {
    setSevenDay(!sevenDay);
    setOneDay(false);
    // setIsNoPreference(false);
    setThirtyDay(false);
  };

  const thirtyDayHandler = () => {
    setThirtyDay(!thirtyDay);
    setSevenDay(false);
    setOneDay(false);
    // setIsNoPreference(false);
  };

  const [isExpanded, setIsExpanded] = useState(true);

  const onTittleHandle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      id="filters-block-filter-timeframe"
      className={isExpanded ? "" : "collapsed-filter-timeframe"}
    >
      <div id="filters-block-filter-timeframe-title" onClick={onTittleHandle}>
        <div>
          <h4>Timeframe</h4>
        </div>
        <div className="filters-block-filter-timeframe-title-icon">
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </div>
      </div>
      <div
        id="filters-block-filter-timeframe-content"
        className={
          isExpanded
            ? "filters-block-filter-timeframe-content-wrapper"
            : "filters-block-filter-timeframe-content collapsed-filter-timeframe-content"
        }
      >
        {/* <div className="filters-block-filter-timeframe-content-checkbox">
          <div
            id="filters-block-filter-timeframe-content-checkbox-1"
            onClick={noPreferenceHandler}
          >
            {isNoPreference ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
          </div>
          <div>
            <p>No Preference</p>
          </div>
        </div> */}
        <div className="filters-block-filter-timeframe-content-checkbox">
          <div
            id="filters-block-filter-timeframe-content-checkbox-2"
            onClick={oneDayHandler}
          >
            {oneDay ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
          </div>
          <div>
            <p
              style={{
                color: oneDay ? colors.blue : colors.secondBg,
              }}
            >
              Listed Today
            </p>
          </div>
        </div>
        <div className="filters-block-filter-timeframe-content-checkbox">
          <div
            id="filters-block-filter-timeframe-content-checkbox-2"
            onClick={sevenDayHandler}
          >
            {sevenDay ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
          </div>
          <div>
            <p
              style={{
                color: sevenDay ? colors.blue : colors.secondBg,
              }}
            >
              List this Week
            </p>
          </div>
        </div>
        <div className="filters-block-filter-timeframe-content-checkbox">
          <div
            id="filters-block-filter-timeframe-content-checkbox-2"
            onClick={thirtyDayHandler}
          >
            {thirtyDay ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
          </div>
          <div>
            <p
              style={{
                color: thirtyDay ? colors.blue : colors.secondBg,
              }}
            >
              Listed this Month
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeframe;
