import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../../constants/colors";
import { CloseIcon, LocationIcon } from "../../../constants/icons";
import { getFilterLocationCities } from "../../../store/actions/getFilterLocationCities";
import { getFilterLocationPostal } from "../../../store/actions/getFilterLocationPostal";
import { BaseMultipleActiveInput, HeaderMultipleInput } from "../../../ui";
import BaseMultipleActiveInput2v from "../../../ui/inputs/BaseMultipleActiveInput/BaseMultipleActiveInput2v";
import "./HeaderLayerLocation.css";

const HeaderLayerLocation = ({
  filterLocationCounties,
  foundCountry,
  setFoundCountry,
  metro,
  city,
  setCity,
  foundCity,
  setFoundCity,
  country,
  setCountry,
  twCities,
  setTwCitiesPage,
  twCitiesPage,
  applyHandler,
  twPostals,
  setTwPostals,
  postal,
  setPostal,
  foundPostal,
  setFoundPostal,
  setTwPostalsPage,
  isPopUpShowLoc,
  setIsPopUpShowLoc,
  setIsPopUpShowType,
  setIsPopUpShowSpecs,
}) => {
  const [applied, setApplied] = useState(null);
  const { isOpenNarrowFilters } = useSelector((state) => state.isResetFilters);

  const headerLayerLocationHandler = () => {
    setIsPopUpShowLoc(true);
    setIsPopUpShowType(false);
    setIsPopUpShowSpecs(false);
  };

  const headerLayerPopupHandler = () => {
    setIsPopUpShowLoc(false);
  };

  useEffect(() => {
    let counter = null;
    if (country?.length > 0) {
      counter++;
    }
    if (city?.length > 0) {
      counter++;
    }
    if (postal?.length > 0) {
      counter++;
    }
    setApplied(counter);
  }, [isPopUpShowLoc, isOpenNarrowFilters]);

  return (
    <div className="HeaderLayerLocation-wrapper">
      <div
        className="HeaderLayerLocation-wrapper-content"
        onClick={headerLayerLocationHandler}
      >
        <div className="HeaderLayerLocation-wrapper-content-title">Area</div>
        <div className="HeaderLayerLocation-wrapper-content-title-icon">
          <div>
            <LocationIcon />
          </div>
        </div>
        {applied && (
          <div className="HeaderLayerLocation-wrapper-content-box">
            <div>{applied}</div>
          </div>
        )}
        {/* <div className="HeaderLayerLocation-wrapper-content-box">
          {applied ? (
            `${applied} Applied`
          ) : (
            <span className="HeaderLayerLocation-wrapper-content-box-none">
              None
            </span>
          )}
        </div> */}
      </div>
      {isPopUpShowLoc && (
        <LocationPopup
          headerLayerPopupHandler={headerLayerPopupHandler}
          filterLocationCounties={filterLocationCounties}
          foundCountry={foundCountry}
          setFoundCountry={setFoundCountry}
          metro={metro}
          city={city}
          setCity={setCity}
          foundCity={foundCity}
          setFoundCity={setFoundCity}
          country={country}
          setCountry={setCountry}
          twCities={twCities}
          setTwCitiesPage={setTwCitiesPage}
          twCitiesPage={twCitiesPage}
          applyHandler={applyHandler}
          twPostals={twPostals}
          setTwPostals={setTwPostals}
          postal={postal}
          setPostal={setPostal}
          foundPostal={foundPostal}
          setFoundPostal={setFoundPostal}
          setTwPostalsPage={setTwPostalsPage}
        />
      )}
    </div>
  );
};

export default HeaderLayerLocation;

const LocationPopup = ({
  headerLayerPopupHandler,
  foundCountry,
  setFoundCountry,
  metro,
  city,
  setCity,
  foundCity,
  setFoundCity,
  country,
  setCountry,
  twCities,
  setTwCitiesPage,
  twCitiesPage,
  applyHandler,
  filterLocationCounties,
  twPostals,
  setTwPostals,
  postal,
  setPostal,
  foundPostal,
  setFoundPostal,
  setTwPostalsPage,
}) => {
  const resetLocationHandler = () => {
    setCountry([]);
    setCity([]);
    setPostal([]);
  };

  return (
    <div className="LocationPopup-wrapper">
      <div className="LocationPopup-wrapper-box">
        <div className="LocationPopup-wrapper-header-wrapper">
          Location Filter
        </div>
        <div
          className="LocationPopup-wrapper-header-close"
          onClick={headerLayerPopupHandler}
        >
          <CloseIcon color={colors.mainBg} />
        </div>
        <div className="LocationPopup-wrapper-header-content-block">
          <LocationPopupCounty
            foundCountry={foundCountry}
            setFoundCountry={setFoundCountry}
            metro={metro}
            country={country}
            setCountry={setCountry}
            filterLocationCounties={filterLocationCounties}
          />
          <LocationPopupCity
            city={city}
            setCity={setCity}
            foundCity={foundCity}
            setFoundCity={setFoundCity}
            country={country}
            twCities={twCities}
            setTwCitiesPage={setTwCitiesPage}
            twCitiesPage={twCitiesPage}
            applyHandler={applyHandler}
          />
          <LocationPopupZip
            city={city}
            twPostals={twPostals}
            setTwPostals={setTwPostals}
            postal={postal}
            setPostal={setPostal}
            foundPostal={foundPostal}
            setFoundPostal={setFoundPostal}
            setTwPostalsPage={setTwPostalsPage}
          />
        </div>
        <div className="LocationPopup-wrapper-header-btns-block">
          <LocationReset
            resetLocationHandler={resetLocationHandler}
            headerLayerPopupHandler={headerLayerPopupHandler}
          />
          <LocationApply
            applyHandler={applyHandler}
            headerLayerPopupHandler={headerLayerPopupHandler}
          />
        </div>
      </div>
    </div>
  );
};

const LocationPopupCounty = ({
  foundCountry,
  setFoundCountry,
  metro,
  country,
  setCountry,
  filterLocationCounties,
}) => {
  const { data, isLoading } = useSelector((state) => state.filterLocationLists);

  return (
    <div className="filters-block-filter-filter-location-content">
      <div>
        <p>County</p>
      </div>
      <div className="filters-block-filter-filter-location-address-input-multiple">
        <BaseMultipleActiveInput
          name="county"
          list={data.countries}
          placeholder="Select County"
          listTop="48px"
          item={country}
          setItem={setCountry}
          found={
            country
              ? foundCountry.filter((obj1) => {
                  if (country) {
                    return !country.some((obj2) => obj1 === obj2);
                  } else {
                    return true;
                  }
                })
              : foundCountry
          }
          setFound={setFoundCountry}
          field="County"
          height={50}
          parrentValue={metro}
          parrentName="Metro"
          callback={filterLocationCounties}
          isLoading={isLoading}
          autocomplete="rutjfkdeaa"
        />
      </div>
      {country !== null && country && country?.length !== 0 && (
        <div className="filters-block-filter-filter-location-address-input-tooltip">
          <p>Click 'Apply' to confirm your changes.</p>
        </div>
      )}
    </div>
  );
};

const LocationPopupCity = ({
  city,
  setCity,
  foundCity,
  setFoundCity,
  country,
  twCities,
  setTwCitiesPage,
  twCitiesPage,
  applyHandler,
}) => {
  const { data, isLoading } = useSelector((state) => state.filterLocationLists);
  const dispatch = useDispatch();
  const [cityInputOpened, setCityInputOpened] = useState(false);
  const [isSearchCity, setIsSearchCity] = useState(false);

  const observerList = (name, callback) => {
    const lastItem = document.querySelector(
      `.LocationPopupCity-active-input-list-wrapper-li-${name}:last-child`
    );
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // setTwCitiesPage((twCitiesPage) => twCitiesPage + 1);
          callback((c) => c + 1);
          observer.disconnect();
        }
      },
      { threshold: 0 }
    );
    observer.observe(lastItem);
  };

  // useEffect(() => {
  //   if (!isSearchCity) {
  //     const lastItem = document.querySelector(
  //       ".LocationPopupCity-active-input-list-wrapper-li-cityList"
  //     );
  //     if (lastItem && twCities?.length > 0)
  //       observerList("cityList", setTwCitiesPage);
  //   }
  // }, [twCities.length, cityInputOpened, isSearchCity]);

  const filterLocationCities = (value) => {
    let countyCodes;
    if (country[0] && country[0] !== "") {
      countyCodes = country.map((c) => +c.CountyCode);
    } else countyCodes = [];
    dispatch(getFilterLocationCities(countyCodes, value));
  };

  return (
    <div className="filters-block-filter-filter-location-content">
      <div>
        <p>City</p>
      </div>
      <div className="filters-block-filter-filter-location-address-input-multiple">
        <BaseMultipleActiveInput2v
          name="c17y"
          list={
            (data?.cities && country && country[0] && country[0] !== "") ||
            isSearchCity
              ? data?.cities
              : // : filterLocationData?.cities || twCities
                twCities
          }
          // list2={filterLocationData?.cities || twCities}
          list2={twCities}
          placeholder={
            country && country?.length > 0
              ? "Select"
              : "Type city to see results"
          }
          listTop="48px"
          item={city}
          setItem={setCity}
          found={
            city
              ? foundCity?.filter((obj1) => {
                  if (city) {
                    return !city.some((obj2) => {
                      return obj1.subdomain === obj2.subdomain;
                    });
                  } else {
                    return true;
                  }
                })
              : foundCity
          }
          setFound={setFoundCity}
          field="City"
          height={50}
          parrentValue={country}
          parrentName="County"
          // parrentValue2={postal}
          // parrentName2="Postal"
          callback={
            country && country[0] && country[0] !== ""
              ? // ? filterLocationCities
                true
              : null
          }
          callback2={
            country && country[0] && country[0] !== "" ? null : true
            // : filterLocationCities
          }
          isLoading={isLoading}
          classNameLi="cityList"
          setIsOpened={setCityInputOpened}
          isOpened={cityInputOpened}
          setIsSearch={setIsSearchCity}
          LocationClassName="LocationPopupCity"
          autocomplete="rutjfkde"
        />
      </div>
      {city && city[0] && (
        <div className="filters-block-filter-filter-location-address-input-tooltip">
          <p>Click 'Apply' to confirm your changes.</p>
        </div>
      )}
    </div>
  );
};

const LocationPopupZip = ({
  city,
  twPostals,
  setTwPostals,
  postal,
  setPostal,
  foundPostal,
  setFoundPostal,
  setTwPostalsPage,
}) => {
  const { data, isLoading } = useSelector((state) => state.filterLocationLists);
  const dispatch = useDispatch();
  const [postalInputOpened, setPostalInputOpened] = useState(false);
  const [isSearchPostal, setIsSearchPostal] = useState(false);

  const observerList = (name, callback) => {
    const lastItem = document.querySelector(
      `.LocationPopupZip-active-input-list-wrapper-li-${name}:last-child`
    );
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // setTwCitiesPage((twCitiesPage) => twCitiesPage + 1);
          callback((c) => c + 1);
          observer.disconnect();
        }
      },
      { threshold: 0 }
    );
    observer.observe(lastItem);
  };

  // useEffect(() => {
  //   if (!isSearchPostal) {
  //     const lastItem = document.querySelector(
  //       ".LocationPopupZip-active-input-list-wrapper-li-postalList"
  //     );
  //     if (
  //       lastItem &&
  //       twPostals?.length > 0
  //       // && (!city[0] || city[0] === "")
  //     )
  //       observerList("postalList", setTwPostalsPage);
  //   }
  // }, [twPostals.length, postalInputOpened, isSearchPostal]);

  const filterLocationPostal = (searchValue) => {
    let filt;
    if (city[0] && city[0] !== "") {
      const temp = city.map((c) => c.value);
      filt = [{ column: "City", operator: "in", value: [...temp] }];
    } else filt = [];
    dispatch(getFilterLocationPostal(filt, searchValue));
  };

  return (
    <div className="filters-block-filter-filter-location-content">
      <div>
        <p>Postal Code</p>
      </div>
      <div className="filters-block-filter-filter-location-address-input-multiple">
        <BaseMultipleActiveInput2v
          name="p057al"
          list={
            (data.postal && city && city[0] && city[0] !== "") || isSearchPostal
              ? data.postal
              : // : filterLocationData?.zips || twPostals
                twPostals
          }
          // list2={filterLocationData?.zips || twPostals}
          list2={twPostals}
          placeholder={
            city && city?.length > 0
              ? "Select"
              : "Type postal code to see results"
          }
          listTop="48px"
          item={postal}
          setItem={setPostal}
          found={foundPostal}
          setFound={setFoundPostal}
          field="ZipCode"
          height={50}
          parrentValue={city}
          parrentName="City"
          callback={
            city && city[0] && city[0] !== "" ? filterLocationPostal : null
          }
          callback2={
            city && city[0] && city[0] !== "" ? null : filterLocationPostal
          }
          isLoading={isLoading}
          // initialArrowHide={true}
          classNameLi="postalList"
          setIsOpened={setPostalInputOpened}
          isOpened={postalInputOpened}
          setIsSearch={setIsSearchPostal}
          LocationClassName="LocationPopupZip"
          autocomplete="rutjfkdedd"
        />
      </div>
      {postal && postal[0] && (
        <div className="filters-block-filter-filter-location-address-input-tooltip">
          <p>Click 'Apply' to confirm your changes.</p>
        </div>
      )}
    </div>
  );
};

const LocationApply = ({ applyHandler, headerLayerPopupHandler }) => {
  const locationApplyHandler = () => {
    applyHandler();
    headerLayerPopupHandler();
  };

  return (
    <div className="LocationApply-wrapper" onClick={locationApplyHandler}>
      <div className="LocationApply-apply-wrapper">
        <div>Apply</div>
      </div>
    </div>
  );
};

const LocationReset = ({ headerLayerPopupHandler, resetLocationHandler }) => {
  const locationResetHandler = () => {
    resetLocationHandler();
    // headerLayerPopupHandler();
  };

  return (
    <div className="LocationReset-wrapper" onClick={locationResetHandler}>
      <div className="LocationReset-apply-wrapper">
        <div>Clear</div>
      </div>
    </div>
  );
};
