import polygonClipping from "polygon-clipping";

// Convert Google Maps polygon paths into polygon-clipping library format
export function googleToPolygonClippingFormat(googlePolygon) {
  return googlePolygon
    .getPaths()
    .getArray()
    .map((path) =>
      path.getArray().map((latLng) => [latLng.lat(), latLng.lng()])
    );
}

// Convert polygon-clipping library format into Google Maps polygon paths
export function polygonClippingToGoogleFormat(polygonClippingResult, maps) {
  return polygonClippingResult.map((path) => {
    return new maps.LatLng(path[0], path[1]);
  });
}

export function polygonClippingToGoogleFormatLatLng(
  polygonClippingResult,
  setState,
  state
) {
  return polygonClippingResult.map((path, id) => {
    return { id: id, lat: path[0], lng: path[1] };
  });
}

export const mergeIntersectingPolygons = (polygons, maps, map, colors) => {
  const intersectionResult = polygonClipping.union(polygons);
  const googleIntersectionResult = new maps.Polygon({
    paths: intersectionResult[0].map((path) =>
      polygonClippingToGoogleFormat(path, maps)
    ),
    /* Additional polygon options */
    strokeColor: colors,
    strokeOpacity: 1.0,
    strokeWeight: 2,
    map: map,
    zIndex: 4,
  });

  return googleIntersectionResult;
};
